import React, { useState } from 'react';
import { Box, Container, makeStyles } from '@material-ui/core';
import Page from 'src/components/Page';
import Toolbar from './toolbar/Toolbar';
import { useQuery } from '@apollo/client';
import Loading from 'src/components/ui/Loading/Loading';
import TransactionList from './transaction-list/TransactionList';
import gql from 'graphql-tag';


export const GET_TRANSACTIONS = gql`
    query getTransactions {
        transactions_reward_redemption(order_by: {xact_datetime_at: desc}) {
            company {
                id
                name
            }
            customer {
                id
            }
            product {
                id
                product_brand
                product_code
            }
            point_token_type {
                id
                name
            }
            transaction_type {
                id
                name
            }
            id
            xact_datetime_at
            product_quantity
            fiat_amount
            fiat_type
            coin_type
            coin_amount
            point_token_amount
        }
    }
`;


const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));


const HistoryAndTransactionsView = (props) => {
  const classes = useStyles();

  return (
    <Page
      className={classes.root}
      title='History & Transactions | Procision'
    >
      <Container maxWidth={false}>
        <Toolbar currentCompany={props.currentCompany} />
        <Box>
          <TransactionList currentCompany={props.currentCompany} data={props.data} />
        </Box>
      </Container>
    </Page>
  );
};

const GetHistoryAndTransactions = (props) => {
  const classes = useStyles();

  const { loading, error, data } = useQuery(GET_TRANSACTIONS);

  if (loading) {
    return <Loading />;
  }

  if (error) {
    return <div className={classes.error}>Error!<br /> Something went wrong</div>;
  }

  if (data) {
    return <HistoryAndTransactionsView
      data={data}
      {...props}
    />;
  }

};

export default GetHistoryAndTransactions;

