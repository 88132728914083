import React from 'react';

const Content = (props) => {
  return (
    <svg {...props} width="16" height="16" viewBox="0 0 16 16" fill="#B9C2D9" xmlns="http://www.w3.org/2000/svg">
      <g>
        <path d="M11.2067 8.66664H3.16669C2.89069 8.66664 2.66669 8.44264 2.66669 8.16664V5.16663C2.66669 4.89063 2.89069 4.66663 3.16669 4.66663H12.8333C13.1093 4.66663 13.3333 4.89063 13.3333 5.16663V6.66663C13.3333 6.94264 13.1093 7.16664 12.8333 7.16664C12.5573 7.16664 12.3333 6.94264 12.3333 6.66663V5.66663H3.66669V7.66664H11.2067C11.4827 7.66664 11.7067 7.89064 11.7067 8.16664C11.7067 8.44264 11.4827 8.66664 11.2067 8.66664Z" />
        <path d="M9.21335 10.66H3.20001C2.92401 10.66 2.70001 10.436 2.70001 10.16C2.70001 9.88403 2.92401 9.66003 3.20001 9.66003H9.21335C9.48935 9.66003 9.71335 9.88403 9.71335 10.16C9.71335 10.436 9.48935 10.66 9.21335 10.66Z" />
        <path d="M7.22668 12.66H3.20001C2.92401 12.66 2.70001 12.436 2.70001 12.16C2.70001 11.884 2.92401 11.66 3.20001 11.66H7.22668C7.50268 11.66 7.72668 11.884 7.72668 12.16C7.72668 12.436 7.50268 12.66 7.22668 12.66Z" />
        <path d="M8.3513 16.0001C8.21997 16.0001 8.09197 15.9481 7.99797 15.8534C7.88264 15.7381 7.83064 15.5741 7.8593 15.4127L8.21264 13.4094C8.23064 13.3087 8.2793 13.2154 8.3513 13.1427L13.3013 8.1934C13.512 7.98273 13.7353 7.85139 13.9653 7.80339C14.2833 7.73673 14.5913 7.8334 14.8333 8.07606L15.658 8.90073C16.1126 9.3554 16.1126 10.0954 15.658 10.5507L10.7086 15.5007C10.636 15.5734 10.5426 15.6221 10.442 15.6394L8.43864 15.9927C8.4093 15.9974 8.38064 16.0001 8.3513 16.0001ZM10.3553 15.1467H10.362H10.3553ZM9.16997 13.7387L8.96797 14.8834L10.1126 14.6814L14.9506 9.84273C15.0153 9.7774 15.0153 9.67207 14.9506 9.60673L14.132 8.78806C14.1253 8.80073 14.0753 8.83273 14.008 8.8994L9.16997 13.7387Z" />
        <path d="M6.55333 14.6667H1.83333C0.822 14.6667 0 13.8447 0 12.8333V1.83333C0 0.822 0.822 0 1.83333 0H14.1667C15.178 0 16 0.822 16 1.83333V6.69333C16 6.96933 15.776 7.19333 15.5 7.19333C15.224 7.19333 15 6.96933 15 6.69333V1.83333C15 1.374 14.626 1 14.1667 1H1.83333C1.374 1 1 1.374 1 1.83333V12.8333C1 13.2927 1.374 13.6667 1.83333 13.6667H6.55333C6.82933 13.6667 7.05333 13.8907 7.05333 14.1667C7.05333 14.4427 6.82933 14.6667 6.55333 14.6667Z" />
        <path d="M15.5 3.33337H0.5C0.224 3.33337 0 3.10937 0 2.83337C0 2.55737 0.224 2.33337 0.5 2.33337H15.5C15.776 2.33337 16 2.55737 16 2.83337C16 3.10937 15.776 3.33337 15.5 3.33337Z" />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Content;
