import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import AccountCircle from '@material-ui/icons/AccountCircle';
import { withStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import SearchIcon from '../../icons/user/SeachIcon';

const useStyles = makeStyles({
  underline: {
    '&&&:before': {
      borderBottom: 'none'
    },
    '&&:after': {
      borderBottom: 'none'
    }
  },
  input: {
    paddingTop: '7px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: '#fff',
    fontFamily:'Roboto',
    opacity:1,
  }
});

const StyledGrid = withStyles(() => ({
  root: {
    maxWidth: '600px',
    width: '100%',
    alignItems: 'center',
    border: '1px solid rgba(255, 255, 255, 0.3) ',
    height: 48,
    padding: '0 15px',
    borderRadius: 100,
    '& :first-child': {
      display: 'flex',
      alignItems: 'center'

    },
    '& :not(:first-child)': {
      display: 'flex',
      alignItems: 'center',
      width: '80%',
      marginLeft: '20px'
    }

  }


}))(Grid);

const SearchInput = () => {
  const classes = useStyles();
  return (
    <StyledGrid container alignItems='flex-end'>
      <Grid item>
        <SearchIcon />
      </Grid>
      <Grid item>
        <TextField fullWidth id='input-with-icon-grid' placeholder='What are you looking for?'
                   InputProps={{ classes }} />
      </Grid>
    </StyledGrid>
  );
};

export default SearchInput;