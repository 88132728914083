import nhost from "nhost-js-sdk";

const config = {
  base_url: process.env.REACT_APP_AUTH_BASE_URL || "https://dev.procision.co",
  use_cookies: true
};

nhost.initializeApp(config);

const auth = nhost.auth();

export { auth };
