import React, { useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Avatar,
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  makeStyles,
  Tooltip
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import Pagination from '@material-ui/lab/Pagination';
import OnRecommend from 'src/icons/content/OnRecommend';
import OffRecommend from 'src/icons/content/OffRecommend';
import Trash from 'src/icons/content/Trash';
import Edit from 'src/icons/content/Edit';
import View from 'src/icons/content/View';
import Filters from '../filters/Filters'
import ProductInput from '../update-product/ProductInput';

import { GET_All_PRODUCTS } from '..';
import { useMutation } from '@apollo/client';
import gql from 'graphql-tag';
// import UpdateUser from './update-user/RuleSetInput';


const useStyles = makeStyles((theme) => ({
  root: {
    borderRadius: '0',
    boxShadow: 'none'
  },
  icon: {
    margin: '0 5px',
    cursor: 'pointer',
    '&:hover': {
      '& $path': {
        stroke: '#00A896'
      }
    }
  }
}));
const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: 'transparent',
    fontWeight: 'bold',
    color: '#010A21',
    borderBottom: 'none',
    textAlign: 'center'
  },
  body: {
    fontSize: 14,
    textAlign: 'center'
  }
}))(TableCell);
const StyledTableBody = withStyles((theme) => ({
  root: {
    backgroundColor: '#F0F4FC',
    borderRadius: '16px',

    '& .MuiTableCell-body': {
      borderBottom: 'none',
      color: '#010A21',
      textAlign: 'center'
    },
    '& .MuiTableRow-hover': {
      '&:first-child': {
        '& .MuiTableCell-body': {
          '&:first-child': { borderTopLeftRadius: '16px' },
          '&:last-child': { borderTopRightRadius: '16px' }
        }
      },
      '&:last-child': {
        '& .MuiTableCell-body': {
          '&:first-child': { borderBottomLeftRadius: '16px' },
          '&:last-child': { borderBottomRightRadius: '16px' }
        }
      }
    },
    '& .MuiTableRow-hover:not(:last-child)': {
      borderBottom: '1px solid #D7DCE8'
    }
  }
}))(TableBody);


const DELETE_PRODUCT = gql`
    mutation deleteProductsByPk($id: uuid!) {
        delete_products_by_pk(id: $id) {
            id
        }
    }
`;


const ProductRewardList = ({ className, data, onToggle, toggleMode, mode, companies, pointTokenTypes, ...rest }) => {
  const classes = useStyles();
  const [selectedUserIds, setSelectedUserIds] = useState([]);
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(0);
  const [isUpdateProduct, setIsUpdateProduct] = useState(false);
  const [product, setProduct] = useState(null);

  const togglePopupUpdateProduct = (product) => {
    setIsUpdateProduct(!isUpdateProduct);
    if (product) {
      setProduct(product);
    }
  };

  const [deleteProduct] = useMutation(DELETE_PRODUCT, {
    refetchQueries: [{ query: GET_All_PRODUCTS }]
  });

  const StyledTooltip = withStyles({
    tooltip: {
      backgroundColor: '#010A21',
      width: '94px',
      fontFamily: 'Open sans',
      fontSize: '13px',
      display: 'block',
      lineHeight: '20px',
      borderRadius: '13px',
      padding: '12px'

    },
    arrow: {
      color: '#010A21',
      left: '15px !important'
    },
    tooltipPlacementBottom: {
      // position: 'absolute',
      left: '10px',
      bottom: '1.5rem'
      // transform: 'translate(-50%, -50%)'
    }
  })(Tooltip);
  const StyledPagination = withStyles({
    root: {
      display: 'flex',
      justifyContent: 'flex-end',
      marginTop: '16px',
      '& ul li button': {
        fontSize: '13px',
        margin: 0,
        minWidth: '25px',
        maxHeight: '25px'
      },
      '& .Mui-selected': {
        color: '#00A896',
        backgroundColor: 'transparent'
      },
      '& .Mui-selected:hover': {
        color: '#ffffff'
      }
    }
  })(Pagination);

  return (
    <>
      {!isUpdateProduct ? (
        <>
          <Filters products={data?.products} />
          <Card
            className={clsx(classes.root, className)}
            {...rest}
          >
            <PerfectScrollbar>
              <Box>
                <Table>
                  <TableHead>
                    <TableRow>
                      <StyledTableCell>
                        Point / Coin
                      </StyledTableCell>
                      <StyledTableCell>
                        Brand
                      </StyledTableCell>
                      <StyledTableCell>
                        Product
                      </StyledTableCell>
                      <StyledTableCell>
                        List Price
                      </StyledTableCell>
                      <StyledTableCell>
                        SKU / %
                      </StyledTableCell>
                      <StyledTableCell>
                        Recommend
                      </StyledTableCell>
                      <StyledTableCell>
                        G R T B
                      </StyledTableCell>
                      <StyledTableCell>
                        Actions
                      </StyledTableCell>
                    </TableRow>
                  </TableHead>

                  <StyledTableBody>
                    {data.products.slice(0, limit).map((product) => (
                      <TableRow
                        hover
                        key={product.id}
                        selected={selectedUserIds.indexOf(product.id) !== -1}
                      >
                        <TableCell>
                          {product.fiat_type}
                        </TableCell>
                        <TableCell>
                          {product.product_brand}
                        </TableCell>
                        <TableCell>
                          {product.product_description}
                        </TableCell>
                        <TableCell>
                          {product.fiat_type === 'CAD' ? product.list_price_points_tokens : product.fiat_list_price}
                        </TableCell>
                        <TableCell>
                          {product.SKU_begin}-{product.SKU_end}
                        </TableCell>
                        <StyledTableCell>
                          {product.is_active ? <OnRecommend /> : <OffRecommend />}
                        </StyledTableCell>
                        <StyledTooltip
                          title={<span>G-grant <br /> R-Redeem <br /> T-Transfer <br /> B-Burn</span>}
                          arrow
                          interactive
                          placement='bottom-end'
                        >
                          <StyledTableCell>
                            G R T B
                          </StyledTableCell>
                        </StyledTooltip>
                        <TableCell>
                          <Box alignItems='center' display='flex' justifyContent='center'>
                            <Edit className={classes.icon} onClick={(e) => {
                              toggleMode('edit');
                              togglePopupUpdateProduct(product);
                            }} />
                            <View className={classes.icon} />
                            <Trash className={classes.icon} onClick={e => {
                              deleteProduct({ variables: { id: product.id } });
                            }
                            } />
                          </Box>
                        </TableCell>
                      </TableRow>
                    ))}
                  </StyledTableBody>
                </Table>
              </Box>
            </PerfectScrollbar>

            {/*<TablePagination*/}
            {/*  component="div"*/}
            {/*  count={users.length}*/}
            {/*  onChangePage={handlePageChange}*/}
            {/*  onChangeRowsPerPage={handleLimitChange}*/}
            {/*  page={page}*/}
            {/*  rowsPerPage={null}*/}
            {/*  rowsPerPageOptions={[5, 10, 25]}*/}
            {/*/>*/}

            <StyledPagination
              defaultPage={6}
              boundaryCount={2}
              count={20}
              color='primary' />
          </Card>
        </>
      ) : (
        <ProductInput
          mode={mode}
          toggleMode={toggleMode}
          onToggle={togglePopupUpdateProduct}
          product={product}
          companies={companies}
          data={data}
          {...rest}
          pointTokenTypes={pointTokenTypes}
        />)
      }
    </>
  );
};

export default ProductRewardList;
