import React from "react";
import { Col, Layout, Row, Typography, Button } from "antd";
import { Link } from "react-router-dom";
import Page from './Page';

const { Header, Content, Footer } = Layout;
const { Text } = Typography;

const H3 = (props) => (
  <Typography.Title level={3} {...props} />
)

export const contentMinHeight = "calc(100vh - 64px - 70px)";

const TransactionLayout = ( props ) => {
  return (
    <Layout>
            <Link  style={{
            textAlign:"center",
            fontSize:'40px'
              }}
               to="/dashboard"> Transaction Failed </Link>
    <Button></Button>
    </Layout>
  );
};

export default function () {
  const title = "Transaction Stripe";
  return (
    <Page
      title="Failed"
    >
    <Layout>
       <Header
          style={{
            boxShadow: "0 2px 8px #f0f1f2",
            backgroundColor: '#010A21',
            zIndex: 1,
            paddingLeft: '20px',
            paddingRight: '20px',
            overflow: "hidden",
          }}
        >
        <Row justify="space-between">
          <Col span={6}>
            <Link to="/"><img src="/static/icons/logo.svg" style={{maxWidth: '100px'}}></img></Link>
          </Col>
          <Col span={12}>
            <H3
              style={{
                margin: 0,
                padding: 0,
                textAlign: "center",
                lineHeight: "64px",
                color: '#fff'
              }}
              data-cy="layout-header-title"
            >
                { title }
            </H3>
          </Col>
          <Col span={6} style={{ textAlign: "right" }} />
          </Row>
       </Header>
        <Content style={{ minHeight: contentMinHeight, paddingTop: '100px' }}>
          <TransactionLayout />
        </Content>
      <Footer>
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "space-between"
          }}
        >
          <Text>
            Copyright &copy; {new Date().getFullYear()} {"Aquire"}. All
            rights reserved.
          </Text>
        </div>
      </Footer>
    </Layout>
    </Page>
  )
}

