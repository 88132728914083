import React from 'react';

const Search = (props) => {
  return (
    <svg {...props} width="18" height="18" viewBox="0 0 18 18" fill="#010A21" xmlns="http://www.w3.org/2000/svg">
      <g>
        <path d="M7.92682 0C3.55609 0 0 3.55609 0 7.92682C0 12.2978 3.55609 15.8536 7.92682 15.8536C12.2978 15.8536 15.8536 12.2978 15.8536 7.92682C15.8536 3.55609 12.2978 0 7.92682 0ZM7.92682 14.3903C4.36293 14.3903 1.46341 11.4908 1.46341 7.92686C1.46341 4.36296 4.36293 1.46341 7.92682 1.46341C11.4907 1.46341 14.3902 4.36293 14.3902 7.92682C14.3902 11.4907 11.4907 14.3903 7.92682 14.3903Z"/>
        <path d="M17.7855 16.7511L13.5904 12.5559C13.3045 12.2701 12.8416 12.2701 12.5557 12.5559C12.2698 12.8416 12.2698 13.305 12.5557 13.5906L16.7508 17.7857C16.8937 17.9286 17.0809 18.0001 17.2682 18.0001C17.4552 18.0001 17.6425 17.9286 17.7855 17.7857C18.0713 17.5001 18.0713 17.0367 17.7855 16.7511Z" />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="18" height="18" fill="white"/>
        </clipPath>
      </defs>
    </svg>
  );
};

export default Search;
