import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/core';
import GlobalStyles from 'src/components/GlobalStyles';
import Loading from 'src/components/ui/Loading/Loading';
import theme from 'src/theme';
import { Switch, Route } from 'react-router-dom';
import BeltaView from 'src/views/belta';
import StorcoView from 'src/views/storco';
import EnterpriseCorpView from 'src/views/enterprisecorp';
import DAppView from 'src/views/d-app';
import RerailView from 'src/views/retailco'
import { useQuery } from '@apollo/client';
import { auth } from 'src/utils/nhost';
import { GET_CURRENT_USER, currentContext } from 'src/utils/context';
import { getAllDataOfUser } from 'src/utils/handleApi';
import { COMPANY_ID, CUSTOMER_RETAILCO_ID } from 'src/utils/constant';
import NftDetail from 'src/views/d-app/nft-detail';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    display: 'flex',
    height: '100%',
    overflow: 'hidden',
    width: '100%'
  },
  wrapper: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
    paddingTop: 90,
    paddingLeft: 290
  },
  contentContainer: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden'
  },
  content: {
    flex: '1 1 auto',
    height: '100%',
    overflow: 'auto',
    paddingLeft: 0
  },
  load: {
    marginLeft:800
  }
}));

const UserLayout = (props) => {
  const classes = useStyles();
  const [products, setProducts] = useState()
  const [users, setUsers] = useState()
  const [propsTransactionType, setPropsTransactionType] = useState()
  useEffect(() => {
    (async () => {
      const data = await getAllDataOfUser(CUSTOMER_RETAILCO_ID,COMPANY_ID.RETAILCO)

      if (data?.data?.products) {
        setProducts(data.data.products)
      }

      if (data?.data?.transactionType) {
        setPropsTransactionType(data.data.transactionType)
      }

      if (data?.data?.user) {
        setUsers(data.data.user)
      }

    })()
  }, []);
  const loadingComp = <Loading />
  const { loading, data } = useQuery(GET_CURRENT_USER, {
    variables: {
      currentUserId: auth.getClaim('x-hasura-user-id')
    }
  });

  if (loading) {
    return null;
  }

  if (data) {
    const {
        currentUser,
        currentCompany,
        isAdmin,
        isSuperAdmin
      } = currentContext(auth, data);

      return (
        <ThemeProvider theme={theme}>
          <GlobalStyles />
          <div className={classes.root}>
            <Switch>
              <Route
                exact
                path='/company/belta'
                render={(props) => (<BeltaView currentCompany={currentCompany} currentUser={currentUser} />)}
              />
              <Route
                exact
                path='/company/storco'
                render={(props) => (<StorcoView
                    isAdmin={isAdmin}
                    isSuperAdmin={isSuperAdmin}
                    currentUser={currentUser}
                    currentCompany={currentCompany}
                    loadingComp={loadingComp}
                />)}
              />
              <Route
                exact
                path='/company/enterprisecorp'
                render={(props) => (<EnterpriseCorpView
                    isAdmin={isAdmin}
                    isSuperAdmin={isSuperAdmin}
                    currentUser={currentUser}
                    currentCompany={currentCompany}
                    loadingComp={loadingComp}
                />)}
              />
              <Route
                exact
                path='/company/d-app'
                render={(props) => (<DAppView
                    isAdmin={isAdmin}
                    isSuperAdmin={isSuperAdmin}
                    currentUser={currentUser}
                    currentCompany={currentCompany}
                    loadingComp={loadingComp}
                />)}
              />
              <Route
                exact
                path='/company/d-app/:id'
                render={(props) => (<NftDetail
                  {...props}
                  currentUser={currentUser}
                  currentCompany={currentCompany}
                />)}
              />
              {products && users && propsTransactionType ?
                <Route
                  exact
                  path='/company/retailcorp'
                  render={() => (<RerailView
                    currentUser={currentUser}
                    currentCompany={currentCompany}
                    products={products}
                    users={users}
                    propsTransactionType={propsTransactionType}

                  />)}
                /> : <div className={classes.load}><Loading /></div> }

            </Switch>
          </div>
        </ThemeProvider>
      );
  }
};

export default UserLayout;
