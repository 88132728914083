import React, {useState} from 'react';
import {Link as RouterLink, useHistory, useLocation} from 'react-router-dom';
import PropTypes from 'prop-types';
import {Box, Button, Divider, Drawer, List, makeStyles} from '@material-ui/core';
import Logo from 'src/icons/Logo';
import Settings from 'src/icons/Settings';
import Collapse from 'src/icons/Collapse';
import NavItem from './NavItem';
import routes from 'src/routes/index';
import {auth} from 'src/utils/nhost';
import PerfectScrollbar from 'react-perfect-scrollbar';
import 'react-perfect-scrollbar/dist/css/styles.css';

const useStyles = makeStyles(() => ({
  mobileDrawer: {
    width: 272
  },
  scrollbarContainer: {
    '& .ps__rail-y': {
      pointerEvents: 'none',
    },
  },
  desktopDrawer: {
    width: 272,
    margin: '19px 16px',
    top: 0,
    height: 'calc(100% - 30px)',
    backgroundColor: '#010A21',
    borderRadius: '16px'
  },
  company_role: {
    color: '#ffffff',
    fontSize: '18px',
    lineHeight: '24px',
    fontFamily: 'Open Sans Regular'
  },
  button: {
    justifyContent: 'center',
    letterSpacing: 0,
    padding: '10px 8px',
    textTransform: 'none',
    width: '42px',
    height: '42px',
    minWidth: '42px',
    backgroundColor: '#022a35',
    borderRadius: '8px',
    '&:last-child': {
      backgroundColor: '#044d52',
    },
    '& $icon': {
      width: '18px',
      height: '18px'
    },
    '&:hover': {
      color: '#b5c9ca',
      backgroundColor: '#024e52',
      borderRadius: '8px',
      '& $icon': {
        fill: '#00A896'
      }
    }
  }
}));

const NavBar = ({currentUser, currentCompany, isSuperAdmin, isAdmin}) => {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();

  const indexOfNavItemSetter = () => {   //new code;  nav Item is checked inspite of reloading page
    let index = null;
    routes.forEach((e, i) => {
      if (e.href === location.pathname) {
        index = i;
      }
      if (e.hasOwnProperty('children')) {
        e.children.forEach(element => {
          if (element.href === location.pathname) {
            index = i
          }
        })
      }
    })
    return index;
  }

  const [isActiveIndex, setIsActiveIndex] = useState(indexOfNavItemSetter());

  const displayCompanyRole = (slug) => {
    switch (slug) {
      case 'storco':
        return 'StorCo';
      case 'belta':
        return 'Belta';
      case 'aquire':
        return 'Procision Inc';
      default:
        return '';
    }
  };

  const logoutHandler = () => {
    auth.logout();
    history.push("/login");
  };

  const handleClick = (index) => {
    isActiveIndex === index ? setIsActiveIndex(null) : setIsActiveIndex(index)
  };

  const content = (
    <PerfectScrollbar className={classes.scrollbarContainer}>
      <Box
        height='100%'
        display='flex'
        flexDirection='column'
      >
        <Box
          alignItems='center'
          display='flex'
          flexDirection='column'
          marginBottom='25px'
          marginTop='32px'
        >
          <RouterLink to='/'>
            <Logo/>
            {currentCompany ? (
              <p className={classes.company_role}>for {displayCompanyRole(currentCompany?.slug)}</p>
            ) : null}
          </RouterLink>
        </Box>

        <Divider/>

        <Box p={2}>
          <List>
            {routes.map((item, index) => (
              <NavItem
                href={item.href}
                index={index}
                key={item.title}
                title={item.title}
                icon={item.icon}
                children={item.children}
                isActiveIndex={isActiveIndex}
                handleClick={() => handleClick(index)}
              />
            ))}
          </List>
        </Box>
        <Box flexGrow={1}/>

        <Box
          m={2}
        >
          <Box
            display='flex'
            justifyContent='space-between'
            mt={2}
          >
            <Button
              component={RouterLink}
              className={classes.button}
              to='/settings'
            >
              <Settings/>
            </Button>

            <Button
              className={classes.button}
              onClick={logoutHandler}
            >
              <Collapse/>
            </Button>
          </Box>
        </Box>
      </Box>
    </PerfectScrollbar>
  );

  return (
    <>
      <Drawer
        anchor='left'
        classes={{paper: classes.desktopDrawer}}
        open
        variant='persistent'
      >
        {content}
      </Drawer>
    </>
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
  currentCompany: PropTypes.object
};

NavBar.defaultProps = {
  onMobileClose: () => {},
  openMobile: false
};

export default NavBar;
