import React from 'react';

const ProgramsCampaigns = (props) => {
  return (
    <svg {...props} width="16" height="16" viewBox="0 0 16 16" fill="#B9C2D9" xmlns="http://www.w3.org/2000/svg">
      <g>
        <path d="M4.50001 12C4.37201 12 4.24401 11.9513 4.14668 11.8533C3.95135 11.658 3.95135 11.3413 4.14668 11.146L5.95935 9.3333L4.14601 7.51997C3.95068 7.32464 3.95068 7.00797 4.14601 6.81264C4.34135 6.6173 4.65801 6.6173 4.85335 6.81264L7.02001 8.9793C7.21534 9.17464 7.21534 9.4913 7.02001 9.68664L4.85335 11.8533C4.75601 11.9513 4.62801 12 4.50001 12Z" />
        <path d="M11.5 12H8.5C8.224 12 8 11.776 8 11.5C8 11.224 8.224 11 8.5 11H11.5C11.776 11 12 11.224 12 11.5C12 11.776 11.776 12 11.5 12Z" />
        <path d="M14.1667 15.3333H1.83333C0.822 15.3333 0 14.5113 0 13.5V2.49996C0 1.48863 0.822 0.666626 1.83333 0.666626H14.1667C15.178 0.666626 16 1.48863 16 2.49996V13.5C16 14.5113 15.178 15.3333 14.1667 15.3333ZM1.83333 1.66663C1.374 1.66663 1 2.04063 1 2.49996V13.5C1 13.9593 1.374 14.3333 1.83333 14.3333H14.1667C14.626 14.3333 15 13.9593 15 13.5V2.49996C15 2.04063 14.626 1.66663 14.1667 1.66663H1.83333Z" />
        <path d="M15.5 4H0.5C0.224 4 0 3.776 0 3.5C0 3.224 0.224 3 0.5 3H15.5C15.776 3 16 3.224 16 3.5C16 3.776 15.776 4 15.5 4Z" />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default ProgramsCampaigns;
