import React, { useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Avatar, Box, Card, Table, TableBody, TableCell, TableHead, TableRow, makeStyles } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import Pagination from '@material-ui/lab/Pagination';

import Trash from 'src/icons/content/Trash';
import Edit from 'src/icons/content/Edit';
import UserInput from '../update-user/UserInput';
import Account from '../../../account/';

const useStyles = makeStyles((theme) => ({
  root: {
    borderRadius: '0',
    boxShadow: 'none'
  },
  icon: {
    margin: '0 5px',
    cursor: 'pointer',
    '&:hover': {
      '& $path': {
        stroke: '#00A896'
      }
    }
  },
  pagination : {
    float: 'right',
    marginTop: 10
  }
}));

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: 'transparent',
    fontWeight: 'bold',
    color: '#010A21',
    borderBottom: 'none'
  },
  body: {
    fontSize: 14
  }
}))(TableCell);

const StyledTableBody = withStyles((theme) => ({
  root: {
    backgroundColor: '#F0F4FC',
    borderRadius: '16px',

    '& .MuiTableCell-body': {
      borderBottom: 'none',
      color: '#010A21'
    },

    '& .MuiTableRow-hover': {
      '&:first-child': {
        '& .MuiTableCell-body': {
          '&:first-child': { borderTopLeftRadius: '16px' },
          '&:last-child': { borderTopRightRadius: '16px' }
        }
      },
      '&:last-child': {
        '& .MuiTableCell-body': {
          '&:first-child': { borderBottomLeftRadius: '16px' },
          '&:last-child': { borderBottomRightRadius: '16px' }
        }
      }
    },
    '& .MuiTableRow-hover:not(:last-child)': {
      borderBottom: '1px solid #D7DCE8'
    }
  }
}))(TableBody);

const UsersList = ({ className, users, onToggle, mode, toggleMode, openInEdit, toggleOpenInEdit, ...rest  }) => {
  const classes = useStyles();
  const [selectedUserIds, setSelectedUserIds] = useState([]);
  const [limit, setLimit] = useState(8);
  const [page, setPage] = useState(1);
  const [isUpdateUser, setIsUpdateUser] = useState(false);
  const [user, setUser] = useState(null);
  const cancel = () => {

      toggleMode();

    //onToggle(obj);
  }

  // const handleSelectAll = (event) => {
  //   let newSelectedUserIds;
  //
  //   if (event.target.checked) {
  //     newSelectedUserIds = users.map((user) => user.id);
  //   } else {
  //     newSelectedUserIds = [];
  //   }
  //
  //   setSelectedUserIds(newSelectedUserIds);
  // };
  //
  // const handleSelectOne = (event, id) => {
  //   const selectedIndex = selectedUserIds.indexOf(id);
  //   let newSelectedUserIds = [];
  //
  //   if (selectedIndex === -1) {
  //     newSelectedUserIds = newSelectedUserIds.concat(selectedUserIds, id);
  //   } else if (selectedIndex === 0) {
  //     newSelectedUserIds = newSelectedUserIds.concat(selectedUserIds.slice(1));
  //   } else if (selectedIndex === selectedUserIds.length - 1) {
  //     newSelectedUserIds = newSelectedUserIds.concat(selectedUserIds.slice(0, -1));
  //   } else if (selectedIndex > 0) {
  //     newSelectedUserIds = newSelectedUserIds.concat(
  //       selectedUserIds.slice(0, selectedIndex),
  //       selectedUserIds.slice(selectedIndex + 1)
  //     );
  //   }
  //
  //   setSelectedUserIds(newSelectedUserIds);
  // };
  //
  // const handleLimitChange = (event) => {
  //   setLimit(event.target.value);
  // };

  const handlePageChange = (e,newPage) => {
    e.preventDefault();
    setPage(newPage);
  };

  const togglePopupUpdateUser = ( {user} ) => {
    setIsUpdateUser(!isUpdateUser);
    if (user) {
      setUser(user)
    }
  };

  const fancyCancel = (user) => {
    toggleMode();
    togglePopupUpdateUser(user);
  }

  const StyledPagination = withStyles({
    root: {
      display: 'flex',
      justifyContent: 'flex-end',
      marginTop: '16px',
      '& ul li button': {
        fontSize: '13px',
        margin: 0,
        minWidth: '25px',
        maxHeight: '25px'
      },
      '& .Mui-selected': {
        color: '#00A896',
        backgroundColor: 'transparent'
      },
      '& .Mui-selected:hover': {
        color: '#ffffff'
      }
    }
  })(Pagination);

  return (
    <>
      {!isUpdateUser ? (
        <Card
          className={clsx(classes.root, className)}
          {...rest}
        >
          <PerfectScrollbar>
            <Box>
              <Table>
                <TableHead>
                  <TableRow>
                    <StyledTableCell>
                      Avatar
                    </StyledTableCell>
                    <StyledTableCell>
                      First Name
                    </StyledTableCell>
                    <StyledTableCell>
                      Last Name
                    </StyledTableCell>
                    <StyledTableCell>
                      Email
                    </StyledTableCell>
                    <StyledTableCell>
                      Role
                    </StyledTableCell>
                    <StyledTableCell>
                      Phone
                    </StyledTableCell>
                    <StyledTableCell>
                      Actions
                    </StyledTableCell>
                  </TableRow>
                </TableHead>

                <StyledTableBody>
                  {users.slice((page - 1) * limit, page * limit).map((user) => (
                    <TableRow
                      hover
                      key={user.user.id}
                      selected={selectedUserIds.indexOf(user.user.id) !== -1}
                    >
                      <TableCell>
                        <Box alignItems='center' display='flex'>
                          <Avatar src={user.user.avatar_url}></Avatar>
                        </Box>
                      </TableCell>
                      <TableCell>
                        {user.user.first_name}
                      </TableCell>
                      <TableCell>
                        {user.user.last_name}
                      </TableCell>
                      <TableCell>
                        {user.user?.account ? user.user.account.email : ''}
                      </TableCell>
                      <TableCell>
                        {user.role}
                      </TableCell>
                      <StyledTableCell>
                        {user.user.user_phone}
                      </StyledTableCell>
                      <TableCell>
                        <Box alignItems='center' display='flex'>
                          <Edit className={classes.icon} onClick={() => {
                             toggleMode('edit');
                             togglePopupUpdateUser(user)
                          }}  />
                          <Trash className={classes.icon} />
                        </Box>
                      </TableCell>
                    </TableRow>
                  ))}
                </StyledTableBody>
              </Table>
            </Box>
          </PerfectScrollbar>

          {/*<TablePagination*/}
          {/*  component="div"*/}
          {/*  count={users.length}*/}
          {/*  onChangePage={handlePageChange}*/}
          {/*  onChangeRowsPerPage={handleLimitChange}*/}
          {/*  page={page}*/}
          {/*  rowsPerPage={null}*/}
          {/*  rowsPerPageOptions={[5, 10, 25]}*/}
          {/*/>*/}

          <Pagination
            className={classes.pagination}
            defaultPage={1}
            onChange={handlePageChange}
            boundaryCount={2}
            count={Math.round(Number(users.length)/ limit)}
            color='primary' />
        </Card>
      ) : (
        <Account user={user} location={user?.location} onToggle ={cancel} cancel={fancyCancel} mode={mode}/>
      )}
    </>
  );
};

//        {/*<UserInput openInEdit={openInEdit} toggleOpenInEdit={toggleOpenInEdit} onToggle={ togglePopupUpdateUser } user={user}/>*/}


UsersList.propTypes = {
  users: PropTypes.array.isRequired
};

export default UsersList;
