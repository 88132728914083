import React from 'react';

const SearchIcon = (props) => {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0)">
        <path d="M7.92682 0C3.55609 0 0 3.55609 0 7.92682C0 12.2978 3.55609 15.8536 7.92682 15.8536C12.2978 15.8536 15.8536 12.2978 15.8536 7.92682C15.8536 3.55609 12.2978 0 7.92682 0ZM7.92682 14.3903C4.36293 14.3903 1.46341 11.4908 1.46341 7.92686C1.46341 4.36296 4.36293 1.46341 7.92682 1.46341C11.4907 1.46341 14.3902 4.36293 14.3902 7.92682C14.3902 11.4907 11.4907 14.3903 7.92682 14.3903Z" fill="white"/>
        <path d="M17.7857 16.751L13.5906 12.5559C13.3047 12.27 12.8418 12.27 12.5559 12.5559C12.2701 12.8415 12.2701 13.3049 12.5559 13.5905L16.7511 17.7856C16.894 17.9285 17.0811 18 17.2684 18C17.4555 18 17.6428 17.9285 17.7857 17.7856C18.0716 17.5 18.0716 17.0366 17.7857 16.751Z" fill="white"/>
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="18" height="18" fill="white"/>
        </clipPath>
      </defs>
    </svg>


  );
};

export default SearchIcon;
