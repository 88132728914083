import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core';
import {
  Checkbox,
  FormControlLabel,
  Box
} from '@material-ui/core';
import ListCart from './ListCart';
import '../../layouts/UserLayout/fonts.css';
import { blue } from '@material-ui/core/colors';
import { withStyles } from '@material-ui/core/styles';
import CheckedBox from '../../icons/user/CheckedBox';
import UnCheckedBox from '../../icons/user/UnCheckedBox';
import MasterCardLogo from '../../icons/MasterCardLogo';
import VisaLogo from '../../icons/user/VisaLogo';
import AmericanExpressLogo from '../../icons/user/AmericanExpressLogo';
import StyledSelect from './Select';


const MainBox = withStyles(() => ({
  root: {
    padding: '110px 40px 40px 40px',
    width: '70%',
    display:'flex',
    flexDirection:'column',

    '& span1': {
      display:'flex',
      fontSize: 28,
      marginRight:10,
      fontFamily:'Roboto',
      fontWeight:'bold', 
      alignItems:'center',
      '& div':{
        background:'#4CAF50',
        color:'#fff',
        fontWeight:'bold',
        fontSize:16,
        fontFamily:'Roboto',    
        display:'flex',
        alignItems: 'center',
        borderRadius:8,
        padding:'0px 6px',
        height:'28px',
        marginLeft:'15px',
      }
    }
  }

}))(Box);

const useStyles = makeStyles((theme) => ({
}));


const Cart = (props) => {
  const classes = useStyles();

  return (
    <MainBox>
      <Box component='span1'>
      Sustainability options  <Box>{ `${props.products.length} Items`}</Box>
      </Box>
      <Box>
        <ListCart products={props?.products} mode={props.mode} toggleMode={props.toggleMode} updateProductQuantity={props.updateProductQuantity} productQuantityMap={props.productQuantityMap} formatNumber={props.formatNumber}/>
      </Box>
    </MainBox>
  );
};


export default Cart;