import React from 'react';

const Reports = (props) => {
  return (
    <svg {...props} width="16" height="16" viewBox="0 0 16 16" fill="#B9C2D9" xmlns="http://www.w3.org/2000/svg">
      <path d="M14.7354 3.34473L11.4666 0.134277C11.3789 0.0482178 11.261 0 11.1381 0H2.87402C1.90967 0 1.125 0.784546 1.125 1.74902V14.251C1.125 15.2153 1.90955 16 2.87402 16H13.1266C14.0909 16 14.8756 15.2155 14.8756 14.251V3.6792C14.8756 3.55334 14.8251 3.43286 14.7354 3.34473ZM11.6068 1.58618L13.2606 3.21045H11.7559C11.6737 3.21045 11.6068 3.14355 11.6068 3.0614V1.58618ZM13.1266 15.0625H2.87402C2.42664 15.0625 2.0625 14.6985 2.0625 14.251V1.74902C2.0625 1.30151 2.42651 0.9375 2.87402 0.9375H10.6694V3.0614C10.6694 3.66052 11.1569 4.14795 11.756 4.14795H13.9381V14.251C13.9381 14.6985 13.5741 15.0625 13.1266 15.0625Z" />
      <path d="M11.2545 7.4375H4.64661C4.3877 7.4375 4.17786 7.64734 4.17786 7.90625C4.17786 8.16516 4.3877 8.375 4.64661 8.375H11.2545C11.5134 8.375 11.7233 8.16516 11.7233 7.90625C11.7233 7.64734 11.5134 7.4375 11.2545 7.4375Z" />
      <path d="M4.64661 10.25H10.0345C10.2935 10.25 10.5033 10.0402 10.5033 9.78125C10.5033 9.52234 10.2935 9.3125 10.0345 9.3125H4.64661C4.3877 9.3125 4.17786 9.52234 4.17786 9.78125C4.17786 10.0402 4.3877 10.25 4.64661 10.25Z" />
      <path d="M10.6846 11.1875H4.64661C4.3877 11.1875 4.17786 11.3973 4.17786 11.6562C4.17786 11.9152 4.3877 12.125 4.64661 12.125H10.6846C10.9434 12.125 11.1533 11.9152 11.1533 11.6562C11.1533 11.3973 10.9434 11.1875 10.6846 11.1875Z" />
    </svg>
  );
};

export default Reports;
