import React, { useState } from 'react';
import { Container, makeStyles } from '@material-ui/core';
import Page from 'src/components/Page';
import UsersList from './users-list/UsersList';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/client';
import Loading from 'src/components/ui/Loading/Loading';
import Toolbar from './toolbar/Toolbar';
import UserInput from './update-user/UserInput';
import Account from '../../account/';


export const GET_USERS = gql`
   query getCompaniesUsers($companyId: uuid!) {
    companies_users(where: {company_id: {_eq: $companyId}, user: {is_active: {_eq: true}}} order_by: {user: {first_name: asc, last_name: asc}}) {
      role
      company_id
      user {
        id
        display_name
        first_name
        last_name
        user_phone
        user_email
        avatar_url
      }
    }
}`;

export const GET_All_USERS = gql`
   query getCompaniesUsers {
    companies_users(where: {user: {is_active: {_eq: true}}}, order_by: {user: {first_name: asc, last_name: asc}}) {
      role
      company_id
      user {
        id
        display_name
        first_name
        last_name
        user_phone
        user_email
        account {
            email
        }
        avatar_url
        created_at
        current_sign_in_at
        last_sign_in_at
        location_id
        org_role
        sign_in_count
        updated_at
        location {
            id
            address_1
            address_2
            city
            country
            id
            loc_name
            postal_code
            state_province
        }
      }
    }
}`;

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  progress: {}
}));

const UsersView = (props) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [mode, setMode] = useState(null);

  const handleClick = () => {
    setOpen((prev) => !prev);
  };

  const toggleMode = (type) => {
    if (type === 'edit') {
      setMode('edit');
    } else if (type === 'add') {
      setMode('add')
    } else {
      setMode(null);
    }
  };

  const cancel = () => {
    handleClick();
    toggleMode();
  }

  return (
    <Page
      className={classes.root}
      title='Users | Procision'
    >
      <Container maxWidth={false}>
        <Toolbar mode={mode} onToggle={handleClick} toggleMode={toggleMode} />
        {open ? (
          <Account cancel={cancel} disabled={true} />
        ) : (
          <UsersList mode={mode} toggleMode={toggleMode} cancel={cancel} users={props.users} />
        )}
      </Container>
    </Page>
  );
};

const GetUsers = (props) => {
  const classes = useStyles();

  const { loading, error, data } = useQuery(GET_All_USERS);

  if (loading) {
    return <Loading />;
  }

  if (error) {
    return <div className={classes.error}>Error!<br />{error.message}</div>;
  }

  return <UsersView users={data.companies_users} />;

};

export default GetUsers;
