import React from 'react';
import Badge from '@material-ui/core/Badge';
import Avatar from '@material-ui/core/Avatar';
import Bell from 'src/icons/tolbar/Bell';
import { makeStyles, withStyles } from '@material-ui/core/styles';

const StyledBadge = withStyles((theme) => ({
  badge: {
    backgroundColor: '#00A896',
    color: '#00A896',
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    '&::after': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      borderRadius: '50%',
      animation: '$ripple 1.2s infinite ease-in-out',
      border: '1px solid currentColor',
      content: '""',
    },
  },
  '@keyframes ripple': {
    '0%': {
      transform: 'scale(.8)',
      opacity: 1,
    },
    '100%': {
      transform: 'scale(2.4)',
      opacity: 0,
    },
  },
}))(Badge);

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  avatar : {
    width: '52px',
    height: '52px',
    background: '#F0F4FC',
    transition: 'all .2s',
    cursor: 'pointer',
    marginRight: '2px',
    '&:hover': {
      background: '#00A896',
      '& $path': {
        stroke: '#ffffff'
      }
    }
  },
}));

const NotificationHeader = (props) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <StyledBadge
        overlap="circle"
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        variant="dot"
      >
        <Avatar className={classes.avatar}>
          <Bell />
        </Avatar>
      </StyledBadge>
    </div>
  );
}

export default NotificationHeader