import React, { useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { v4 as uuid } from 'uuid';
import moment from 'moment';
import dataPng from '../../icons/testPng/Date 1.png';
import Clock from 'src/icons/alerts/Clock';
import {
  Box,
  Button,
  Card,
  CardHeader,
  Divider,
  List,
  ListItem,
  ListItemText,
  makeStyles,
  Avatar
} from '@material-ui/core';

const data = [
  {
    id: uuid(),
    name: 'Post Transaction 1',
    imageUrl: '/static/images/products/product_1.png',
    updatedAt: moment().subtract(2, 'hours')
  },
  {
    id: uuid(),
    name: 'Post Transaction 2',
    imageUrl: '/static/images/products/product_2.png',
    updatedAt: moment().subtract(2, 'hours')
  },
  {
    id: uuid(),
    name: 'Post Transaction 3',
    imageUrl: '/static/images/products/product_3.png',
    updatedAt: moment().subtract(3, 'hours')
  }
];

const useStyles = makeStyles(({
  root: {
    marginBottom: 16,
    boxShadow: 'none',
    '& .MuiCardHeader-root': {
      padding: '0 16px 16px 0'
    }
  },
  list_item: {
    padding: 0
  },
  image: {
    height: 48,
    width: 48
  },
  label_a:{
    color: 'rgba(1, 10, 33, 0.64)',
    textDecoration: 'underline',
    fontFamily: 'Open Sans Regular',
    fontSize: 14,
    padding: 0,
    textTransform: 'capitalize',
    '&:hover': {
      textDecoration: 'underline',
      color:'#00A896',
    }
  },

  label: {
    color: '#6B799D',
    textDecoration: 'underline',
    fontFamily: 'Open Sans Bold',
    fontSize: 14,

    padding: 0,
    textTransform: 'capitalize',
    '&:hover': {
      textDecoration: 'underline'
    }
  },
  list: {
    marginTop: '16px',
    padding: 0
  },

  hide_all: {
    height: '150px',
    overflow: 'hidden',
    opacity: 0
  },
  list_item_text: {
    color: '#000',
    marginLeft: 12
  },
  list_item_text_secondary: {
    color: 'rgb(1,10,33,0.64)'
  }
}));

const ToDo = ({ className, ...rest }) => {
  const classes = useStyles();
  const [notifications] = useState(data);
  const [isSeeAll, setIsSeeAll] = useState(false);

  const handleIsSeeAll = () => {
    setIsSeeAll(!isSeeAll);
  };

  return (
    <Card
      className={clsx(classes.root, className)}
      {...rest}
    >
      <CardHeader
        subtitle={`${notifications.length} in total`}

        title={
          <Box
            display='flex'
            justifyContent='space-between'
            alignItems='center'
          >
            <Box component='span' fontFamily='Open Sans' fontSize='24px'>To Do</Box>
            <Button
              className={classes.label}
              size='small'
              variant='text'
              onClick={handleIsSeeAll}
            >
              {(isSeeAll ? 'Hide all' : 'See all')}
            </Button>
          </Box>}
      />
      <Divider />
      <List className={classes.list}>
        {notifications.map((notification, i) => (
          (isSeeAll || i < 2 ?
            <ListItem className={classes.list_item}>
              <Avatar src={dataPng} variant='rounded'>
              </Avatar>
              <ListItemText
                primary={notification.name}
                className={classes.list_item_text}
                secondary={<span
                  className={classes.list_item_text_secondary}><a
                  className={classes.label_a}
                  size='small'
                  variant='text'

                >
             Details
            </a></span>}
              />
              <ListItemText>
                <Clock />
                <Box style={{ opacity: 0.64 }} fontFamily='Open Sans Regular' fontSize='14px' marginLeft='6px'
                     component='span'>
                  08 Am - 12 Pm
                </Box>
              </ListItemText>
            </ListItem> : null)
        ))}
      </List>
    </Card>
  );
};


export default ToDo;
