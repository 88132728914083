import React, { useEffect, useState } from 'react';
import {
  Box,
  Avatar,
  MenuItem,
  makeStyles,
  Link,
  Dialog
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import ArrowDown from 'src/icons/ArrowDown';
import Trash from 'src/icons/Trash';
import Select from './Select';
import Scrollbar from 'react-scrollbars-custom';
import { getNftMetadata } from 'src/utils/handleApi';

const StyledSelect = withStyles(() => ({
  root: {
    width: 110,
    border: '1px solid #DCDCDC',
    padding: '12px 24px',
    borderRadius: 100,
    height: '25%',
    fontSize: 18,
    fontWeight: 'normal',
    position: 'relative',
    zIndex: 1,
    '&:focus': {
      borderRadius: 100,
      background: '#fff',
      display: 'flex',
      fontFamily: 'Roboto',
      fontSize: 18,
      fontWeight: 'normal',
      position: 'relative',
      zIndex: 1
    }
  },
  nativeInput: {}
}))(Select);
const StyledMenuItem = withStyles(() => ({}))(MenuItem);
const StyledAvatar = withStyles(() => ({
  root: {
    width: '200px',
    height: '200px'
  },
  rounded: {
    borderRadius: 12
  }
}))(Avatar);

const useStyles = makeStyles(() => ({
  mainBox: {
    border: '1px solid #DCDCDC',
    borderRadius: 20,
    padding: '16px 24px',
    marginBottom: 12,
    marginRight: 10,
    display: 'flex',
    justifyContent: 'space-between',
    '& div span:first-child': {
      fontWeight: 'bold',
      fontSize: 20,
      fontFamily: 'Roboto'
    },
    '& div span:last-child': {
      fontSize: 18,
      color: '#000',
      fontFamily: 'Roboto',
      fontWeight: 'normal'
    }
  },
  buttonRemove: {
    display: 'flex',
    width: 48,
    height: 48,
    alignItems: 'center',
    justifyContent: 'center',
    border: '1px solid #000',
    borderRadius: '100%',
    cursor: 'pointer',
    transition: '.2s all',
    '&:hover': {
      border: '1px solid #FF5F00'
      // '& $path': {
      //   stroke: '#FF5F00'
      // }
    }
  },
  priceBox: {
    justifyContent: 'flex-start',
    fontWeight: 'bold',
    fontSize: 20,
    fontFamily: 'Roboto',
    marginRight: 10
  },
  rightBox: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    maxWidth: '400px',
    width: '100%'
  },
  leftBox: {
    display: 'flex',
    justifyContent: 'center'
  },
  spanBox: {
    marginLeft: 24,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    width: '100%'
  },
  select: {
    maxWidth: 110,
    width: '100%',
    height: 48,
    display: 'flex',
    '& div svg': {
      position: 'relative',
      zIndex: 0,
      right: 39
    },
    marginRight: '20px',
    marginLeft: '30px'
  }
}));

const ItemCart = (props) => {
  const classes = useStyles();
  const propsProduct = props?.product ? props.product : null;

  const mediaMatch = window.matchMedia('(max-width: 1440px)');
  const [matches, setMatches] = useState(mediaMatch.matches);
  const [metadata, setMetadata] = useState({});
  const [dialogData, setDialogData] = useState(false);

  useEffect(() => {
    async function fetchData() {
      const res = await getNftMetadata(propsProduct.url);
      setMetadata(res?.data);
    }
    fetchData();
  }, []);

  const showCarbonDialog = () => {
    setDialogData(true);
  };

  const closeCarbonDialog = () => {
    setDialogData(false);
  };

  return (
    <>
      <div
        className={classes.mainBox}
        style={
          matches
            ? { padding: '24px 32px 24px 24px !important' }
            : { padding: '16px 24px !important' }
        }
      >
        <div className={classes.leftBox}>
          <StyledAvatar variant="rounded" src={metadata?.metadata?.image} />
          <div className={classes.spanBox}>
            <Box component="span">Token Id: {propsProduct?.tokenId}</Box>
            <Box component="span">Name: {metadata?.metadata?.name}</Box>
            <Box component="span">
              <Link
                target="_blank"
                href={`https://alfajores.celoscan.io/tx/${metadata?.txHash}`}
              >
                View on Celo scan
              </Link>
            </Box>
            <Box component="span">
              <button
                className={classes.bnt_offset_detail}
                color="primary"
                onClick={(e) => {
                  e.preventDefault();
                  showCarbonDialog();
                }}
              >
                {' '}
                View token detail{' '}
              </button>
            </Box>
          </div>
        </div>
      </div>
      {dialogData && (
        <Dialog
          onClose={closeCarbonDialog}
          open={dialogData}
          fullWidth={true}
          maxWidth={'lg'}
          aria-labelledby="ruleset-dialog-title"
        >
          <div style={{ display: 'flex', width: '100%', padding: '16px' }}>
            <StyledAvatar variant="rounded" src={metadata?.metadata?.image} />
            <div style={{ marginLeft: '12px', flexGrow: '1' }}>
              <div style={{ borderBottom: '1px solid', fontSize: '35px' }}>
                {metadata?.metadata?.name}
              </div>
              <br></br>
              <div>
                <span style={{ fontSize: '25px' }}>Attributes</span>
                <div
                  style={{
                    display: 'grid',
                    gridTemplateColumns: 'repeat(3, 1fr)',
                    gap: '10px'
                  }}
                >
                  {metadata?.metadata?.attributes?.map((e) => {
                    return (
                      <>
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                            background: '#d4d4d4',
                            padding: '6px',
                            borderRadius: '6px',
                            height: '64px',
                            justifyContent: 'center'
                          }}
                        >
                          <div style={{ fontWeight: 'bold' }}>
                            {e.trait_type}
                          </div>
                          <div dangerouslySetInnerHTML={{__html: e.value}}></div>
                        </div>
                      </>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </Dialog>
      )}
    </>
  );
};

export default ItemCart;
