import React, { useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { v4 as uuid } from 'uuid';
import moment from 'moment';

import {
  Box,
  Button,
  Card,
  CardHeader,
  Divider,
  List,
  ListItem,
  ListItemText,
  makeStyles,
  Avatar
} from '@material-ui/core';

const data = [
  {
    id: uuid(),
    name: 'Dropbox',
    imageUrl: '/static/images/products/product_1.png',
    updatedAt: moment().subtract(2, 'hours')
  },
  {
    id: uuid(),
    name: 'Medium Corporation',
    imageUrl: '/static/images/products/product_2.png',
    updatedAt: moment().subtract(2, 'hours')
  },
  {
    id: uuid(),
    name: 'Slack',
    imageUrl: '/static/images/products/product_3.png',
    updatedAt: moment().subtract(3, 'hours')
  },
  {
    id: uuid(),
    name: 'Lyft',
    imageUrl: '/static/images/products/product_4.png',
    updatedAt: moment().subtract(5, 'hours')
  },
  {
    id: uuid(),
    name: 'GitHub',
    imageUrl: '/static/images/products/product_5.png',
    updatedAt: moment().subtract(9, 'hours')
  }
];

const useStyles = makeStyles(({
  root: {
    marginBottom:16,
    '& .MuiCardHeader-root':{
      padding:'0 16px 16px 0',
    },
    boxShadow: 'none'
  },
  image: {
    height: 48,
    width: 48
  },
  label: {
    color: '#6B799D',
    textDecoration: 'underline',
    fontFamily: 'Open Sans',
    fontSize: 14,
    padding: 0,
    textTransform: 'capitalize',
    '&:hover': {
      textDecoration: 'underline'
    }
  },
  list: {
    marginTop: '16px',
    padding: '12px',
    background: '#F0F4FC',
    borderRadius: '12px'
  },
  see_all: {
    '&:hover': {
      background: '#00A896',
      borderRadius: 8,
      cursor:'pointer',
      '& div span': {
        color: '#fff'
      }
    }
  },
  hide_all: {
    height: '150px',
    overflow: 'hidden',
    opacity: 0
  },
  list_item_text: {
    color: '#000',
    marginLeft: 12
  },
  list_item_text_secondary: {
    color: 'rgb(1,10,33,0.64)'
  }
}));

const Alerts = ({ className, ...rest }) => {
  const classes = useStyles();
  const [notifications] = useState(data);
  const [isSeeAll, setIsSeeAll] = useState(false);

  const handleIsSeeAll = () => {
    setIsSeeAll(!isSeeAll);
  };

  return (
    <Card
      className={clsx(classes.root, className)}
      {...rest}
    >
      <CardHeader
        subtitle={`${notifications.length} in total`}

        title={
          <Box
            display='flex'
            justifyContent='space-between'
            alignItems='center'
          >
            <Box component='span' fontFamily='Open Sans' fontSize='24px'>Alerts</Box>
            <Button
              className={classes.label}
              size='small'
              variant='text'
              onClick={handleIsSeeAll}
            >
              {(isSeeAll ? 'Hide all' : 'See all')}
            </Button>
          </Box>}
      />
      <Divider />
      <List className={classes.list}>
        {notifications.map((notification, i) => (
          (isSeeAll || i < 2 ?
            <ListItem className={classes.see_all}>
              <Avatar src={notification.imageUrl} variant='circular'>
              </Avatar>
              <ListItemText
                primary={notification.name}
                className={classes.list_item_text}
                secondary={<span
                  className={classes.list_item_text_secondary}>Updated {notification.updatedAt.fromNow()}</span>}
              />
            </ListItem> : null)
        ))}
      </List>
    </Card>
  );
};

Notification.propTypes = {
  className: PropTypes.string
};

export default Alerts;
