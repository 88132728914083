import React from 'react';
import {
  Container,
  makeStyles
} from '@material-ui/core';
import Page from 'src/components/Page';
import SwaggerUI from "swagger-ui-react"
import "swagger-ui-react/swagger-ui.css"

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const ApiAndDocumentation = () => {
  const classes = useStyles();
  const url = `${process.env.REACT_APP_API_URL}/api/base-url/swaggerJson`

  return (
    <Page
      className={classes.root}
      title="API & Documentation | Procision"
    >
     <Container maxWidth={false}>
     <SwaggerUI url={url} />
      </Container>
    </Page>
  );
};

export default ApiAndDocumentation;
