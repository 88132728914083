import React, { useState } from 'react';
import { Container, Grid, makeStyles } from '@material-ui/core';
import Page from 'src/components/Page';
import Toolbar from './toolbar/Toolbar';
import ProductRewardList from './products-rewards-list/ProductRewardList'
import gql from 'graphql-tag';
import { useQuery } from '@apollo/client';
import Loading from 'src/components/ui/Loading/Loading';
import ProductInput from './update-product/ProductInput';


export const GET_All_PRODUCTS = gql`
  query getProducts {
    products(order_by: {product_brand: asc, fiat_list_price: asc}) {
      id
      product_description
      product_code
      product_brand
      price_point_token_type {
        id
        name
        is_counter
        is_crypto
      }
      premium_loyalty_point_type {
        id
        name
        is_counter
        is_crypto
      }
      premium_loyalty_point_price
      fiat_type
      list_price_points_tokens
      fiat_list_price
      is_active
      discount_allowed
      company {
          id
          name
      }
      coin_type
      coin_amount
      available_start
      available_end
      SKU_end
      SKU_begin
    }
    companies {
        id
        name
    }
    point_token_types {
        id
        name
        is_counter
        is_crypto
        company {
            id
            name
        }
    }
    fiat_enum {
      value
    }
    coin_enum {
      value
    }
}`;

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    padding: theme.spacing(3),

  }
}));


const PageProducts = (props) => {
  const [open, setOpen] = useState(false);
  const [mode, setMode] = useState(null)

  const handleClick = () => {
    setOpen((prev) => !prev);
  };

  const toggleMode = (type) => {
    setMode(type);
  }

  const classes = useStyles();

  return (
    <Page
      className={classes.root}
      title='Programs & Campaigns | Procision'
    >
      <Grid item lg={12}
        sm={12}
        xl={12}
        xs={12}>
        <Toolbar currentCompany={props.currentCompany} data={props.data} mode={mode} toggleMode={toggleMode} onToggle={handleClick} />
        {open ? (
          <ProductInput currentCompany={props.currentCompany} data={props.data} mode={mode} toggleMode={toggleMode} onToggle={handleClick} products={props.products} companies={props.companies} pointTokenTypes={props.pointTokenTypes} fiatTypes={props.fiatTypes} />
        ) : (
          <ProductRewardList currentCompany={props.currentCompany} data={props.data} mode={mode} toggleMode={toggleMode} companies={props.companies} pointTokenTypes={props.pointTokenTypes} fiatTypes={props.fiatTypes} />
        )}
      </Grid>
    </Page>
  );
};

const GetProducts = (props) => {
  const classes = useStyles();
  const { loading, error, data } = useQuery(GET_All_PRODUCTS);

  if (loading) {
    return <Loading />;
  }

  if (error) {
    return <div className={classes.error}>Error!<br />{error.message}</div>;
  }

  if (data) {
    return <PageProducts
      data={data}
      currentCompany={props?.currentCompany}
      companies={data.companies}
      products={data.products}
      pointTokenTypes={data.point_token_types}
      fiatTypes={data.fiat_enum}
    />;
  }


};

export default GetProducts;
