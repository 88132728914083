import React, {useState} from "react";
import classesFile from './LoyaltyInput.module.css'
import {makeStyles, withStyles} from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import DateFnsUtils from '@date-io/date-fns';
import {KeyboardDatePicker, MuiPickersUtilsProvider} from '@material-ui/pickers';
import SelectIconArrow from "src/icons/SelectIconArrow";

const useStyles = makeStyles((theme) => ({
  root:{
    width:'100%',
  },
  picker: {
    width: '100%',
    height: '52px',
    margin: '0',
    '& $formControl': {
      flexDirection: 'row-reverse'
    },
    '& label.Mui-focused': {
      color: '#010A21',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: '#D7DCE8',
    },
    '& .MuiInputAdornment-positionEnd button': {
      transform: 'translateX(12px)'
    },
    '& .MuiInput-underline:before': {
      borderBottomColor: '#D7DCE8',
    },
    '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
      borderBottom: '2px solid #D7DCE8',
    }
  },
  formControl: {
    flexDirection: 'row-reverse'
  },
  select: {
    width: '100%',
    margin: '0',
    '& label.Mui-focused': {
      color: '#010A21',
    },
    '& input': {
      border: '1px solid #D7DCE8'
    },
    '& .MuiSelect-underline:after': {
      borderBottomColor: '#D7DCE8',
    },
    '& .MuiSelectAdornment-positionEnd button': {
      paddingRight: 0
    },
    '&:before': {
      borderBottom: '1px solid #D7DCE8',
    },
    '& .MuiSelect-underline:hover:not(.Mui-disabled):before': {
      borderBottom: '1px solid #D7DCE8',
    }
  }

}));

// const ADD_LOYALTY = gql`
//   mutation($todo: String!, $isPublic: Boolean!) {
//     insert_todos(objects: { title: $todo, is_public: $isPublic }) {
//       affected_rows
//       returning {
//         id
//         title
//         created_at
//         is_completed
//       }
//     }
//   }
// `;

const UserInput = (props) => {
  const propsUser = props.user;
  const classes=useStyles();
  const [user, setUser] = useState({
    first_name: propsUser?.first_name ? propsUser.first_name : '',
    last_name: propsUser?.last_name ? propsUser.last_name : '',
    user_phone: propsUser?.user_phone ? propsUser.user_phone : '',
    user_email: propsUser?.user_email ? propsUser.user_email : '',
  });

  const handleChange = (event) => {
    setUser({
      [event.target.name]: event.target.value
    });
  };

  const submitHandler = event => {
    event.preventDefault()
  };

  return (
    <div>
      <form onSubmit={ submitHandler } noValidate autoComplete="off">
        <div className={classesFile.LoyaltyFormWrapper}>
          <div className={classesFile.listField}>
            <div className={classesFile.ItemField}>
              <InputLabel id="demo-simple-select-label">First name</InputLabel>
              <Input
                className={classes.root}
                value={user.first_name}
                placeholder="First name"
                onChange={handleChange}
                inputProps={{'aria-label': 'description'}}
              />
            </div>
            <div className={classesFile.ItemField}>
              <InputLabel id="demo-simple-select-label">Last Name</InputLabel>
              <Input
                className={classes.root}
                value={user.last_name}
                placeholder="Last Name"
                onChange={handleChange}
                inputProps={{'aria-label': 'description'}}
              />
            </div>

            {/*<div className={classesFile.ItemField}>*/}
            {/*  <InputLabel id="demo-simple-select-label">Last Name</InputLabel>*/}
            {/*  <FormControl>*/}
            {/*    <Select*/}
            {/*      IconComponent={SelectIconArrow}*/}
            {/*      labelId="demo-simple-select-label"*/}
            {/*      id="demo-simple-select"*/}
            {/*      value=''*/}
            {/*      className={classes.select}*/}
            {/*    >*/}
            {/*      <MenuItem value={10}>Ten</MenuItem>*/}
            {/*      <MenuItem value={20}>Twenty</MenuItem>*/}
            {/*      <MenuItem value={30}>Thirty</MenuItem>*/}
            {/*    </Select>*/}
            {/*  </FormControl>*/}
            {/*</div>*/}
          </div>

          <div className={classesFile.listField}>
            <div className={classesFile.ItemField}>
              <InputLabel id="demo-simple-select-label">Email</InputLabel>
              <Input
                className={classes.root}
                value={user.user_email}
                placeholder="Email"
                // onChange={e => setUserEmail(e.target.value)}
                onChange={handleChange}
                inputProps={{'aria-label': 'description'}}
              />
            </div>

            <div className={classesFile.ItemField}>
              <InputLabel id="demo-simple-select-label">Phone</InputLabel>
              <Input
                className={classes.root}
                value={user.user_phone}
                placeholder="Phone"
                // onChange={e => setUserPhone(e.target.value)}
                onChange={handleChange}
                inputProps={{'aria-label': 'description'}}
              />
            </div>


            {/*<div className={classesFile.ItemField}>*/}
            {/*  <InputLabel id="demo-simple-select-label">Token / Points:</InputLabel>*/}
            {/*  <FormControl>*/}
            {/*    <Select*/}
            {/*      IconComponent={SelectIconArrow}*/}
            {/*      labelId="demo-simple-select-label"*/}
            {/*      id="demo-simple-select"*/}
            {/*      value=''*/}
            {/*      className={classes.select}*/}
            {/*    >*/}
            {/*      <MenuItem value={10}>Ten</MenuItem>*/}
            {/*      <MenuItem value={20}>Twenty</MenuItem>*/}
            {/*      <MenuItem value={30}>Thirty</MenuItem>*/}
            {/*    </Select>*/}
            {/*  </FormControl>*/}
            {/*</div>*/}

            {/*<div className={classesFile.ItemField}>*/}
            {/*  <InputLabel id="demo-simple-select-label">Rule Set</InputLabel>*/}
            {/*  <FormControl>*/}
            {/*    <Select*/}
            {/*      IconComponent={SelectIconArrow}*/}
            {/*      labelId="demo-simple-select-label"*/}
            {/*      id="demo-simple-select"*/}
            {/*      value=''*/}
            {/*      className={classes.select}*/}
            {/*    >*/}
            {/*      <MenuItem value={10}>Ten</MenuItem>*/}
            {/*      <MenuItem value={20}>Twenty</MenuItem>*/}
            {/*      <MenuItem value={30}>Thirty</MenuItem>*/}
            {/*    </Select>*/}
            {/*  </FormControl>*/}
            {/*</div>*/}
          </div>
        </div>

        <div className={classesFile.ButtonWrapper}>
          <button className={classesFile.ButtonSave}>
            Save
          </button>
          <div className={classesFile.ButtonCancel} onClick={(obj) => {
            if (props.openInEdit) {
              props.toggleOpenInEdit();
            }
            props.onToggle(obj);
          }}>
            Cancel
          </div>
        </div>
      </form>
    </div>
  )
};

export default UserInput;
