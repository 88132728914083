import React from 'react';
import { Container, Grid, makeStyles } from '@material-ui/core';
import Page from 'src/components/Page';
import Profile from './Profile';
import gql from 'graphql-tag';
import ProfileDetails from './ProfileDetails';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useQuery } from '@apollo/client';
import Loading from '../../components/ui/Loading/Loading';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  progress: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 'calc(100% - 80px) !important',
    margin: '0 auto'
  },
  error: {
    height: 'calc(100% - 80px)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    textAlign: 'center',
    fontSize: '32px',
  }
}));

export const GET_USER = gql`
   query getUserInfo ($currentUserId: uuid!) {
      currentUser: users_by_pk(id: $currentUserId) {
        id
        display_name
        first_name
        last_name
        user_phone
        user_email
        account {
            email
        }
        avatar_url
        created_at
        current_sign_in_at
        last_sign_in_at
        location_id
        org_role
        sign_in_count
        updated_at
        location {
            id
            address_1
            address_2
            city
            country
            id
            loc_name
            postal_code
            state_province
        }
      }
}`;

const Account = (props) => {
  const classes = useStyles();
  const user = props?.user;
  const location = user?.location;
  let cancel = props?.cancel;
  let onToggle = props?.onToggle

  return (
    <Page
      className={classes.root}
      title="Account | Procision"
    >
      <Container maxWidth="lg">
        <Grid
          container
          spacing={3}
        >
          <Grid
            item
            lg={4}
            md={6}
            xs={12}
          >
            <Profile user={props?.user} disabled={props?.disabled ? props.disabled : false} />
          </Grid>
          <Grid
            item
            lg={8}
            md={6}
            xs={12}
          >
            <ProfileDetails onToggle={onToggle} props={props} user={user} location={location} cancel={cancel} disabled={props?.disabled ? props.disabled : false} />
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
};

const GetAccount = (props) => {
  const classes = useStyles();
  const { loading, error, data } = useQuery(GET_USER, {
    variables: {
      currentUserId: props.currentUser?.id
    }
  });

  if (loading) {
    return <Loading />;
  }

  if (error) {
    return <div className={classes.error}>Error!<br /> Something went wrong</div>;
  }

  return <Account user={data.currentUser} location={data.currentUser?.location} />;
};


export default Account;
