import React from 'react';
import { Container, Grid, makeStyles } from '@material-ui/core';
import Page from 'src/components/Page';
import Notification from './Notification';
// import TrafficByDevice from './TrafficByDevice';
import Alerts from './Alerts';
import ToDo from './ToDo';
import transition from 'src/icons/testPng/transactions.png'
import programs from 'src/icons/testPng/Programs.png'
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const Dashboard = (props) => {
  const classes = useStyles();
  return (
    <Page
      className={classes.root}
      title='Dashboard | Procision'
    >
      <Container maxWidth={false}>
        <Grid
          container
          spacing={3}
        >
          <Grid
            style={{ padding: 16, }}
            item
            lg={8}
            sm={6}
            xl={8}
            xs={12}
          >
            <img src={transition} width="100%" style={{ marginBottom: '32px' }} />
            <img src={programs} width="100%" />
            {/*<Sales />*/}
            {/*<TrafficByDevice />*/}
          </Grid>

          <Grid
            item
            lg={4}
            sm={6}
            xl={4}
            xs={12}
          >
            <Notification />
            <ToDo />
            <Alerts />
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
};

export default Dashboard;
