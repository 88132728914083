import React from "react";
import { useAuth } from "react-nhost";
import { Route, Redirect } from "react-router-dom";
import Loading from 'src/components/ui/Loading/Loading';
import UserLayout from "src/layouts/UserLayout";

export default function PrivateRoute({ children, isUser, ...rest }) {
  const { signedIn } = useAuth();

  if (signedIn === null) {
    return <Loading />
  }

  // if (isUser) {
  //   return <UserLayout />
  // }

  return (
    <Route
      {...rest}
      render={({ location }) =>
        signedIn ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: location },
            }}
          />
        )
      }
    />
  );
}
