import React from "react";
import './Login.css';
import { Col, Layout, Row, Typography } from "antd";

import { Link } from "react-router-dom";

const { Header, Content, Footer } = Layout;
const { Text } = Typography;

const H3 = (props) => (
  <Typography.Title level={3} {...props} />
)

export const contentMinHeight = "calc(100vh - 64px - 70px)";

const LoginInner = ( props ) => {
  return (
    <div className="login">
      An error has occured while activating your account. Contact the administrator or
        <Link to="/register"> sign up again </Link>
    </div>
  );
};

export default function () {
  const title = "Error";
  return (
    <Layout>
       <Header
          style={{
            boxShadow: "0 2px 8px #f0f1f2",
            zIndex: 1,
            overflow: "hidden",
          }}
        >
        <Row justify="space-between">
          <Col span={6}>
            <Link to="/">Aquire</Link>
          </Col>
          <Col span={12}>
            <H3
              style={{
                margin: 0,
                padding: 0,
                textAlign: "center",
                lineHeight: "64px"
              }}
              data-cy="layout-header-title"
            >
                { title }
            </H3>
          </Col>
          <Col span={6} style={{ textAlign: "right" }} />
          </Row>
       </Header>
        <Content style={{ minHeight: contentMinHeight }}>
          <LoginInner />
        </Content>
      <Footer>
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "space-between"
          }}
        >
          <Text>
            Copyright &copy; {new Date().getFullYear()} {"Aquire"}. All
            rights reserved.
          </Text>
        </div>
      </Footer>
    </Layout>
  )
}

