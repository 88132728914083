import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import ArrowRight from 'src/icons/ArrowRight';
import { NavLink as RouterLink, useLocation } from 'react-router-dom';
import { Button, List, ListItem, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  item: {
    display: 'flex',
    paddingTop: 0,
    paddingBottom: 0,
    flexDirection: 'column'
  },
  button: {
    color: theme.palette.text.secondary,
    justifyContent: 'flex-start',
    letterSpacing: 0,
    padding: '10px 8px',
    textTransform: 'none',
    width: '100%',
    '& .MuiButton-label': {
      marginLeft: '8px'
    },
    '& .title': {
      marginRight: 10,
      fontSize: '14px',
      color: '#B9C2D9',
      marginLeft: '16px',
      fontWeight: 'normal'
    },
    '& .iconRotate': {
      transition: 'all .2s linear'
    },
    '& .iconRotate.active': {
      transform: 'rotate(90deg)'
    },
    '&:hover': {
      color: '#ffffff',
      backgroundColor: '#024e52',
      borderRadius: '8px',
      '& .icon': {
        fill: '#00A896'
      },
      '& .title': {
        color: '#ffffff'
      }
    }
  },
  active: {
    color: '#ffffff',
    backgroundColor: '#024e52',
    borderRadius: '8px',
    '& $icon': {
      fill: '#00A896'
    },
    '& .title': {
      color: '#ffffff'
    }
  },

  list_child: {
    width: '100%',
    paddingTop: '0'
  },

  active_dropdown: {
    color: '#ffffff',
    borderRadius: 8,
    backgroundColor: '#024e52',
    '& .icon': {
      fill: '#00A896'
    },
    '& .title': {
      color: '#ffffff'
    }
  },

  child_item_active: {
    visibility: 'visible',
    height: 'auto',
    maxHeight: '290px',
    paddingTop: '0',
    opacity: 1,
    transition: 'maxHeight 0.7s, opacity 1.5s, visibility 2s ease',
    borderRadius: '0 !important',
    '& .MuiListItem-root': {
      padding: 0,
      '&:hover': {
        backgroundColor: 'rgba(255, 255, 255, 0.08) !important'
      }
    },
    '& .MuiButtonBase-root': {
      padding: '7px 0 7px 48px',
      '&:hover': {
        color: '#ffffff',
        borderRadius: '0',
        backgroundColor: 'transparent',
        '& .title_child': {
          color: '#ffffff'
        }
      }
    },
    '& .MuiButton-label': {
      marginLeft: '0',
      '& .title_child': {
        fontSize: '13px',
        marginLeft: '0',
        color: '#B9C2D9'
      }
    }
  },
  active_child: {
    backgroundColor: 'rgba(255, 255, 255, 0.08) !important',
    '& .title_child': {
      color: '#ffffff !important'
    }
  },
  child_item: {
    padding: 0,
    height: '36px',
    position: 'relative',
    visibility: 'hidden',
    opacity: 0,
    maxHeight: 0,
    transition: 'maxHeight 0.6s ease'
  }
}));

const Dropdown = ({ icon: Icon, title, children, handleClick, isActiveIndex, index, ...rest }) => {
  const classes = useStyles();
  // const location = useLocation();
  // const childrenHref = [...children.map(child => child.href)];

  //|| (childrenHref.includes(location.pathname))
  return (
    <div className={ isActiveIndex === index ? classes.active_dropdown : null}>
      <Button className={classes.button} onClick={ handleClick } >
        <Icon
          className='icon'
          size='20'
        />
        <span className='title'>
          {title}
        </span>

        <ArrowRight className={`iconRotate ${isActiveIndex === index ? 'active' : ''}`} />
      </Button>

      <List className={ isActiveIndex === index ? classes.child_item_active : classes.child_item}>
        {children.map((item) => (
          <ListItem
            key={item.title}
            disableGutters
            activeClassName={classes.active_child}
            component={ RouterLink }
            to={item.href}
            {...rest}
          >
            <Button className={classes.button}>
                <span className='title_child'>
                  {item.title}
                </span>
            </Button>
          </ListItem>
        ))}
      </List>
    </div>
  );
};

Dropdown.propTypes = {
  children: PropTypes.array,
  icon: PropTypes.elementType,
  title: PropTypes.string
};

export default Dropdown;




