import React from 'react';

const PaymentFinancial = (props) => {
  return (
    <svg {...props} width="16" height="16" viewBox="0 0 16 16" fill="#B9C2D9" xmlns="http://www.w3.org/2000/svg">
      <path d="M15.5 9.99988C15.224 9.99988 15 10.2239 15 10.4999V13.9999H1V7.99988H6.5C6.776 7.99988 7 7.77588 7 7.49988C7 7.22388 6.776 6.99988 6.5 6.99988H1V4.99988H6.5C6.776 4.99988 7 4.77588 7 4.49988C7 4.22388 6.776 3.99988 6.5 3.99988H1C0.448 3.99988 0 4.44788 0 4.99988V13.9999C0 14.5519 0.448 14.9999 1 14.9999H15C15.552 14.9999 16 14.5519 16 13.9999V10.4999C16 10.2239 15.776 9.99988 15.5 9.99988Z" />
      <path d="M4.5 9.99988H2.5C2.224 9.99988 2 10.2239 2 10.4999C2 10.7759 2.224 10.9999 2.5 10.9999H4.5C4.776 10.9999 5 10.7759 5 10.4999C5 10.2239 4.776 9.99988 4.5 9.99988Z" />
      <path d="M15.697 2.53987L12.197 1.03987C12.07 0.986872 11.929 0.986872 11.802 1.03987L8.302 2.53987C8.119 2.61887 8 2.79987 8 2.99987V4.99987C8 7.75087 9.017 9.35887 11.751 10.9339C11.828 10.9779 11.914 10.9999 12 10.9999C12.086 10.9999 12.172 10.9779 12.249 10.9339C14.983 9.36287 16 7.75487 16 4.99987V2.99987C16 2.79987 15.881 2.61887 15.697 2.53987ZM15 4.99987C15 7.30887 14.236 8.57987 12 9.91987C9.764 8.57687 9 7.30587 9 4.99987V3.32987L12 2.04387L15 3.32987V4.99987Z" />
      <path d="M13.813 4.10886C13.598 3.93886 13.284 3.97186 13.11 4.18686L11.538 6.15286L10.916 5.22286C10.761 4.99286 10.45 4.93186 10.223 5.08386C9.99402 5.23686 9.93102 5.54786 10.084 5.77686L11.084 7.27686C11.173 7.40986 11.319 7.49186 11.479 7.49986C11.486 7.49986 11.494 7.49986 11.5 7.49986C11.651 7.49986 11.795 7.43186 11.891 7.31186L13.891 4.81186C14.063 4.59586 14.029 4.28186 13.813 4.10886Z" />
    </svg>
  );
};

export default PaymentFinancial;
