import React, { useState } from 'react';
// import { Outlet } from 'react-router-dom';
import { makeStyles } from '@material-ui/core';
import NavBar from './NavBar';
import TopBar from './TopBar';
import { ThemeProvider } from '@material-ui/core';
import GlobalStyles from 'src/components/GlobalStyles';
import theme from 'src/theme';
import { Switch, Route, useHistory, useLocation } from 'react-router-dom';
import AccountView from 'src/views/account';
import SettingsView from 'src/views/settings';
import { useQuery } from '@apollo/client';
import { auth } from 'src/utils/nhost';
import { GET_CURRENT_USER, currentContext } from 'src/utils/context';
import GetProducts from 'src/views/products-and-rewards';
import DashboardView from 'src/views/dashboard';
import GetUsers from 'src/views/users-and-companies/users';
import CompaniesView from 'src/views/users-and-companies/companies';
import GetStandardLoyalty from 'src/views/programs-campaigns/standard-loyalty';
import GetHistoryAndTransactions from 'src/views/history-and-transactions'
import ContentView from 'src/views/content';
import CommunicationView from 'src/views/communication';
import ReportsView from 'src/views/reports';
import HistoryTransactionsView from 'src/views/history-and-transactions';
import PaymentFinancialView from 'src/views/payment-and-financial';
import ApiDocumentationView from 'src/views/api-and-documentation';
import Loading from 'src/components/ui/Loading/Loading';
import RuleSetView from 'src/views/ruleset';

import './fonts.css'

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    display: 'flex',
    height: '100%',
    overflow: 'hidden',
    width: '100%'
  },
  wrapper: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
    paddingTop: 90,
    paddingLeft: 290
  },
  contentContainer: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden'
  },
  content: {
    flex: '1 1 auto',
    height: '100%',
    overflow: 'auto',
    paddingLeft: 0
  },
}));

const switchRoutes = ({ currentUser, currentCompany, isSuperAdmin, isAdmin }) => (
  <Switch>
    <Route
      exact
      path='/dashboard'
      render={(props) => (<DashboardView currentCompany={currentCompany} currentUser={currentUser} />)}
    />
    <Route
      exact
      path='/users-and-companies/users'
      render={(props) => (<GetUsers currentCompany={currentCompany} currentUser={currentUser} isSuperAdmin={isSuperAdmin} isAdmin={isAdmin} />)}
    />
    <Route
      exact
      path='/users-and-companies/companies'
      render={(props) => (<CompaniesView currentCompany={currentCompany} currentUser={currentUser} />)}
    />
    <Route
      exact
      path='/programs-and-campaigns/standard-loyalty'
      render={(props) => (<GetStandardLoyalty currentCompany={currentCompany} currentUser={currentUser} isSuperAdmin={isSuperAdmin} isAdmin={isAdmin}/>)}
    />
    <Route
      exact
      path='/products-and-rewards'
      render={(props) => (<GetProducts currentCompany={currentCompany} currentUser={currentUser} isSuperAdmin={isSuperAdmin} isAdmin={isAdmin}/>)}
    />
    <Route
      exact
      path='/ruleset'
      render={(props) => (<RuleSetView currentCompany={currentCompany} currentUser={currentUser} isSuperAdmin={isSuperAdmin} isAdmin={isAdmin}/>)}
    />
    <Route
      exact
      path='/coalition-marketplace'
      render={(props) => (<ContentView currentCompany={currentCompany} currentUser={currentUser} />)}
    />
    <Route
      exact
      path='/coalition-marketplace/:page'
      render={(props) => (<ContentView currentCompany={currentCompany} currentUser={currentUser} />)}
    />
    <Route
      exact
      path='/communication'
      render={(props) => (<CommunicationView currentCompany={currentCompany} currentUser={currentUser} />)}
    />
    <Route
      exact
      path='/reports'
      render={(props) => (<ReportsView currentCompany={currentCompany} currentUser={currentUser} />)}
    />
    <Route
      exact
      path='/reports/usage-reports'
      render={(props) => (<ReportsView currentCompany={currentCompany} currentUser={currentUser} />)}
    />
    <Route
      exact
      path='/history-and-transactions'
      render={(props) => (<GetHistoryAndTransactions currentCompany={currentCompany} currentUser={currentUser} />)}
    />
    <Route
      exact
      path='/payment-and-financial'
      render={(props) => (<PaymentFinancialView currentCompany={currentCompany} currentUser={currentUser} />)}
    />
    <Route
      exact
      path='/api-and-documentation'
      render={(props) => (<ApiDocumentationView currentCompany={currentCompany} currentUser={currentUser} />)}
    />
    <Route
      exact
      path='/account'
      render={(props) => (<AccountView currentCompany={currentCompany} user={currentUser} />)}
    />
    <Route
      exact
      path='/settings'
      render={(props) => (<SettingsView />)}
    />
  </Switch>
);


const DashboardLayout = () => {
  const classes = useStyles();
  const [isMobileNavOpen, setMobileNavOpen] = useState(false);

  let history = useHistory();
  const location = useLocation();

  if (location.pathname === '/') {
    history.push('/dashboard')
  }

  const { loading, data } = useQuery(GET_CURRENT_USER, {
    variables: {
      currentUserId: auth.getClaim('x-hasura-user-id')
    }
  });

  if (loading) {
    return <Loading />
  }

  const {
    currentUser,
    currentCompany,
    isAdmin,
    isSuperAdmin
  } = currentContext(auth, data);

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles />
      <div className={classes.root}>
        <TopBar
          onMobileNavOpen={() => setMobileNavOpen(true)}
          isAdmin={isAdmin}
          isSuperAdmin={isSuperAdmin}
          currentUser={currentUser}
          currentCompany={currentCompany}
        />
        <NavBar
          onMobileClose={() => setMobileNavOpen(false)}
          openMobile={ isMobileNavOpen }
          isAdmin={isAdmin}
          isSuperAdmin={isSuperAdmin}
          currentUser={currentUser}
          currentCompany={currentCompany}
        />
        <div className={classes.wrapper}>
          <div className={classes.contentContainer}>
            <div className={classes.content}>
              {/*<Outlet />*/}
              {switchRoutes({ currentUser, currentCompany, isSuperAdmin, isAdmin })}
            </div>
          </div>
        </div>
      </div>
    </ThemeProvider>
  );
};

export default DashboardLayout;
