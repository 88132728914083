import { PROVIDER_OPTION, TYPE_POINT } from "./constant";

export const responseCarbonOffset = async (response, companyId) => {
    const transactions = [];
    response.forEach(res => {
        let inputCarbonOffset = {
            companyId: companyId,
            carbonOffsetVolume: res.carbonOffsetVolume,
            countOfOffsets: res.countOfOffsets,
            customerUserId: res.customerUserId,
            offsetTransactionId: res.offsetTransactionId,
            serialNumberFrom: res.serialNumberFrom,
            serialNumberTo: res.serialNumberTo,
            subfractionFrom: res.subfractionFrom,
            subfractionTo: res.subfractionTo,
            allocatedQuantity: res.allocatedQuantity,
            customerAccountId: res.customerAccountId ? res.customerAccountId : null,
            customerOrgId: res.customerOrgId ? res.customerOrgId : null,
            customerOrgName: res.customerOrgName ? res.customerOrgName : null,
            metaData: res.metaData ? res.metaData : null,
            typePoint: TYPE_POINT.CARBON_OFFSET,
        }
        transactions.push(inputCarbonOffset)
    });
    return transactions;
}

const toHex = (num) => {
    const val = Number(num);
    return "0x" + val.toString(16);
};

export const switchNetwork = async (switchLibrary) => {
    try {
        await switchLibrary.provider.request({
            method: "wallet_switchEthereumChain",
            params: [{ chainId: toHex(PROVIDER_OPTION.CHAIN_ID) }]
        });
    } catch (error) {
        console.log(error);
    }
};

export const switchNetworks = async({library,chainId,chainName,rpcUrls,blockExplorerUrls}) => {
    try {
      await library.provider.request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId: chainId }],
      });
    } catch (switchError) {
      // This error code indicates that the chain has not been added to MetaMask.
      if (switchError.code === 4902) {
        try {
          await library.provider.request({
            method: "wallet_addEthereumChain",
            params: [
              {
                chainId: chainId,
                chainName: chainName,
                rpcUrls: [rpcUrls],
                blockExplorerUrls: [blockExplorerUrls],
              },
            ],
          });
        } catch (addError) {
          throw addError;
        }
      }
    }
  }