const API_URL = process.env.REACT_APP_API_URL || 'https://poc.procision.co';
const API_SOLIPOINT_URL = process.env.API_SOLIPOINT_URL || 'https://devapi.solipoints.com';
export const ENDPOINT = {
    STRIPE_URL: API_URL + '/api/purchase/payment-gateway',
    CREATE_PURCHASE: API_URL + '/api/purchase',
    CREATE_SIGNATURE: API_URL + '/api/purchase/create-signature',
    UPDATE_BALANCE_POINT: API_URL + '/api/users/update-balance',
    GET_PUBLIC_KEY: API_URL + '/api/purchase/get-public-key/',
    CREATE_PRODUCT_PRICE: API_URL + '/api/products/create-product-price',
    GET_PRODUCT_PRICE: API_URL + '/api/products/product-price/',
    UPDATE_PRODUCT_PRICE: API_URL + '/api/products/update-product-price',
    CREATE_SOLI_CARBON_OFFSET: API_URL + '/api/reward',
    DELETE_PRODUCT_PRICE: API_URL + '/api/products/delete-product-price/',
    GET_REWARD_DATA:API_URL + '/api/users/',
    GET_ALL_DATA_USER:API_URL + '/api/users/data/',
    SAVE_CLIMATE_COINS: API_URL + '/api/reward/createClimateCoins',
    // Send mail to user once payment order succeed
    SEND_NOTICE_MAIL: API_URL + '/api/notification/send-mail',
    REWARD_CUSTOMER_CLIME_TOKEN: API_URL + '/api/purchase/transfer-climate-token',
    // Soli endpoints
    ALLOCATE_CARBON_OFFSET:`${API_SOLIPOINT_URL}/api/v1/allocateCarbonOffset`,
    GET_BALANCE_CLIMATE_REWARD:`${API_SOLIPOINT_URL}/api/v1/balancePoints`,
    REDEEM_REWARD_POINTS:`${API_SOLIPOINT_URL}/api/v1/redeemRewardPoints`,
    CREATE_REWARD_CLAIMATE:`${API_SOLIPOINT_URL}/api/v1/creditRewardPoints`,
}

export const ADDRESS_CONTRACT = '0xa1CB300f31fd2E0389371feD79E121870c34066B';

export const CELO_CONTRACT_ADDRESS = '0x6c05a57742ccd2c1c0a3c12b302a822f159ac85d';
export const CELO_TOKEN_CONTRACT_ADDRESS = '0x25Cb09dF6c163faABc09A8804bf298CDF1ABD65D';

export const AMOUNT_APROVE = '115792089237316195423570985008687907853269984665640564039457584007913129639935';
export const TOKEN_ADDRESS = '0xABA31898c4472a09C8295807cd47A6a54071c9D5';
export const CLIMATE_TOKEN_TRANSFER = '0x6657Faec1bc0bFB1F490f1d2C81e9C196A9f0ae9';
export const CLIMATE_NFT_COLLECTION_ADDRESS = '0x2d0bcf29522a97fc8d521bf7b2afb919e32e7e29';

export const PAYMENT_ETH_ADDRESS = '0x0000000000000000000000000000000000000000';

export const VALUE_DEFAULT_STORCO_COINS = 350000;
export const VALUE_DEFAULT_CARBON_OFFSET = 12;
export const VALUE_DEFAULT_CLIMATE_COINS = 12000;
export const VALUE_DEFAULT_RETAILCO_COINS = 1000000000;

export const CUSTOMER_ID = 'ab04b59d-563b-495b-a026-baa45d3f7821'
export const CUSTOMER_RETAILCO_ID = '29d88c98-0534-4ed4-b486-9985461f2568'
export const WALLET = {
    BUYER: '0x08Ece22fCC8411c747Fe1d1960274Cd3541c5d21',
    COMPANY: '0x0a084d929ec289fcfBeF126B01279aC8D974994d',
    SIGNER: '0x0a084d929ec289fcfBeF126B01279aC8D974994d',
}

export const TYPE_TOKEN = {
    ETH: 1,
    BTC: 2,
    CUSD: 3,
}


export const PROVIDER_OPTION = {
    INFURA_ID: 'fee98de9651149e8910cc308598861e4',
    CHAIN_ID: 11155111,
    COINBASE: 'Coinbase'
}

export const WEB3MODAL = {
    SEPOLIA_NETWORK: 'Sepolia',
    THEME: 'light'
}

export const COMPANY_ID = {
    STORCO: 'c390fd1e-c417-464f-b9c5-0a29a0e28081',
    BELTA: '3414bfa3-06b5-4f98-84ce-95979a8ffa88',
    ENTERPRISE: 'd8b97f44-e13a-4109-b498-2a73bdac7b0e',
    RETAILCO:'4042284c-67dd-4a24-a9ad-673d76590141'
}

export const HEADERS = {
    "Content-Type": "application/json",
    "API-Key": 'api-key-d949339ad22d-cc52-1a892156-1fdd-bc05'
}

export const REWARD = {
    CARBON_OFFSET_ID:'74219bde-e7d1-4ca3-be12-56eefc4eb573',
    BELTA_MILE_ID:'938f169d-11e6-4430-ac71-f2d244d45e7e',
    BELTA_COIN_ID:'c579805f-7afd-4f8d-9d4e-bcaa2ee7f244',
    STORCO_COIN_ID:'4e6bd837-f590-4199-813a-c97dacd53fc3',
    CLIMATE_COIN_ID:'4c813ff7-ad23-4e4c-9ca8-480fc03677ea',
    RETAIL_COIN_ID:'729a3f67-c9bf-436a-8fc8-1c9cdede3b93',
}

export const TYPE_POINT = {
    CARBON_OFFSET: 1,
    CLIMATE_COIN: 2,
  }
