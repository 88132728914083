import React from 'react';

const Trash = (props) => {
    return (
        <svg {...props} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M2 4H3.33333H14" stroke="#6B799D" strokeWidth="1.2" strokeLinecap="round"
                  strokeLinejoin="round"/>
            <path
                d="M5.33325 3.99992V2.66659C5.33325 2.31296 5.47373 1.97382 5.72378 1.72378C5.97383 1.47373 6.31296 1.33325 6.66659 1.33325H9.33325C9.68687 1.33325 10.026 1.47373 10.2761 1.72378C10.5261 1.97382 10.6666 2.31296 10.6666 2.66659V3.99992M12.6666 3.99992V13.3333C12.6666 13.6869 12.5261 14.026 12.2761 14.2761C12.026 14.5261 11.6869 14.6666 11.3333 14.6666H4.66659C4.31296 14.6666 3.97382 14.5261 3.72378 14.2761C3.47373 14.026 3.33325 13.6869 3.33325 13.3333V3.99992H12.6666Z"
                stroke="#6B799D" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M6.66675 7.33325V11.3333" stroke="#6B799D" strokeWidth="1.2" strokeLinecap="round"
                  strokeLinejoin="round"/>
            <path d="M9.33325 7.33325V11.3333" stroke="#6B799D" strokeWidth="1.2" strokeLinecap="round"
                  strokeLinejoin="round"/>
        </svg>

    );
};

export default Trash;
