import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  makeStyles,
  Tooltip,
  FormControlLabel,
  Switch,
  Dialog,
  DialogActions,
  Button,
  DialogTitle,
  DialogContent,
  Snackbar
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import Pagination from '@material-ui/lab/Pagination';
import Trash from 'src/icons/content/Trash';
import Edit from 'src/icons/content/Edit';
import View from 'src/icons/content/View';
import moment from 'moment';
import LoyaltyInput from '../loyalty-input/LoyaltyInput';

import { useMutation } from '@apollo/client';
import gql from 'graphql-tag';
import { GET_STANDARD_LOYALTY } from '../index';
import { AlertTitle } from '@material-ui/lab';
import MuiAlert from '@material-ui/lab/Alert';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';

const useStyles = makeStyles((theme) => ({
  root: {
    borderRadius: '0',
    boxShadow: 'none'
  },
  icon: {
    margin: '0 5px',
    cursor: 'pointer',
    '&:hover': {
      '& $path': {
        stroke: '#00A896'
      }
    }
  }
}));
const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: 'transparent',
    fontWeight: 'bold',
    color: '#010A21',
    borderBottom: 'none'
  },
  body: {
    fontSize: 14
  }
}))(TableCell);

const IOSSwitch = withStyles((theme) => ({
  root: {
    width: 48,
    height: 26,
    padding: 0,
    borderRadius: 20,
    backgroundColor: '#fff'
  },
  switchBase: {
    padding: 2,
    margin: 0,
    color: '#31BD00',
    '&$checked': {
      transform: 'translateX(22px)',
      color: '#ffffff',
      '& + $track': {
        backgroundColor: '#ffffff',
        opacity: 1,
      },
      '& .MuiSwitch-thumb': {
        background: '#31BD00',
      },
    },
  },
  thumb: {
    width: 22,
    height: 22,
    background: '#D7DCE8',
    position: 'relative',
    '&:before': {
      content: 'x',
      position: 'absolute',
    }
  },
  track: {
    borderRadius: 14,
    border: '1px solid #D7DCE8',
    backgroundColor: '#ffffff',
    opacity: 1,
    transition: theme.transitions.create(['background-color', 'border']),
  },
  checked: {
  },
  focusVisible: {},
}))(({ classes, ...props }) => {
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      {...props}
    />
  );
});

const StyledTableBody = withStyles((theme) => ({
  root: {
    backgroundColor: '#F0F4FC',
    borderRadius: '16px',

    '& .MuiTableCell-body': {
      borderBottom: 'none',
      color: '#010A21'

    },
    '& .MuiTableRow-hover': {
      '&:first-child': {
        '& .MuiTableCell-body': {
          '&:first-child': { borderTopLeftRadius: '16px' },
          '&:last-child': { borderTopRightRadius: '16px' }
        }
      },
      '&:last-child': {
        '& .MuiTableCell-body': {
          '&:first-child': { borderBottomLeftRadius: '16px' },
          '&:last-child': { borderBottomRightRadius: '16px' }
        }
      }
    },
    '& .MuiTableRow-hover:not(:last-child)': {
      borderBottom: '1px solid #D7DCE8'
    }
  }
}))(TableBody);

const StyledSnackbar=withStyles(() => ({
  root: {
      fontFamily: 'Roboto',
      fontSize: 18,
  }
}))(Snackbar)

const StyledMuiAlert=withStyles(() => ({
  root: {
      fontFamily: 'Roboto',
      fontSize: 18,
      backgroundColor: '#fff',
      borderWidth: '2px',
      width: '450px'
  }
}))(MuiAlert)

const StyledAlertTitle=withStyles(() => ({
  root: {
      fontFamily: 'Roboto',
      fontWeight: '400',
      fontSize: 24,
  }
}))(AlertTitle)


const UPDATE_LOYALTY_IS_ACTIVE = gql`
    mutation updateLoyaltyProgramsByPk($id: uuid!, $isActive: Boolean!) {
        update_loyalty_programs_by_pk(
            _set: {
                is_active: $isActive
            }
            pk_columns: {
                id: $id
            }
        ) {
            id
            is_active
            modified_by_user_id
        }
    }
`;


const DELETE_LOYALTY_PROGRAM = gql`
    mutation deleteLoyaltyProgramsByPk($id: uuid!) {
        delete_loyalty_programs_by_pk(id: $id) {
            id
        }
    }
`;


const LoyaltyList = ({ className, data, toggleMode, mode, setDialogRuleset, ...rest }) => {
  const classes = useStyles();
  const [loyalty, setLoyalty] = useState(null);
  const [limit, setLimit] = useState(10);
  const [loyaltyPrograms, setLoyaltyPrograms] = useState([]);

  const [updateIsActive] = useMutation(UPDATE_LOYALTY_IS_ACTIVE);
  const [deleteLoyaltyProgram] = useMutation(DELETE_LOYALTY_PROGRAM, {
    refetchQueries: [{ query: GET_STANDARD_LOYALTY }]
  });

  const [isUpdateLoyolty, setIsUpdateLoyolty] = useState(false);
  useEffect(()=> {
    setLoyaltyPrograms(data.loyalty_programs.slice(0, limit));
  }, [])

  const StyledPagination = withStyles({
    root: {
      display: 'flex',
      justifyContent: 'flex-end',
      marginTop: '16px',
      '& ul li button': {
        fontSize: '13px',
        margin: 0,
        minWidth: '25px',
        maxHeight: '25px'
      },
      '& .Mui-selected': {
        color: '#00A896',
        backgroundColor: 'transparent'
      },
      '& .Mui-selected:hover': {
        color: '#ffffff'
      }
    }
  })(Pagination);


  const formatDate = (date) => {
    return moment(date).format('ll')
  }

  const togglePopupUpdateLoyolty = (loyalty) => {
    setIsUpdateLoyolty(!isUpdateLoyolty);
    if (loyalty) {
      setLoyalty(loyalty)
    }
  };

  const [page, setPage] = useState(1);
  const handleChangePage = (
    event,
    newPage,
  ) => {
    setPage(newPage);
    setLoyaltyPrograms(data.loyalty_programs.slice((newPage -1) * limit,(newPage-1) * limit + limit));
  };

  const [isOpenDialog, setIsOpenDialog] = useState(false);
  const [loyaltyDelete, setLoyaltyDelete] = useState(null);

  const handleCancelDialog = () => {
    setIsOpenDialog(false);
  }

  const handleConfirmDialog = () => {
    deleteLoyaltyProgram({ variables: { id: loyaltyDelete.id } });
    setIsOpenDialog(false);
    setIsShowPopUpSuccess(true);
    window.location.reload();
  }

  const openDialogConfirm = (loyalty) => {
    setLoyaltyDelete(loyalty);
    setIsOpenDialog(true);
  }

  const [isShowPopUpSuccess, setIsShowPopUpSuccess] = useState(false);

  const closePopupStripe = () => {
    setIsShowPopUpSuccess(false);
    window.location.reload();
  }

  return (
    <>
    <StyledSnackbar
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}
            open={isShowPopUpSuccess}
            autoHideDuration={3000}
            onClose={closePopupStripe}
        >
          <StyledMuiAlert
                onClose={closePopupStripe}
                severity="success"
                variant="outlined"
                iconMapping={{ success: <CheckCircleOutlineIcon fontSize="24" /> }}
          >
            <StyledAlertTitle>Success!</StyledAlertTitle>
            <p>Campaign deleted.</p>
          </StyledMuiAlert>
        </StyledSnackbar>
      <Dialog
      open={isOpenDialog}
      onClose={handleCancelDialog}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Delete Campaign?"}
        </DialogTitle>
        {/* <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Let Google help apps determine location. This means sending anonymous
            location data to Google, even when no apps are running.
          </DialogContentText>
        </DialogContent> */}
        <DialogActions>
          <Button onClick={handleCancelDialog}>Cancel</Button>
          <Button onClick={handleConfirmDialog} autoFocus>
            Ok
          </Button>
        </DialogActions>
      </Dialog>

      {!isUpdateLoyolty ? (
        <Card
          className={clsx(classes.root, className)}
          {...rest}
        >
          <PerfectScrollbar>
            <Box>
              <Table>
                <TableHead>
                  <TableRow>
                    <StyledTableCell>
                      Active
                    </StyledTableCell>
                    <StyledTableCell>
                      Name
                    </StyledTableCell>
                    <StyledTableCell>
                      Token
                    </StyledTableCell>
                    <StyledTableCell>
                      Partner
                    </StyledTableCell>
                    <StyledTableCell>
                      Start Date
                    </StyledTableCell>
                    <StyledTableCell>
                      Actions
                    </StyledTableCell>
                  </TableRow>
                </TableHead>

                <StyledTableBody>
                  {loyaltyPrograms.map((loyalty) => (
                    <TableRow
                      hover
                      key={loyalty.id}
                    >
                      <TableCell>
                        <FormControlLabel control={
                          <IOSSwitch
                            checked={loyalty.is_active}
                            onClick={e => {
                              e.preventDefault();
                              updateIsActive({ variables: { id: loyalty.id, isActive: !loyalty.is_active } })
                            }}
                            name='checkedB'
                          />
                        } />
                      </TableCell>
                      <TableCell>
                        {loyalty.program_name}
                      </TableCell>
                      <TableCell>
                        {loyalty?.point_token_type ? loyalty.point_token_type.name : null}
                      </TableCell>
                      <TableCell>
                        {loyalty?.partner_company ? loyalty.partner_company.name : null}
                      </TableCell>
                      <TableCell>
                        {formatDate(loyalty.program_begin_at)}
                      </TableCell>

                      <TableCell>
                        <Box alignItems='center'>
                          <Edit className={classes.icon} onClick={() => {
                            toggleMode('edit');
                            togglePopupUpdateLoyolty(loyalty)
                          }} />
                          <View className={classes.icon} />
                          <Trash className={classes.icon} onClick={e => {
                            openDialogConfirm(loyalty)
                            // deleteLoyaltyProgram({ variables: { id: loyalty.id } });
                          }
                          } />
                        </Box>
                      </TableCell>
                    </TableRow>
                  ))}
                </StyledTableBody>
              </Table>
            </Box>
          </PerfectScrollbar>

          <StyledPagination
            defaultPage={page}
            page={page}
            onChange={handleChangePage}
            // boundaryCount={Math.ceil(data.loyalty_programs.length/limit)}
            count={Math.ceil(data.loyalty_programs.length/limit)}
            color='primary' />
        </Card>
      ) : (
        <LoyaltyInput
          mode={mode}
          toggleMode={toggleMode}
          setDialogRuleset={setDialogRuleset}
          onToggle={togglePopupUpdateLoyolty}
          loyalty={loyalty}
          pointTokenTypes={data?.point_token_types}
          rulesets={data?.rulesets}
          relatedCompanies={loyalty.company?.related_companies}
          currentCompany={rest.currentCompany}
        />
      )
      }
    </>
  );
};

LoyaltyList.propTypes = {
  listLoyalty: PropTypes.array
};

export default LoyaltyList;
