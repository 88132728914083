import { useMutation, useQuery } from '@apollo/client';
import {
  Box,
  Card,
  CardContent, Checkbox,
  Container,
  Divider,
  FormControl,
  Input,
  makeStyles,
  MenuItem,
  Paper,
  Select,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  withStyles
} from '@material-ui/core';
import gql from 'graphql-tag';
import React, { useState } from 'react';
import { useHistory } from "react-router-dom";
import Page from 'src/components/Page';
import CollapseCard from 'src/components/ui/CollapseCard/CollapseCard';
import DataPicker from 'src/components/ui/DataPicker/DatePicker';
import classesFile from './content.css';
import Toolbar from './toolbar/Toolbar';
import MuiAlert from '@material-ui/lab/Alert';
import { AlertTitle } from '@material-ui/lab';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';

const GET_STANDARD_LOYALTY = gql`
  query getStandardLoyalty {
    loyalty_programs(order_by: { program_name: asc }) {
      id
      allow_recommendation
      company_id
      company {
        related_companies {
          related_company {
            id
            name
            company_type {
              co_type
            }
            point_token_types {
              id
              name
            }
          }
        }
      }
      created_at
      is_active
      modified_at
      modified_by_user_id
      partner_point_token_type_id
      premium_counter_point_type {
        id
        name
        is_counter
        is_crypto
      }
      partner_point_token_type {
        id
        name
        is_counter
        is_crypto
      }
      db_counter_point_token_type {
        id
        name
        is_counter
        is_crypto
      }
      crypto_point_type {
        id
        name
        is_counter
        is_crypto
      }
      partner_co_id
      partner_company {
        id
        name
        point_token_types {
          id
          name
        }
      }
      program_begin_at
      program_end_at
      program_name
      ruleset {
        id
        ruleset_name
      }
    }
    point_token_types {
      id
      name
      is_counter
      is_crypto
      company_id
    }
    related_companies {
      related_company {
        id
        name
        company_type {
          co_type
        }
        point_token_types {
          id
          name
        }
      }
    }
    rulesets {
      id
      ruleset_name
      purchase_includes_company_points_grant
      redeem_using_basic_company_points
      redeem_using_crypto_loyalty_points
      redeem_using_partner_points
      redeem_using_premium_company_points
      reward_by_percentage_reduction
      reward_by_price_reduction
      reward_includes_bonus_product
      reward_includes_cash_back
      reward_includes_company_points_grant
      reward_includes_partner_points_grant
      top_up_points_with_cash_payment
      ruleset_begin_at
      ruleset_end_at
      is_active
    }
  }
`;

const GET_All_RULESET = gql`
  query getListRuleset {
    rulesets(order_by: { ruleset_name: asc }) {
      id
      company_id
      company {
        id
        name
      }
      partner_company_id
      purchase_includes_company_points_grant
      redeem_using_basic_company_points
      redeem_using_crypto_loyalty_points
      redeem_using_partner_points
      redeem_using_premium_company_points
      reward_by_percentage_reduction
      reward_by_price_reduction
      reward_includes_bonus_product
      reward_includes_cash_back
      reward_includes_company_points_grant
      reward_includes_partner_points_grant
      ruleset_name
      top_up_points_with_cash_payment
      ruleset_begin_at
      ruleset_end_at
      is_active
    }
    related_companies {
      related_company {
        id
        name
        company_type {
          co_type
        }
      }
    }
  }`;

const CREATE_LOYALTY_PROGRAM = gql`
  mutation createLoyaltyProgramsOne(
    $program_name: String
    $program_end_at: timestamptz
    $program_begin_at: timestamptz
    $allow_recommendation: Boolean
    $is_active: Boolean
    $company_id: uuid
    $partner_co_id: uuid
    $partner_point_token_type_id: uuid
    $premium_counter_point_type_id: uuid
    $db_counter_point_type_id: uuid
    $crypto_point_type_id: uuid
    $ruleset_id: uuid
  ) {
    insert_loyalty_programs_one(
      object: {
        program_name: $program_name
        program_end_at: $program_end_at
        program_begin_at: $program_begin_at
        allow_recommendation: $allow_recommendation
        is_active: $is_active
        company_id: $company_id
        partner_co_id: $partner_co_id
        partner_point_token_type_id: $partner_point_token_type_id
        premium_counter_point_type_id: $premium_counter_point_type_id
        db_counter_point_type_id: $db_counter_point_type_id
        crypto_point_type_id: $crypto_point_type_id
        ruleset_id: $ruleset_id
      }
    ) {
      id
      program_name
      program_end_at
      program_begin_at
      allow_recommendation
      is_active
      company_id
      partner_co_id
      partner_point_token_type_id
      premium_counter_point_type_id
      db_counter_point_type_id
      crypto_point_type_id
      ruleset_id
    }
  }
`;


const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },

}));

const StyledSnackbar=withStyles(() => ({
  root: {
      fontFamily: 'Roboto',
      fontSize: 18,
  }
}))(Snackbar)

const StyledMuiAlert=withStyles(() => ({
  root: {
      fontFamily: 'Roboto',
      fontSize: 18,
      backgroundColor: '#fff',
      borderWidth: '2px',
      width: '450px'
  }
}))(MuiAlert)

const StyledAlertTitle=withStyles(() => ({
  root: {
      fontFamily: 'Roboto',
      fontWeight: '400',
      fontSize: 24,
  }
}))(AlertTitle)

const Content = (props) => {
  const [selectedRule, setSelectedRule] = useState(null);
  console.log({ selectedRule })
  const [loyalty, setLoyalty] = useState({
    program_name: '',
    program_begin_at: new Date(),
    program_end_at: new Date(),
    allow_recommendation: true,
    is_active: true,
    partner_co_id: 'c390fd1e-c417-464f-b9c5-0a29a0e28081',
    partner_point_token_type_id: '', // '4e6bd837-f590-4199-813a-c97dacd53fc3',
    premium_counter_point_type_id: '', // '282bd584-72fa-4e72-ac92-ba8b9f50f959',
    db_counter_point_type_id: '', // "938f169d-11e6-4430-ac71-f2d244d45e7e",
    crypto_point_type_id: '', // '4e6bd837-f590-4199-813a-c97dacd53fc3',
    ruleset_id: selectedRule
  });

  const updateLoyalty = (updates) => {
    setLoyalty({ ...loyalty, ...updates })
  }
  const [selection, setSelection] = useState(null);
  const [isShowPopUpSuccess, setIsShowPopUpSuccess] = useState(false);
  const { data: rulesets } = useQuery(GET_All_RULESET);
  const { data: loyaltyprograms } = useQuery(GET_STANDARD_LOYALTY);
  const [createLoyalty] = useMutation(CREATE_LOYALTY_PROGRAM, {
    refetchQueries: [{ query: GET_STANDARD_LOYALTY }]
  });

  if (loyaltyprograms) {
    loyaltyprograms.loyalty_programs.forEach((loyalty) => {
      loyalty.point_token_type = {
        ...loyalty.db_counter_point_token_type,
        ...loyalty.crypto_point_type
      };
    });
  }

  const closePopupStripe = () => {
    setIsShowPopUpSuccess(false);
    window.location.reload();
  }

  const [campaignName, setCampaignName] = useState('')
  const data = [
    {
      name: 'Great Tshirt',
      list: 1200,
      max: 10000,
      quantity: 1000,
      beltaMiles: 3200,
      goldMiles: 0,
      beltaCoins: 17000
    },
    {
      name: 'Greater Tshirt',
      list: 1300,
      max: 7000,
      quantity: 7000,
      beltaMiles: 3700,
      goldMiles: 0,
      beltaCoins: 19500
    },
    {
      name: 'Greatest Tshirt',
      list: 1500,
      max: 3500,
      quantity: 3500,
      beltaMiles: 4200,
      goldMiles: 0,
      beltaCoins: 22000
    }
  ];
  const cardsArray = ['Etsy', 'Best Buy', 'StorCo', 'Starbucks', 'McDonalds'];
  const dynamicContent = (selection) => {
    return (
      <Container>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>
                  <Typography color="textPrimary" gutterBottom variant="h3">
                    {' '}
                    Selection{' '}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography color="textPrimary" gutterBottom variant="h3">
                    {selection}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography color="textPrimary" gutterBottom variant="h3">
                    List Price
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography color="textPrimary" gutterBottom variant="h3">
                    Avail
                  </Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((element) => (
                <TableRow>
                  <TableCell>
                    <Checkbox defaultChecked />
                  </TableCell>
                  <TableCell>{element.name}</TableCell>
                  <TableCell>{element.list}</TableCell>
                  <TableCell>{element.max}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Container>
    );
  };

  const cleansedLoyalty = (loyalty) => {
    const newLoyalty = { ...loyalty };
    Object.keys(newLoyalty).forEach((k) => {
      if (newLoyalty[k] === '') newLoyalty[k] = null;
    });
    return newLoyalty;
  };
  let history = useHistory();
  const submitHandler = (event) => {
    event.preventDefault();
    // const companyIds = loyaltyprograms.point_token_types.filter(
    //   (p) =>
    //     p.id === loyalty.db_counter_point_type_id ||
    //     p.id === loyalty.crypto_point_type_id
    // );
    const cleanLoyalty = cleansedLoyalty(loyalty);
    cleanLoyalty.program_name = campaignName.length ? campaignName : null
    createLoyalty({
      variables: {
        ...cleanLoyalty,
        ruleset_id: selectedRule || rulesets?.rulesets[0]?.id,
        company_id: 'c390fd1e-c417-464f-b9c5-0a29a0e28081',
        partner_co_id: 'c390fd1e-c417-464f-b9c5-0a29a0e28081',
        // partner_company: 'c390fd1e-c417-464f-b9c5-0a29a0e28081',
      }
    });
    setIsShowPopUpSuccess(true);
    window.location.reload();
    // history.push('/coalition-marketplace');
  };

  const pageTwo = (rulesets) => {
    console.log({ rulesets })
    return (
      <Container>
        <Typography
          color="textPrimary"
          align="center"
          variant="h6"
          style={{
            fontSize: '30px'
          }}
        >
          Proposal - Belta - {selection}
        </Typography>
        <div>
          <TableContainer>
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell>
                    <Typography color="textPrimary" gutterBottom variant="h3">
                      Item
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography color="textPrimary" gutterBottom variant="h3">
                      List Price
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography color="textPrimary" gutterBottom variant="h3">
                      Quantity
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography color="textPrimary" gutterBottom variant="h3">
                      Avail
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography color="textPrimary" gutterBottom variant="h3">
                      Belta Miles
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography color="textPrimary" gutterBottom variant="h3">
                      Gold Miles
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography color="textPrimary" gutterBottom variant="h3">
                      Belta Coins
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableBody>
              <TableBody>
                {data.map((element) => (
                  <TableRow>
                    <TableCell>{element.name}</TableCell>
                    <TableCell>{element.list}</TableCell>
                    <TableCell>{element.quantity}</TableCell>
                    <TableCell>{element.max}</TableCell>
                    <TableCell>{element.beltaMiles}</TableCell>
                    <TableCell>{element.goldMiles}</TableCell>
                    <TableCell>{element.beltaCoins}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
        <Box display="flex" justifyContent="space-evenly" p={2}>
          <Divider />
          <div>
            <Typography>Start Date</Typography>
            <DataPicker
              selectedDate={loyalty.program_begin_at}
              setSelectedDate={(e) => updateLoyalty({ program_begin_at: e })}
            />
          </div>
          <div>
            <Typography>End Date</Typography>
            <DataPicker
              selectedDate={loyalty.program_end_at}
              setSelectedDate={(e) => updateLoyalty({ program_end_at: e })}
            />
          </div>
        </Box>
        <br />
        <Box display="flex" justifyContent="space-around" p={2}>
          <div>
            <Typography>Program Name</Typography>
            <Input
              type="text"
              placeholder="Program Name"
              onChange={(e) => setCampaignName(e.target.value)}
              value={campaignName}
            />
          </div>
          <div style={{ width: '350px' }}>
            <FormControl fullWidth>
              {/* <InputLabel id="demo-simple-select-label">Rule Set</InputLabel> */}
              <Typography>Rule Set </Typography>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={selectedRule || rulesets[0].id}
                label="Ruleset"
                // defaultValue={rulesets[0].id}
                // defaultValue={''}
                onChange={(e) => setSelectedRule(e.target.value)}
              >
                {rulesets.map((rule) => (
                  <MenuItem value={rule.id}>{rule.ruleset_name}</MenuItem>
                ))}
                {/* <MenuItem value={'StorCo simple redemption rule1'}>
                  StorCo simple redemption rule 1
                </MenuItem>
                <MenuItem value={'StorCo simple redemption rule2'}>
                  StorCo simple redemption rule 2
                </MenuItem>
                <MenuItem value={'StorCo simple redemption rule3'}>
                  StorCo simple redemption rule 3
                </MenuItem> */}
              </Select>
            </FormControl>
          </div>
        </Box>
        <Box display="flex" justifyContent="flex-end">
          <button
            className="successButton"
            style={{ background: '#000000 !important' }}
            onClick={() => setSelection(null)}
          >
            Cancel
          </button>
          <div className={classesFile.ButtonWrapper}>
            <button
              className="successButton"
              onClick={(e) => {
                submitHandler(e, props.mode);
              }}
            >
              Submit
            </button>
          </div>
        </Box>
      </Container>
    );
  };

  const pageOne = () => {
    return (
      <CardContent>
        {cardsArray.map((element) => (
          <CollapseCard title={element}>
            {dynamicContent(element)}
            <br />
            <Box display="flex" justifyContent="space-evenly" p={2}>
              <Divider />
              <div>
                <Typography>Start Date </Typography>
                <DataPicker />
              </div>
              <div>
                <Typography>End Date </Typography>
                <DataPicker />
              </div>
              <br />
              <div className={classesFile.ButtonWrapper}>
                <button className='successButton' onClick={() => setSelection(element)}>Select</button>
              </div>
            </Box>
          </CollapseCard>
        ))}
      </CardContent>
    );
  };

  const classes = useStyles();

  return (
    <div>
      <StyledSnackbar
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}
            open={isShowPopUpSuccess}
            autoHideDuration={3000}
            onClose={closePopupStripe}
        >
          <StyledMuiAlert
                onClose={closePopupStripe}
                severity="success"
                variant="outlined"
                iconMapping={{ success: <CheckCircleOutlineIcon fontSize="24" /> }}
          >
            <StyledAlertTitle>Success!</StyledAlertTitle>
            <p>Campaign created.</p>
          </StyledMuiAlert>
        </StyledSnackbar>
      <Page className={classes.root} title="Coalition Marketplace | Procision">
        <Container maxWidth={false}>
          <Toolbar currentCompany={props.currentCompany} data={props.data} />
          <Card style={{ marginTop: '20px' }}>
            <Divider />
            {selection ? pageTwo(rulesets.rulesets) : pageOne()}
          </Card>
        </Container>
      </Page>
    </div>
  );
};

export default Content;
