import React, { useState,useEffect } from 'react';
import axios from 'axios';
import classesFile from './LoyaltyInput.module.css';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import UnCheckedBox from 'src/icons/user/UnCheckedBox';
import WhiteCheck from 'src/icons/WhiteCheck';
import Select from '@material-ui/core/Select';
import DateFnsUtils from '@date-io/date-fns';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import SelectIconArrow from 'src/icons/SelectIconArrow';
import Picker from "src/icons/Picker";
import { TextField } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import {GET_All_PRODUCTS} from '../index';
import { useMutation } from '@apollo/client';
import gql from 'graphql-tag';
import { createProductPrice, deleteProductPrice, getProductPrice, updateProductPrice } from 'src/utils/handleApi';
import { ENDPOINT } from 'src/utils/constant';



const StyledCheckbox = withStyles(() => ({
    root: {
      marginLeft: '11px',
    },
    checked: {
      background: '#00A896',
      color: '#fff',
      '&:first-child': {
        borderRadius: '2px',
        padding: 0,
        width: '26px',
        height: '26px',
        '&:hover': {
          background: '#00A896'
        }
      }
    },

    colorPrimary: {
      color: '#D7DCE8',
      padding: 0,
      marginRight: '10px'
    },
    label: {
      marginTop: 20
    }
  }))(Checkbox);


  const StyledFormControlLabel=withStyles(()=>({
    root:{
      marginLeft:'0px',
      marginTop:'24px',
      alignItems: 'flex-start',
    },

  }))(FormControlLabel)


  const IOSSwitch = withStyles((theme) => ({
    root: {
      width: 48,
      height: 26,
      padding: 0,
      marginRight: '10px',
      borderRadius: 20,
      backgroundColor: '#fff'
    },
    switchBase: {
      padding: 2,
      margin: 0,
      color: '#31BD00',
      '&$checked': {
        transform: 'translateX(22px)',
        color: '#ffffff',
        '& + $track': {
          backgroundColor: '#ffffff',
          opacity: 1,
        },
        '& .MuiSwitch-thumb': {
          background: '#31BD00',
        },
      },
    },
    thumb: {
      width: 22,
      height: 22,
      background: '#D7DCE8',
      position: 'relative',
      '&:before': {
        content: 'x',
        position: 'absolute',
      }
    },
    track: {
      borderRadius: 14,
      border: '1px solid #D7DCE8',
      backgroundColor: '#ffffff',
      opacity: 1,
      transition: theme.transitions.create(['background-color', 'border']),
    },
    checked: {
    },
    focusVisible: {},
  }))(({classes, ...props}) => {
    return (
      <Switch
        focusVisibleClassName={classes.focusVisible}
        disableRipple
        classes={{
          root: classes.root,
          switchBase: classes.switchBase,
          thumb: classes.thumb,
          track: classes.track,
          checked: classes.checked,
        }}
        {...props}
      />
    );
  });

const useStyles = makeStyles((theme) => ({
  root:{
    width:'100%',
  },
  picker: {
    width: '100%',
    height: '52px',
    margin: '0',
    '& $formControl': {
      flexDirection: 'row-reverse'
    },
    '& label.Mui-focused': {
      color: '#010A21'
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: '#D7DCE8'
    },
    '& .MuiInputAdornment-positionEnd button': {
      transform: 'translateX(12px)'
    },
    '& .MuiInput-underline:before': {
      borderBottomColor: '#D7DCE8'
    },
    '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
      borderBottom: '2px solid #D7DCE8'
    }
  },
  formControl: {
    flexDirection: 'row-reverse'
  },
  select: {
    width: '100%',
    margin: '0',
    '& label.Mui-focused': {
      color: '#010A21'
    },
    '& input': {
      border: '1px solid #D7DCE8'
    },
    '& .MuiSelect-underline:after': {
      borderBottomColor: '#D7DCE8'
    },
    '& .MuiSelectAdornment-positionEnd button': {
      paddingRight: 0
    },
    '&:before': {
      borderBottom: '1px solid #D7DCE8'
    },
    '& .MuiSelect-underline:hover:not(.Mui-disabled):before': {
      borderBottom: '1px solid #D7DCE8'
    }
  },
  currencyButton: {
    color:'white',
    background:'#00A896',
    marginTop:'10px'
  },
  distance : {
    height:'1em',
    width:'1em',
    marginTop:'10px'
  },
  removeButton: {
    height:'1em',
    width:'1em',
    marginTop:'10px',
    color:'red',
    marginLeft:'10px'
  }

}));


const UPDATE_PRODUCT= gql`
    mutation updateProductsByPk(
        $id: uuid!
        $company_id: uuid
        $fiat_type: String,
        $fiat_list_price: Int
        $list_price_points_tokens: Int
        $list_points_token_type_id: uuid
        $premium_loyalty_point_price: Int
        $premium_loyalty_point_type_id: uuid
        $product_description: String
        $SKU_begin: Int
        $SKU_end: Int
        $product_brand: String
        $product_code: String
        $is_active: Boolean
        $discount_allowed: Boolean
        $available_end: timestamptz
        $available_start: timestamptz
        $coin_type: String
        $coin_amount: String
    ) {
        update_products_by_pk (
            _set: {
                company_id: $company_id
                fiat_type: $fiat_type
                fiat_list_price: $fiat_list_price
                list_price_points_tokens: $list_price_points_tokens
                list_points_token_type_id: $list_points_token_type_id
                product_description: $product_description
                SKU_begin: $SKU_begin
                SKU_end: $SKU_end
                product_brand: $product_brand
                product_code: $product_code
                is_active: $is_active
                discount_allowed: $discount_allowed
                available_end: $available_end
                available_start: $available_start
                premium_loyalty_point_price: $premium_loyalty_point_price
                premium_loyalty_point_type_id: $premium_loyalty_point_type_id
                coin_type: $coin_type
                coin_amount: $coin_amount
            }
            pk_columns: {
                id: $id
            }
        ) {
            id
            company_id
            fiat_type
            fiat_list_price
            list_price_points_tokens
            list_points_token_type_id
            premium_loyalty_point_price
            premium_loyalty_point_type_id
            product_description
            SKU_begin
            SKU_end
            product_brand
            product_code
            is_active
            discount_allowed
            available_end
            available_start
            coin_type
            coin_amount
        }
    }
`;


const CREATE_PRODUCT = gql`
    mutation createProductsOne(
        $company_id: uuid
        $fiat_type: String,
        $fiat_list_price: Int
        $list_price_points_tokens: Int
        $list_points_token_type_id: uuid
        $premium_loyalty_point_price: Int
        $premium_loyalty_point_type_id: uuid
        $product_description: String
        $SKU_begin: Int
        $SKU_end: Int
        $product_brand: String
        $product_code: String
        $is_active: Boolean
        $discount_allowed: Boolean
        $available_end: timestamptz
        $available_start: timestamptz
        $coin_type: String
        $coin_amount: String
    ) {
        insert_products_one(
            object: {
                company_id: $company_id
                fiat_type: $fiat_type
                fiat_list_price: $fiat_list_price
                list_price_points_tokens: $list_price_points_tokens
                list_points_token_type_id: $list_points_token_type_id
                product_description: $product_description
                SKU_begin: $SKU_begin
                SKU_end: $SKU_end
                product_brand: $product_brand
                product_code: $product_code
                is_active: $is_active
                discount_allowed: $discount_allowed
                available_end: $available_end
                available_start: $available_start
                premium_loyalty_point_price: $premium_loyalty_point_price
                premium_loyalty_point_type_id: $premium_loyalty_point_type_id
                coin_type: $coin_type
                coin_amount: $coin_amount
            }
        ) {
            id
            company_id
            fiat_type
            fiat_list_price
            list_price_points_tokens
            list_points_token_type_id
            product_description
            SKU_begin
            SKU_end
            product_brand
            product_code
            is_active
            discount_allowed
            available_end
            available_start
            premium_loyalty_point_price
            premium_loyalty_point_type_id
            coin_type
            coin_amount
        }
    }
`;




const ProductInput = (props) => {
  const propsProduct = props.product;
  const propsCoinType = props.data.coin_enum;
  const propsCompanies = props.companies;
  const propsCurrentCompany = props.currentCompany;
  const propsPointTokenTypes = props.pointTokenTypes;
  const propsFiatTypes = props.fiatTypes;
 const mergeType = []
 const [arrInput, setArrInput] = useState()

 const getProductPrice = (id) => {
  try {
  axios.get(`${ENDPOINT.GET_PRODUCT_PRICE}${id}`)
    .then(res =>{
      setArrInput(res.data)

    })
    .catch(error => {
      console.log(error)
  });
  } catch (error) {
    console.log(error);
  }
}

useEffect(() => {
  if (props.mode === 'edit') {
    getProductPrice(propsProduct.id);
  }

}, []);

 propsCoinType.forEach ((coin) =>{
  mergeType.push({value:coin.value})
 })

 propsFiatTypes.forEach ((fiat) =>{
  mergeType.push({value:fiat.value})
 })

 propsPointTokenTypes.forEach ((point) =>{
  mergeType.push({value:point.name})
 })

  const [arr, setArr] = useState([]);
  const [updateProduct] = useMutation(UPDATE_PRODUCT);
  const [createProduct] = useMutation(CREATE_PRODUCT, {
      refetchQueries: [{query: GET_All_PRODUCTS}]
  });

  const [product, setProduct] = useState({
    company_id: propsProduct?.company ? propsProduct.company.id : propsCurrentCompany.id,
    fiat_type: propsProduct?.fiat_type ? propsProduct.fiat_type : '',
    fiat_list_price: propsProduct?.fiat_list_price ? propsProduct.fiat_list_price : 0,
    list_price_points_tokens: propsProduct?.list_price_points_tokens ? propsProduct.list_price_points_tokens : 0,
    list_points_token_type_id: propsProduct?.price_point_token_type ? propsProduct.price_point_token_type.id : '',
    product_description: propsProduct?.product_description ? propsProduct.product_description : '',

    premium_loyalty_point_price: propsProduct?.premium_loyalty_point_price ? propsProduct.premium_loyalty_point_price : 0,
    premium_loyalty_point_type_id: propsProduct?.premium_loyalty_point_type ? propsProduct.premium_loyalty_point_type.id : '',

    coin_type: propsProduct?.coin_type? propsProduct.coin_type : '',
    coin_amount: propsProduct?.coin_amount ? propsProduct.coin_amount : '0',

    SKU_begin: propsProduct?.SKU_begin ? propsProduct.SKU_begin : '',
    SKU_end: propsProduct?.SKU_end ? propsProduct.SKU_end : '',
    product_brand: propsProduct?.product_brand ? propsProduct.product_brand : '',
    product_code: propsProduct?.product_code ? propsProduct.product_code : '',
    is_active: propsProduct?.is_active ? propsProduct.is_active : false,
    discount_allowed: propsProduct?.discount_allowed ? propsProduct.discount_allowed : false,
    available_end: propsProduct?.available_end ? propsProduct.available_end : new Date(),
    available_start: propsProduct?.available_start ? propsProduct.available_start : new Date()
  });

  const cleansedProduct = (product) => {
    const newProduct = {...product};
    Object.keys(newProduct).forEach(k => {
        if (newProduct[k] === '')
            newProduct[k] = null;
    });
    return newProduct;
  }

  const handleChange = (event) => {
    const boolFields = ['discount_allowed', 'is_active'];
    const numericFields = ['fiat_list_price', 'list_price_point_tokens, coins_list_price']
    const [name, value, isChecked] = [event.target.name, event.target.value, event.target.checked]

    let update = {};
    if (boolFields.includes(name)) {
        update[name] = isChecked;
    } else if (numericFields.includes(name) && typeof(value) === 'number') {
        update[name] = value;
    } else {
        update[name] = value;
    }

    setProduct( {...product, ...update} );
  };


    // Material pickers don't pass typical event fields
    const handleDateChange = (event, fieldName) => {
        let e = event ? {...event} : {};
        e.target = {};
        e.target.name = fieldName;
        e.target.value = event;
        handleChange(e);
    }


  const submitHandler = async(event, mode) => {
    event.preventDefault();
    const cleanProduct = cleansedProduct(product);
    if (mode === 'edit') {
        updateProduct({variables: {...cleanProduct, id: propsProduct.id}})
        arr.forEach((item)=>{
          const input = {
            productId:propsProduct.id,
            currentType:item.currencyType,
            price:item.priceCurrency
          }
          createProductPrice(input)
        })

        arrInput.forEach((item) => {
          const input = {
            id:item.id,
            currentType:item.currency_type,
            price:item.price
          }
          updateProductPrice(input)
        })


    } else if (mode === 'add') {

       const product = await createProduct({variables: {
            ...cleanProduct,
        }})

        arr.forEach((item)=>{
          const input = {
            productId:product.data.insert_products_one.id,
            currentType:item.currencyType,
            price:item.priceCurrency
          }
          createProductPrice(input)
        })
    }
  };

  const handleChangeInput  = (e, index) => {
    e.preventDefault();
    const { name, value } = e.target;
    const list = [...arr];
    list[index][name] =value;
    setArr(list)
  }

  const handleChangeCurrencyInput  = (e, index) => {
    e.preventDefault();
    const { name, value } = e.target;
    const list = [...arrInput];
    list[index][name] =value;
    setArrInput(list)
  }

  const classes = useStyles();
  const removebtn = <DeleteIcon className={classes.removeButton} onClick={e => {
    e.preventDefault();
    arr.splice(-1)
    localStorage.setItem('currency', JSON.stringify(arr))
    setArr(JSON.parse(localStorage.getItem('currency')))
    if (props.mode === 'edit' && arrInput.length > 0) {
      localStorage.setItem('removeProductPrice', JSON.stringify(arrInput.splice(-1)))
      const data = JSON.parse(localStorage.getItem('removeProductPrice'))
      deleteProductPrice(data[0].id)
      localStorage.setItem('currencyProductPrice', JSON.stringify(arrInput))
      setArrInput(JSON.parse(localStorage.getItem('currencyProductPrice')))
    }
  }} />
  const plusIcon = <AddIcon className={classes.currencyButton} onClick={e => {
    e.preventDefault();
    const add = [...arr,{currencyType:'', priceCurrency:'0'}]
    setArr(add)
   }} />
  return (
    <div>
      <form onSubmit={submitHandler} noValidate autoComplete='off'>
        <div className={classesFile.LoyaltyFormWrapper}>
          <div className={classesFile.listField}>
            <div className={classesFile.ItemField}>
                <InputLabel id="product-company-select-label">Company</InputLabel>
                <FormControl  className={classes.root}>
                    <Select
                        IconComponent={SelectIconArrow}
                        labelId="product-company-select-label"
                        id="product-company-select"
                        name="company_id"
                        value={product.company_id || ''}
                        onChange={handleChange}
                        className={classes.select}
                    >
                        <MenuItem value={''}>None</MenuItem>
                        {
                            propsCompanies.map((company, idx) => {
                                return <MenuItem key={idx} value={company.id}>{company.name}</MenuItem>
                            })
                        }
                    </Select>
                </FormControl>
            </div>

            <div className={classesFile.ItemField}>
                <InputLabel id='demo-simple-select-label'>Product description</InputLabel>
                <Input
                    className={classes.root}
                    value={product.product_description}
                    name="product_description"
                    placeholder='Product Name'
                    onChange={handleChange}
                    inputProps={{ 'aria-label': 'description' }}
                />
            </div>

            <div className={classesFile.ItemField}>
                <InputLabel id='sku-begin-input-label'>SKU begin</InputLabel>
                <TextField
                    type="number"
                    className={classes.root}
                    name="SKU_begin"
                    value={product.SKU_begin}
                    placeholder='SKU begin'
                    onChange={handleChange}
                    inputProps={{ 'aria-label': 'description' }}
                />
            </div>

            <div className={classesFile.ItemField}>
                <InputLabel id='demo-simple-select-label'>Fiat type</InputLabel>
                <FormControl  className={classes.root}>
                    <Select
                        IconComponent={SelectIconArrow}
                        labelId="fiat_type-label"
                        id="fiat_type-select"
                        name="fiat_type"
                        value={product.fiat_type}
                        onChange={handleChange}
                        className={classes.select}
                    >
                        {
                            propsFiatTypes.map((fiatType, idx) => {
                                return <MenuItem key={fiatType.value} value={fiatType.value}>{fiatType.value}</MenuItem>
                            })
                        }
                    </Select>
                </FormControl>
            </div>

            <div className={classesFile.ItemField}>
                <InputLabel id="price_point_token_type-label">Loyalty point type</InputLabel>
                <FormControl  className={classes.root}>
                    <Select
                        IconComponent={SelectIconArrow}
                        labelId="price_point_token_type-label"
                        id="price_point_token_type-select"
                        name="list_points_token_type_id"
                        value={product.list_points_token_type_id}
                        onChange={handleChange}
                        className={classes.select}
                    >
                        <MenuItem value={''}>None</MenuItem>
                        {
                            propsPointTokenTypes.map((tokenType, idx) => {
                                return <MenuItem key={idx} value={tokenType.id}>{tokenType.name}</MenuItem>
                            })
                        }
                    </Select>
                </FormControl>
            </div>
            <div className={classesFile.ItemField}>
                <InputLabel id="premium_loyalty_point_type-label">Premium loyalty point type</InputLabel>
                <FormControl  className={classes.root}>
                    <Select
                        IconComponent={SelectIconArrow}
                        labelId="premium_loyalty_point_type-label"
                        id="premium_loyalty_point_type-select"
                        name="premium_loyalty_point_type_id"
                        value={product.premium_loyalty_point_type_id}
                        onChange={handleChange}
                        className={classes.select}
                    >
                        <MenuItem value={''}>None</MenuItem>
                        {
                            propsPointTokenTypes.map((tokenType, idx) => {
                                return <MenuItem key={idx} value={tokenType.id}>{tokenType.name}</MenuItem>
                            })
                        }
                    </Select>
                </FormControl>
            </div>
{
  
  arrInput? props.mode === 'edit' && arrInput.map((item,i) => {
    return (
      <div className={classesFile.ItemField}>
        <InputLabel id="price_point_token_type-label">Currency Type</InputLabel>
        <FormControl className={classes.root}>
          <Select
            IconComponent={SelectIconArrow}
            labelId="price_point_token_type-label"
            id="price_point_token_type-selectt"
            name="currency_type"
            value={item.currency_type}
            onChange={e => handleChangeCurrencyInput(e, i)}
            className={classes.select}
          >
            {
              mergeType.map((coinType, idx) => {
                return <MenuItem key={coinType.value} value={coinType.value}>{coinType.value}</MenuItem>
              })
            }
          </Select>
        </FormControl>
      </div>
    );
  }) : null
}

            { arr ? arr.map((item, i) => {
              return (
                <div className={classesFile.ItemField}>
                  <InputLabel id="price_point_token_type-label">Currency Type</InputLabel>
                  <FormControl className={classes.root}>
                    <Select
                      IconComponent={SelectIconArrow}
                      labelId="price_point_token_type-label"
                      id="price_point_token_type-selectt"
                      name="currencyType"
                      value={item.currencyType}
                      onChange={e => handleChangeInput(e, i)}
                      className={classes.select}
                    >
                      {
                        mergeType.map((coinType, idx) => {
                          return <MenuItem key={coinType.value} value={coinType.value}>{coinType.value}</MenuItem>
                        })
                      }
                    </Select>
                  </FormControl>
                </div>
              );
            }):null}
            <div style={{  display: 'flex'}}>
            {plusIcon}
            {removebtn}
            </div>

            <div className={classesFile.ItemField}>
                <InputLabel id="available_start-label">Available starting</InputLabel>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                        keyboardIcon={<Picker/>}
                        disableToolbar
                        format="MM/dd/yyyy"
                        margin="normal"
                        id="available_start-picker"
                        name="available_start"
                        value={ product.available_start }
                        autoOk={ true }
                        onChange={(e) => handleDateChange(e, 'available_start')}
                        KeyboardButtonProps={{
                            'aria-label': 'change date',
                            display: 'flex'
                        }}
                        inputProps={{
                            style: {
                            color: '#6B799D',
                            borderColor: '#D7DCE8',
                            },
                        }}
                        className={classes.picker}
                    />
                </MuiPickersUtilsProvider>
            </div>
            <div className={classesFile.ItemField}>
                <FormControlLabel
                    control={<StyledCheckbox
                        icon={<UnCheckedBox />}
                        checkedIcon={<WhiteCheck />}
                        checked={ product.discount_allowed }
                        color='primary'
                        name='discount_allowed'
                        onChange={ handleChange }
                    />}
                    label="Discount allowed"
                    labelPlacement="end"
                />
            </div>
            <div className={classesFile.ItemField}>
                <StyledFormControlLabel
                    control={
                        <IOSSwitch
                            checked={ product.is_active }
                            onChange={ handleChange }
                            name="is_active"
                        />
                    }
                    labelPlacement="end"
                    label={ product.is_active ? 'Active' : 'Inactive' }
                />
            </div>
        </div>

        <div className={classesFile.listField}>
            <div className={classesFile.ItemField}>
                <InputLabel id='demo-simple-select-label'>Product brand</InputLabel>
                <Input
                    className={classes.root}
                    value={product.product_brand}
                    name="product_brand"
                    placeholder='Product brand'
                    onChange={handleChange}
                    inputProps={{ 'aria-label': 'description' }}
                />
            </div>
            <div className={classesFile.ItemField}>
                <InputLabel id='demo-simple-select-label'>Product code</InputLabel>
                <Input
                    className={classes.root}
                    value={product.product_code}
                    name="product_code"
                    placeholder='Product code'
                    onChange={handleChange}
                    inputProps={{ 'aria-label': 'description' }}
                />
            </div>
            <div className={classesFile.ItemField}>
                <InputLabel id='sku-end-input-label'>SKU end</InputLabel>
                <TextField
                    type="number"
                    className={classes.root}
                    name="SKU_end"
                    value={product.SKU_end}
                    placeholder='SKU end'
                    onChange={handleChange}
                    inputProps={{ 'aria-label': 'description' }}
                />
            </div>
            <div className={classesFile.ItemField}>
                <InputLabel id='fiat-list-price-input-label'>Fiat list price</InputLabel>
                <TextField
                    type="number"
                    className={classes.root}
                    labelId="fiat-list-price-input-label"
                    value={product.fiat_list_price}
                    name="fiat_list_price"
                    placeholder='Fiat list price'
                    onChange={handleChange}
                    inputProps={{ 'aria-label': 'description' }}
                />
            </div>
            <div className={classesFile.ItemField}>
                <InputLabel id='list-price-points-tokens-label'>Price loyalty points</InputLabel>
                <TextField
                    type="number"
                    className={classes.root}
                    value={product.list_price_points_tokens}
                    name="list_price_points_tokens"
                    placeholder='List price points tokens'
                    onChange={handleChange}
                    inputProps={{ 'aria-label': 'description' }}
                />
            </div>

            <div className={classesFile.ItemField}>
                <InputLabel id='premium_loyalty_point_price-label'>Price premium points</InputLabel>
                <TextField
                    type="number"
                    className={classes.root}
                    value={product.premium_loyalty_point_price}
                    name="premium_loyalty_point_price"
                    placeholder='Price premium loyalty points'
                    onChange={handleChange}
                    inputProps={{ 'aria-label': 'description' }}
                />
            </div>
{
  arrInput ?  props.mode === 'edit' && arrInput.map((item,i) => {
      return (
        <div className={classesFile.ItemField}>
          <InputLabel id='coins-list-price-input-label'>Price</InputLabel>
          <TextField
            type="number"
            className={classes.root}
            labelId="coins-list-price-input-label"
            value={item.price}
            name="price"
            placeholder=' currency price'
            onChange={e => handleChangeCurrencyInput(e,i)}
            inputProps={{ 'aria-label': 'description' }}
          />
        </div>
      );
    }) : null
}
            {arr ? arr.map((item, i) => {
              return (
                <div className={classesFile.ItemField}>
                  <InputLabel id='coins-list-price-input-label'>Price</InputLabel>
                  <TextField
                    type="number"
                    className={classes.root}
                    labelId="coins-list-price-input-label"
                    value={item.priceCurrency}
                    name="priceCurrency"
                    placeholder=' currency price'
                    onChange={e => handleChangeInput(e,i)}
                    inputProps={{ 'aria-label': 'description' }}
                  />
                </div>
              );
            }):null}
            <div className={classes.distance}></div>
            <div className={classesFile.ItemField}>
                <InputLabel id="available_end-label">Available ending</InputLabel>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                        keyboardIcon={<Picker/>}
                        disableToolbar
                        format="MM/dd/yyyy"
                        margin="normal"
                        id="available_end-picker"
                        name="available_end"
                        value={ product.available_end}
                        autoOk={ true }
                        onChange={(e) => handleDateChange(e, 'available_end')}
                        KeyboardButtonProps={{
                            'aria-label': 'change date',
                            display: 'flex'
                        }}
                        inputProps={{
                            style: {
                            color: '#6B799D',
                            borderColor: '#D7DCE8',
                            },
                        }}
                        className={classes.picker}
                    />
                </MuiPickersUtilsProvider>
            </div>

          </div>
        </div>

        <div className={classesFile.ButtonWrapper}>
          <button className={classesFile.ButtonSave} onClick={ e => {
            submitHandler(e, props.mode);
            props.toggleMode();
            props.onToggle(e);
          }}>
            Save
          </button>
          <div
            className={classesFile.ButtonCancel}
            onClick={(e) => {
                props.toggleMode();
                props.onToggle(e);
              }
            }
        >
            Cancel
          </div>
        </div>
      </form>
    </div>
  );
};

export default ProductInput;
