import { gql } from '@apollo/client';

export const GET_CURRENT_USER = gql`
  query getCurrentUser($currentUserId: uuid!) {
    companies {
      id
      slug
      name
    }
    currentUser: users_by_pk(id: $currentUserId) {
        id
        display_name
        first_name
        last_name
        user_phone
        user_email
        account {
            email
        }
        avatar_url
        created_at
        current_sign_in_at
        last_sign_in_at
        location_id
        org_role
        sign_in_count
        updated_at
        location {
            id
            address_1
            address_2
            city
            country
            id
            loc_name
            postal_code
            state_province
        }
      companies_users {
        id
        role
        company_id
        company {
            id
            slug
            name
        }
        user_id
      }
    }
}`;

export function currentContext (auth, data) {
  const currentUser = data.currentUser;
  const hasuraRole = auth.getClaim("x-hasura-default-role")

  // user can belong to several companies but for PoC lets fetch the first one
  const company = data.companies.filter( c => c.slug === "aquire")[0] || data.currentUser?.companies_users[0]?.company;
  // what is the user's role in this company?
  const userRole = data.currentUser.companies_users.filter(cu => cu.company_id === company.id)[0].role;

  if( hasuraRole === 'admin' ) {
    return {
      currentUser,
      currentCompany: company,
      isSuperAdmin: true
    }
  }

  return {
    currentUser,
    currentCompany: company,
    isAdmin: userRole === 'admin'
  };
};