import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Select from 'react-select';

const useStyle = makeStyles(() => ({
  select_placeholder:{
    color:'#000 !important' ,
  }
}));
const customStyles = {
  root: {
    color: '#FF5F00',
    backgroundColor: '#FF5F00',
    cursor:'pointer',
  },
  control: (props) => ({
    ...props,
    borderRadius: '100px',
    boxShadow: 'none',
    borderColor: '#000',
    height:48,
    cursor:'pointer',
    '&:focus': {
      outline: 'none',
      borderColor: '#FF5F00',
      color:'#fff'
    },
    '&:hover': {
      borderColor: '#FF5F00'
    }
  }),
  indicatorSeparator: () => ({
    width: 0
  }),
  container: () => ({
    maxWidth: '110px',
    width: '100%',
    cursor:'pointer',
  }),
  option: (props) => ({
    ...props,

    cursor:'pointer',
    // backgroundColor:'yellow',
    '&:hover': {
      backgroundColor: '#FF5F00',
      color: '#fff'
    },
    '&:before': {
      backgroundColor: '#FF5F00',
      color: '#fff'
    },
  }),
  menu: (props) => ({
    ...props,
    maxWidth: '110px',
    borderRadius: '20px',
    margin: 0,
    top: 'none',
    cursor:'pointer',
  }),
  menuList: (props) => ({
    ...props,
    borderRadius: 20,
    padding: 0,
    cursor:'pointer',
  }),
  dropdownIndicator:(props)=>({
    ...props,
    '& svg':{
      right:'0 !important' ,
      color:'#000'
    }
  }),
  valueContainer:(props)=>({
    ...props,
    height:48,
  }),
};
const StyledSelect = (props) => {
  const propsProduct = props?.product ? props.product : null;
  const classes = useStyle();

  const options = [
    { value: 1, label: '1' },
    { value: 2, label: '2' },
    { value: 3, label: '3' }
  ];

  const handleChange = (e) => {
      props.updateProductQuantity(propsProduct?.id, e.value)
  }

  return (
    <Select
      styles={customStyles}
      placeholder={<span className={classes.select_placeholder}>1</span>}
      options={options}
      onChange={e => handleChange(e)}
      theme={theme => ({
        ...theme,
        borderRadius: 0,
        colors: {
          ...theme.colors,
          primary25: '#FF5F00',
          primary: '#FF5F00',
        },
      })}
    >
    </Select>

  );
};

export default StyledSelect;