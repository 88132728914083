import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core';
import TopBar from './TopBar';
import Boxes from './Boxes';
import { ThemeProvider } from '@material-ui/core';
import GlobalStyles from 'src/components/GlobalStyles';
import theme from 'src/theme';
import Loading from '../../components/ui/Loading/Loading';
import Page from '../../components/Page';
import { useLocation } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import gql from 'graphql-tag';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    display: 'flex',
    overflow: 'hidden',
    width: '100%'
  },
  wrapper: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
    paddingTop: 90,
    paddingLeft: 290
  },
  contentContainer: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden'
  },
  content: {
    flex: '1 1 auto',
    height: '100%',
    overflow: 'auto',
    paddingLeft: 0
  },
}));


export const GET_ALL_PRODUCTS = gql`
  query getProducts {
    products(
        order_by: {product_brand: asc, product_description: asc, fiat_list_price: asc}
        where: {company: {id: {_eq: "3414bfa3-06b5-4f98-84ce-95979a8ffa88"}}}
    ) {
        id
        product_description
        product_code
        product_brand
        price_point_token_type {
            id
            name
            is_counter
            is_crypto
        }
        fiat_type
        list_price_points_tokens
        list_points_token_type_id
        fiat_list_price
        coin_type
        coin_amount
        is_active
        discount_allowed
        company {
            id
            name
        }
        available_start
        available_end
        SKU_end
        SKU_begin
    }
    companies {
        id
        name
    }
    point_token_types {
        id
        name
        is_counter
        is_crypto
        company {
            id
            name
        }
    }
    transaction_types {
        id
        name
    }
    users(
        where: {id: {_eq: "5836857d-71e9-4960-8b6e-4a99cef2d68f"}}
    ) {
        id
        display_name
        customer_points {
            id
            balance
            points_token_types_id
            point_token_type {
                id
                name
            }
        }
    }
}`;

const BeltaView = (props) => {
  const classes = useStyles();
  const location=useLocation();

  const selectTransactionTypeId = (mode) => {
    const transactionTypes = props?.data?.transaction_types;

    const modeTransactionTypeMap = {
        'fiat': 'fiat_purchase',
        'coins': 'redeem_simple',
        'miles': 'redeem_simple',
        'token' : 'transfer_customer'
    }

    const typeName = modeTransactionTypeMap[mode];
    const filtered = transactionTypes?.filter(type => type?.name === typeName);

    return filtered ? filtered[0]?.id : null;
}

  const filterPointType = (id, pointTypeList) => {
      const matches = pointTypeList.filter(point => point.point_token_type && point.point_token_type.id === id);
      if (matches.length === 1) {
        return matches[0];
      }
      return matches;
  }

  const formatNumber = (number) => {
    const nfObject = new Intl.NumberFormat('en-US');
    return nfObject.format(number);
  }

  const defaultTransactionTypeId = selectTransactionTypeId('fiat');

  const [state, setState] = useState({
      mode: 'fiat',
      transaction_type_id: defaultTransactionTypeId
  });

  const toggleMode = (newMode) => {
    const transactionTypeId = selectTransactionTypeId(newMode);
    setState({
        mode: newMode,
        transaction_type_id: transactionTypeId
    });
}

  return (
    <Page title="Checkout | BELTA" style={{width: '100%'}}>
    <ThemeProvider theme={theme}>
      <GlobalStyles />
      <div className={classes.root}>
        <TopBar formatNumber={formatNumber} {...props}/>
        <Boxes
            mode={state.mode}
            toggleMode={toggleMode}
            formatNumber={formatNumber}
            filterPointType={filterPointType}
            transactionTypeId={state.transaction_type_id}
            refetch={props.refetch}
            {...props}
        />
      </div>
    </ThemeProvider>
    </Page>
  );
};

const GetBeltaView = (props) => {
    const classes = useStyles();

    const { loading, error, data, refetch } = useQuery(GET_ALL_PRODUCTS);

    if (loading) {
      return null;
    }

    if (error) {
      return <div className={ classes.error }>Error!<br />{error.message}</div>;
    }

    if (data) {
      return <BeltaView data={data} products={data.products} users={data.users} refetch={refetch}  {...props}/>;
    }
}

export default GetBeltaView;