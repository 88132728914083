import React from 'react';

const Success = (props) => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M15.5425 2.18737C15.3071 1.95159 14.9252 1.95118 14.6899 2.18637L7.45571 9.40143L4.84564 6.56665C4.62011 6.32184 4.2388 6.30596 3.99358 6.53146C3.74855 6.75699 3.73286 7.13849 3.95839 7.38352L6.99358 10.6798C7.10474 10.8006 7.2603 10.8708 7.42433 10.8742C7.42874 10.8744 7.43299 10.8744 7.43721 10.8744C7.59661 10.8744 7.74996 10.8111 7.86293 10.6985L15.5413 3.04021C15.7773 2.80506 15.7777 2.42315 15.5425 2.18737Z" fill="#31BD00"/>
      <path d="M15.397 7.39697C15.0639 7.39697 14.794 7.66691 14.794 8C14.794 11.7463 11.7463 14.794 8 14.794C4.25387 14.794 1.20603 11.7463 1.20603 8C1.20603 4.25387 4.25387 1.20603 8 1.20603C8.33306 1.20603 8.60303 0.936094 8.60303 0.603031C8.60303 0.269938 8.33306 0 8 0C3.58875 0 0 3.58875 0 8C0 12.4111 3.58875 16 8 16C12.4111 16 16 12.4111 16 8C16 7.66694 15.7301 7.39697 15.397 7.39697Z" fill="#31BD00"/>
    </svg>

  );
};

export default Success;
