import { createTheme, colors } from '@material-ui/core';
import shadows from './shadows';
import typography from './typography';

const theme = createTheme({
  palette: {
    background: {
      dark: '#ffffff',
      default: colors.common.white,
      paper: colors.common.white
    },
    primary: {
      main: '#00A896'
    },
    secondary: {
      main: '#00A896'
    },
    text: {
      primary: '#010A21',
      secondary: '#ffffff',
    }
  },
  shadows,
  typography
});

export default theme;
