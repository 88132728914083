import React from 'react'
import classes from './Toolbar.module.css'
import {Box, Tooltip, Fab,} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';

const Toolbar = props => {

  return (
    <div className={classes.Container}>
      <Box
        display="flex"
        justifyContent="space-between"
      >
        <div className={classes.Export}>
            Companies
        </div>

        <Tooltip
          title="Add new Users"
          aria-label="add"
          className={classes.Toolbar}
          /*onClick={e => {
            props.onToggle(e)
            props.toggleMode('add');
          }}*/
        >
          <Fab color="primary" className={classes.IconToolbar}>
            <AddIcon />
          </Fab>
        </Tooltip>
      </Box>
    </div>
  )
}

export default Toolbar
