import React, {  useState } from 'react';
import { useHistory } from "react-router-dom";
import { makeStyles } from '@material-ui/styles';

import {
    useStripe,
    useElements,
    PaymentElement
  } from '@stripe/react-stripe-js';

const useStyle=makeStyles(()=>({
  bntSubmit:{
    whiteSpace:'nowrap',
    border: '0',
    borderRadius:'4px',
    width:'180px',
    height:'30px',
    marginLeft:'30%',
    background:'#293bd4',
    marginTop:'20px',
    color:'white'
  },
  bntCancel:{
    whiteSpace:'nowrap',
    border: '0',
    borderRadius:'4px',
    width:'180px',
    height:'30px',
    marginLeft:'30%',
    background:'#eecc23',
    marginTop:'20px',
    color:'white'
  },
  titleCard:{
    marginBottom:'5px'
  },
  inputCard: {
    display: 'block',
    margin: '10px 0 20px 0',
    maxWidth: '500px',
    padding: '10px 14px',
    fontSize: '1em',
    fontFamily: "Source Code Pro",
    boxShadow: 'rgba(50, 50, 93, 0.14902) 0px 1px 3px',
    border: '0',
    outline: '0',
    borderRadius: '4px',
    background: 'white'
  },
  payment: {
    border:' #b2bec9 solid 1px',
    borderRadius: '3px',
    padding: '20px',
    margin: '20px 0',
    boxShadow: '0 30px 50px -20px rgb(50 50 93 / 25%)',
    marginTop: '3%',
    width: '30%',
    marginLeft: '36%',
  }
}))


const CheckoutForm  = () => {
    const classes=useStyle()

    const stripe = useStripe();
    const elements = useElements();
    const [isProcessing, setIsProcessing] = useState(false);

    const history = useHistory();
    let cancelButton = <button className={classes.bntCancel}
    onClick={e => {
      e.preventDefault();
      history.push('/company/storco')
    }}
   >
    Cancel
  </button>

    const handleSubmit = async (e) => {
      e.preventDefault();
      if (!stripe || !elements) {
        // Stripe.js has not yet loaded.
        // Make sure to disable form submission until Stripe.js has loaded.
        return;
      }
      setIsProcessing(true);
      // localStorage.clear()
      localStorage.setItem('isStorcoSuccess', true);
     await stripe.confirmPayment({
        elements,
        confirmParams: {
          // Make sure to change this to your payment completion page
          return_url: `${window.location.origin}/company/storco`,
        },
      });

      setIsProcessing(false);
    };
  return (
    <form onSubmit={handleSubmit} className={classes.payment}>
      <PaymentElement id="payment-element"  />
      <button className={classes.bntSubmit} >
      {isProcessing ? "Processing ... " : "Pay now"}
      </button>
      { cancelButton}
  </form>
  );
};

export default CheckoutForm;