import React from 'react';

const Error = (props) => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M8 0C3.57837 0 0 3.57803 0 8C0 12.4217 3.57803 16 8 16C12.4216 16 16 12.422 16 8C16 3.57834 12.422 0 8 0ZM8 14.75C4.26925 14.75 1.25 11.731 1.25 8C1.25 4.26922 4.26897 1.25 8 1.25C11.7308 1.25 14.75 4.26897 14.75 8C14.75 11.7308 11.731 14.75 8 14.75Z" fill="#CE0C0C"/>
      <path d="M10.7371 9.85315L8.88389 7.99997L10.7371 6.14678C10.9811 5.90272 10.9812 5.507 10.7371 5.2629C10.493 5.01881 10.0973 5.01884 9.85323 5.2629L8.00001 7.11609L6.14679 5.2629C5.90276 5.01881 5.50698 5.01881 5.26292 5.2629C5.01886 5.507 5.01886 5.90272 5.26295 6.14678L7.11614 7.99997L5.26295 9.85315C5.01886 10.0972 5.01883 10.493 5.26292 10.737C5.50708 10.9812 5.90279 10.9811 6.14679 10.737L8.00001 8.88384L9.85323 10.737C10.0972 10.9811 10.493 10.9811 10.7371 10.737C10.9812 10.4929 10.9812 10.0972 10.7371 9.85315Z" fill="#CE0C0C"/>
    </svg>
  );
};

export default Error;
