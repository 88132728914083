import React from 'react';

const AmericanExpressLogo = (props) => {
  return (
    <svg width="54" height="20" viewBox="0 0 54 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M13.9655 8.99183V8.28338L13.6369 8.99183H10.789L10.4604 8.28338V8.99183H5.09331L4.43611 7.52044H3.4503L2.73834 8.99183H0V4.57766L2.02637 0H5.91481L6.46247 1.3079V0H11.1724L12.213 2.17984L13.2535 0H28.2049C28.643 0 29.1359 0.108992 29.5193 0.326975V0H33.2982V0.490463C33.7911 0.163488 34.3935 0 34.9959 0H41.6227L42.2252 1.3079V0H46.497L47.2637 1.3079V0H51.3164V9.04632H47.1542L46.1684 7.46594V9.04632H40.9655L40.2535 7.46594H39.213L38.501 9.04632H35.3793C34.6673 9.04632 33.9554 8.82834 33.3529 8.39237V9.04632H27V6.92098C27 6.59401 26.7809 6.6485 26.7809 6.6485H26.5619V9.04632H13.9655V8.99183Z" fill="white"/>
      <path d="M10.8438 10.9537H19.0588L20.2636 12.3161L21.4685 10.9537H27.7667C28.15 10.9537 28.5882 11.0627 28.9168 11.2806V10.9537H34.5578C34.9959 10.9537 35.4888 11.0627 35.8722 11.2806V10.9537H43.1014V11.2806C43.4847 11.0627 43.8681 10.9537 44.3062 10.9537H49.0162V11.2806C49.3995 11.0627 49.8377 10.9537 50.221 10.9537H53.9452V19.455C53.5618 19.8365 53.0141 20 52.4665 20H47.5922V19.7275C47.2089 19.891 46.8255 20 46.4421 20H33.4624V17.8202C33.4624 17.6022 33.3529 17.5477 33.1886 17.5477H33.0243V20H28.9715V17.5477C28.5882 17.7112 28.2048 17.8202 27.7667 17.8202H26.288V20H21.3042L20.1541 18.6376L18.9492 20H10.8438V10.9537Z" fill="white"/>
      <path d="M36.7485 12.3161H41.8966V13.6785H38.3368V14.9319H41.8418V16.1308H38.3368V17.3842H41.8966V18.7466H36.7485V12.3161Z" fill="#0077A6"/>
      <path d="M51.9189 14.8229C53.8357 14.9319 54 15.9128 54 16.8937C54 17.9292 53.1238 18.7466 52.0832 18.7466C52.0284 18.7466 52.0284 18.7466 51.9737 18.7466H48.6329V17.3842H51.2069C51.6451 17.3842 52.3023 17.3842 52.3023 16.7847C52.3023 16.5123 52.1927 16.2943 51.6998 16.2398C51.4808 16.2398 50.714 16.1853 50.6045 16.1853C48.8519 16.1308 48.4686 15.2589 48.4686 14.2234C48.4138 13.2425 49.1805 12.4251 50.1663 12.3706C50.2211 12.3706 50.2759 12.3706 50.3854 12.3706H53.6714V13.733H51.2617C50.714 13.733 50.1116 13.6785 50.1116 14.2779C50.1116 14.6594 50.3854 14.7684 50.7688 14.7684C50.9331 14.7684 51.7546 14.8229 51.9189 14.8229Z" fill="#0077A6"/>
      <path d="M45.9493 14.8229C47.9209 14.9319 48.0304 15.9128 48.0304 16.8937C48.0304 17.9292 47.1541 18.7466 46.1136 18.7466C46.0588 18.7466 46.004 18.7466 45.9493 18.7466H42.6633V17.3842H45.2373C45.6754 17.3842 46.3326 17.3842 46.3326 16.7847C46.3326 16.5123 46.2231 16.2943 45.7302 16.2398C45.5111 16.2398 44.7444 16.1853 44.6349 16.1853C42.8823 16.1308 42.499 15.2589 42.499 14.2234C42.4442 13.2425 43.2109 12.4251 44.142 12.3706C44.1967 12.3706 44.3063 12.3706 44.361 12.3706H47.7018V13.733H45.2921C44.7444 13.733 44.142 13.6785 44.142 14.2779C44.142 14.6594 44.4158 14.7684 44.7992 14.7684C44.9635 14.7684 45.8397 14.8229 45.9493 14.8229Z" fill="#0077A6"/>
      <path d="M27.2191 12.3161H21.6876L19.8803 14.2779L18.1278 12.2616H11.8844V18.6376H17.9087L19.8256 16.5123L21.6876 18.6376H24.6998V16.4578H26.8357C27.6572 16.4578 29.1359 16.4578 29.1359 14.1689C29.2455 13.188 28.5335 12.3161 27.5477 12.2071C27.4382 12.3161 27.3286 12.3161 27.2191 12.3161ZM17.0872 17.3842H13.4726V16.1308H16.9777V14.9319H13.4726V13.733H17.3063L18.8398 15.4768L17.0872 17.3842ZM23.1116 18.1471L20.8661 15.4768L23.1116 12.97V18.1471ZM26.7262 15.2589H24.7546V13.733H26.7262C27.1643 13.6785 27.5477 14.0054 27.6025 14.3869C27.6572 14.8229 27.3286 15.2044 26.9453 15.2589C26.8357 15.2589 26.781 15.2589 26.7262 15.2589Z" fill="#0077A6"/>
      <path d="M34.8316 15.8038C35.5436 15.5313 35.9817 14.8229 35.9269 14.0055C35.9269 12.6431 34.8316 12.3161 34.0101 12.3161H29.9026V18.7466H31.4908V16.5123H33.572C34.1196 16.5123 34.3387 17.0572 34.3387 17.6022L34.3935 18.8011H35.9269L35.8722 17.4387C35.8722 16.4033 35.5983 15.9128 34.8316 15.8038ZM33.4624 15.1499H31.4908V13.733H33.4624C33.9006 13.6785 34.2839 13.951 34.3387 14.3869C34.3387 14.4414 34.3387 14.4414 34.3387 14.4959C34.3935 14.8774 34.1196 15.1499 33.4624 15.1499Z" fill="#0077A6"/>
      <path d="M32.1481 1.2534H30.5598V7.68392H32.1481V1.2534Z" fill="#0077A6"/>
      <path d="M17.6349 1.3079H22.8377V2.6703H19.2231V3.86921H22.7282V5.06812H19.2231V6.32152H22.8377V7.68392H17.6349V1.3079Z" fill="#0077A6"/>
      <path d="M28.643 4.79564C29.355 4.52316 29.7931 3.81471 29.7383 2.99727C29.7383 1.63487 28.643 1.3079 27.8215 1.3079H23.714V7.68392H25.3022V5.44959H27.3834C27.931 5.44959 28.1501 5.99455 28.1501 6.53951L28.2049 7.68392H29.7383L29.6836 6.37602C29.6836 5.3406 29.4097 4.85013 28.643 4.79564ZM27.2738 4.08719H25.3022V2.6703H27.2738C27.712 2.6158 28.0953 2.88828 28.1501 3.32425C28.1501 3.37874 28.1501 3.37874 28.1501 3.43324C28.1501 3.81471 27.931 4.08719 27.2738 4.08719Z" fill="#0077A6"/>
      <path d="M14.0203 1.3079L12.0487 5.55858L10.1318 1.2534H7.66734V7.41144L4.92901 1.3079H2.84787L0 7.68392H1.643L2.24544 6.26703H5.42191L6.02434 7.68392H9.20081V2.94278L11.3367 7.68392H12.7606L14.9513 2.99727V7.68392H16.5396V1.3079H14.0203ZM2.90264 4.90463L3.83367 2.6158L4.81947 4.90463H2.90264Z" fill="#0077A6"/>
      <path d="M48.4685 1.3079V5.72207L45.7849 1.3079H43.43V7.30245L40.7464 1.3079H38.6653L36.4746 6.26703C36.4746 6.26703 35.5436 6.26703 35.4888 6.26703C35.1054 6.21253 34.503 5.94005 34.503 4.79564V4.30517C34.503 2.77929 35.3245 2.6703 36.3651 2.6703H37.3509V1.3079H35.3245C34.6125 1.3079 33.0243 1.85286 32.9695 4.46866C32.9148 6.26703 33.6815 7.68392 35.4888 7.68392H37.5699L38.1724 6.26703H41.3488L41.9513 7.68392H45.073V2.99727L47.9208 7.68392H50.0567V1.3079H48.4685ZM38.7748 4.90463L39.7058 2.6703L40.6916 4.90463H38.7748Z" fill="#0077A6"/>
    </svg>


  );
};

export default AmericanExpressLogo;
