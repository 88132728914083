import React from 'react';

const SelectIconArrow = (props) => {
  return (
    <svg {...props} width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1 1L5 5L9 1" stroke="#6B799D" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>

  );
};

export default SelectIconArrow;
