import React from 'react';
import {useHistory} from 'react-router-dom';
import {
  Box,
  Container,
  makeStyles
} from '@material-ui/core';
import Page from 'src/components/Page';
import Notifications from './Notifications';
import Password from './Password';
import SuperAdmin from './SuperAdmin';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const SettingsView = () => {
  const classes = useStyles();
  const history = useHistory();

  const cancel = (route) => {
      history.push(route)
  }

  return (
    <Page
      className={classes.root}
      title="Settings"
    >
      <Container maxWidth="lg">
        <Notifications cancel={cancel}/>
        <Box mt={3}>
        <SuperAdmin/>
        </Box>
        <Box mt={3}>
          <Password cancel={cancel}/>
        </Box>
      </Container>
    </Page>
  );
};

export default SettingsView;
