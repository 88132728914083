import React from 'react';

const VisaLogo = (props) => {
  return (
    <svg width="31" height="10" viewBox="0 0 31 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M13.4378 9.83169H10.9113L12.4883 0.172417H15.019L13.4378 9.83169Z" fill="#0066B2"/>
      <path d="M8.78106 0.172415L6.37009 6.81856L6.08523 5.38588L5.23479 1.04269C5.18337 0.772929 5.03052 0.532777 4.80734 0.371104C4.58416 0.20943 4.30742 0.138381 4.03343 0.172415H0.0454122L0 0.336618C0.933655 0.568106 1.82611 0.940519 2.64629 1.44089L4.84259 9.85222H7.48062L11.5058 0.172415H8.78106Z" fill="#0066B2"/>
      <path d="M28.6798 9.85222H31L28.9771 0.172416H26.9418C26.6956 0.153483 26.4497 0.212875 26.2397 0.342011C26.0297 0.471147 25.8664 0.663337 25.7735 0.890807L22.0001 9.85222H24.6382L25.1831 8.39902H28.3991L28.6798 9.85222ZM25.8932 6.43268L27.2225 2.81609L27.9698 6.43268H25.8932Z" fill="#0066B2"/>
      <path d="M22.1983 2.4959L22.5616 0.443354C21.8302 0.186949 21.0623 0.0483668 20.2869 0.0328445C19.0484 0.0328445 16.047 0.578821 16.047 3.23071C16.047 5.7266 19.5479 5.75945 19.5479 7.06897C19.5479 8.37849 16.4103 8.1445 15.3741 7.31938L14.9984 9.44171C15.9024 9.8186 16.8752 10.0045 17.8553 9.98769C19.5809 9.98769 22.1859 9.09688 22.1859 6.67899C22.1859 4.17078 18.652 3.93268 18.652 2.84073C18.652 1.74877 21.1167 1.90066 22.1983 2.4959Z" fill="#0066B2"/>
      <path d="M6.08523 5.38588L5.23479 1.04269C5.18337 0.772929 5.03052 0.532777 4.80734 0.371104C4.58416 0.20943 4.30742 0.138381 4.03343 0.172415H0.0454122L0 0.336618C1.37224 0.6836 2.65254 1.32224 3.7527 2.20854C4.79893 3.04407 5.60377 4.1404 6.08523 5.38588Z" fill="#F9A533"/>
    </svg>



  );
};

export default VisaLogo;
