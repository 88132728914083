import React, { useEffect, useState } from 'react';
import { Box } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import ItemCart from './ItemCart';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import Scrollbar from 'react-scrollbars-custom';
import { makeStyles } from '@material-ui/styles';
import recommendations from '../recommendOptions';

const useStyle=makeStyles(()=>({
  scrollbarss:{
    '& .ScrollbarsCustom-TrackY':{
      background:'#fff !important',
      width:'5px !important',
      right:'-10px !important',
      '& div':{
        background:'#FF5F00 !important'

      }
    }
  },

  recommendation_desc: {
      fontSize: '24px !important'
  },

  recommend_col: {
      display: 'flex',
      flexDirection: 'column',
  },

  audio: {
    height: 24,
    width: 300
  },

  payment_options_row:{
    display: 'flex',
    flexDirection: 'row',
    marginTop:50,
  },
  payment_options_button:{
    background:'#000000',
    color:'#fff',
    borderRadius:100,
    padding:16,
    fontWeight:'bold',
    fontSize:16,
    height: 57,
    textTransform:'none',
  },
  payment_options_span:{
    marginTop: 12,
    maxWidth: '315px',
    '& span': {
      fontFamily: 'Open Sans Regular',
      fontSize: '14px !important',
      lineHeight: '20px'
    }
  }
}))
const MainBox = withStyles(() => ({
  root: {
    marginTop: 40,
    height:'50%',
  },

}))(Box);

const StyledButton=withStyles(()=>({
    root:{
      background:'#000000',
      color:'#fff',
      borderRadius:100,
      width: 200,
      padding:16,
      fontWeight:'bold',
      height: 57,
      marginRight: 50,
      textTransform:'none',
      '&:hover':{
        background:'#000000',
      }
    },
    label: {
        fontSize: '24px !important',
    }
  }))(Button);


const ListCart = (props) => {
    const classes=useStyle()
    const propsProducts = props?.products ? props.products : [];
    const sizes = ['S', 'M', 'L', 'XL'];
    const recommendOptions = recommendations?.storco;

    const [recommendationState, setRecommendationState] = useState({
        recommendation: null,
        idx: null
    });

    const [audioDelay, setaudioDelay] = useState(false);

    const renderItems = () => {
        if (props.products) {
            return props.products.map((product, index) => {
                return <ItemCart key={index} product={product} size={sizes[index]} mode={props.mode} updateProductQuantity={props.updateProductQuantity} productQuantityMap={props.productQuantityMap} formatNumber={props.formatNumber}/>
            });
        }
    }

    const roundRobinRecommend = () => {
        let idx, recommendation;
        if (recommendationState.idx == null || recommendationState.idx == recommendOptions.length - 1) {
            idx = 0;
            recommendation = recommendOptions[idx];
        } else {
            idx = recommendationState.idx + 1;
            recommendation = recommendOptions[idx];
        }
        props.toggleMode(recommendation.mode);
        setRecommendationState({
            recommendation: recommendOptions[idx],
            idx: idx
        });

        setaudioDelay(true);
        timeoutAudioDelay();
    }

    const timeoutAudioDelay = () => {
        setTimeout(setaudioDelay, 1000, false)
    }


  return (
<Scrollbar
  style={{ width: '100%', height: 'calc(100vh - 154px)'}}
  noScrollX={true}
  className={classes.scrollbarss}
>
    <MainBox>
        {
            renderItems()
        }
    </MainBox>
  <Box className={classes.payment_options_row}>
      <StyledButton onClick={e => {
          roundRobinRecommend();
      }}>
          Recommend
      </StyledButton>
      <Box className={classes.recommend_col}>
        <span className={classes.recommendation_desc}>
            {recommendationState?.recommendation ? recommendationState.recommendation.message : ''}
        </span>
        {
            recommendationState?.recommendation != null ? (
                audioDelay ? <CircularProgress size="35px" color="black"/> : (
                    <audio className={classes.audio} key={recommendationState.idx} controls autoPlay>
                        <source src={`/static/audio/${recommendationState.recommendation.file}`} type="audio/mp3" />
                        Your browser does not support the audio element.
                    </audio>
                )
            ) : ''
        }
      </Box>
  </Box>
</Scrollbar>
  );
};

export default ListCart;