import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core';
import TopBar from './tolbar/TopBar';
import { ThemeProvider } from '@material-ui/core';
import GlobalStyles from 'src/components/GlobalStyles';
import theme from 'src/theme';
import PaymentOptions from './PaymentOptions';
import Cart from './Cart'
import { useLocation } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import Loading from '../../components/ui/Loading/Loading';
import Page from '../../components/Page';
import gql from 'graphql-tag';
import { getAllDataOfUser } from 'src/utils/handleApi';


const useStyles = makeStyles((theme) => ({
    root: {
      backgroundColor: theme.palette.background.dark,
      display: 'flex',
      height: '100%',
      overflow: 'hidden',
      width: '100%'
    },
    wrapper: {
      display: 'flex',
      flex: '1 1 auto',
      overflow: 'hidden',
      paddingTop: 90,
      paddingLeft: 290
    },
    contentContainer: {
      display: 'flex',
      flex: '1 1 auto',
      overflow: 'hidden'
    },
    content: {
      flex: '1 1 auto',
      height: '100%',
      overflow: 'auto',
      paddingLeft: 0
    },
    loading:{
      marginLeft:800
    }
  }));


const RetailCo = (props) => {
  const classes = useStyles();

    const selectTransactionTypeId = (mode) => {
        const transactionTypes = props.propsTransactionType ?  props.propsTransactionType : [];

        const modeTransactionTypeMap = {
            'fiat': 'fiat_purchase',
            'points': 'redeem_simple',
            'coins': 'transfer_customer',
        }

        const typeName = modeTransactionTypeMap[mode];
        const filtered = transactionTypes?.filter(type => type?.name === typeName);

        return filtered ? filtered[0]?.id : null;
    }

    const defaultTransactionTypeId = selectTransactionTypeId('fiat');

  const [state, setState] = useState({
      mode: 'fiat',
      transaction_type_id: defaultTransactionTypeId
  });

  const propsProducts = props.products ? props.products: [];
  let propsProductsQuantityMap = {};
  propsProducts.forEach(product => {
    propsProductsQuantityMap[product?.id] = 1;
  });

  const [productQuantityMap, setProductQuantityMap] = useState(propsProductsQuantityMap);

  const toggleMode = (newMode) => {
      const transactionTypeId = selectTransactionTypeId(newMode);
      setState({
          mode: newMode,
          transaction_type_id: transactionTypeId
      });
  }

  const updateProductQuantity = (productId, quantity) => {
    setProductQuantityMap({
        ...productQuantityMap,
        [productId]: quantity
    });
  }

  const formatNumber = (number) => {
    const nfObject = new Intl.NumberFormat('en-US');
    return nfObject.format(number);
  }


  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles />

          <Page title="Checkout | RetailCorp" style={{width: '100%'}}>
          <div className={classes.root}>
            <TopBar
                currentCompany={props.currentCompany}
                currentUser={props.currentUser}
            />
            <Cart
                currentCompany={props.currentCompany}
                currentUser={props.currentUser}
                mode={state.mode} toggleMode={toggleMode} products={props.products} updateProductQuantity={updateProductQuantity} productQuantityMap={productQuantityMap} formatNumber={formatNumber}/>
            <PaymentOptions
                currentCompany={props.currentCompany}
                currentUser={props.currentUser}
                mode={state.mode}
                products={props.products}
                toggleMode={toggleMode}
                productQuantityMap={productQuantityMap}
                user={props.users ? props.users[0] : []}
                transactionTypeId={state.transaction_type_id}
                refetch={props.refetch}
                formatNumber={formatNumber}
            />
          </div>
          </Page>
  
    </ThemeProvider>
  );
};

const GetRailcoView = (props) => {
      return <RetailCo  {...props}/>;
}

export default GetRailcoView;
