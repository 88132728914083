import { createStyles, makeStyles } from '@material-ui/core';
import OpenSans from "../fonts/open-sans/OpenSans-Bold.ttf";

const useStyles = makeStyles(() => createStyles({
  '@global': {
    '*': {
      boxSizing: 'border-box',
      margin: 0,
      padding: 0,
    },
    html: {
      '-webkit-font-smoothing': 'antialiased',
      '-moz-osx-font-smoothing': 'grayscale',
      height: '100%',
      width: '100%'
    },
    body: {
      fontFamily: 'Open Sans',
      backgroundColor: '#ffffff',
      height: '100%',
      width: '100%',
    },
    a: {
      textDecoration: 'none'
    },
    '#root': {
      height: '100%',
      width: '100%',
      backgroundColor: '#ffffff',
    },
    p: {
      margin: '0',
      padding: '0'
    },
  }
}));

const GlobalStyles = () => {
  useStyles();

  return null;
};

export default GlobalStyles;
