import React from 'react';

const Clock = (props) => {
  return (
    <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M6.78156 5.88295L5.38728 4.83725V2.70713C5.38728 2.49295 5.21416 2.31982 4.99998 2.31982C4.7858 2.31982 4.61267 2.49295 4.61267 2.70713V5.03092C4.61267 5.15291 4.67 5.26795 4.76759 5.34076L6.31678 6.50266C6.38648 6.55494 6.46783 6.58012 6.54877 6.58012C6.66689 6.58012 6.78308 6.52705 6.859 6.4248C6.98762 6.25398 6.95275 6.01115 6.78156 5.88295Z" fill="#010A21" fillOpacity="0.64"/>
      <path d="M5 0C2.24283 0 0 2.24283 0 5C0 7.75717 2.24283 10 5 10C7.75717 10 10 7.75717 10 5C10 2.24283 7.75717 0 5 0ZM5 9.22541C2.67041 9.22541 0.77459 7.32959 0.77459 5C0.77459 2.67041 2.67041 0.77459 5 0.77459C7.32998 0.77459 9.22541 2.67041 9.22541 5C9.22541 7.32959 7.32959 9.22541 5 9.22541Z" fill="#010A21" fillOpacity="0.64"/>
    </svg>
  );
};

export default Clock;
