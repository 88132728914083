import React, { useEffect, useMemo, useState } from 'react';
import clsx from 'clsx';
import classesFile from './LoyaltyInput.module.css';
import PropTypes from 'prop-types';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableContainer,
   Box, Button, Card, CardContent, CardHeader, Divider, Grid, TextField, makeStyles, Typography, IconButton } from '@material-ui/core';
import gql from "graphql-tag";
import {auth} from "src/utils/nhost";
import { useMutation } from "@apollo/client";
import  Select from 'react-select';
import countryList from 'react-select-country-list'
import {GET_USER} from './index';
import {useHistory} from 'react-router-dom';
import InfoIcon from '@material-ui/icons/Info';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import CloseIcon from '@material-ui/icons/Close';
import Pagination from '@material-ui/lab/Pagination';
import moment from 'moment';
import { getBalanceClimateReward, getListCarbonOffsets, getRewarData } from 'src/utils/handleApi';
import { REWARD, TYPE_POINT } from 'src/utils/constant';


const UPSERT_USER_AND_LOCATION = gql`
    mutation upsertUserAndLocation (
        $userId: uuid!
        $first_name: String
        $last_name: String
        $display_name: String
        $user_phone: String
        $city: String
        $address_1: String
        $address_2: String
        $postal_code: String
        $country: String
) {
  insert_users_one (
    object: {
        id: $userId
        first_name: $first_name
        last_name: $last_name
        display_name: $display_name
        user_phone: $user_phone

        location: {
            data: {
                city: $city
                address_1: $address_1
                address_2: $address_2
                postal_code: $postal_code
                country: $country
            }
            on_conflict: {
                constraint: locations_address_1_address_2_city_state_province_postal_code_c
                update_columns: [city, address_1, address_2, state_province, postal_code, country]
            }
        }
    }
    on_conflict: {
        constraint: users_pkey
        update_columns: [first_name, last_name, display_name, user_phone, location_id]
    }
) {
    id
    first_name
    last_name
    user_phone
    location_id
    location {
        id
        city
        address_1
        address_2
        postal_code
        country
    }
}}`;


const customStyles={
  control: (base, state) => ({
    ...base,

    position: 'relative',
    top: -15,
    height:42,
    boxShadow: "none",
    border:'none',
    borderRadius:0,
    borderBottom:'2px solid #DCDCDC',
    '&:hover':{
      borderColor:'#00A896'
    },
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontWeight: "400",
    fontSize:'1rem',
    color:'#000'
  }),
  dropdownIndicator:(styles,state)=>({
    ...styles,
    transform: state.selectProps.menuIsOpen?'rotate(-90deg)':'rotate(0deg)',
    transition: 'all .3s',
  }),
  placeholder:(styles,state)=>({
    ...styles,
    color:'#000',
    top:27,
  }),
  valueContainer:(styles,state)=>({
    ...styles,
    padding:0,
  }),
  option:(styles,{ data, isDisabled, isFocused, isSelected })=>({
    ...styles,
    backgroundColor: isFocused ? "#00A896" : isSelected ?null:null,
    color:isFocused?"#FFFFFF":'#000',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
  fontWeight: "400",
    cursor:'pointer',

  }),
  input:(styles,state)=>({
    ...styles,
    paddingTop:10,
    paddingBottom:0,
    margin:0,
  }),
  menu:(styles)=>({
    ...styles,
    top:20,
  })


};
const useStyles = makeStyles(() => ({
  root: {
    '& .MuiFormLabel-root': {
      color: '#010A21',

    },
    '& .MuiPaper-root':{
      overflow:'visible',
    }
  },
  buttonSave: {
    color: '#ffffff',
    background: '#00A896',
    borderRadius: '8px'
  },
  label_span: {
    fontSize: 12,
    zIndex: 1,
    position: 'relative',
    fontFamily: 'Roboto',
  },

  bnt_offset_detail: {
    fontSize: '13px',
    marginTop: '10px',
    padding:'5px',
    color: '#ffffff',
    background: '#00A896',
    borderRadius: '8px',
    border: 'none',
    '&:hover': {
      cursor: 'pointer'
    }
  },

  pagination : {
    float: 'right',
  }
}));

const ProfileDetails = ({onToggle,props,user, cancel, location, disabled, className, ...rest}) => {
  const classes = useStyles();
  const userId = user?.id
  const options = countryList().getData()
  const initialCountry = () => {
    const fetchedCountry = location?.country ? location.country : null;
    const filtered = options.filter(option => option?.value === fetchedCountry);

    if (filtered) {
        return filtered[0];
    }
    return fetchedCountry;
  }
  const initCountry = initialCountry();

  const [first_name, setFirstName] = useState(user?.first_name);
  const [last_name, setLastName] = useState(user?.last_name);
  const [user_phone, setUserPhone] = useState(user?.user_phone);
  const [user_email, setUserEmail] = useState(user?.account ? user.account.email : '');
  const [city,setCity]=useState(location?.city ? location.city : null);
  const [address_1,setAddress1]=useState(location?.address_1 ? location.address_1 : null);
  const [address_2,setAddress2]=useState(location?.address_2 ? location.address_2 : null);
  const [postal_code,setPostalCode]=useState(location?.postal_code ? location.postal_code : null)
  const [display_name,setDisplayName]=useState(user?.display_name ? user.display_name : null)
  const [country, setCountry] = useState(initCountry)
  const [currLocation, setCurrLocation]=useState(location?.country==='USA'?'United States':location?.country)
  const [carbonOffsetPoint, setCarbonOffsetPoint] = useState('0')
  const [belltaMilePoint, setBelltaMilePoint] = useState('0')
  const [belltaCoinPoint, setBelltaCoinPoint] = useState('0')
  const [retailCoin, setRetailCoin] = useState('0')
  const [storcoCoinPoin, setStorcoCoinPoin] = useState('0')
  const [climateCoinsData, setClimateCoinsData] = useState('0')
  const [dialogData, setDialogData] = useState(false)
  const [dialogSoliPoint, setDialogSoliPoint] = useState(false)
  const [listCarbonOffsets, setListCarbonOffsets] = useState([])
  const [listSoliPoint, setlistSoliPoint] = useState([])
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const history = useHistory();

  const [ upsertUserAndLocation ] = useMutation(UPSERT_USER_AND_LOCATION);
  let cancelFn;
  if (!cancel || cancel === undefined) {
    cancelFn = () => {
        history.push('/dashboard');
    }
  } else {
      cancelFn = cancel;
  }

  // let cancelSave;
  // if (!onToggle || onToggle === undefined) {
  //   onToggle = () => {
  //       history.push('/dashboard');
  //   }
  // } else {
  //   cancelSave = onToggle;
  // }

  const sendRequest = () => {
    upsertUserAndLocation({
        variables: {
            userId: userId,
            first_name: first_name,
            last_name: last_name,
            display_name: display_name,
            user_phone: user_phone,
            address_1: address_1,
            address_2: address_2,
            postal_code: postal_code,
            city: city,
            country: country?.value
        }
    });
  };



  useEffect(() => {
    (async function () {
      if (props.mode === 'edit') {
        const [carbonOffsetData, belltaMileData, belltaCoinData, storcoCoinData, detailsCarbonOffset,detailsClimate, retailCoin] = await Promise.all([
          getRewarData(userId, REWARD.CARBON_OFFSET_ID),
          getRewarData(userId, REWARD.BELTA_MILE_ID),
          getRewarData(userId, REWARD.BELTA_COIN_ID),
          getRewarData(userId, REWARD.STORCO_COIN_ID),
          getListCarbonOffsets(userId,TYPE_POINT.CARBON_OFFSET),
          getListCarbonOffsets(userId,TYPE_POINT.CLIMATE_COIN),
          
          getRewarData(userId, REWARD.RETAIL_COIN_ID),
        ])

          setListCarbonOffsets(detailsCarbonOffset.data)


        if (carbonOffsetData.data.length > 0) {
          setCarbonOffsetPoint(carbonOffsetData.data[0].balance)
        }

        if (belltaMileData.data.length > 0) {
          setBelltaMilePoint(belltaMileData.data[0].balance)
        }

        if (belltaCoinData.data.length > 0) {
          setBelltaCoinPoint(belltaCoinData.data[0].balance)
        }

        if (storcoCoinData.data.length > 0) {
          setStorcoCoinPoin(storcoCoinData.data[0].balance)
        }

        if (userId === '5d51fc5b-9539-4950-9616-c58cf53337be') {
          const climateCoinsData = await getBalanceClimateReward(userId)
          setClimateCoinsData(climateCoinsData.data.totalPoints)
          setlistSoliPoint(detailsClimate.data)
        }

        if (retailCoin.data.length > 0) {
          setRetailCoin(retailCoin.data[0].balance)
        }
      }
    })();
  }, []);


  const changeHandler = value => {
    setCountry(value);
  };

  const closeSoliPoints = () => {
    setDialogSoliPoint(false)
  }

  const showCarbonDialog = () => {
    setDialogData(true)
  }

  const closeCarbonDialog = () => {
    setDialogData(false)
  }
  
  const formatDate = (date) => {
    return moment(date).format('MM-DD-YYYY h:mm a')
  }

  const handlePageChange = (e,newPage) => {
    e.preventDefault();
    setPage(newPage);
  };

  const data = <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
    <Grid
      item
      md={6}
      xs={12}
      style={{ margin: 12 }}>
      <TextField
        fullWidth
        label="Belta Miles"
        value={belltaMilePoint}
      />
    </Grid>
    <Grid
      item
      md={6}
      xs={12}
      style={{ margin: 12 }}
    >
      <TextField
        fullWidth
        label="Belta Coins"
        value={belltaCoinPoint}
      />
    </Grid>
    <Grid
      item
      md={6}
      xs={12}
      style={{ margin: 12 }}
    >
      <TextField
        fullWidth
        label="Retail Coin"
        value={retailCoin}
      />
    </Grid>
  </div>
  const dataPoint = <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
    <Grid
      item
      md={6}
      xs={12}
      style={{ margin: 12 }}
    >
      <TextField
        fullWidth
        label="Carbon Offset"
        value={carbonOffsetPoint}
      />
      <button
        className={classes.bnt_offset_detail}
        color="primary"
        onClick={(e) => {
          e.preventDefault();
          showCarbonDialog()
      }}
      > Offset details </button>
    </Grid>

    <Grid
      item
      md={6}
      xs={12}
      style={{ margin: 12 }}
    >
      <TextField
        fullWidth
        label="Storco Coins"
        value={storcoCoinPoin}
      />
    </Grid>
    <Grid
      item
      md={6}
      xs={12}
      style={{ margin: 12 }}
    >
      <TextField
        fullWidth
        label="Climate donations"
        value={climateCoinsData}
      />
      <button
        className={classes.bnt_offset_detail}
        color="primary"
        onClick={(e) => {
          e.preventDefault();
          setDialogSoliPoint(true)
        }}
      > Climate project donations </button>
    </Grid>
  </div>


  return (
    <form
      noValidate
      className={clsx(classes.root, className)}
      {...rest}
    >
      <Card>
        <CardHeader
          subheader="The information can be edited"
          title={
            <Typography gutterBottom component="h1">
              Profile
            </Typography>
          }
        />
        <Divider/>
        <CardContent>
          <Grid
            container
            spacing={3}
          >
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                required
                id="standard-multiline-flexible"
                label="First name"
                name="FirstName"
                value={first_name}
                onChange={e => setFirstName(e.target.value)}
              />
            </Grid>

            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="Last name"
                name="lastName"
                onChange={e => setLastName(e.target.value)}
                required
                value={last_name}
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="Display name"
                name="displayName"
                onChange={e => setDisplayName(e.target.value)}
                required
                value={display_name}
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                disabled
                label="Email Address"
                name="email"
                onChange={e => setUserEmail(e.target.value)}
                type="email"
                value={user_email}
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="Phone Number"
                name="phoneNumber"
                onChange={e => setUserPhone(e.target.value)}
                value={user_phone}
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="Address 1"
                name="address_1"
                onChange={e => setAddress1(e.target.value)}
                value={address_1}
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="Address 2"
                name="address_2"
                onChange={e => setAddress2(e.target.value)}
                value={address_2}
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="City"
                name="city"
                value={city}
                onChange={e => {e.preventDefault(); setCity(e.target.value); }}
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="Postal code"
                name="postal_code"
                onChange={e => setPostalCode(e.target.value)}
                value={postal_code}
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <span className={classes.label_span}>Country</span>
              <Select

                styles={customStyles}
                indicatorSeparator={false}
                options={options}
                placeholder={country?.label}
                value={country?.value}
                onChange={changeHandler}
                components={{IndicatorSeparator: () => null}}
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    text: 'null',
                    primary25: 'null',
                    primary: 'null',
                    primary50: 'null',
                  },
                })}

              />
            </Grid>
            {
              user && belltaCoinPoint && belltaMilePoint && data
            }
            {
              user  && carbonOffsetPoint && storcoCoinPoin &&  dataPoint
            }

            {/*<Grid*/}
            {/*  item*/}
            {/*  md={6}*/}
            {/*  xs={12}*/}
            {/*>*/}
            {/*  <TextField*/}
            {/*    fullWidth*/}
            {/*    label="Country"*/}
            {/*    name="country"*/}
            {/*    onChange={ handleChange }*/}
            {/*    required*/}
            {/*    value={values.country}*/}
            {/*    variant="outlined"*/}
            {/*  />*/}
            {/*</Grid>*/}
            {/*<Grid*/}
            {/*  item*/}
            {/*  md={6}*/}
            {/*  xs={12}*/}
            {/*>*/}
            {/*  <TextField*/}
            {/*    fullWidth*/}
            {/*    label="Select State"*/}
            {/*    name="state"*/}
            {/*    onChange={ handleChange }*/}
            {/*    required*/}
            {/*    select*/}
            {/*    SelectProps={{ native: true }}*/}
            {/*    value={values.state}*/}
            {/*    variant="outlined"*/}
            {/*  >*/}
            {/*    {states.map((option) => (*/}
            {/*      <option*/}
            {/*        key={option.value}*/}
            {/*        value={option.value}*/}
            {/*      >*/}
            {/*        {option.label}*/}
            {/*      </option>*/}
            {/*    ))}*/}
            {/*  </TextField>*/}
            {/*</Grid>*/}
          </Grid>
        </CardContent>
        <Divider/>
        <Box
          display="flex"
          justifyContent="flex-end"
          p={2}
        >
        <div className={classesFile.ButtonWrapper}>
          <button className={classesFile.ButtonSave} disabled={disabled === true} onClick={e => {
              e.preventDefault();
              sendRequest();
              // cancelFn();
              setTimeout((window.location.reload()),3000)
              // cancelSave()
          }}>
            Save
          </button>
          <div className={classesFile.ButtonCancel} onClick={cancelFn}>
            Cancel
          </div>
        </div>
        </Box>
      </Card>

      {
        dialogData
        &&
        <Dialog
          onClose={closeCarbonDialog}
          open={dialogData}
          fullWidth={true}
          maxWidth={'lg'}
          aria-labelledby="ruleset-dialog-title"
        >
          <DialogTitle
            id="ruleset-dialog-title"
            disableTypography
            style={{ paddingTop: 0, paddingBottom: 0 }}
          >
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <span style={{ display: 'flex', alignItems: 'center' }}>
                <h2>Carbon offsets for {first_name} customer</h2>
              </span>
              <DialogActions>
                <IconButton onClick={closeCarbonDialog}>
                  <CloseIcon />
                </IconButton>
              </DialogActions>
            </div>
          </DialogTitle>
          <Divider />
          <DialogContent>
            <TableContainer>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>Source</TableCell>
                    <TableCell align="right">Volume ID</TableCell>
                    <TableCell align="right">Count</TableCell>
                    <TableCell align="right">From serial #</TableCell>
                    <TableCell align="right">From subfraction</TableCell>
                    <TableCell align="right">To serial #</TableCell>
                    <TableCell align="right">To subfraction</TableCell>
                    <TableCell align="right">Date</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {
                    listCarbonOffsets ? listCarbonOffsets.slice((page - 1) * limit, page * limit).map((carbon) => {
                      return (
                        <TableRow
                          sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                          <TableCell align="right">Soli</TableCell>
                          <TableCell align="right">{carbon.carbon_offset_volume}</TableCell>
                          <TableCell align="right">{carbon.count_of_offsets}</TableCell>
                          <TableCell align="right">{carbon.serial_number_from}</TableCell>
                          <TableCell align="right">{carbon.subfraction_from}</TableCell>
                          <TableCell align="right">{carbon.serial_number_to}</TableCell>
                          <TableCell align="right">{carbon.subfraction_to}</TableCell>
                          <TableCell align="right"> {formatDate(carbon.xact_datetime_at)}</TableCell>
                        </TableRow>
                      )
                    })
                      : null
                  }
                </TableBody>
              </Table>
            </TableContainer>
          </DialogContent>
          <div style={{float:'right',marginTop: 10, marginRight:30, marginBottom:10}}>
          <Pagination
            className={classes.pagination}
            defaultPage={1}
            onChange={handlePageChange}
            boundaryCount={2}
            count={Math.round(Number(listCarbonOffsets.length)/ limit)}
            color='primary' />
          </div>

        </Dialog>

      }

      {
        dialogSoliPoint
        &&
        <Dialog
          onClose={closeSoliPoints}
          open={dialogSoliPoint}
          fullWidth={true}
          maxWidth={'xl'}
          aria-labelledby="ruleset-dialog-title"
        >
          <DialogTitle
            id="ruleset-dialog-title"
            disableTypography
            style={{ paddingTop: 0, paddingBottom: 0 }}
          >
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <span style={{ display: 'flex', alignItems: 'center' }}>
                <h2>Climate project donations for {first_name} customer</h2>
              </span>
              <DialogActions>
                <IconButton onClick={closeSoliPoints}>
                  <CloseIcon />
                </IconButton>
              </DialogActions>
            </div>
          </DialogTitle>
          <Divider />
          <DialogContent>
            <TableContainer>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>Source</TableCell>
                    <TableCell align="right">Campaign Name</TableCell>
                    <TableCell align="right">Company Name</TableCell>
                    <TableCell align="right">Customer Id</TableCell>
                    <TableCell align="right">Customer Org Name</TableCell>
                    <TableCell align="right">Project Name</TableCell>
                    <TableCell align="right">Donation amount</TableCell>
                    <TableCell align="right">Date</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {
                    listSoliPoint ? listSoliPoint.slice((page - 1) * limit, page * limit).map((soli) => {
                      return (
                        <TableRow
                          sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                          <TableCell align="right">Soli Points</TableCell>
                          <TableCell align="right">RR Procision</TableCell>
                          <TableCell align="right">Enterprise Corp</TableCell>
                          <TableCell align="right">{soli.customer_user_id}</TableCell>
                          <TableCell align="right">{soli.customer_org_name}</TableCell>
                          <TableCell align="right">{soli.product_id == 'a8250869-1f38-4baa-8669-319f1622cd02' ? 'Save Earth Project': 'Green Foundation Project'}</TableCell>
                          <TableCell align="right">${soli.reward_points}</TableCell>
                          <TableCell align="right"> {formatDate(soli.xact_datetime_at)}</TableCell>
                        </TableRow>
                      )
                    })
                      : null
                  }
                </TableBody>
              </Table>
            </TableContainer>
          </DialogContent>
          <div style={{float:'right',marginTop: 10, marginRight:30, marginBottom:10}}>
          <Pagination
            className={classes.pagination}
            defaultPage={1}
            onChange={handlePageChange}
            boundaryCount={2}
            count={Math.round(Number(listSoliPoint.length)/ limit)}
            color='primary' />
          </div>
        </Dialog>

      }

    </form>
  );
};

ProfileDetails.propTypes = {
  className: PropTypes.string
};

export default ProfileDetails;
