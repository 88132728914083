import React, { useState } from 'react';
import classesFile from './RuleSetInput.module.css';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import UnCheckedBox from 'src/icons/user/UnCheckedBox';
import WhiteCheck from 'src/icons/WhiteCheck';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import SelectIconArrow from "src/icons/SelectIconArrow";
import Switch from '@material-ui/core/Switch';
import Input from '@material-ui/core/Input';
import Picker from "src/icons/Picker";
import DateFnsUtils from '@date-io/date-fns';
import {KeyboardDatePicker, MuiPickersUtilsProvider} from '@material-ui/pickers';

import gql from "graphql-tag";
import { useMutation } from "@apollo/client";
import {GET_All_RULESET} from '../index';


const UPDATE_RULESET = gql`
    mutation updateRulesetsByPk(
        $id: uuid!
        $ruleset_name: String
        $company_id: uuid!
        $partner_company_id: uuid
        $redeem_using_basic_company_points: Boolean
        $redeem_using_premium_company_points: Boolean
        $redeem_using_crypto_loyalty_points: Boolean
        $reward_includes_company_points_grant: Boolean
        $reward_by_price_reduction: Boolean
        $reward_by_percentage_reduction: Boolean
        $purchase_includes_company_points_grant: Boolean
        $top_up_points_with_cash_payment: Boolean
        $reward_includes_cash_back: Boolean
        $reward_includes_bonus_product: Boolean
        $redeem_using_partner_points: Boolean
        $reward_includes_partner_points_grant: Boolean
        $is_active: Boolean
        $reward_with_a_soli_points_carbon_offset: Boolean
        $ruleset_begin_at: timestamptz
        $ruleset_end_at: timestamptz
    ) {
        update_rulesets_by_pk(
            _set: {
                ruleset_name: $ruleset_name
                company_id: $company_id
                partner_company_id: $partner_company_id
                redeem_using_basic_company_points: $redeem_using_basic_company_points
                redeem_using_premium_company_points: $redeem_using_premium_company_points
                redeem_using_crypto_loyalty_points: $redeem_using_crypto_loyalty_points
                reward_includes_company_points_grant: $reward_includes_company_points_grant
                reward_by_price_reduction: $reward_by_price_reduction
                reward_by_percentage_reduction: $reward_by_percentage_reduction
                purchase_includes_company_points_grant: $purchase_includes_company_points_grant
                top_up_points_with_cash_payment: $top_up_points_with_cash_payment
                reward_includes_cash_back: $reward_includes_cash_back
                reward_includes_bonus_product: $reward_includes_bonus_product
                redeem_using_partner_points: $redeem_using_partner_points
                reward_includes_partner_points_grant: $reward_includes_partner_points_grant
                is_active: $is_active
                reward_with_a_soli_points_carbon_offset: $reward_with_a_soli_points_carbon_offset
                ruleset_begin_at: $ruleset_begin_at
                ruleset_end_at: $ruleset_end_at
            }
            pk_columns: {
                id: $id
            }
        ) {
            id
            company_id
            ruleset_name
            company_id
            partner_company_id
            redeem_using_basic_company_points
            redeem_using_premium_company_points
            redeem_using_crypto_loyalty_points
            reward_includes_company_points_grant
            reward_by_price_reduction
            reward_by_percentage_reduction
            purchase_includes_company_points_grant
            top_up_points_with_cash_payment
            reward_includes_cash_back
            reward_includes_bonus_product
            redeem_using_partner_points
            reward_includes_partner_points_grant
            is_active
            reward_with_a_soli_points_carbon_offset
            ruleset_begin_at
            ruleset_end_at
        }
    }
`;


const CREATE_RULESET = gql`
    mutation createRulesetsOne(
        $ruleset_name: String
        $company_id: uuid!
        $partner_company_id: uuid
        $redeem_using_basic_company_points: Boolean
        $redeem_using_premium_company_points: Boolean
        $redeem_using_crypto_loyalty_points: Boolean
        $reward_includes_company_points_grant: Boolean
        $reward_by_price_reduction: Boolean
        $reward_by_percentage_reduction: Boolean
        $purchase_includes_company_points_grant: Boolean
        $top_up_points_with_cash_payment: Boolean
        $reward_includes_cash_back: Boolean
        $reward_includes_bonus_product: Boolean
        $redeem_using_partner_points: Boolean
        $reward_includes_partner_points_grant: Boolean
        $is_active: Boolean
        $reward_with_a_soli_points_carbon_offset: Boolean
        $ruleset_begin_at: timestamptz
        $ruleset_end_at: timestamptz
    ) {
        insert_rulesets_one(
            object: {
                ruleset_name: $ruleset_name
                company_id: $company_id
                partner_company_id: $partner_company_id
                redeem_using_basic_company_points: $redeem_using_basic_company_points
                redeem_using_premium_company_points: $redeem_using_premium_company_points
                redeem_using_crypto_loyalty_points: $redeem_using_crypto_loyalty_points
                reward_includes_company_points_grant: $reward_includes_company_points_grant
                reward_by_price_reduction: $reward_by_price_reduction
                reward_by_percentage_reduction: $reward_by_percentage_reduction
                purchase_includes_company_points_grant: $purchase_includes_company_points_grant
                top_up_points_with_cash_payment: $top_up_points_with_cash_payment
                reward_includes_cash_back: $reward_includes_cash_back
                reward_includes_bonus_product: $reward_includes_bonus_product
                redeem_using_partner_points: $redeem_using_partner_points
                reward_includes_partner_points_grant: $reward_includes_partner_points_grant
                is_active: $is_active
                reward_with_a_soli_points_carbon_offset: $reward_with_a_soli_points_carbon_offset
                ruleset_begin_at: $ruleset_begin_at
                ruleset_end_at: $ruleset_end_at
            }
        ) {
            id
            company_id
            ruleset_name
            company_id
            partner_company_id
            redeem_using_basic_company_points
            redeem_using_premium_company_points
            redeem_using_crypto_loyalty_points
            reward_includes_company_points_grant
            reward_by_price_reduction
            reward_by_percentage_reduction
            purchase_includes_company_points_grant
            top_up_points_with_cash_payment
            reward_includes_cash_back
            reward_includes_bonus_product
            redeem_using_partner_points
            reward_includes_partner_points_grant
            is_active
            reward_with_a_soli_points_carbon_offset
        }
    }
`;


const IOSSwitch = withStyles((theme) => ({
    root: {
      width: 48,
      height: 26,
      padding: 0,
      marginRight: '10px',
      borderRadius: 20,
      backgroundColor: '#fff'
    },
    switchBase: {
      padding: 2,
      margin: 0,
      color: '#31BD00',
      '&$checked': {
        transform: 'translateX(22px)',
        color: '#ffffff',
        '& + $track': {
          backgroundColor: '#ffffff',
          opacity: 1,
        },
        '& .MuiSwitch-thumb': {
          background: '#31BD00',
        },
      },
    },
    thumb: {
      width: 22,
      height: 22,
      background: '#D7DCE8',
      position: 'relative',
      '&:before': {
        content: 'x',
        position: 'absolute',
      }
    },
    track: {
      borderRadius: 14,
      border: '1px solid #D7DCE8',
      backgroundColor: '#ffffff',
      opacity: 1,
      transition: theme.transitions.create(['background-color', 'border']),
    },
    checked: {
    },
    focusVisible: {},
  }))(({classes, ...props}) => {
    return (
      <Switch
        focusVisibleClassName={classes.focusVisible}
        disableRipple
        classes={{
          root: classes.root,
          switchBase: classes.switchBase,
          thumb: classes.thumb,
          track: classes.track,
          checked: classes.checked,
        }}
        {...props}
      />
    );
  });

const StyledCheckbox = withStyles(() => ({
  checked: {
    background: '#00A896',
    color: '#fff',
    '&:first-child': {
      marginLeft: 0,
      borderRadius: '2px',
      padding: 0,
      width: '26px',
      height: '26px',
      '&:hover': {
        background: '#00A896'
      }
    }
  },
  colorPrimary: {
    color: '#D7DCE8',
    padding: 0,
    marginRight: '10px',
    '& $rect': {
      stroke: '#6B799D'
    }
  },

}))(Checkbox);

const StyledFormControlLabel=withStyles(()=>({
    root:{
      marginLeft:'0px',
      marginTop:'24px',
      alignItems: 'flex-start',
    },
  }))(FormControlLabel)

const useStyles = makeStyles((theme) => ({
    root:{
      width:'100%',
    },
      picker: {
        width: '100%',
        height: '52px',
        margin: '0',
        '& $formControl': {
          flexDirection: 'row-reverse'
        },
        '& label.Mui-focused': {
          color: '#010A21',
        },
        '& .MuiInput-underline:after': {
          borderBottomColor: '#D7DCE8',
        },
        '& .MuiInputAdornment-positionEnd button': {
          transform: 'translateX(12px)'
        },
        '& .MuiInput-underline:before': {
          borderBottomColor: '#D7DCE8',
        },
        '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
          borderBottom: '2px solid #D7DCE8',
        }
      },
      formControl: {
        flexDirection: 'row',

      },
      select: {
        width: '100%',
        margin: '0',
        '& label.Mui-focused': {
          color: '#010A21',
        },
        '& input': {
          border: '1px solid #D7DCE8'
        },
        '& .MuiSelect-underline:after': {
          borderBottomColor: '#D7DCE8',
        },
        '& .MuiSelectAdornment-positionEnd button': {
          paddingRight: 0
        },
        '&:before': {
          borderBottom: '1px solid #D7DCE8',
        },
        '& .MuiSelect-underline:hover:not(.Mui-disabled):before': {
          borderBottom: '1px solid #D7DCE8',
        }
      }

    }));



const RuleSetInput = ({ mode, selectedRuleset, onToggle, currentCompany, relatedCompanies, toggleMode, ...rest}) => {
  const classes = useStyles();
  const propsRuleset = selectedRuleset ? selectedRuleset : null;
  const propsPartnerCompanies = relatedCompanies ? relatedCompanies : [];
  const propsPartnerCompany = propsRuleset?.partner_company ? propsRuleset.partner_company : null;

  const [updateRuleset] = useMutation(UPDATE_RULESET);
  const [createRuleset] = useMutation(CREATE_RULESET, {
      refetchQueries: [{query: GET_All_RULESET}]
  });

  const [ruleset, setRuleSet] = useState({
    ruleset_name: propsRuleset?.ruleset_name ? propsRuleset.ruleset_name : '',
    company_id: propsRuleset?.company ? propsRuleset.company?.id : currentCompany?.id,
    partner_company_id: propsPartnerCompany ? propsPartnerCompany.id : '',
    redeem_using_basic_company_points: propsRuleset?.redeem_using_basic_company_points ? propsRuleset?.redeem_using_basic_company_points : false,
    redeem_using_premium_company_points: propsRuleset?.redeem_using_premium_company_points ? propsRuleset?.redeem_using_premium_company_points : false,
    redeem_using_crypto_loyalty_points: propsRuleset?.redeem_using_crypto_loyalty_points ? propsRuleset?.redeem_using_crypto_loyalty_points : false,
    reward_includes_company_points_grant: propsRuleset?.reward_includes_company_points_grant ? propsRuleset?.reward_includes_company_points_grant : false,
    reward_by_price_reduction: propsRuleset?.reward_by_price_reduction ? propsRuleset?.reward_by_price_reduction : false,
    reward_by_percentage_reduction: propsRuleset?.reward_by_percentage_reduction ? propsRuleset?.reward_by_percentage_reduction : false,
    purchase_includes_company_points_grant: propsRuleset?.purchase_includes_company_points_grant ? propsRuleset?.purchase_includes_company_points_grant : false,
    top_up_points_with_cash_payment: propsRuleset?.top_up_points_with_cash_payment ? propsRuleset?.top_up_points_with_cash_payment : false,
    reward_includes_cash_back: propsRuleset?.reward_includes_cash_back ? propsRuleset?.reward_includes_cash_back : false,
    reward_includes_bonus_product: propsRuleset?.reward_includes_bonus_product ? propsRuleset?.reward_includes_bonus_product : false,
    redeem_using_partner_points: propsRuleset?.redeem_using_partner_points ? propsRuleset?.redeem_using_partner_points : false,
    reward_includes_partner_points_grant: propsRuleset?.reward_includes_partner_points_grant ? propsRuleset?.reward_includes_partner_points_grant : false,
    is_active: propsRuleset?.is_active ? propsRuleset.is_active : false,
    reward_with_a_soli_points_carbon_offset: propsRuleset?.reward_with_a_soli_points_carbon_offset ? propsRuleset.reward_with_a_soli_points_carbon_offset : false,
    ruleset_begin_at: propsRuleset?.ruleset_begin_at ? propsRuleset.ruleset_begin_at : new Date(),
    ruleset_end_at: propsRuleset?.ruleset_end_at ? propsRuleset.ruleset_end_at : new Date(),
});

  const handleChange = (event) => {
    const nonBooleanFields = ['ruleset_name', 'company_id', 'partner_company_id', 'ruleset_begin_at', 'ruleset_end_at']
    const [name, value, isChecked] = [event.target.name, event.target.value, event.target.checked]
    let update = {};
    if (nonBooleanFields.includes(name)) {
        update[name] = value;
    } else {
        update[name] = isChecked
    }

    setRuleSet({ ...ruleset, ...update});
  };


    // Material pickers don't pass typical event fields
    const handleDateChange = (event, fieldName) => {
        let e = event ? {...event} : {};
        e.target = {};
        e.target.name = fieldName;
        e.target.value = event;
        handleChange(e);
      }


  const cleansedRuleset = (ruleset) => {
    const newRuleset = {...ruleset};
    Object.keys(newRuleset).forEach(k => {
        if (newRuleset[k] === '')
            newRuleset[k] = null;
    });
    return newRuleset;
  }

  const submitHandler = event => {
    event.preventDefault();
    const cleanRuleset = cleansedRuleset(ruleset);
    if (mode === 'edit') {
        updateRuleset({variables: {...cleanRuleset, id: propsRuleset.id}})
    } else if (mode === 'add') {
        createRuleset({variables: {...cleanRuleset}});
    }
  };


  return (
    <div>
      <form onSubmit={submitHandler} noValidate autoComplete='off' className={classes.containerHeader}>
        <div className={classesFile.LoyaltyFormWrapper}>
          <div className={classesFile.listField}>
            <div className={classesFile.ItemField}>
                <InputLabel id='demo-simple-select-label'>Ruleset name</InputLabel>
                <Input
                    className={classes.root}
                    value={ruleset?.ruleset_name}
                    name="ruleset_name"
                    placeholder='Ruleset Name'
                    onChange={handleChange}
                    inputProps={{ 'aria-label': 'description' }}
                />
            </div>
            <div className={classesFile.ItemField}>
                <InputLabel id='demo-simple-select-label'>Company</InputLabel>
                <Input
                    disabled
                    className={classes.root}
                    value={propsRuleset?.company ? propsRuleset.company?.name : currentCompany?.name}
                    placeholder='Company Name'
                    inputProps={{ 'aria-label': 'description' }}
                />
            </div>
            <div className={classesFile.ItemField}>
                <InputLabel id="ruleset_partner-select-label">Partner company</InputLabel>
                <FormControl  className={classes.root}>
                    <Select
                        IconComponent={SelectIconArrow}
                        labelId="ruleset_partner-select-label"
                        id="ruleset_partner-select"
                        name="partner_company_id"
                        value={ruleset.partner_company_id || ''}
                        onChange={handleChange}
                        className={classes.select}
                    >
                        <MenuItem value={''}>None</MenuItem>
                        {
                            propsPartnerCompanies.map((value, index) => {
                                if (value.related_company?.id)
                                    return <MenuItem key={index} value={value.related_company?.id}>{value.related_company?.name}</MenuItem>
                            })
                        }
                    </Select>
                </FormControl>
            </div>

            <div className={classesFile.rulesDiv}>
                <p className={classesFile.rulesDivLabel}>Basic rules:</p>
                <div className={classes.ItemField}>
                    <StyledFormControlLabel

                    control={<StyledCheckbox
                        icon={<UnCheckedBox />}
                        checkedIcon={<WhiteCheck />}
                        color='primary'
                        name='redeem_using_basic_company_points'
                        onChange={handleChange}
                        checked={ruleset.redeem_using_basic_company_points}
                    />}
                    label='Redeem using basic company points'
                    labelPlacement='end'
                    />
                </div>
                <div className={classes.ItemField}>
                    <StyledFormControlLabel
                    control={<StyledCheckbox
                        icon={<UnCheckedBox />}
                        checkedIcon={<WhiteCheck />}
                        color='primary'
                        name='redeem_using_premium_company_points'
                        onChange={handleChange}
                        checked={ruleset.redeem_using_premium_company_points}
                    />}
                    label='Redeem using premium company points'
                    labelPlacement='end'
                    />
                </div>
                <div className={classes.ItemField}>
                    <StyledFormControlLabel

                    control={<StyledCheckbox
                        icon={<UnCheckedBox />}
                        checkedIcon={<WhiteCheck />}
                        color='primary'
                        name='redeem_using_crypto_loyalty_points'
                        onChange={handleChange}
                        checked={ruleset.redeem_using_crypto_loyalty_points}
                    />}
                    label='Redeem using crypto loyalty points'
                    labelPlacement='end'
                    />
                </div>
                <div className={classes.ItemField}>
                    <StyledFormControlLabel

                    control={<StyledCheckbox
                        icon={<UnCheckedBox />}
                        checkedIcon={<WhiteCheck />}
                        color='primary'
                        name='reward_includes_company_points_grant'
                        onChange={handleChange}
                        checked={ruleset.reward_includes_company_points_grant}
                    />}
                    label='Reward includes company points grant'
                    labelPlacement='end'
                    />
                </div>
                <div className={classes.ItemField}>
                    <StyledFormControlLabel

                    control={<StyledCheckbox
                        icon={<UnCheckedBox />}
                        checkedIcon={<WhiteCheck />}
                        color='primary'
                        name='reward_by_price_reduction'
                        onChange={handleChange}
                        checked={ruleset.reward_by_price_reduction}
                    />}
                    label='Reward by price reduction'
                    labelPlacement='end'
                    />
                </div>
                <div className={classes.ItemField}>
                    <StyledFormControlLabel

                    control={<StyledCheckbox
                        icon={<UnCheckedBox />}
                        checkedIcon={<WhiteCheck />}
                        color='primary'
                        name='reward_by_percentage_reduction'
                        onChange={handleChange}
                        checked={ruleset.reward_by_percentage_reduction}
                    />}
                    label='Reward by percentage reduction'
                    labelPlacement='end'
                    />
                </div>
        </div>
        <div className={classesFile.rulesDiv}>
            <p className={classesFile.rulesDivLabel}>Additional rules:</p>
            <div className={classes.ItemField}>
                <StyledFormControlLabel
                control={<StyledCheckbox
                    icon={<UnCheckedBox />}
                    checkedIcon={<WhiteCheck />}
                    color='primary'
                    name='reward_with_a_soli_points_carbon_offset'
                    onChange={handleChange}
                    checked={ruleset.reward_with_a_soli_points_carbon_offset}
                />}
                label='Reward with a Soli Points carbon offset'
                labelPlacement='end'
                />
            </div>
            <div className={classes.ItemField}>
                <StyledFormControlLabel
                control={<StyledCheckbox
                    icon={<UnCheckedBox />}
                    checkedIcon={<WhiteCheck />}
                    color='primary'
                    name='purchase_includes_company_points_grant'
                    onChange={handleChange}
                    checked={ruleset.purchase_includes_company_points_grant}
                />}
                label='Purchase includes company points grant'
                labelPlacement='end'
                />
            </div>
            <div className={classes.ItemField}>
                <StyledFormControlLabel
                control={<StyledCheckbox
                    icon={<UnCheckedBox />}
                    checkedIcon={<WhiteCheck />}
                    color='primary'
                    name='top_up_points_with_cash_payment'
                    onChange={handleChange}
                    checked={ruleset.top_up_points_with_cash_payment}
                />}
                label='Top up points with cash payment'
                labelPlacement='end'
                />
            </div>
            <div className={classes.ItemField}>
                <StyledFormControlLabel
                control={<StyledCheckbox
                    icon={<UnCheckedBox />}
                    checkedIcon={<WhiteCheck />}
                    color='primary'
                    name='reward_includes_bonus_product'
                    onChange={handleChange}
                    checked={ruleset.reward_includes_bonus_product}
                />}
                label='Reward includes bonus product'
                labelPlacement='end'
                />
            </div>
            <div className={classes.ItemField}>
                <StyledFormControlLabel
                control={<StyledCheckbox
                    icon={<UnCheckedBox />}
                    checkedIcon={<WhiteCheck />}
                    color='primary'
                    name='reward_includes_cash_back'
                    onChange={handleChange}
                    checked={ruleset.reward_includes_cash_back}
                />}
                label='Reward includes cash back'
                labelPlacement='end'
                />
            </div>
        </div>
        <div className={classesFile.rulesDiv}>
          <p className={classesFile.rulesDivLabel}>Partner rules:</p>
          <div className={classes.ItemField}>
            <StyledFormControlLabel
              control={<StyledCheckbox
                icon={<UnCheckedBox />}
                checkedIcon={<WhiteCheck />}
                color='primary'
                name='redeem_using_partner_points'
                onChange={handleChange}
                checked={ruleset.redeem_using_partner_points}
              />}
              label='Redeem using partner points'
              labelPlacement='end'
            />
            </div>
            <div className={classes.ItemField}>
                <StyledFormControlLabel
                control={<StyledCheckbox
                    icon={<UnCheckedBox />}
                    checkedIcon={<WhiteCheck />}
                    color='primary'
                    name='reward_includes_partner_points_grant'
                    onChange={handleChange}
                    checked={ruleset.reward_includes_partner_points_grant}
                />}
                label='Reward includes partner points grant'
                labelPlacement='end'
                />
            </div>
            <div className={classesFile.ItemField}>
                <StyledFormControlLabel
                    control={
                        <IOSSwitch
                            checked={ ruleset.is_active }
                            onChange={ handleChange }
                            name="is_active"
                        />
                    }
                    labelPlacement="end"
                    label={ ruleset.is_active ? 'Active' : 'Inactive' }
                />
            </div>
            </div>
        </div>
        <div className={classes.listField}>
            <div className={classesFile.ItemField}>
                <InputLabel id="ruleset-startdate-label">Start date:</InputLabel>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                    keyboardIcon={<Picker/>}
                    disableToolbar
                    format="MM/dd/yyyy"
                    margin="normal"
                    id="ruleset-startdate-picker"
                    name="ruleset_begin_at"
                    value={ ruleset.ruleset_begin_at }
                    autoOk={ true }
                    onChange={(e) => handleDateChange(e, 'ruleset_begin_at')}
                    KeyboardButtonProps={{
                        'aria-label': 'change date',
                        display: 'flex'
                    }}
                    inputProps={{
                        style: {
                        color: '#6B799D',
                        borderColor: '#D7DCE8',
                        },
                    }}
                    className={classes.picker}
                    />
                </MuiPickersUtilsProvider>
            </div>
            <div className={classesFile.ItemField}>
                <InputLabel id="ruleset-enddate-label">End date:</InputLabel>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                    keyboardIcon={<Picker/>}
                    disableToolbar
                    format="MM/dd/yyyy"
                    margin="normal"
                    id="ruleset-enddate-picker"
                    name="ruleset_end_at"
                    value={ ruleset.ruleset_end_at }
                    autoOk={ true }
                    onChange={(e) => handleDateChange(e, 'ruleset_end_at')}
                    KeyboardButtonProps={{
                        'aria-label': 'change date',
                        display: 'flex'
                    }}
                    inputProps={{
                        style: {
                        color: '#6B799D',
                        borderColor: '#D7DCE8',
                        },
                    }}
                    className={classes.picker}
                    />
                </MuiPickersUtilsProvider>
            </div>
        </div>
        </div>

        <div className={classesFile.ButtonWrapper}>
          <button className={classesFile.ButtonSave} onClick={e => {
              submitHandler(e, mode);
              onToggle(e);
          }}>
            Save
          </button>
          <div className={classesFile.ButtonCancel} onClick={e => {
              toggleMode();
              onToggle(e);
            }}>
            Cancel
          </div>
        </div>
      </form>
    </div>
  );

};

export default RuleSetInput;
