import React from 'react';
import { NavLink as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Button, ListItem, makeStyles } from '@material-ui/core';
import ArrowRight from "src/icons/ArrowRight";
import Dropdown from "src/components/ui/Dropdown/Dropdown";

const useStyles = makeStyles((theme) => ({
  item: {
    display: 'flex',
    paddingTop: 0,
    paddingBottom: 0,
    flexDirection: 'column',
  },
  button: {
    color: theme.palette.text.secondary,
    justifyContent: 'flex-start',
    letterSpacing: 0,
    padding: '10px 8px',
    textTransform: 'none',
    width: '100%',
    '& .MuiButton-label': {
      marginLeft: '8px',
    },
    '& .title': {
      marginRight: 10,
      fontSize: '14px',
      color: '#B9C2D9',
      marginLeft: '16px',
      fontWeight: 'normal',
    },
    '&:hover': {
      color: '#ffffff',
      backgroundColor: '#024e52',
      borderRadius: '8px',
      '& .icon': {
        fill: '#00A896'
      },
      '& .title': {
        color: '#ffffff',
      },
    },
  },
  activeClass: {
    color: '#ffffff',
    backgroundColor: '#024e52',
    borderRadius: '8px',
    '& .icon': {
      fill: '#00A896'
    },
    '& .title': {
      color: '#ffffff',
    },
  },
}));

const NavItem = ({handleClick, isActiveIndex, index, href, icon: Icon, title, children, ...rest}) => {
  const classes = useStyles();

  return (
    <>
      { href ? (
        <ListItem
          className={`${clsx(classes.item)} ${isActiveIndex === index ? classes.activeClass : ''}`}
          disableGutters
          component={ RouterLink }
          onClick={ handleClick }
          to={ href }
        >
          <Button className={classes.button}>
            {Icon && (
              <Icon
                className='icon'
                size="20"
              />
            )}
            <span className='title'>
          {title}
        </span>

            {children && (
              <ArrowRight/>
            )}
          </Button>
        </ListItem>
      ) : (
        <Dropdown
          icon={Icon}
          title={title}
          children={children}
          index={index}
          handleClick={ handleClick }
          isActiveIndex={ isActiveIndex }
        />
      )}
      </>
  );
};

NavItem.propTypes = {
  className: PropTypes.string,
  href: PropTypes.string,
  icon: PropTypes.elementType,
  title: PropTypes.string,
  currentCompany: PropTypes.object
};

NavItem.defaultProps = {
  onMobileClose: () => {},
  openMobile: false
};


export default NavItem;
