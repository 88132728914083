import { ethers } from "ethers";
import axios from 'axios';
// import BigNumber from 'bignumber.js';
var prosicionAbi = require('./prosicion.abi.json');
const celoAbi  = require('./celo.abi.json');
const celoTokenAbi = require('./celoToken.abi.json');
const token = require('./token.abi.json');
const { ENDPOINT,ADDRESS_CONTRACT,PAYMENT_ETH_ADDRESS,WALLET,TOKEN_ADDRESS,TYPE_TOKEN,AMOUNT_APROVE, CELO_TOKEN_CONTRACT_ADDRESS, CELO_CONTRACT_ADDRESS } = require('./constant');
const Units = require('ethereumjs-units');


export async function buyProducts(totalPrice,companyId,typeToken,signer) {
  try {
    const buyerWallet = signer._address
    const priceProducts = Units.convert(totalPrice, 'eth', 'wei')
    const tokenContract = new ethers.Contract(TOKEN_ADDRESS, token, signer)
    const allowance = await tokenContract.allowance(buyerWallet,ADDRESS_CONTRACT)
    const parseData = Units.convert(priceProducts, 'wei', 'eth')
    const options = {value: ethers.utils.parseEther(parseData)}
    const ProsicionContract = new ethers.Contract(ADDRESS_CONTRACT, prosicionAbi,signer)
    const dataSign = await createSign(priceProducts,typeToken)
    const signature = dataSign.data
    const str = [companyId]
    let data
    let sendPurchase

    if (typeToken === TYPE_TOKEN.ETH) {
      data = [priceProducts]
      const addr  = [buyerWallet,WALLET.COMPANY,PAYMENT_ETH_ADDRESS,WALLET.SIGNER]
      sendPurchase = await ProsicionContract.buyProduct(data,addr,str,signature,options)
    }

    if (typeToken === TYPE_TOKEN.BTC) {
      if (Number(allowance) < Number(priceProducts)) {
        return await tokenContract.approve(ADDRESS_CONTRACT, AMOUNT_APROVE)
      }
      data = [priceProducts,1]
      const addr  = [buyerWallet,WALLET.COMPANY,TOKEN_ADDRESS,WALLET.SIGNER]
      sendPurchase = await ProsicionContract.buyProduct(data,addr,str,signature)
    }

    return sendPurchase.hash
  
  } catch (error) {
    console.log(error);
  }
}

export async function submitPayment(data,totalPrice,companyId,typeToken,provider) {
  const txHash = await buyProducts(totalPrice,companyId,typeToken,provider)
  const dataInput = data;
  dataInput.txHash = txHash;
  return await paymentToken(dataInput)
}

export async function cUSD(data,totalPrice,typeToken,provider) {
  const txHash =  await buyProductByCUSD(totalPrice,typeToken,provider)
  data.txHash = txHash
  return await paymentToken(data)
}

async function buyProductByCUSD(totalPrice,typeToken,signer) {
  const buyerWallet = signer._address
  const str1 = ['c390fd1e-c417-464f-b9c5-0a29a0e28081']
  const celoTokenContract = new ethers.Contract(CELO_TOKEN_CONTRACT_ADDRESS, celoTokenAbi, signer)
  const celoContract = new ethers.Contract(CELO_CONTRACT_ADDRESS, celoAbi, signer)
  const allowance = await celoTokenContract.allowance(buyerWallet,CELO_CONTRACT_ADDRESS)
  const priceProducts = Units.convert(totalPrice, 'eth', 'wei')

  if (Number(allowance) < Number(priceProducts)) {
    return await celoTokenContract.approve(CELO_CONTRACT_ADDRESS, AMOUNT_APROVE)
  }
  
  let data = [priceProducts]
  const dataSign = await createSign(priceProducts,typeToken)
  const signature = dataSign.data
  const addr  = [buyerWallet,WALLET.COMPANY,CELO_TOKEN_CONTRACT_ADDRESS,WALLET.SIGNER]

 const celo =  await celoContract.buyProduct(data,addr,str1,signature)
 return celo.hash

}

export async function paymentToken(dataInput) {
  try {
     return await  axios.post(ENDPOINT.CREATE_PURCHASE, dataInput)
  } catch (error) {
   console.log(error);
  }
}

export async function createSign(price,type) {
  try {
    const data = {
      totalPrice: price,
      typeToken:type
    }
     return await  axios.post(ENDPOINT.CREATE_SIGNATURE, data)
  } catch (error) {
   console.log(error);
  }
}