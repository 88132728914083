import React, { useState,useEffect } from 'react';
import { useHistory } from "react-router-dom";
import { ethers } from "ethers";
import { Box, Checkbox, FormControlLabel } from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import UnCheckedBox from '../../icons/user/UnCheckedBox';
import CheckedBox from '../../icons/user/CheckedBox';
import Snackbar from '@material-ui/core/Snackbar';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import MuiAlert from '@material-ui/lab/Alert';
import {AlertTitle} from '@material-ui/lab';
import CircularProgress from '@material-ui/core/CircularProgress';
import MasterCardLogo from '../../icons/MasterCardLogo';
import VisaLogo from '../../icons/user/VisaLogo';
import AmericanExpressLogo from '../../icons/user/AmericanExpressLogo';
import WhiteCheck from '../../icons/WhiteCheck';
import Button from '@material-ui/core/Button';
import gql from 'graphql-tag';
import { useMutation } from '@apollo/client';
import { getPublicKey, handleApi, pointsTransaction, updateBalancePoint, soliCarbonOffset, allocateCarbonOffset, getRewarData, getBalanceClimateReward, redeemRewardPoints, createRewardPoints, saveRewardClimateCoins, claimCustomerReward } from 'src/utils/handleApi';
import { submitPayment } from 'src/utils/ether';
import { TYPE_TOKEN, COMPANY_ID, VALUE_DEFAULT_STORCO_COINS, VALUE_DEFAULT_CARBON_OFFSET, CUSTOMER_ID, PROVIDER_OPTION, REWARD, VALUE_DEFAULT_CLIMATE_COINS } from 'src/utils/constant';
import { web3Modal } from'src/utils/web3Modal'
import { responseCarbonOffset, switchNetwork } from 'src/utils/common';
import WrappedCeloWalletConnect from 'src/components/CeloWalletConnect';
import { getClimeBalance } from 'src/components/CeloWalletConnect';

const useStyle = makeStyles(() => ({
  hr: {
    border: 'none',
    height: 1,
    background: '#DCDCDC',
    margin: '20px 0px'
  },
  compact_hr: {
    border: 'none',
    height: 1,
    background: '#DCDCDC',
    margin: '30px 0px'
  },
  styled_label: {
    marginLeft:0,
    marginBottom: 30,
    display: 'flex',
    alignItems: 'center',
    '&:last-child':{
      marginBottom:0,
    },
    '& span div': {
      display: 'flex',
      alignItems: 'center',
      '& div svg': {
        marginRight: 16
      }
    }
  },
  payment_div_text:{
    display: 'flex',
    justifyContent: 'space-between',
  },
  payment_span_2:{
    fontFamily: 'Roboto',
    fontSize: 18,
    fontWeight:'bold',
    '&:first-child': {
      color: '#000000'
    }
  },
  fiat_reward: {
    fontFamily: 'Roboto',
    fontSize: 16,
    textAlight: 'center',
    fontWeight: 'normal',
    fontStyle: 'italic'
  },
  payment_span: {
    fontFamily: 'Roboto',
    fontSize: 18,
    '&:first-child': {
      color: '#000000'
    }},
  total_div:{
    width:'100%',
  },
cancel_btn:{
  padding: '8px 24px',
  marginLeft: '8px',
  border: 'none',
  background: 'none',
  fontFamily: 'Open Sans SemiBold',
  fontSize: 18,
  height:44,
  '&:hover': {
      cursor: 'pointer'
  }
},
  div_btns:{
    display:'flex',
    marginTop:10,
    marginBottom:10,
    // alignItems:'center',
    // flexDirection:'column',
    // padding:'0px 32px',
  },
  div_btn_wallet:{
    display:'flex',
    marginTop:30,
  },
  connect_btn:{
    borderRadius:100,
    padding: '10px',
    height:44,
    border: '1px solid #000',
    background: '#4CAF50',
    fontSize: 18,
    width:'60%',
    textTransform:'capitalize',
    marginRight:5,
    marginLeft:5,
    color:'#fff',
  },
  
  disconnect_btn: {
    borderRadius:100,
    padding: '10px',
    height:44,
    border: '1px solid #000',
    background: '#4CAF50',
    fontSize: 18,
    width:'60%',
    textTransform:'capitalize',
    marginRight:5,
    marginLeft:5,
    marginBottom:5,
    color:'#fff',
  },
  payment_options_button: {
    padding: '8px 24px',
    color: '#fff',
    border: 'none',
    background: 'black',
    borderRadius: 4,
    fontFamily: 'Open Sans SemiBold',
    fontSize: 18,
    height:44,
    borderRadius:100,
    marginLeft:5,
    width: '60%',
    '&:hover': {
        cursor: 'pointer'
    }
  },

}));
const StyledButton=withStyles(()=>({
  root:{
    marginLeft:5,
    marginRight:5,
    background:'#000000',
    color:'#fff',
    borderRadius:100,
    width:'100%',
    padding:10,
    fontWeight:'bold',
    fontSize:20,
    height: 44,
    textTransform:'none',
    '&:hover':{
      background:'#000000',
    }
  },
}))(Button);

const StyledProgress=withStyles(()=> ({
    circle:{
        color: '#fff'
    }
}))(CircularProgress)

const StyledSnackbar=withStyles(() => ({
    root: {
        fontFamily: 'Roboto',
        fontSize: 18,
    }
}))(Snackbar)

const StyledMuiAlert=withStyles(() => ({
    root: {
        fontFamily: 'Roboto',
        fontSize: 18,
        backgroundColor: '#fff',
        borderWidth: '2px',
        width: '450px'
    }
}))(MuiAlert)

const StyledAlertTitle=withStyles(() => ({
    root: {
        fontFamily: 'Roboto',
        fontWeight: '400',
        fontSize: 24,
    }
}))(AlertTitle)

const StyledCheckbox = withStyles(() => ({

  checked: {
    background: '#4CAF50',
    color: '#fff',

    '&:first-child': {
      marginLeft: 0,
      borderRadius: '2px',
      padding: 0,
      width: '26px',
      height: '26px',
      '&:hover': {
        background: '#4CAF50'
      }
    }
  },
  colorPrimary: {
    color: '#D7DCE8',
    padding: 0,
    marginRight: '10px'

  },
  label: {
    marginTop: 20

  }

}))(Checkbox);

const MainBox = withStyles(() => ({
  root: {

    padding: '40px 32px 40px 32px',

    background: '#fff',
    display: 'flex',
    flexDirection: 'column'
  }
}))(Box);

const WrapperBox = withStyles(() => ({
  root: {
    borderLeft: '1px solid #DCDCDC',
    marginTop: 80,
    width: '30%',
    display: 'flex',
    flexDirection: 'column',
    background: 'rgba(220, 220, 220, 0.2)'
  }
}))(Box);

const SecondBox = withStyles(() => ({
  root: {
    padding: '20px 32px 20px 32px',
    display: 'flex',
    flexDirection: 'column'
  }
}))(Box);
const TotalBox = withStyles(() => ({
  root: {
    fontFamily: 'Roboto',
    fontWeight: '400',
    fontSize: 20,

  }
}))(Box);
const TotalDivBox = withStyles(() => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between'

  }
}))(Box);

const PriceTotalBox = withStyles(() => ({
  root: {
    fontFamily: 'Roboto',
    fontWeight: '700',
    fontSize: 20,

  }
}))(Box);
const SpanBox = withStyles(() => ({
  root: {
    fontFamily: 'Roboto',
    fontWeight: '500',
    fontSize: 20,
    marginBottom: 15
  }
}))(Box);
const PrimaryBox = withStyles(() => ({
  root: {
    fontFamily: 'Roboto',
    fontWeight: 'normal',
    fontSize: 16,
    color: '#000',
    maxWidth: 200,
    width: '100%'
  }
}))(Box);
const DefaultBox = withStyles(() => ({
  root: {
    fontFamily: 'Roboto',
    fontWeight: 'normal',
    fontSize: 16,
    color: '#010A21',
    whiteSpace: 'nowrap'
  }
}))(Box);
const RowBox = withStyles(() => ({
  root: {
    display: 'flex',


    marginTop: '20px'
  }
}))(Box);


const baseMutation = `
    insert_transactions_reward_redemption(objects: $objects) {
        returning {
            id
            transaction_type_id
            company_id
            customer_id
            is_product_purchase
            is_simple_redemption
            point_token_type_id
            fiat_type
            point_token_amount
            fiat_amount
            xact_datetime_at
        }
    }
`;

const updateBalanceMutation = `
    update_customer_points_by_pk(
        _set: {
            balance: $balance
        }
        pk_columns: {id: $customer_points_id}
    ) {
        id
        customer_id
        balance
        points_token_types_id
        point_token_type {
            id
            name
        }
    }
`;


const UPDATE_BALANCE = gql`
    mutation updateBalance(
        $customer_points_id: uuid!
        $balance: bigint!
    ) {
        ${updateBalanceMutation}
    }
`;

const INSERT_TRANSACTIONS_UPDATE_BALANCE = gql`
    mutation insertTransactionsUpdateBalance(
        $objects: [transactions_reward_redemption_insert_input!]!
        $customer_points_id: uuid!
        $balance: bigint!
    ) {
        ${baseMutation}
        ${updateBalanceMutation}
    }
`;


const PaymentOptions = (props) => {
  const classes = useStyle();
  const history = useHistory();
  const pointTokenTypeId = '4c813ff7-ad23-4e4c-9ca8-480fc03677ea';    // hard code for now
  const carbonOffsetPointId = '74219bde-e7d1-4ca3-be12-56eefc4eb573';
  const carbonOffsetProductId = 'a7405ba7-bfea-41b8-a184-53281107a815'
  const quantityCarbon = props.productQuantityMap[carbonOffsetProductId];
  const checkCarbonOffset = localStorage.getItem('carbonOffsetPoints')
  const propsUser = props?.user ? props.user : null;
  const [snackBar, setSnackBar] = useState(false);
  const [accountConnect, setAccountConnect] = useState('');
  const [provider, setProvider] = useState();
  const [popupConnectWallet, setPopupConnectWallet] = useState(false);
  const [reward, setReward] = useState({
    rewardClimateCoin : 0,
    rewardCarbonOffset:0,
  });
  const [isGenerateToken, setIsGenerateToken] = useState(false);
  const [isCheckStripe, setIsCheckStripe] = useState(false)
  const [switchLibrary, setSwitchLibrary] = useState()
  const [isConnectWallet, setIsConnectWallet] = useState(false)
  const [walletConnected, setWalletConnected] = useState(false)
  useEffect(() => {
    (async function() {
    const isCheckPayment =  localStorage.getItem('isEnterpriseSuccess')
    const carbonOffsetPoints = localStorage.getItem ('carbonOffsetPoints')
      if (isCheckPayment) {
        setIsCheckStripe(true)
        setTotalCarbonOffset(totalCarbonOffset + Number(carbonOffsetPoints))
        setTimeout(() => {
          localStorage.clear()
        }, 3000)
      }
    })();
  }, []);

  useEffect(() => {
    (async function() {
      const balanceClimateCoin =  await getBalanceClimateReward(propsUser.id)
      if (balanceClimateCoin) {
        setTotalPoint(balanceClimateCoin.data.totalPoints)
      }

    })();
  }, []);
  const dataClimateCoins = localStorage.getItem('climateCoins')
  const closePopupStripe = () => {
    setIsCheckStripe(false)
  }

  useEffect(() => {
    const climeBalance = localStorage.getItem('CLIME_balance');
    const celowallet = localStorage.getItem('react-celo/last-used-address');
    if (celowallet) {
      // setAccountConnect(celowallet);
      setWalletConnected(true);
      if (climeBalance) {
        setTotalClime(climeBalance);
      }
    } else {
      // setAccountConnect('');
      setWalletConnected(false);
    }
  }, [])

  const formatAddress = (address) => {
    if (address) {
      const add1 = address.substring(0, 4);
      const add2 = address.substring(address.length - 4);
      const finalAddress = `${add1}...${add2}`;
      return finalAddress;
    }
  };

  const disconnect = async () => {
    props?.disconnect();
    setAccountConnect('');
    localStorage.setItem('isWalletConnected', false)
    setIsConnectWallet(false)
    localStorage.removeItem('-walletlink:https://www.walletlink.org:Addresses')
    localStorage.removeItem('quantityCarbonOffset')
    localStorage.removeItem('climateCoins')
    setWalletConnected(false);
    // window.location.reload();
  };

  const closePopupConnectWallet = () => {
    setPopupConnectWallet(false)
  }

  const payBycUSD = async (e, txHash) => {
    // const typeToken = TYPE_TOKEN.CUSD;
    const dataIput = {
      ...cUSDInput(),
      txHash: txHash
    };

    if (props.mode === 'cUSD') {
      const dataTransaction = await pointsTransaction(e, dataIput)
      getPublicKey(e, COMPANY_ID.ENTERPRISE)

      const response = await saveRewardClimateCoins(dataTransaction.data, propsUser.id, props.products, props.productQuantityMap)
      // Call API to mint CLIME token to buyer wallet
      // This should be done on backend for security reason for production env
      // For now we're temporarily calling it from frontend
      // const totalRewardClime = calculateRewardClimate(dataTransaction.data);
      if (response?.data > 0 && dataTransaction?.data?.length > 0) {
        // Inform Soli to allocate reward for buyer
        // Responsed error from Soli
        await createRewardPoints(dataTransaction.data[0].id, propsUser.id, response?.data);
        await claimCustomerReward({
          buyerWallet: localStorage.getItem('react-celo/last-used-address'),
          companyId: 'a67b662d-c543-446e-9d3d-e39a147c9800',
          amount: response?.data
        });
      }
      const balanceClimateCoins = await getBalanceClimateReward(propsUser.id)
      if (balanceClimateCoins) {
        setTotalPoint(balanceClimateCoins.data.totalPoints)
      }
      const countTotalCarbon = Number(totalCarbonOffset + quantityCarbon)

      if (quantityCarbon) {
        const inputTransactionSoli = await solidInput(dataTransaction.data)
        const response = await allocateCarbonOffset(inputTransactionSoli)
        const inputCarbonOffset = await responseCarbonOffset(response.data, COMPANY_ID.ENTERPRISE)
        soliCarbonOffset(inputCarbonOffset)
      }

      const balanceClimateCoin = await getBalanceClimateReward(propsUser.id)

      if (balanceClimateCoin) {
        setTotalPoint(balanceClimateCoin.data.totalPoints)
      }

      if (quantityCarbon) {
        setTotalCarbonOffset(countTotalCarbon)
        updateBalancePoint(e, {
          customerId: propsUser.id,
          pointsTokenTypesId: carbonOffsetPointId,
          balance: countTotalCarbon
        })
      }

      openSnackBar();
      if (localStorage.getItem('react-celo/last-used-address')) {
        const clime = await getClimeBalance(localStorage.getItem('react-celo/last-used-address'));
        setTotalClime(clime);
      }
    }
  }

  const connect = async () => {
    setWalletConnected(true);
    props?.connect();
  }

  const purchaseProductList = () => {
    let products = [];
    const propsProducts = props?.products ? props.products : [];

    propsProducts.forEach(product => {
      const quantity = props.productQuantityMap[product.id];

      products.push(`Code: ${product.product_code} --- Desc: ${product.product_description} --- Quantity: ${quantity}`);
    });

    return products;
  }

  let buttonSubmit = <button
    className={classes.payment_options_button}
    onClick={async (e) => {
      e.preventDefault();
      const data = transactionInputs();
      const dataTransaction = await pointsTransaction(e, data)
      getPublicKey(e, COMPANY_ID.ENTERPRISE)

      const response = await saveRewardClimateCoins(dataTransaction.data, propsUser.id, props.products, props.productQuantityMap)
      // Call API to mint CLIME token to buyer wallet
      // This should be done on backend for security reason for production env
      // For now we're temporarily calling it from frontend
      // const totalRewardClime = calculateRewardClimate(dataTransaction.data);
      if (response?.data > 0 && dataTransaction?.data?.length > 0) {
        // Inform Soli to allocate reward for buyer
        // Responsed error from Soli
        await createRewardPoints(dataTransaction.data[0].id, propsUser.id, response?.data);
        await claimCustomerReward({
          buyerWallet: localStorage.getItem('react-celo/last-used-address'),
          companyId: 'a67b662d-c543-446e-9d3d-e39a147c9800',
          amount: response?.data
        });
      }
      const balanceClimateCoins = await getBalanceClimateReward(propsUser.id)
      if (balanceClimateCoins) {
        setTotalPoint(balanceClimateCoins.data.totalPoints)
      }
      const countTotalCarbon = Number(totalCarbonOffset + quantityCarbon)

      if (quantityCarbon) {
        const inputTransactionSoli = await solidInput(dataTransaction.data)
        const response = await allocateCarbonOffset(inputTransactionSoli)
        const inputCarbonOffset = await responseCarbonOffset(response.data, COMPANY_ID.ENTERPRISE)
        soliCarbonOffset(inputCarbonOffset)
      }

      if (props.mode === 'fiat') {
        const stripeData = stripeInputs()
        handleApi(e, stripeData)
        stripeData.transactionIds = dataTransaction.data

        if (quantityCarbon) {
          updateBalancePoint(e,
            {
              customerId: propsUser.id,
              pointsTokenTypesId: carbonOffsetPointId,
              balance: totalCarbonOffset
            }
          )
        }

        localStorage.setItem('dataEnterprise', JSON.stringify(stripeData))
        localStorage.setItem('climateCoins', countDonateStripe)
        localStorage.setItem('carbonOffsetPoints', quantityCarbon)
        history.push("/checkout-enterprisecorp")
      }

      if (props.mode === 'points') {

        // redeem reward coins
        if (dataTransaction) {
          await redeemRewardPoints(dataTransaction.data[0].id, propsUser.id, calculateTotalPoints())
        }

        if (quantityCarbon) {
          setTotalCarbonOffset(countTotalCarbon)
          updateBalancePoint(e, {
            customerId: propsUser.id,
            pointsTokenTypesId: carbonOffsetPointId,
            balance: countTotalCarbon
          })
        }

        openSnackBar();
      }

      // if (props.mode === 'coins' && !accountConnect) {
      //   setPopupConnectWallet(true)
      // }

      const balanceClimateCoin = await getBalanceClimateReward(propsUser.id)

      if (balanceClimateCoin) {
        setTotalPoint(balanceClimateCoin.data.totalPoints)
      }
      // props.refetch()
      if (localStorage.getItem('react-celo/last-used-address')) {
        const clime = await getClimeBalance(localStorage.getItem('react-celo/last-used-address'));
        setTotalClime(clime);
      }

    }}>Submit Payment</button>;

  let cancelButton = <button className={classes.cancel_btn}
    onClick={e => {
      e.preventDefault();

      updateCustomerPoints({
        variables: {
          balance: VALUE_DEFAULT_CARBON_OFFSET,
          customer_points_id:'e98432fb-30bd-4081-bd80-31cd84befd88'
        }
      });
      
      window.location.reload();
    }}
  >
    Cancel
  </button>

    const openSnackBar = () => {
        setSnackBar(true);
    }

    const delayedUpdate = () => {
        openSnackBar();
        setTimeout(props.refetch, 3000);
    }

    const closeSnackBar = () => {
        setSnackBar(false);
    }

    const getTargetCustomerPoints = () => {
        const customerPoints = propsUser?.customer_points;
        let targetCP = customerPoints.filter(cp => cp.points_token_types_id === pointTokenTypeId);
        targetCP = targetCP ? targetCP[0] : null;
        return targetCP;
    }

  const [updateCustomerPoints] = useMutation(UPDATE_BALANCE)

  const [transactAndUpdate] = useMutation(INSERT_TRANSACTIONS_UPDATE_BALANCE, {
      'fetchPolicy': 'no-cache',
      onCompleted: delayedUpdate,
  });

  const solidInput =  async(dataInput) => {
    const result = {}
    result.companyId = 'a67b662d-c543-446e-9d3d-e39a147c9800';
    result.campaignId = '73b99f46-3be5-40b6-9ba6-d0b8c449e528';

    let transactions = [];

    dataInput.forEach((soli) => {
      let transactionInput = {
        offsetTransactionId: soli.id,
        customerOrgId: "",
        customerOrgName: "EnterpriseCorp",
        customerAccountId: "",
        metaData: {},
        customerUserId: propsUser.id,
        countOfOffsets: quantityCarbon
      }
      transactions.push(transactionInput);
    })

    let input = {};
    input.transactions = transactions;

    const response = {...result, ...input}
    return response
  } 

  const coinsInput = (typeToken) => {
    const token = 3;
    let result = {};
    result.customerId = propsUser.id;
    result.transactionTypeId = props.transactionTypeId;
    result.currencyType= token
    let products = [];
    const propsProducts = props?.products ? props.products : [];

    propsProducts.forEach(product => {
      const quantity = props.productQuantityMap[product.id];
      let transactionInput;

      transactionInput = {
        productId: product.id,
        productQuantity: quantity,
        typeToken : typeToken === 1 ?'ETH':'BTC',
        coinsAmount :product.coin_amount
      };
        products.push(transactionInput);
    });

    let input = {};
    input.products = products;
    return {...input, ...result}
  }
  const transactionInputs = () => {
    const fiat = 1;
    const point = 4;
    let result = {};
    result.customerId = propsUser.id;
    result.transactionTypeId = props.transactionTypeId;
    result.currencyType = props.mode === 'fiat' ? fiat : point;
    result.amount = props.mode === 'fiat' ? Number(calculateTotalClimate()) + Number(quantityCarbon * 20) : null;
    let products = [];


    const propsProducts = props.products.filter(product => props.productQuantityMap[product.id] > 0);

    propsProducts.forEach(product => {
      const quantity = props.productQuantityMap[product.id];
      let transactionInput;
      transactionInput = {
        productId: product.id,
        productQuantity: quantity,
      };
      products.push(transactionInput);

    });

    let input = {};
    input.products = products;
    return { ...input, ...result }
  }

  const cUSDInput = () => {
    const cUSD = 6;
    let result = {};
    result.customerId = propsUser.id;
    result.transactionTypeId = props.transactionTypeId;
    result.currencyType = cUSD
    let products = [];
    const propsProducts = props?.products ? props.products : [];

    propsProducts.forEach(product => {
      const quantity = props.productQuantityMap[product.id];
      let transactionInput;

      transactionInput = {
        productId: product.id,
        productQuantity: quantity,
        coinsAmount: product.list_price_points_tokens / 1
      };
      products.push(transactionInput);
    });

    let input = {};
    input.products = products;
    return { ...input, ...result }
  }

  const stripeInputs = () => {
    const fiat = 1;
    let result = {};
    result.customerId = propsUser.id;
    result.transactionTypeId = props.transactionTypeId;
    result.currencyType=  fiat;
    result.amount = props.mode === 'fiat' ? Number(calculateTotalClimate()) + Number(quantityCarbon * 20) : null;
    return result
  }

  const calculateTotalFiat = () => {
    let total = 0;
    const products = props?.products ? props.products : [];
    products.forEach(product => {
        total += (product.fiat_list_price * props.productQuantityMap[product.id]);
    })
    return total;
  }

  const calculateTotalPoints = () => {
    let total = 0;
    props.products.forEach(product => {
      total += (props.productQuantityMap[product.id]);
    })
    return total;
  }

  const calculateTotalcUSD = () => {
    let total = 0;
    props.products.forEach(product => {
      total += (product.list_price_points_tokens / 1 * props.productQuantityMap[product.id]);
    })
    return total;
  }

  const calculateTotalToken = () => {
    let total = 0;
    props.products.forEach(product => {
        total += (Number(product.coin_amount) * props.productQuantityMap[product.id]);
    })
    return total;
  }

  const calculateTotalClimate = () => {
    let total = 0;
    const products = props?.products ? props.products : [];
    products.forEach(product => {
      if (product.id !== 'a7405ba7-bfea-41b8-a184-53281107a815' && props.mode === 'fiat') {
        total += (product.fiat_list_price * props.productQuantityMap[product.id]);
      }
    })
    return total;
  }

  const calculateRewardClimate = (transactionData: any) => {
    let total = 0;
    if (transactionData) {
      transactionData.forEach(data => {
        // if (product.id !== 'a7405ba7-bfea-41b8-a184-53281107a815' && props.mode === 'fiat') {
        //   total += (product.fiat_list_price * props.productQuantityMap[product.id]);
        // }
        total += data?.rewardPoints ?? 0;
      });
    }
    return total;
  }

  const updateClime = (clime: any) => {
    setTotalClime(clime);
  }

  const countDonate = calculateTotalPoints() - quantityCarbon
  const countDonateStripe = (calculateTotalClimate()/100)
  const dataCarbonOffset = propsUser.customer_points.filter(data => data.points_token_types_id === carbonOffsetPointId)
  const [totalCarbonOffset, setTotalCarbonOffset ] = useState(dataCarbonOffset[0].balance)
  const [totalPoint, setTotalPoint] = useState(0)
  const [totalClime, setTotalClime] = useState('N/A');

  const countFiat = `$${(Number(calculateTotalClimate()) + Number(quantityCarbon * 2000))/100}`
  const countPoint = `${props.formatNumber(calculateTotalPoints())} CLIMATE Coins`
  const countCoins = `${calculateTotalToken().toFixed(4)} ETH`
  const countcUSD = `${calculateTotalcUSD()} cUSD`;
  const modeFiat = props.mode === 'fiat'
  const modePoint = props.mode === 'points'
  const modeCoins = props.mode === 'coins'
  const modecUSD = props.mode === 'cUSD'

  return (
    <WrapperBox>
      <StyledSnackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        open={popupConnectWallet}
        autoHideDuration={3000}
        onClose={closePopupConnectWallet}
      >
        <StyledMuiAlert
          onClose={closePopupConnectWallet}
        >
          <p>Please connect a wallet.</p>
        </StyledMuiAlert>
      </StyledSnackbar>
        <StyledSnackbar
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}
            open={snackBar}
            autoHideDuration={3000}
            onClose={closeSnackBar}
        >
            <StyledMuiAlert
                onClose={closeSnackBar}
                severity="success"
                variant="outlined"
                iconMapping={{ success: <CheckCircleOutlineIcon fontSize="24" /> }}
            >
                <StyledAlertTitle>Success!</StyledAlertTitle>
                {
                  !modePoint ?<p>Hang tight while we ship your items.</p>:''
                }
                
                {
                  modePoint ?
                  <p>You donated {countDonate} Climate Coins </p>
                  :''
                }

               {
                  quantityCarbon ?
                      <><br></br><p>{quantityCarbon} Carbon Offset granted!</p></>
                        : ''
                }
            </StyledMuiAlert>
        </StyledSnackbar>
        <StyledSnackbar
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}
            open={isCheckStripe}
            autoHideDuration={3000}
            onClose={closePopupStripe}
        >
            <StyledMuiAlert
                onClose={closePopupStripe}
                severity="success"
                variant="outlined"
                iconMapping={{ success: <CheckCircleOutlineIcon fontSize="24" /> }}
            >
                <StyledAlertTitle>Success!</StyledAlertTitle>
                <p>Hang tight while we ship your items.</p>
                {
                  dataClimateCoins ?
                  <><br></br><p>You donated {dataClimateCoins} $ </p></>
                  :''
                }
                {
                    checkCarbonOffset ?
                        <><br></br><p>+ {checkCarbonOffset} Carbon Offset granted!</p></>
                        : ''
                }
            </StyledMuiAlert>
        </StyledSnackbar>
      <>
        <WrappedCeloWalletConnect
          updateClime={updateClime}
          connect={connect}
          disconnect={disconnect} 
          connectOnly={true}/>
      </>
      {/* <Box className={classes.div_btn_wallet}>
        {
          !accountConnect && !isConnectWallet ? (
            connectWallet
          ) : (
            disconnectWallet
          )
        }
      </Box> */}
      {/* <Box style={{ marginBottom:5, marginLeft:5 }}>
        {
        isConnectWallet && accountConnect && <span style={{ color: 'black', fontSize: 14 }}>Connected to ${formatAddress(accountConnect)}</span>
        }
      </Box> */}
    </WrapperBox>
  );
};

export default PaymentOptions;
