import React from 'react';
import FormControl from '@material-ui/core/FormControl';
import Select from 'react-select';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';

const useStyles = makeStyles((theme) => ({
  '&.react-select__control--is-focused': {
    '&.react-select__indicators': {
      '&.react-select__dropdown-indicator': {
        transform: 'rotate(270deg)'
      }
    }
  },
  main_div: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    borderBottom: '1px solid #D7DCE8'

  },
  item_field: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '50%',

    '& label': {
      fontWeight: 'normal',
      fontSize: '16px',
      lineHeight: '20px',
      color: '#010A21'
    },
    '&:first-child': {
      marginRight: 50
    }
  },
  select: {
    width: '342px',
    margin: '0',
    '& label.Mui-focused': {
      color: '#010A21'
    },
    '& input': {
      border: '1px solid #D7DCE8'
    },
    '& .MuiSelect-underline:after': {
      borderBottomColor: '#D7DCE8'
    },

    '& .MuiSelectAdornment-positionEnd button': {
      paddingRight: 0
    },
    '&:before': {
      borderBottom: 'none'
    },
    '& .MuiSelect-underline:hover:not(.Mui-disabled):before': {
      borderBottom: 'none'
    }
  }
}));
const customStyles = {
  control: () => ({
    minWidth: '100px',
    display: 'flex',
    alignItems: 'center',
    border:'none',
    '$:hover':{border:'none'}
  }),
  option: (styles, { data, isDisables, isFocused, isSelected }) => {
    return {
      ...styles,
      backgroundColor: isFocused ? '#00A896': 'transparent',
      color: isFocused ? '#ffffff': '#010A21',
    };
  },
  indicatorsContainer: (provided, state) => ({
    border: 'none',
    transition: 'all .2s ease',
    transform: state.selectProps.menuIsOpen && 'rotate(-90deg)'
  }),
  input: () => ({
    paddingTop: '12px'
  }),
  menu: (props) => ({
    ...props,
    marginTop: '2px',
    borderRadius: '0px',
    borderTop: 'none',
    boxShadow: '0 3px 6px #00000029'
  }),
  menuList: () => ({
    fontStyle: 'normal',
    fontFamily: 'Open Sans',
    fontWeight: '200',
    fontSize: '15px',
    lineHeight: '24px',
    color: '#010A21',
    borderTop: 'none',
    '$:nth-child:focus': {
      backgroundColor: 'red'
    }
  }),

  placeholder: () => ({
    color: '#010A21'
  })

};


const Filters = (props) => {
  const classes = useStyles();
  let clientOptions = props.products.map(product => product.product_brand)
  clientOptions = [...new Set(clientOptions)];
  const uniqueClientOptions = clientOptions.map(brand => {return {value: brand, label: brand}})


  return (
    <div className={classes.main_div}>
      <div className={classes.item_field}>
        <InputLabel>Client</InputLabel>
        <FormControl>
          <Select
            placeholder='Brand Name'
            styles={customStyles}
            options={uniqueClientOptions}
          >
          </Select>
        </FormControl>
      </div>
    </div>
  );
};

export default Filters;