import React, { useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  makeStyles,
  Tooltip,
  FormControlLabel,
  Switch
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import Pagination from '@material-ui/lab/Pagination';
import Trash from 'src/icons/content/Trash';
import Edit from 'src/icons/content/Edit';
import View from 'src/icons/content/View';
import moment from 'moment';
import RuleSetInput from '../ruleset-input/RuleSetInput';

import { useMutation } from '@apollo/client';
import gql from 'graphql-tag';
import {GET_All_RULESET} from '../index';


const useStyles = makeStyles((theme) => ({
  root: {
    borderRadius: '0',
    boxShadow: 'none'
  },
  icon: {
    margin: '0 10px 0 0',
    cursor: 'pointer',
    '&:hover': {
      '& $path': {
        stroke: '#00A896'
      }
    }
  }
}));
const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: 'transparent',
    fontWeight: 'bold',
    color: '#010A21',
    borderBottom: 'none'
  },
  body: {
    fontSize: 14
  }
}))(TableCell);

const IOSSwitch = withStyles((theme) => ({
    root: {
      width: 48,
      height: 26,
      padding: 0,
      borderRadius: 20,
      backgroundColor: '#fff'
    },
    switchBase: {
      padding: 2,
      margin: 0,
      color: '#31BD00',
      '&$checked': {
        transform: 'translateX(22px)',
        color: '#ffffff',
        '& + $track': {
          backgroundColor: '#ffffff',
          opacity: 1,
        },
        '& .MuiSwitch-thumb': {
          background: '#31BD00',
        },
      },
    },
    thumb: {
      width: 22,
      height: 22,
      background: '#D7DCE8',
      position: 'relative',
      '&:before': {
        content: 'x',
        position: 'absolute',
      }
    },
    track: {
      borderRadius: 14,
      border: '1px solid #D7DCE8',
      backgroundColor: '#ffffff',
      opacity: 1,
      transition: theme.transitions.create(['background-color', 'border']),
    },
    checked: {
    },
    focusVisible: {},
  }))(({classes, ...props}) => {
    return (
      <Switch
        focusVisibleClassName={classes.focusVisible}
        disableRipple
        classes={{
          root: classes.root,
          switchBase: classes.switchBase,
          thumb: classes.thumb,
          track: classes.track,
          checked: classes.checked,
        }}
        {...props}
      />
    );
});


const StyledPagination = withStyles({
  root: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: '16px',
    '& ul li button': {
      fontSize: '13px',
      margin: 0,
      minWidth: '25px',
      maxHeight: '25px'
    },
    '& .Mui-selected': {
      color: '#00A896',
      backgroundColor: 'transparent'
    },
    '& .Mui-selected:hover': {
      color: '#ffffff'
    }
  }
})(Pagination);

const StyledTableBody = withStyles((theme) => ({
  root: {
    backgroundColor: '#F0F4FC',
    borderRadius: '16px',

    '& .MuiTableCell-body': {
      borderBottom: 'none',
      color: '#010A21'

    },
    '& .MuiTableRow-hover': {
      '&:first-child': {
        '& .MuiTableCell-body': {
          '&:first-child': { borderTopLeftRadius: '16px' },
          '&:last-child': { borderTopRightRadius: '16px' }
        }
      },
      '&:last-child': {
        '& .MuiTableCell-body': {
          '&:first-child': { borderBottomLeftRadius: '16px' },
          '&:last-child': { borderBottomRightRadius: '16px' }
        }
      }
    },
    '& .MuiTableRow-hover:not(:last-child)': {
      borderBottom: '1px solid #D7DCE8'
    }
  }
}))(TableBody);


const UPDATE_RULESET_IS_ACTIVE = gql`
    mutation updateRulesetsByPk($id: uuid!, $isActive: Boolean!) {
        update_rulesets_by_pk(
            _set: {
                is_active: $isActive
            }
            pk_columns: {
                id: $id
            }
        ) {
            id
            is_active
        }
    }
`;


const DELETE_RULESET = gql`
    mutation deleteRulesetsByPk($id: uuid!) {
        delete_rulesets_by_pk(id: $id) {
            id
        }
    }
`;



const RuleSetList = ({ rulesets, className, currentCompany, toggleMode, mode, relatedCompanies, ...rest }) => {
  const classes = useStyles();
  const [ruleset, setRulesets] = useState(null);
  const [limit, setLimit] = useState(10);

  const [updateIsActive] = useMutation(UPDATE_RULESET_IS_ACTIVE);
  const [deleteRuleset] = useMutation(DELETE_RULESET, {
      refetchQueries: [{query: GET_All_RULESET}]
  });

  const [isUpdateRuleSet, setIsisUpdateRuleSet] = useState(false);


  const formatDate = (date) => {
    return moment(date).format('ll')
  }

  const togglePopupUpdateRuleSet = ( rule ) => {
    setIsisUpdateRuleSet(!isUpdateRuleSet);
    if (rule) {
      setRulesets(rule)
    }
  };

  return (
    <>
      {!isUpdateRuleSet ? (
        <Card
          className={clsx(classes.root, className)}
          {...rest}
        >
          <PerfectScrollbar>
            <Box>
              <Table>
                <TableHead>
                  <TableRow>
                    <StyledTableCell>
                      Active
                    </StyledTableCell>
                    <StyledTableCell>
                      Name
                    </StyledTableCell>
                    <StyledTableCell>
                      Date
                    </StyledTableCell>
                    <StyledTableCell>
                      <Box textAlign='center'>
                        Actions
                      </Box>
                    </StyledTableCell>
                  </TableRow>
                </TableHead>

                <StyledTableBody>
                  {rulesets.slice(0, limit).map((rule) => (
                    <TableRow
                      hover
                      key={rule.id}
                    >
                      <TableCell>
                        <FormControlLabel control={
                          <IOSSwitch
                            checked={ rule.is_active }
                            onClick={ e => {
                                e.preventDefault();
                                updateIsActive({variables: {id: rule.id, isActive: !rule.is_active}})
                            }}
                            name='is_active'
                          />
                        } />
                      </TableCell>
                      <TableCell>
                        {rule.ruleset_name}
                      </TableCell>
                      <TableCell>
                        { formatDate(rule.ruleset_begin_at)} - {formatDate(rule.ruleset_end_at)}
                      </TableCell>

                      <TableCell>
                        <Box textAlign='center'>
                          <Edit className={classes.icon} onClick={() => {
                              toggleMode('edit');
                              togglePopupUpdateRuleSet(rule);
                            }
                          }/>
                          <View className={classes.icon} />
                          <Trash className={classes.icon} onClick={() => {
                              deleteRuleset({variables: {id: rule.id}});
                            }
                          }/>
                        </Box>
                      </TableCell>
                    </TableRow>
                  ))}
                </StyledTableBody>
              </Table>
            </Box>
          </PerfectScrollbar>

          <StyledPagination
            defaultPage={6}
            boundaryCount={2}
            count={20}
            color='primary' />
        </Card>
      ) : (
          <RuleSetInput
            mode={mode}
            toggleMode={toggleMode}
            onToggle={ togglePopupUpdateRuleSet }
            selectedRuleset={ ruleset }
            currentCompany={ currentCompany }
            relatedCompanies={relatedCompanies}
          />
        )
      }
    </>
  );
};

RuleSetList.propTypes = {
  listLoyalty: PropTypes.array
};

export default RuleSetList;
