import React from 'react'
import classes from './Toolbar.module.css'
import {Box, Tooltip, Fab,} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';

const Toolbar = props => {

    const title = () => {
        /*if (props.mode === 'edit') {
            return 'Edit Loyalty Program'
        } else if (props.mode === 'add') {
            return 'Add Loyalty Program'
        } else {
            return 'History and Transactions'
        }*/
        return 'History and Transactions'
    }

  return (
    <div className={classes.Container}>
      <Box
        display="flex"
        justifyContent="space-between"
      >
        <div className={classes.Export}>
            {title()}
        </div>

        <Tooltip
          title="Add new Loyalty"
          aria-label="add"
          className={classes.Toolbar}
        >
          <Fab color="primary" className={classes.IconToolbar}>
            <AddIcon />
          </Fab>
        </Tooltip>
      </Box>
    </div>
  )
}

export default Toolbar
