import { Avatar, Box, makeStyles, withStyles } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/styles';
import GlobalStyles from 'src/components/GlobalStyles';
import Page from 'src/components/Page';
import theme from 'src/theme';
import TopBar from '../tolbar/TopBar';
import { useEffect, useState } from 'react';
import { getNftMetadata, getNftOfAddress } from 'src/utils/handleApi';
import { CLIMATE_NFT_COLLECTION_ADDRESS } from 'src/utils/constant';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    display: 'flex',
    height: '100%',
    overflow: 'hidden',
    width: '100%'
  },
  wrapper: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
    paddingTop: 90,
    paddingLeft: 290
  },
  contentContainer: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden'
  },
  content: {
    flex: '1 1 auto',
    height: '100%',
    overflow: 'auto',
    paddingLeft: 0
  }
}));

const StyledAvatar = withStyles(() => ({
  root: {
    width: '450px',
    height: '450px'
  },
  rounded: {
    borderRadius: 12
  }
}))(Avatar);

const NftDetail = (props) => {
  const classes = useStyles();
  const [metadata, setMetadata] = useState({});
  const celoAccount = localStorage.getItem('celo_account');
  useEffect(() => {
    async function fetchData() {
      const res = await getNftOfAddress(celoAccount);
      let nft = {};
      if (res && res.data) {
        res.data.forEach((e) => {
          if (e.contractAddress.toLowerCase() === CLIMATE_NFT_COLLECTION_ADDRESS.toLowerCase()) {
            nft = e.metadata.find((n) => n.tokenId === props.match.params.id);
          }
        });
        if (nft) {
          const resMetadata = await getNftMetadata(nft.url);
          setMetadata(resMetadata?.data?.metadata);
        }
      }
    }
    fetchData();
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles />
      <Page title="DApp" style={{ width: '100%' }}>
        <div className={classes.root}>
          <TopBar
            currentCompany={props.currentCompany}
            currentUser={props.currentUser}
          />
          <div style={{ display: 'flex', marginTop: '100px', width: '100%' }}>
            <StyledAvatar variant="rounded" src={metadata?.image} />
            <div style={{ marginLeft: '12px', flexGrow: '1' }}>
              <div style={{ borderBottom: '1px solid', fontSize: '35px' }}>
                {metadata?.name}
              </div>
              <br></br>
              <div>
                <span style={{ fontSize: '25px' }}>Attributes</span>
                <div
                  style={{
                    display: 'grid',
                    gridTemplateColumns: 'repeat(3, 1fr)',
                    gap: '10px'
                  }}
                >
                  {metadata?.attributes?.map((e) => {
                    return (
                      <>
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                            background: '#d4d4d4',
                            padding: '6px',
                            borderRadius: '6px',
                            height: '64px',
                            justifyContent: 'center'
                          }}
                        >
                          <div style={{ fontWeight: 'bold' }}>
                            {e.trait_type}
                          </div>
                          <div>{e.value}</div>
                        </div>
                      </>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Page>
    </ThemeProvider>
  );
};

export default NftDetail;
