import React from 'react';

const Collapse = (props) => {
  return (
    <svg {...props}  width="18" height="18" viewBox="0 0 18 18" fill="white" xmlns="http://www.w3.org/2000/svg">
      <g>
        <path d="M17.4369 8.43783H10.3121C10.0016 8.43783 9.74963 8.18584 9.74963 7.87535C9.74963 7.56486 10.0016 7.31287 10.3121 7.31287H17.4369C17.7474 7.31287 17.9994 7.56486 17.9994 7.87535C17.9994 8.18584 17.7474 8.43783 17.4369 8.43783Z" fill="white"/>
        <path d="M14.6245 11.2502C14.4805 11.2502 14.3366 11.1954 14.227 11.0852C14.0073 10.8654 14.0073 10.5091 14.227 10.2894L16.642 7.87457L14.227 5.45958C14.0073 5.23986 14.0073 4.88364 14.227 4.66392C14.4468 4.44406 14.8031 4.44406 15.0228 4.66392L17.8352 7.47633C18.0549 7.69605 18.0549 8.05227 17.8352 8.27199L15.0228 11.0844C14.9125 11.1954 14.7686 11.2502 14.6245 11.2502Z" fill="white"/>
        <path d="M5.99986 18C5.83933 18 5.68703 17.9775 5.53488 17.9303L1.02142 16.4266C0.407305 16.2121 0 15.6398 0 15.0002V1.50061C0 0.673364 0.672754 0.000610352 1.5 0.000610352C1.66039 0.000610352 1.81269 0.0231316 1.96498 0.0703714L6.4783 1.57408C7.09255 1.78858 7.49972 2.36081 7.49972 3.00047V16.5C7.49972 17.3273 6.8271 18 5.99986 18ZM1.5 1.12557C1.29374 1.12557 1.12496 1.29435 1.12496 1.50061V15.0002C1.12496 15.1599 1.23222 15.3083 1.3852 15.3616L5.8775 16.8586C5.90977 16.869 5.95179 16.8751 5.99986 16.8751C6.20612 16.8751 6.37475 16.7063 6.37475 16.5V3.00047C6.37475 2.84076 6.2675 2.69231 6.11452 2.63903L1.62222 1.14205C1.58995 1.13162 1.54793 1.12557 1.5 1.12557Z" fill="white"/>
        <path d="M11.4371 6.00046C11.1266 6.00046 10.8746 5.74847 10.8746 5.43798V2.06309C10.8746 1.54634 10.454 1.12557 9.93725 1.12557H1.50001C1.18952 1.12557 0.937531 0.873584 0.937531 0.563092C0.937531 0.252601 1.18952 0.000610352 1.50001 0.000610352H9.93725C11.075 0.000610352 11.9996 0.925355 11.9996 2.06309V5.43798C11.9996 5.74847 11.7476 6.00046 11.4371 6.00046Z" fill="white"/>
        <path d="M9.9372 15.7501H6.93721C6.62672 15.7501 6.37473 15.4981 6.37473 15.1876C6.37473 14.8771 6.62672 14.6251 6.93721 14.6251H9.9372C10.454 14.6251 10.8746 14.2044 10.8746 13.6876V10.3127C10.8746 10.0022 11.1266 9.75024 11.4371 9.75024C11.7476 9.75024 11.9995 10.0022 11.9995 10.3127V13.6876C11.9995 14.8254 11.0749 15.7501 9.9372 15.7501Z" fill="white"/>
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="18" height="18" fill="white"/>
        </clipPath>
      </defs>
    </svg>
  );
};

export default Collapse;
