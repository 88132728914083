import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import ImagesUpload from 'src/components/ui/ImagesUpload/ImagesUpload'
import {Avatar, Box, Card, CardActions, CardContent, Divider, Typography, makeStyles} from '@material-ui/core';
import gql from "graphql-tag";
import { auth } from "src/utils/nhost";
import { useMutation} from "@apollo/client";

const useStyles = makeStyles(() => ({
  root: {},
  avatar: {
    height: 100,
    width: 100,
    marginBottom: 10
  }
}));

const UPDATE_USER = gql`
  mutation updateUserInfo ($currentUserId : uuid!, $avatar_url : String!) {
    update_users_by_pk(pk_columns: {id: $currentUserId}, _set: {avatar_url: $avatar_url}) {
      id
      avatar_url
    }
}`;

const Profile = ({ user, className, disabled, ...rest }) => {
  const classes = useStyles();

  const [updateUser ] = useMutation(UPDATE_USER);

  const handleChildClick = (image) => {
    if (!disabled || disabled === undefined) {
        let file = image[0];
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = () => {
          user.avatar_url = reader.result
        };
        setTimeout(() => {
          updateUser({
            variables:
                {
                  currentUserId: user?.id,
                  avatar_url: user?.avatar_url,
                }
          })
        }, 100)
    }
  }

  const displayName = () => {
    const dn = user?.display_name ? user.display_name : '';
    const fn = user?.first_name ? user.first_name : '';
    const ln = user?.last_name ? user.last_name : '';

    return dn ? dn : fn + ln;
  }

  return (
    <Card
      className={clsx(classes.root, className)}
      {...rest}
    >
      <CardContent>
        <Box
          alignItems="center"
          display="flex"
          flexDirection="column"
        >
          <Avatar
            className={classes.avatar}
            src={user?.avatar_url}
          />
          <Typography
            color="textPrimary"
            gutterBottom
            variant="h3"
          >
            {displayName()}
          </Typography>
          <Typography
            color="textPrimary"
            variant="body1"
          >
            {user?.account ? user.account.email : user?.user_email}
          </Typography>
          <Typography
            color="textPrimary"
            variant="body1"
          >
            {user?.user_phone}
          </Typography>
          <Typography
            className={classes.dateText}
            color="textSecondary"
            variant="body1"
          >
            {/*{`${moment().format('hh:mm A')} ${user.timezone}`}*/}
          </Typography>
        </Box>
      </CardContent>
      <Divider/>
      <CardActions>
        <ImagesUpload maxNumberOfFiles={disabled === true || disabled === undefined ? 1 : 0} onChildClick={handleChildClick}/>
      </CardActions>
    </Card>
  );
};

Profile.propTypes = {
  className: PropTypes.string
};

export default Profile;
