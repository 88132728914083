import React, { useState } from 'react';
import { Box, Container, makeStyles } from '@material-ui/core';
import Page from 'src/components/Page';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import Toolbar from './toolbar/Toolbar';
import LoyaltyInput from './loyalty-input/LoyaltyInput';
import { useQuery } from '@apollo/client';
import Loading from 'src/components/ui/Loading/Loading';
import LoyaltyList from './loyalty-list/LoyaltyList';
import gql from 'graphql-tag';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import OnRecommend from 'src/icons/content/OnRecommend';
import OffRecommend from 'src/icons/content/OffRecommend';
import moment from 'moment';



const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});


export const GET_STANDARD_LOYALTY = gql`
   query getStandardLoyalty {
			loyalty_programs(order_by: {program_name: asc}) {
				id
				allow_recommendation
				company_id
                company {
                    related_companies {
                        related_company {
                            id
                            name
                            company_type {
                                co_type
                            }
                            point_token_types {
                                id
                                name
                            }
                        }
                    }
                }
				created_at
				is_active
				modified_at
				modified_by_user_id
				partner_point_token_type_id
                premium_counter_point_type {
                    id
					name
					is_counter
					is_crypto
                }
				partner_point_token_type {
					id
					name
					is_counter
					is_crypto
				}
        partner_counter_point_type {
					id
					name
					is_counter
					is_crypto
				}
				db_counter_point_token_type {
					id
					name
					is_counter
					is_crypto
				 }
				 crypto_point_type {
					 id
					 name
					 is_counter
					 is_crypto
				 }
				 crypto_point_type_2 {
					 id
					 name
					 is_counter
					 is_crypto
				 }
				partner_co_id
				partner_company {
					id
					name
                    point_token_types {
                        id
                        name
                    }
				}
				program_begin_at
				program_end_at
				program_name
				ruleset {
					id
					ruleset_name
				}
			}
			point_token_types {
				id
				name
				is_counter
				is_crypto
                company_id
			}
            related_companies {
                related_company {
                    id
                    name
                    company_type {
                        co_type
                    }
                    point_token_types {
                        id
                        name
                    }
                }
            }
			rulesets {
				id
				ruleset_name
                purchase_includes_company_points_grant
                redeem_using_basic_company_points
                redeem_using_crypto_loyalty_points
                redeem_using_partner_points
                redeem_using_premium_company_points
                reward_by_percentage_reduction
                reward_by_price_reduction
                reward_includes_bonus_product
                reward_includes_cash_back
                reward_includes_company_points_grant
                reward_includes_partner_points_grant
                top_up_points_with_cash_payment
                ruleset_begin_at
                ruleset_end_at
                is_active
                reward_with_a_soli_points_carbon_offset
			}
    }
`;


const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.dark,
        minHeight: '100%',
        paddingBottom: theme.spacing(3),
        paddingTop: theme.spacing(3)
    }
}));

const StandardLoyaltyView = (props) => {
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const [mode, setMode] = useState(null);
    const [dialogRuleset, setDialogRuleset] = useState(null);

    const toggleForm = () => {
        setOpen((prev) => !prev);
    };

    const closeDialog = () => {
        setDialogRuleset(null);
    }

    const dialogListIcon = (field) => {
        if (dialogRuleset === null) {
            return null;
        }
        if (dialogRuleset[field]) {
            console.log(dialogRuleset);
            return <OnRecommend />
        }
        return <OffRecommend />
    }

    const toggleMode = (type) => {
        if (type === 'edit') {
            setMode('edit');
        } else if (type === 'add') {
            setMode('add')
        } else {
            setMode(null);
        }
    };

    return (
        <Page
            className={classes.root}
            title='Programs & Campaigns | Procision'
        >
            <Container maxWidth={false}>
                <Dialog
                    onClose={closeDialog}
                    open={dialogRuleset}
                    TransitionComponent={Transition}
                    fullWidth={true}
                    maxWidth={'sm'}
                    aria-labelledby="ruleset-dialog-title"
                >
                    <DialogTitle
                        id="ruleset-dialog-title"
                        disableTypography
                        style={{ paddingTop: 0, paddingBottom: 0 }}
                    >
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <span style={{ display: 'flex', alignItems: 'center' }}><h2>{dialogRuleset?.ruleset_name}</h2></span>
                            <DialogActions>
                                <IconButton onClick={closeDialog}>
                                    <CloseIcon />
                                </IconButton>
                            </DialogActions>
                        </div>
                    </DialogTitle>
                    <Divider />
                    <DialogContent>
                        <DialogContentText id="alert-dialog-slide-description" color="black">
                            <h3 style={{ fontWeight: 'bolder' }}>State:</h3>
                            <div style={{ display: 'flex', justifyContent: 'space-between', padding: '8px', paddingLeft: '16px', paddingRight: '43px', paddingBottom: '16px' }}>
                                <span style={{ display: 'flex', alignItems: 'center' }}>
                                    <p style={{ paddingRight: '10px', fontWeight: 'bolder' }}>Begins:</p>
                                    {dialogRuleset?.ruleset_begin_at ? moment(dialogRuleset.ruleset_begin_at).format('MM-DD-YYYY') : ''}
                                </span>
                                <span style={{ display: 'flex', alignItems: 'center' }}>
                                    <p style={{ paddingRight: '10px', fontWeight: 'bolder' }}>Ends:</p>
                                    {dialogRuleset?.ruleset_end_at ? moment(dialogRuleset.ruleset_end_at).format('MM-DD-YYYY') : ''}</span>
                                <span style={{ display: 'flex', alignItems: 'center' }}>
                                    <p style={{ paddingRight: '10px', fontWeight: 'bolder' }}>Is Active:</p>
                                    {dialogRuleset?.is_active ? <OnRecommend /> : <OffRecommend />}</span>
                            </div>
                            <Divider />
                            <List>
                                <h3 style={{ fontWeight: 'bolder' }}>Basic Rules:</h3>
                                <ListItem>
                                    <ListItemText>Redeem using basic company points</ListItemText>
                                    <ListItemIcon>{dialogListIcon('redeem_using_basic_company_points')}</ListItemIcon>
                                </ListItem>
                                <ListItem>
                                    <ListItemText>Redeem using premium company points</ListItemText>
                                    <ListItemIcon>{dialogListIcon('redeem_using_premium_company_points')}</ListItemIcon>
                                </ListItem>
                                <ListItem>
                                    <ListItemText>Redeem using crypto loyalty points</ListItemText>
                                    <ListItemIcon>{dialogListIcon('redeem_using_crypto_loyalty_points')}</ListItemIcon>
                                </ListItem>
                                <ListItem>
                                    <ListItemText>Reward includes company points grant</ListItemText>
                                    <ListItemIcon>{dialogListIcon('reward_includes_company_points_grant')}</ListItemIcon>
                                </ListItem>
                                <ListItem>
                                    <ListItemText>Reward by price reduction</ListItemText>
                                    <ListItemIcon>{dialogListIcon('reward_by_price_reduction')}</ListItemIcon>
                                </ListItem>
                                <ListItem style={{ paddingBottom: '16px' }}>
                                    <ListItemText>Reward by percentage reduction</ListItemText>
                                    <ListItemIcon>{dialogListIcon('reward_by_percentage_reduction')}</ListItemIcon>
                                </ListItem>

                                <Divider />

                                <h3 style={{ paddingTop: '8px', fontWeight: 'bolder' }}>Advanced Rules:</h3>
                                <ListItem>
                                    <ListItemText>Reward with a Soli Points carbon offset</ListItemText>
                                    <ListItemIcon>{dialogListIcon('reward_with_a_soli_points_carbon_offset')}</ListItemIcon>
                                </ListItem>
                                <ListItem>
                                    <ListItemText>Puchase includes company points grant</ListItemText>
                                    <ListItemIcon>{dialogListIcon('purchase_includes_company_points_grant')}</ListItemIcon>
                                </ListItem>
                                <ListItem>
                                    <ListItemText>Top up points with cash payment</ListItemText>
                                    <ListItemIcon>{dialogListIcon('top_up_points_with_cash_payment')}</ListItemIcon>
                                </ListItem>
                                <ListItem>
                                    <ListItemText>Reward includes bonus product</ListItemText>
                                    <ListItemIcon>{dialogListIcon('reward_includes_bonus_product')}</ListItemIcon>
                                </ListItem>
                                <ListItem style={{ paddingBottom: '16px' }}>
                                    <ListItemText>Reward includes cash back</ListItemText>
                                    <ListItemIcon>{dialogListIcon('reward_includes_cash_back')}</ListItemIcon>
                                </ListItem>

                                <Divider />

                                <h3 style={{ paddingTop: '8px', fontWeight: 'bolder' }}>Partner Rules:</h3>
                                <ListItem>
                                    <ListItemText>Redeem using partner points</ListItemText>
                                    <ListItemIcon>{dialogListIcon('redeem_using_partner_points')}</ListItemIcon>
                                </ListItem>
                                <ListItem>
                                    <ListItemText>Reward includes partner points grant</ListItemText>
                                    <ListItemIcon>{dialogListIcon('reward_includes_partner_points_grant')}</ListItemIcon>
                                </ListItem>

                            </List>
                        </DialogContentText>
                    </DialogContent>
                </Dialog>
                <Toolbar currentCompany={props.currentCompany} onToggle={toggleForm} mode={mode} toggleMode={toggleMode} />
                {open ? (
                    <LoyaltyInput setDialogRuleset={setDialogRuleset} currentCompany={props.currentCompany} onToggle={toggleForm} mode={mode} toggleMode={toggleMode} pointTokenTypes={props.point_token_types} rulesets={props.rulesets} />
                ) : (
                    <Box>
                        <LoyaltyList setDialogRuleset={setDialogRuleset} currentCompany={props.currentCompany} data={props.data} mode={mode} toggleMode={toggleMode} pointTokenTypes={props.point_token_types} rulesets={props.rulesets} />
                    </Box>)}
            </Container>
        </Page>
    );
};

const GetStandardLoyalty = (props) => {
    const classes = useStyles();

    const { loading, error, data } = useQuery(GET_STANDARD_LOYALTY);

    if (data) {
        data.loyalty_programs.forEach(loyalty => {
            loyalty.point_token_type = {
                ...loyalty.db_counter_point_token_type,
                ...loyalty.crypto_point_type
            };
        });
        window.data = data
    }

    if (loading) {
        return <Loading />;
    }

    if (error) {
        return <div className={classes.error}>Error!<br /> Something went wrong</div>;
    }

    return <StandardLoyaltyView
        data={data}
        point_token_types={data.point_token_types}
        related_companies={data.related_companies}
        rulesets={data.rulesets}
        {...props}
    />;
};

export default GetStandardLoyalty;
