import React, { useState } from "react";
import classesFile from './LoyaltyInput.module.css'
import { makeStyles, withStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Picker from "src/icons/Picker";
import DateFnsUtils from '@date-io/date-fns';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import SelectIconArrow from "src/icons/SelectIconArrow";
import UnCheckedBox from 'src/icons/user/UnCheckedBox';
import WhiteCheck from 'src/icons/WhiteCheck';
import InfoIcon from '@material-ui/icons/Info';
import gql from "graphql-tag";
import { useMutation } from "@apollo/client";
import { GET_STANDARD_LOYALTY } from '../index';


const UPDATE_LOYALTY_PROGRAM = gql`
    mutation updateLoyaltyProgramsByPk(
        $id: uuid!
        $program_name: String
        $program_end_at: timestamptz
        $program_begin_at: timestamptz
        $allow_recommendation: Boolean
        $is_active: Boolean
        $partner_co_id: uuid
        $partner_point_token_type_id: uuid,
        $premium_counter_point_type_id: uuid,
        $db_counter_point_type_id: uuid,
        $crypto_point_type_id: uuid,
        $ruleset_id: uuid,

        $partner_counter_point_type_id: uuid,
        $crypto_point_type_2_id: uuid
    ) {
        update_loyalty_programs_by_pk(
            _set: {
                program_name: $program_name
                program_end_at: $program_end_at
                program_begin_at: $program_begin_at
                allow_recommendation: $allow_recommendation
                is_active: $is_active
                partner_co_id: $partner_co_id
                partner_point_token_type_id: $partner_point_token_type_id,
                premium_counter_point_type_id: $premium_counter_point_type_id,
                db_counter_point_type_id: $db_counter_point_type_id,
                crypto_point_type_id: $crypto_point_type_id,
                ruleset_id: $ruleset_id,
                partner_counter_point_type_id: $partner_counter_point_type_id,
                crypto_point_type_2_id: $crypto_point_type_2_id
            }
            pk_columns: {
                id: $id
            }
        ) {
            id
            program_name
            program_end_at
            program_begin_at
            allow_recommendation
            is_active
            partner_co_id
            partner_point_token_type_id
            premium_counter_point_type_id
            db_counter_point_type_id
            crypto_point_type_id
            ruleset_id
            partner_counter_point_type_id
            crypto_point_type_2_id
        }
    }
`;


const CREATE_LOYALTY_PROGRAM = gql`
    mutation createLoyaltyProgramsOne(
        $program_name: String
        $program_end_at: timestamptz
        $program_begin_at: timestamptz
        $allow_recommendation: Boolean
        $is_active: Boolean
        $company_id: uuid
        $partner_co_id: uuid
        $partner_point_token_type_id: uuid,
        $premium_counter_point_type_id: uuid,
        $db_counter_point_type_id: uuid,
        $crypto_point_type_id: uuid,
        $ruleset_id: uuid,
        $partner_counter_point_type_id: uuid,
        $crypto_point_type_2_id: uuid
    ) {
        insert_loyalty_programs_one(
            object: {
                program_name: $program_name
                program_end_at: $program_end_at
                program_begin_at: $program_begin_at
                allow_recommendation: $allow_recommendation
                is_active: $is_active
                company_id: $company_id
                partner_co_id: $partner_co_id
                partner_point_token_type_id: $partner_point_token_type_id,
                premium_counter_point_type_id: $premium_counter_point_type_id,
                db_counter_point_type_id: $db_counter_point_type_id,
                crypto_point_type_id: $crypto_point_type_id,
                ruleset_id: $ruleset_id,
                partner_counter_point_type_id: $partner_counter_point_type_id,
                crypto_point_type_2_id: $crypto_point_type_2_id
            }
        ) {
            id
            program_name
            program_end_at
            program_begin_at
            allow_recommendation
            is_active
            company_id
            partner_co_id
            partner_point_token_type_id
            premium_counter_point_type_id
            db_counter_point_type_id
            crypto_point_type_id
            ruleset_id
            partner_counter_point_type_id
            crypto_point_type_2_id
        }
    }
`;


const StyledCheckbox = withStyles(() => ({
  root: {
    marginLeft: '11px',
  },
  checked: {
    background: '#00A896',
    color: '#fff',
    '&:first-child': {
      borderRadius: '2px',
      padding: 0,
      width: '26px',
      height: '26px',
      '&:hover': {
        background: '#00A896'
      }
    }
  },

  colorPrimary: {
    color: '#D7DCE8',
    padding: 0,
    marginRight: '10px'
  },
  label: {
    marginTop: 20
  }
}))(Checkbox);


const StyledFormControlLabel = withStyles(() => ({
  root: {
    marginLeft: '0px',
    marginTop: '24px',
    alignItems: 'flex-start',
  },

}))(FormControlLabel)


const IOSSwitch = withStyles((theme) => ({
  root: {
    width: 48,
    height: 26,
    padding: 0,
    marginRight: '10px',
    borderRadius: 20,
    backgroundColor: '#fff'
  },
  switchBase: {
    padding: 2,
    margin: 0,
    color: '#31BD00',
    '&$checked': {
      transform: 'translateX(22px)',
      color: '#ffffff',
      '& + $track': {
        backgroundColor: '#ffffff',
        opacity: 1,
      },
      '& .MuiSwitch-thumb': {
        background: '#31BD00',
      },
    },
  },
  thumb: {
    width: 22,
    height: 22,
    background: '#D7DCE8',
    position: 'relative',
    '&:before': {
      content: 'x',
      position: 'absolute',
    }
  },
  track: {
    borderRadius: 14,
    border: '1px solid #D7DCE8',
    backgroundColor: '#ffffff',
    opacity: 1,
    transition: theme.transitions.create(['background-color', 'border']),
  },
  checked: {
  },
  focusVisible: {},
}))(({ classes, ...props }) => {
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      {...props}
    />
  );
});

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  picker: {
    width: '100%',
    height: '52px',
    margin: '0',
    '& $formControl': {
      flexDirection: 'row-reverse'
    },
    '& label.Mui-focused': {
      color: '#010A21',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: '#D7DCE8',
    },
    '& .MuiInputAdornment-positionEnd button': {
      transform: 'translateX(12px)'
    },
    '& .MuiInput-underline:before': {
      borderBottomColor: '#D7DCE8',
    },
    '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
      borderBottom: '2px solid #D7DCE8',
    }
  },
  formControl: {
    flexDirection: 'row',
  },
  select: {
    width: '100%',
    margin: '0',
    '& label.Mui-focused': {
      color: '#010A21',
    },
    '& input': {
      border: '1px solid #D7DCE8'
    },
    '& .MuiSelect-underline:after': {
      borderBottomColor: '#D7DCE8',
    },
    '& .MuiSelectAdornment-positionEnd button': {
      paddingRight: 0
    },
    '&:before': {
      borderBottom: '1px solid #D7DCE8',
    },
    '& .MuiSelect-underline:hover:not(.Mui-disabled):before': {
      borderBottom: '1px solid #D7DCE8',
    }
  },
  ruleset_icon: {
    fontSize: '20px',
    marginLeft: '20px',
    '&:hover': {
      cursor: 'pointer'
    }
  }

}));

const LoyaltyInput = (props) => {
  const classes = useStyles();
  const propsLoyalty = props.loyalty;
  const propsPointTokenTypes = props.pointTokenTypes;
  const propsPartnerCompany = propsLoyalty?.partner_company ? propsLoyalty.partner_company : null;
  const propsPartnerTokenTypes = propsPartnerCompany?.point_token_types ? propsPartnerCompany.point_token_types : [];
  const propsPartnerCompanies = props?.relatedCompanies ? props.relatedCompanies : [];
  const propsPartnerCompaniesTokenTypes = propsPartnerCompanies.map(partner => partner?.related_company ? partner.related_company?.point_token_types : [])
  const propsRulesets = props.rulesets;

  const [updateLoyalty] = useMutation(UPDATE_LOYALTY_PROGRAM);
  const [createLoyalty] = useMutation(CREATE_LOYALTY_PROGRAM, {
    refetchQueries: [{ query: GET_STANDARD_LOYALTY }]
  });

  const [loyalty, setLoyalty] = useState({
    program_name: propsLoyalty?.program_name ? propsLoyalty.program_name : '',
    program_end_at: propsLoyalty?.program_end_at ? propsLoyalty.program_end_at : new Date(),
    program_begin_at: propsLoyalty?.program_begin_at ? propsLoyalty.program_begin_at : new Date(),
    allow_recommendation: propsLoyalty?.allow_recommendation ? propsLoyalty.allow_recommendation : false,
    is_active: propsLoyalty?.is_active ? propsLoyalty.is_active : false,
    partner_co_id: propsLoyalty?.partner_company ? propsLoyalty?.partner_company.id : '',
    partner_point_token_type_id: propsLoyalty?.partner_point_token_type ? propsLoyalty.partner_point_token_type.id : '',
    premium_counter_point_type_id: propsLoyalty?.premium_counter_point_type ? propsLoyalty.premium_counter_point_type.id : '',
    db_counter_point_type_id: propsLoyalty?.db_counter_point_token_type ? propsLoyalty.db_counter_point_token_type.id : '',
    crypto_point_type_id: propsLoyalty?.crypto_point_type ? propsLoyalty.crypto_point_type.id : '',
    ruleset_id: propsLoyalty?.ruleset ? propsLoyalty.ruleset.id : '',
    crypto_point_type_2_id: propsLoyalty?.crypto_point_type_2 ? propsLoyalty.crypto_point_type_2.id : '',
    partner_counter_point_type_id: propsLoyalty?.partner_counter_point_type ? propsLoyalty.partner_counter_point_type.id : '',
 
  });


  const showRulesetDialog = () => {
    if (loyalty.ruleset_id) {
      const filtered = propsRulesets.filter(ruleset => ruleset.id === loyalty.ruleset_id);
      const selected = filtered[0];
      props.setDialogRuleset(selected);
    } else {
      props.setDialogRuleset(null);
    }
  }

  const cleansedLoyalty = (loyalty) => {
    const newLoyalty = { ...loyalty };
    Object.keys(newLoyalty).forEach(k => {
      if (newLoyalty[k] === '')
        newLoyalty[k] = null;
    });
    return newLoyalty;
  }

  const handleChange = (event) => {
    const boolFields = ['allow_recommendation', 'is_active'];
    const [name, value, isChecked] = [event.target.name, event.target.value, event.target.checked]

    let update = {};
    if (boolFields.includes(name)) {
      update[name] = isChecked;
    } else {
      update[name] = value;
    }
    setLoyalty({ ...loyalty, ...update });
  };

  // Material pickers don't pass typical event fields
  const handleDateChange = (event, fieldName) => {
    let e = event ? { ...event } : {};
    e.target = {};
    e.target.name = fieldName;
    e.target.value = event;
    handleChange(e);
  }

  const tokenTypesFromPartner = () => {
    const selectedPartner = propsPartnerCompanies.filter(p => {
      if (p?.related_company) {
        return p.related_company?.id === loyalty?.partner_co_id ? true : false;
      }
      return false;
    });

    return Array.isArray(selectedPartner) ? selectedPartner[0]?.related_company?.point_token_types : [];
  }

  const submitHandler = (event, mode) => {
    event.preventDefault();

    if (mode === 'edit') {
      const cleanLoyalty = cleansedLoyalty(loyalty)
      updateLoyalty({ variables: { ...cleanLoyalty, id: propsLoyalty.id } })
      console.log({ cleanLoyalty })
    } else if (mode === 'add') {
      const companyIds = propsPointTokenTypes.filter(p =>
        (p.id === loyalty.db_counter_point_type_id || p.id === loyalty.crypto_point_type_id)
      );

      const cleanLoyalty = cleansedLoyalty(loyalty);
      console.log({
        ...cleanLoyalty,
        company_id:
          companyIds.length > 0
            ? companyIds[0].company_id
            : props.currentCompany.id
      });
      createLoyalty({
        variables: {
          ...cleanLoyalty,
          company_id: companyIds.length > 0 ? companyIds[0].company_id : props.currentCompany.id
        }
      })
    }
  };

  return (
    <div>
      <form noValidate autoComplete="off">
        <div className={classesFile.LoyaltyFormWrapper}>
          <div className={classesFile.listField}>
            <div className={classesFile.ItemField}>
              <InputLabel id="demo-simple-select-label">Retail / Loyalty Program Name</InputLabel>
              <Input
                className={classes.root}
                value={loyalty.program_name}
                placeholder="Name"
                name="program_name"
                onChange={handleChange}
                inputProps={{ 'aria-label': 'description' }}
              />
            </div>

            <div className={classesFile.ItemField}>
              <InputLabel id="demo-loyalty-startdate-label">Start date:</InputLabel>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  keyboardIcon={<Picker />}
                  disableToolbar
                  format="MM/dd/yyyy"
                  margin="normal"
                  id="date-loyalty-startdate-picker"
                  name="program_begin_at"
                  value={loyalty.program_begin_at}
                  autoOk={true}
                  onChange={(e) => handleDateChange(e, 'program_begin_at')}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                    display: 'flex'
                  }}
                  inputProps={{
                    style: {
                      color: '#6B799D',
                      borderColor: '#D7DCE8',
                    },
                  }}
                  className={classes.picker}
                />
              </MuiPickersUtilsProvider>
            </div>

            <div className={classesFile.ItemField}>
              <InputLabel id="demo-simple-select-label">Loyalty Point Type</InputLabel>
              <FormControl className={classes.root}>
                <Select
                  IconComponent={SelectIconArrow}
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  name="db_counter_point_type_id"
                  value={loyalty.db_counter_point_type_id || ''}
                  onChange={handleChange}
                  className={classes.select}
                >
                  <MenuItem value={''}>None</MenuItem>
                  {
                    propsPointTokenTypes.filter(p => !p.is_crypto).map((value, index) => {
                      return <MenuItem key={index} value={value.id}>{value.name}</MenuItem>
                    })
                  }
                </Select>
              </FormControl>
            </div>

            <div className={classesFile.ItemField}>
              <InputLabel id="crypto_point_type_id-select-label">Crypto Point Type 1</InputLabel>
              <FormControl className={classes.root}>
                <Select
                  IconComponent={SelectIconArrow}
                  labelId="crypto_point_type_id-select-label"
                  id="crypto_point_type_id-select"
                  name="crypto_point_type_id"
                  value={loyalty.crypto_point_type_id || ''}
                  onChange={handleChange}
                  className={classes.select}
                >
                  <MenuItem value={''}>None</MenuItem>
                  {
                    propsPointTokenTypes.filter(p => p.is_crypto).map((value, index) => {
                      return <MenuItem key={index} value={value.id}>{value.name}</MenuItem>
                    })
                  }
                </Select>
              </FormControl>
            </div>
            <div className={classesFile.ItemField}>
              <InputLabel id="partner_company-select-label">Partner Company</InputLabel>
              <FormControl className={classes.root}>
                <Select
                  IconComponent={SelectIconArrow}
                  labelId="partner_company-select-label"
                  id="partner_company-select"
                  name="partner_co_id"
                  value={loyalty.partner_co_id || ''}
                  onChange={handleChange}
                  className={classes.select}
                >
                  <MenuItem value={''}>None</MenuItem>
                  {
                    propsPartnerCompanies.map((value, index) => {
                      if (value.related_company?.id)
                        return <MenuItem key={index} value={value.related_company?.id}>{value.related_company?.name}</MenuItem>
                    })
                  }
                </Select>
              </FormControl>
            </div>
            <div className={classesFile.ItemField}>
              <InputLabel id="demo-simple-select-label">Partner Loyalty Point Type</InputLabel>
              <FormControl className={classes.root}>
                <Select
                  IconComponent={SelectIconArrow}
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  name="partner_counter_point_type_id"
                  value={loyalty.partner_counter_point_type_id || ''}
                  onChange={handleChange}
                  className={classes.select}
                >
                  <MenuItem value={''}>None</MenuItem>
                  {
                    tokenTypesFromPartner()?.map((value, index) => {
                      return <MenuItem key={index} value={value.id}>{value.name}</MenuItem>
                    })
                  }
                </Select>
              </FormControl>
            </div>
            <div className={classesFile.ItemField}>
              <FormControlLabel
                control={<StyledCheckbox
                  icon={<UnCheckedBox />}
                  checkedIcon={<WhiteCheck />}
                  checked={loyalty.allow_recommendation}
                  color='primary'
                  name='allow_recommendation'
                  onChange={handleChange}
                />}
                label="Allow Recommendations"
                labelPlacement="end"
              />
            </div>
          </div>

          <div className={classesFile.listField}>

            <div className={classesFile.ItemField}>
              <InputLabel id="demo-loyalty-ruleset-select-label">Rule Set</InputLabel>
              <FormControl className={classes.root} style={{ width: '80%' }}>
                <Select
                  IconComponent={SelectIconArrow}
                  labelId="demo-loyalty-ruleset-select-label"
                  id="demo-loyalty-ruleset-select"
                  name="ruleset_id"
                  value={loyalty.ruleset_id || ''}
                  onChange={e => {
                    handleChange(e);
                  }}
                  className={classes.select}
                >
                  <MenuItem value={''}>None</MenuItem>
                  {
                    propsRulesets.map((value, index) => {
                      return <MenuItem key={index} value={value.id}>{value.ruleset_name}</MenuItem>
                    })
                  }
                </Select>
              </FormControl>
              {
                loyalty?.ruleset_id ?
                  <InfoIcon
                    className={classes.ruleset_icon}
                    color="primary"
                    onClick={showRulesetDialog}
                  />
                  : ''
              }
            </div>

            <div className={classesFile.ItemField}>
              <InputLabel id="demo-loyalty-enddate-label">End date:</InputLabel>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  keyboardIcon={<Picker />}
                  disableToolbar
                  format="MM/dd/yyyy"
                  margin="normal"
                  id="date-loyalty-enddate-picker"
                  name="program_end_at"
                  value={loyalty.program_end_at}
                  autoOk={true}
                  onChange={(e) => handleDateChange(e, 'program_end_at')}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                    display: 'flex'
                  }}
                  inputProps={{
                    style: {
                      color: '#6B799D',
                      borderColor: '#D7DCE8',
                    },
                  }}
                  className={classes.picker}
                />
              </MuiPickersUtilsProvider>
            </div>

            <div className={classesFile.ItemField}>
              <InputLabel id="premium_counter_point_type_id-label">Premium Loyalty Point Type</InputLabel>
              <FormControl className={classes.root}>
                <Select
                  IconComponent={SelectIconArrow}
                  labelId="premium_counter_point_type_id-label"
                  id="premium_counter_point_type_id_id"
                  name="premium_counter_point_type_id"
                  value={loyalty.premium_counter_point_type_id || ''}
                  onChange={handleChange}
                  className={classes.select}
                >
                  <MenuItem value={''}>None</MenuItem>
                  {
                    propsPointTokenTypes.map((value, index) => {
                      return <MenuItem key={index} value={value.id}>{value.name}</MenuItem>
                    })
                  }
                </Select>
              </FormControl>
            </div>

            <div className={classesFile.ItemField}>
              <InputLabel id="crypto_point_type_2_id-select-label">Crypto Point Type 2</InputLabel>
              <FormControl className={classes.root}>
                <Select
                  IconComponent={SelectIconArrow}
                  labelId="crypto_point_type_2_id-select-label"
                  id="crypto_point_type_2_id-select"
                  name="crypto_point_type_2_id"
                  value={loyalty.crypto_point_type_2_id || ''}
                  onChange={handleChange}
                  className={classes.select}
                >
                  <MenuItem value={''}>None</MenuItem>
                  {
                    propsPointTokenTypes.filter(p => p.is_crypto).map((value, index) => {
                      return <MenuItem key={index} value={value.id}>{value.name}</MenuItem>
                    })
                  }
                </Select>
              </FormControl>
            </div>
            <div className={classesFile.ItemField} style={{height: '34px'}}>
            </div>
            <div className={classesFile.ItemField}>
              <InputLabel id="demo-simple-select-label">Partner Crypto Point Type</InputLabel>
              <FormControl className={classes.root}>
                <Select
                  IconComponent={SelectIconArrow}
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  name="partner_point_token_type_id"
                  value={loyalty.partner_point_token_type_id || ''}
                  onChange={handleChange}
                  className={classes.select}
                >
                  <MenuItem value={''}>None</MenuItem>
                  {
                    tokenTypesFromPartner()?.map((value, index) => {
                      return <MenuItem key={index} value={value.id}>{value.name}</MenuItem>
                    })
                  }
                </Select>
              </FormControl>
            </div>

          </div>
        </div>
        <div className={classesFile.ItemField}>
          <StyledFormControlLabel
            control={
              <IOSSwitch
                checked={loyalty.is_active}
                onChange={handleChange}
                name="is_active"
              />
            }
            labelPlacement="end"
            label={loyalty.is_active ? 'Active' : 'Inactive'}
          />
        </div>
        <div className={classesFile.ButtonWrapper}>
          <button className={classesFile.ButtonSave} onClick={e => {
            submitHandler(e, props.mode);
            props.toggleMode();
          }
          }>
            Save
          </button>
          <div className={classesFile.ButtonCancel} onClick={(obj) => {
            props.toggleMode();
            props.onToggle(obj)
          }}>
            Cancel
          </div>
        </div>
      </form>
    </div>
  )

};

export default LoyaltyInput;
