import React from 'react';

const OnRecommend = (props) => {
  return (
    <svg width='28' height='28' viewBox='0 0 28 28' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <rect x='1' y='1' width='26' height='26' rx='13' fill='white' stroke='#D7DCE8' />
      <rect x='3' y='3' width='22' height='22' rx='11' fill='#31BD00' />
      <path
        d='M13.6213 16.7102C13.2586 17.0729 12.6701 17.0729 12.3075 16.7102L10.272 14.6747C9.90932 14.3121 9.90932 13.7236 10.272 13.3611C10.6346 12.9984 11.2231 12.9984 11.5858 13.3611L12.7985 14.5737C12.8901 14.6651 13.0387 14.6651 13.1304 14.5737L16.4142 11.2898C16.7767 10.9271 17.3653 10.9271 17.728 11.2898C17.9021 11.464 18 11.7003 18 11.9466C18 12.1929 17.9021 12.4293 17.728 12.6034L13.6213 16.7102Z'
        fill='white' />
    </svg>
  );
};

export default OnRecommend;
