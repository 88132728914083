import React, { Component } from 'react';
import classes from './Toolbar.module.css';
import {
  Box, Button, Tooltip, Fab, makeStyles, Grid
} from '@material-ui/core';

import AddIcon from '@material-ui/icons/Add';

const Toolbar = (props) => {

  const title = (props) => {
      if (props.mode === 'edit') {
          return 'Edit Product or Reward';
      } else if (props.mode === 'add') {
          return 'Add Product or Reward';
      } else {
          return 'Products & Rewards';
      }
  }


  return (
    <div className={classes.Container}>
      <Box
        display='flex'
        justifyContent='space-between'
      >
        <div className={classes.Export}>
          {title(props)}
        </div>
        <Tooltip
          title='Add new Product'
          aria-label='add'
          className={classes.Toolbar}
          onClick={(e) => {
              if (props.mode !== 'add') {
                props.toggleMode('add');
                props.onToggle(e);
              }
            }
          }
        >
          <Fab color='primary' className={classes.IconToolbar}>
            <AddIcon />
          </Fab>
        </Tooltip>
      </Box>
    </div>

  );
};

export default Toolbar;
