import DashboardIcon from 'src/icons/Dashboard';
import ProgramsCampaignsIcon from 'src/icons/ProgramsCampaigns';
import UserCompaniesIcon from 'src/icons/UserCompanies';
import ProductsRewardsIcon from 'src/icons/ProductsRewards';
import ContentIcon from 'src/icons/Content';
import CommunicationIcon from 'src/icons/Communication';
import ReportsIcon from 'src/icons/Reports';
import HistoryTransactionsIcon from 'src/icons/HistoryTransactions';
import PaymentFinancialIcon from 'src/icons/PaymentFinancial';
import ApiDocumentationIcon from 'src/icons/ApiDocumentation';
import Ruleset from 'src/icons/Ruleset';

const routes = [
  {
    href: '/dashboard',
    icon: DashboardIcon,
    title: 'Dashboard',
    role: ['admin']
  },
  {
    icon: ProgramsCampaignsIcon,
    title: 'Programs & Campaigns',
    children: [
      {
        href: '/programs-and-campaigns/standard-loyalty',
        title: 'Retail & Loyalty',
        role: ['admin']
      },
      {
        href: '/programs-and-campaigns/commissions',
        title: 'Commissions',
        role: ['admin']
      },
      {
        href: '/programs-and-campaigns/promotions',
        title: 'Promotions',
        role: ['admin']
      },
      {
        href: '/programs-and-campaigns/market-research',
        title: 'Market Research',
        role: ['admin']
      },
      {
        href: '/programs-and-campaigns/employee-recognition',
        title: 'Employee Recognition',
        role: ['admin']
      }
    ]
  },
  {
    icon: UserCompaniesIcon,
    title: 'Users & Companies',
    children: [
      {
        href: '/users-and-companies/users',
        title: 'Users',
        role: ['admin']
      },
      {
        href: '/users-and-companies/companies',
        title: 'Companies',
        role: ['admin']
      }
    ]
  },
  {
    href: '/products-and-rewards',
    icon: ProductsRewardsIcon,
    title: 'Products & Rewards',
    role: ['admin']
  },
  {
    href: '/ruleset',
    icon: Ruleset,
    title: 'Rulesets',
    role: ['admin']
  },
  {
    href: '/coalition-marketplace',
    icon: ContentIcon,
    title: 'Coalition Marketplace',
    role: ['admin']
  },
  {
    icon: ReportsIcon,
    title: 'Reports',
    children: [
      {
        href: '/reports/usage-reporting',
        title: 'Usage Reporting',
        role: ['admin']
      },
      {
        href: '/reports/tax-reporting',
        title: 'Tax Reporting',
        role: ['admin']
      }
    ]
  },
  {
    href: '/history-and-transactions',
    icon: HistoryTransactionsIcon,
    title: 'History & Transactions',
    role: ['admin']
  },
  {
    href: '/payment-and-financial',
    icon: PaymentFinancialIcon,
    title: 'Payment & Financial',
    role: ['admin']
  },
  {
    href: '/api-and-documentation',
    icon: ApiDocumentationIcon,
    title: 'API & Documentation',
    role: ['admin']
  }
];

export default routes;
