import { useQuery } from '@apollo/client';
import { Box, Container, makeStyles } from '@material-ui/core';
import gql from 'graphql-tag';
import React, { useState } from 'react';
import Page from 'src/components/Page';
import Loading from 'src/components/ui/Loading/Loading';
import RuleSetInput from './ruleset-input/RuleSetInput';
import RuleSetList from './ruleset-list/RuleSetList';
import Toolbar from './toolbar/Toolbar';


export const GET_All_RULESET = gql`
  query getListRuleset {
    rulesets (order_by: {ruleset_name: asc}) {
      id
      company_id
      company {
          id
          name
      }
      partner_company_id
      purchase_includes_company_points_grant
      redeem_using_basic_company_points
      redeem_using_crypto_loyalty_points
      redeem_using_partner_points
      redeem_using_premium_company_points
      reward_by_percentage_reduction
      reward_by_price_reduction
      reward_includes_bonus_product
      reward_includes_cash_back
      reward_includes_company_points_grant
      reward_includes_partner_points_grant
      ruleset_name
      top_up_points_with_cash_payment
      ruleset_begin_at
      ruleset_end_at
      is_active
      reward_with_a_soli_points_carbon_offset
    }
    related_companies {
        related_company {
            id
            name
            company_type {
                co_type
            }
        }
    }
  }`;

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const RuleSetView = (props) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [mode, setMode] = useState(null);

  const handleClick = () => {
    setOpen((prev) => !prev);
  };

  const toggleMode = (type) => {
    if (type === 'edit') {
      setMode('edit');
    } else if (type === 'add') {
      setMode('add')
    } else {
      setMode(null);
    }
  };

  return (
    <Page
      className={classes.root}
      title='Ruleset | Procision'
    >
      <Container maxWidth={false}>
        <Toolbar mode={mode} toggleMode={toggleMode} onToggle={handleClick} />
        {open ? (
          <RuleSetInput mode={mode} toggleMode={toggleMode} onToggle={handleClick} currentCompany={props.currentCompany} rulesetName={props.rulesets?.ruleset_name} relatedCompanies={props?.relatedcompanies} />
        ) : (
          <Box mt={3}>
            <RuleSetList mode={mode} toggleMode={toggleMode} rulesets={props.rulesets} currentCompany={props.currentCompany} relatedCompanies={props?.relatedcompanies} />
          </Box>)}
      </Container>
    </Page>
  );
};
const GetRules = (props) => {
  const classes = useStyles();

  const { loading, error, data } = useQuery(GET_All_RULESET);
  console.log({ loading, error, data });

  if (loading) {
    return <Loading />;
  }

  if (error) {
    return <div className={classes.error}>Error!<br /> Something went wrong</div>;
  }

  return <RuleSetView
    rulesets={data.rulesets}
    currentCompany={props.currentCompany}
    relatedcompanies={data.related_companies}
  />;
};

export default GetRules;
