import React from 'react';

const HistoryTransactions = (props) => {
  return (
    <svg {...props} width="16" height="16" viewBox="0 0 16 16" fill="#B9C2D9" xmlns="http://www.w3.org/2000/svg">
      <path d="M8 0C7.74113 0 7.53125 0.209875 7.53125 0.46875C7.53125 0.727625 7.74113 0.9375 8 0.9375C11.8943 0.9375 15.0625 4.10572 15.0625 8C15.0625 11.8943 11.8943 15.0625 8 15.0625C4.10572 15.0625 0.9375 11.8943 0.9375 8C0.9375 6.11384 1.73031 4.29275 3.09428 2.96762V3.78125C3.09428 4.04012 3.30416 4.25 3.56303 4.25C3.82191 4.25 4.03125 4.04012 4.03125 3.78125V1.90625C4.03125 1.64737 3.82191 1.4375 3.56303 1.4375H1.67188C1.413 1.4375 1.20312 1.64737 1.20312 1.90625C1.20312 2.16512 1.413 2.37188 1.67188 2.37188H2.35975C0.844 3.88506 0 5.90872 0 8C0 12.3981 3.60028 16 8 16C12.3981 16 16 12.3997 16 8C16 3.60187 12.3997 0 8 0Z" />
      <path d="M8 2.84375C5.15703 2.84375 2.84375 5.15778 2.84375 8C2.84375 10.8432 5.15797 13.1562 8 13.1562C10.843 13.1562 13.1562 10.8422 13.1562 8C13.1562 5.15684 10.842 2.84375 8 2.84375ZM11.2947 10.6318L10.9831 10.3202C10.8001 10.1371 10.5033 10.1371 10.3202 10.3202C10.1372 10.5033 10.1372 10.8 10.3202 10.9831L10.6318 11.2947C10.0248 11.7805 9.28125 12.1023 8.46875 12.1926V11.75C8.46875 11.4911 8.25887 11.2812 8 11.2812C7.74113 11.2812 7.53125 11.4911 7.53125 11.75V12.1926C6.71875 12.1023 5.97525 11.7805 5.36825 11.2947L5.67981 10.9831C5.86287 10.8 5.86287 10.5033 5.67981 10.3202C5.49678 10.1371 5.19997 10.1371 5.01691 10.3202L4.70534 10.6318C4.2195 10.0248 3.89766 9.28125 3.80744 8.46875H4.25C4.50888 8.46875 4.71875 8.25887 4.71875 8C4.71875 7.74113 4.50888 7.53125 4.25 7.53125H3.80744C3.89769 6.71875 4.2195 5.97525 4.70534 5.36825L5.01691 5.67981C5.19994 5.86287 5.49678 5.86287 5.67981 5.67981C5.86287 5.49675 5.86287 5.19997 5.67981 5.01691L5.36825 4.70534C5.97525 4.2195 6.71875 3.89769 7.53125 3.80744V4.25C7.53125 4.50888 7.74113 4.71875 8 4.71875C8.25887 4.71875 8.46875 4.50888 8.46875 4.25V3.80744C9.28125 3.89769 10.0248 4.2195 10.6318 4.70534L10.3202 5.01691C10.1371 5.19997 10.1371 5.49675 10.3202 5.67981C10.5032 5.86287 10.8001 5.86287 10.9831 5.67981L11.2946 5.36825C11.7805 5.97525 12.1023 6.71875 12.1926 7.53125H11.75C11.4911 7.53125 11.2812 7.74113 11.2812 8C11.2812 8.25887 11.4911 8.46562 11.75 8.46562H12.1926C12.1023 9.27812 11.7805 10.0248 11.2947 10.6318Z" />
      <path d="M9.26898 8.60604L8.46875 7.80585V6.125C8.46875 5.86613 8.25888 5.65625 8 5.65625C7.74113 5.65625 7.53125 5.86613 7.53125 6.125V8.00001C7.53125 8.12432 7.58066 8.24354 7.66856 8.33144L8.60607 9.26895C8.7891 9.45201 9.08594 9.45201 9.26898 9.26895C9.45204 9.08588 9.45204 8.7891 9.26898 8.60604Z" />
    </svg>
  );
};

export default HistoryTransactions;
