import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core';
import TopBar from './tolbar/TopBar';
import { ThemeProvider } from '@material-ui/core';
import GlobalStyles from 'src/components/GlobalStyles';
import theme from 'src/theme';
import PaymentOptions from './PaymentOptions';
import Cart from './Cart'
import { useLocation } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import Loading from '../../components/ui/Loading/Loading';
import Page from '../../components/Page';
import gql from 'graphql-tag';
import { getNftOfAddress } from 'src/utils/handleApi';
import { CLIMATE_NFT_COLLECTION_ADDRESS } from 'src/utils/constant';


const useStyles = makeStyles((theme) => ({
    root: {
      backgroundColor: theme.palette.background.dark,
      display: 'flex',
      height: '100%',
      overflow: 'hidden',
      width: '100%'
    },
    wrapper: {
      display: 'flex',
      flex: '1 1 auto',
      overflow: 'hidden',
      paddingTop: 90,
      paddingLeft: 290
    },
    contentContainer: {
      display: 'flex',
      flex: '1 1 auto',
      overflow: 'hidden'
    },
    content: {
      flex: '1 1 auto',
      height: '100%',
      overflow: 'auto',
      paddingLeft: 0
    },
  }));


export const GET_ALL_PRODUCTS = gql`
  query getProducts {
    products(
        order_by: {product_brand: asc, fiat_list_price: asc}
        where: {company: {id: {_eq: "d8b97f44-e13a-4109-b498-2a73bdac7b0e"}}}
    ) {
        id
        product_description
        product_code
        product_brand
        price_point_token_type {
            id
            name
            is_counter
            is_crypto
        }
        fiat_type
        coin_type
        coin_amount
        list_price_points_tokens
        list_points_token_type_id
        fiat_list_price
        is_active
        discount_allowed
        company {
            id
            name
        }
        available_start
        available_end
        SKU_end
        SKU_begin
    }
    companies {
        id
        name
    }
    point_token_types {
        id
        name
        is_counter
        is_crypto
        company {
            id
            name
        }
    }
    transaction_types {
        id
        name
    }
    users(
        where: {id: {_eq: "5d51fc5b-9539-4950-9616-c58cf53337be"}}
    ) {
        id
        display_name
        customer_points {
            id
            balance
            points_token_types_id
            point_token_type {
                id
                name
            }
        }
    }
}`;



const DApp = (props) => {
  
  const classes = useStyles();
  const location=useLocation();
  const [climateNft, setClimateNft] = useState([]);
  const celoAccount = localStorage.getItem('celo_account');

  useEffect(()=> {
    async function fetchData() {
      const res = await getNftOfAddress(celoAccount);
      let nft = [];
      if (res && res.data) {
        res.data.forEach(e => {
          if (e.contractAddress.toLowerCase() === CLIMATE_NFT_COLLECTION_ADDRESS.toLowerCase()) {
            nft = nft.concat(e.metadata);
          }
        });
      }
      setClimateNft(nft);
    }
    fetchData();
  }, []);

    const selectTransactionTypeId = (mode) => {
        const transactionTypes = props?.data?.transaction_types;

        const modeTransactionTypeMap = {
            'fiat': 'fiat_purchase',
            'points': 'redeem_simple',
            'coins': 'transfer_customer',
            'cUSD': 'transfer_customer',
        }

        const typeName = modeTransactionTypeMap[mode];
        const filtered = transactionTypes?.filter(type => type?.name === typeName);

        return filtered ? filtered[0]?.id : null;
    }

    const defaultTransactionTypeId = selectTransactionTypeId('fiat');

  const [state, setState] = useState({
      mode: 'fiat',
      transaction_type_id: defaultTransactionTypeId
  });

  const propsProducts = props?.products ? props.products : [];
  let propsProductsQuantityMap = {};
  propsProducts.forEach(product => {
    propsProductsQuantityMap[product?.id] = 1;
  });

  const [productQuantityMap, setProductQuantityMap] = useState(propsProductsQuantityMap);

  const toggleMode = (newMode) => {
      const transactionTypeId = selectTransactionTypeId(newMode);
      setState({
          mode: newMode,
          transaction_type_id: transactionTypeId
      });
  }

  const updateProductQuantity = (productId, quantity) => {
    setProductQuantityMap({
        ...productQuantityMap,
        [productId]: quantity
    });
  }

  const formatNumber = (number) => {
    const nfObject = new Intl.NumberFormat('en-US');
    return nfObject.format(number);
  }

  const disconnect = async () => {
    setClimateNft([])
  };
  const connect = async () => {
    const res = await getNftOfAddress(localStorage.getItem('react-celo/last-used-address'));
    let nft = [];
      if (res.data) {
        res.data.forEach(e => {
          nft = nft.concat(e.metadata);
        });
      }
    setClimateNft(nft);
  }
  // if(location.pathname==='/company/storco')
  // {
  //   document.body.style.overflow='hidden'
  // }

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles />
      <Page title="DApp" style={{width: '100%'}}>
      <div className={classes.root}>
        <TopBar
            currentCompany={props.currentCompany}
            currentUser={props.currentUser}
        />
        <Cart
            currentCompany={props.currentCompany}
            currentUser={props.currentUser}
            mode={state.mode} toggleMode={toggleMode} products={climateNft} formatNumber={formatNumber}/>
        <PaymentOptions
            currentCompany={props.currentCompany}
            currentUser={props.currentUser}
            mode={state.mode}
            products={props.products}
            toggleMode={toggleMode}
            productQuantityMap={productQuantityMap}
            user={props?.users ? props.users[0] : []}
            transactionTypeId={state.transaction_type_id}
            refetch={props.refetch}
            formatNumber={formatNumber}
            disconnect={disconnect}
            connect={connect}
        />
      </div>
      </Page>
    </ThemeProvider>
  );
};

const GetDApp = (props) => {
    const classes = useStyles();

    const { loading, error, data, refetch } = useQuery(GET_ALL_PRODUCTS);

    if (loading) {
        return null;
    }

    if (error) {
      return <div className={ classes.error }>Error!<br />{error.message}</div>;
    }

    if (data) {
      return <DApp data={data} products={data.products} users={data.users} refetch={refetch} {...props}/>;
    }
}

export default GetDApp;
