import React  from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { AppBar, Box, Toolbar, makeStyles, TextField } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Search from 'src/icons/Search';
import DataPicker from 'src/components/ui/DataPicker/DatePicker';
import DropdownMenu from 'src/components/DropdownMenu';
import Avatar from '../../components/ui/Avatar';
import Button from '@material-ui/core/Button';
import NotificationHeader from 'src/components/ui/NotificationHeader/NotificationHeader'

const useStyles = makeStyles(() => ({
  root: {
    left: '312px',
    width: 'calc(100% - 335px)',
    paddingTop: 15,
    zIndex:1,
    backgroundColor: '#fff'
  },
  avatar: {
    width: 60,
    height: 60
  },
  search: {
    width: '342px',
    '& $icon': {
      paddingLeft: '32px',
    },
    '& $input': {
      marginTop: '3px',
      paddingLeft: '32px',
      paddingBottom: '16px',
      color: '#010A21',
    },
    '& $label': {
      paddingLeft: '32px',
      fontWeight: 'normal',
      fontSize: '16px',
      lineHeight: '20px',
      color: '#010A21',
      top: '-16px'
    },
  },
  container: {
    width: '342px',
    marginRight: '32px',
    '& label.Mui-focused': {
      color: '#010A21',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: '#010A21',
    },
    '& .MuiInput-underline:before': {
      borderBottomColor: '#010A21',
    },
    '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
      borderBottom: '2px solid #010A21',
    }
  },
  icon: {
    position: 'absolute',
    top: '20px'
  },
  inputPlaceholder: {
    '&::placeholder': {
      color: "#010A21 !important",
      opacity: 1
    }
  },
}));

const TopBar = ({
  className,
  onMobileNavOpen,
  isSuperAdmin,
  isAdmin,
  currentUser,
  currentCompany,
  ...rest
}) => {
  const classes = useStyles();

  return (
    <AppBar
      className={clsx(classes.root, className)}
      elevation={0}
      {...rest}
    >
      <Toolbar disableGutters>
        <div className={classes.container}>
          <Grid container alignItems="flex-end">
            <Grid item className={classes.icon}>
              <Search />
            </Grid>
            <Grid item>
              <TextField
                color='secondary'
                className={classes.search}
                id="input-with-icon-grid"
                placeholder="What are you looking for?"
                InputProps={{
                  classes: { input: classes.inputPlaceholder}
                }}
                />
            </Grid>
          </Grid>
        </div>
        <DataPicker />
        <Box flexGrow={1} />

        <NotificationHeader />

        <DropdownMenu
          currentUser={currentUser}
          isAdmin={isAdmin}
          isSuperAdmin={isSuperAdmin}
        />
      </Toolbar>
    </AppBar>
  );
};

TopBar.propTypes = {
  className: PropTypes.string,
  onMobileNavOpen: PropTypes.func
};

export default TopBar;

