import React from 'react';

const Ruleset = (props) => {
  return (
    <svg {...props} xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512' fill="#B9C2D9" width="16" height="16" >
      <path d='M131.187,19.652c-32.212,0-58.423,25.972-58.423,57.775l-2.124,299.552l31.867,0.228l2.125-299.663
		c0-14.351,11.913-26.025,26.556-26.025h320.266V19.652H131.187z' />
      <path d='M451.452,19.652c-33.386,0-60.548,27.161-60.548,60.548v336.199c0,24.304-19.779,44.083-44.083,44.083
		s-44.083-19.779-44.083-44.083v-38.772c0-8.801-7.133-15.934-15.934-15.934H15.934C7.133,361.693,0,368.826,0,377.627v38.772
		c0,41.879,34.071,75.95,75.95,75.95v-31.867c-24.309,0-44.083-19.779-44.083-44.083V393.56h239.004v22.838
		c0,41.879,34.071,75.95,75.95,75.95c41.879,0,75.95-34.071,75.95-75.95V167.303h73.295c8.801,0,15.934-7.133,15.934-15.934v-71.17
		C512,46.813,484.839,19.652,451.452,19.652z M480.133,135.436h-57.361V80.199c0-15.817,12.869-28.68,28.68-28.68
		s28.68,12.864,28.68,28.68V135.436z' />

      <path d='M236.88,108.349h-82.324c-8.801,0-15.934,7.133-15.934,15.934s7.133,15.934,15.934,15.934h82.324
		c8.801,0,15.934-7.133,15.934-15.934S245.68,108.349,236.88,108.349z' />
      <path d='M332.481,172.083H154.556c-8.801,0-15.934,7.133-15.934,15.934s7.133,15.934,15.934,15.934h177.925
		c8.801,0,15.934-7.133,15.934-15.934S341.282,172.083,332.481,172.083z' />
      <path d='M332.481,235.817H154.556c-8.801,0-15.934,7.133-15.934,15.934c0,8.801,7.133,15.934,15.934,15.934h177.925
		c8.801,0,15.934-7.128,15.934-15.934C348.415,242.95,341.282,235.817,332.481,235.817z' />
      <path d='M332.481,299.552H154.556c-8.801,0-15.934,7.133-15.934,15.934s7.133,15.934,15.934,15.934h177.925
		c8.801,0,15.934-7.133,15.934-15.934S341.282,299.552,332.481,299.552z' />
    </svg>
  );
};

export default Ruleset;
