import React, { Component } from 'react';
import classes from './Toolbar.module.css';
import {
  Box,
} from '@material-ui/core';

const Toolbar = () => {

  const title = () => {
    return 'Coalition Marketplace'
  }

  return (
    <div>
      <div className={classes.Container}>
        <Box
          display='flex'
          justifyContent='space-between'
        >
          <div className={classes.Export}>
            {title()}
          </div>

        </Box>

      </div>
      <div style={{marginTop: '10px'}} className={classes.Export}>
        Partner Offers
      </div>
    </div>
  );
};

export default Toolbar;
