import React, { useState,useEffect } from 'react';
import { useHistory } from "react-router-dom";
import { ethers } from "ethers";
import { Box, Checkbox, FormControlLabel } from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import UnCheckedBox from '../../icons/user/UnCheckedBox';
import CheckedBox from '../../icons/user/CheckedBox';
import Snackbar from '@material-ui/core/Snackbar';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import MuiAlert from '@material-ui/lab/Alert';
import {AlertTitle} from '@material-ui/lab';
import CircularProgress from '@material-ui/core/CircularProgress';
import MasterCardLogo from '../../icons/MasterCardLogo';
import VisaLogo from '../../icons/user/VisaLogo';
import AmericanExpressLogo from '../../icons/user/AmericanExpressLogo';
import WhiteCheck from '../../icons/WhiteCheck';
import Button from '@material-ui/core/Button';
import gql from 'graphql-tag';
import { useMutation } from '@apollo/client';
import { sendOrderNotification, getPublicKey, handleApi,pointsTransaction,updateBalancePoint,soliCarbonOffset, allocateCarbonOffset, getRewarData } from 'src/utils/handleApi';
import { cUSD, paymentToken, submitPayment } from 'src/utils/ether';
import { TYPE_TOKEN, COMPANY_ID, VALUE_DEFAULT_STORCO_COINS, VALUE_DEFAULT_CARBON_OFFSET, CUSTOMER_ID, PROVIDER_OPTION, REWARD } from 'src/utils/constant';
import { web3Modal } from'src/utils/web3Modal'
import { responseCarbonOffset, switchNetwork, switchNetworks } from 'src/utils/common';
import WrappedCeloWalletConnect from 'src/components/CeloWalletConnect';

const useStyle = makeStyles(() => ({
  hr: {
    border: 'none',
    height: 1,
    background: '#DCDCDC',
    margin: '20px 0px'
  },
  compact_hr: {
    border: 'none',
    height: 1,
    background: '#DCDCDC',
    margin: '15px 0px'
  },
  styled_label: {
    marginLeft:0,
    marginBottom: 22,
    display: 'flex',
    alignItems: 'center',
    '&:last-child':{
      marginBottom:0,
    },
    '& span div': {
      display: 'flex',
      alignItems: 'center',
      '& div svg': {
        marginRight: 16
      }
    }
  },
  payment_div_text:{
    display: 'flex',
    justifyContent: 'space-between',
  },
  payment_span_2:{
    fontFamily: 'Roboto',
    fontSize: 18,
    fontWeight:'bold',
    '&:first-child': {
      color: '#000000'
    }
  },
  fiat_reward: {
    fontFamily: 'Roboto',
    fontSize: 16,
    textAlight: 'center',
    fontWeight: 'normal',
    fontStyle: 'italic'
  },
  payment_span: {
    fontFamily: 'Roboto',
    fontSize: 18,
    '&:first-child': {
      color: '#000000'
    }},
  total_div:{
    width:'100%',
  },
cancel_btn:{
  padding: '8px 24px',
  marginLeft: '8px',
  border: 'none',
  background: 'none',
  fontFamily: 'Open Sans SemiBold',
  fontSize: 18,
  height:44,
  '&:hover': {
      cursor: 'pointer'
  }
},
  div_btns:{
    display:'flex',
    marginTop:5,
    // alignItems:'center',
    // flexDirection:'column',
    // padding:'0px 32px',
  },
  div_btn_wallet:{
    display:'flex',
    marginTop:5,
  },
  connect_btn:{
    borderRadius:100,
    padding: '10px',
    height:44,
    border: '1px solid #000',
    background: '#4CAF50',
    fontSize: 18,
    width:'60%',
    textTransform:'capitalize',
    marginRight:5,
    marginLeft:5,
    color:'#fff',
  },
  
  disconnect_btn: {
    borderRadius:100,
    padding: '10px',
    height:44,
    border: '1px solid #000',
    background: '#4CAF50',
    fontSize: 18,
    width:'60%',
    textTransform:'capitalize',
    marginRight:5,
    marginLeft:5,
    marginBottom:5,
    color:'#fff',
  },
  payment_options_button: {
    padding: '8px 24px',
    color: '#fff',
    border: 'none',
    background: 'black',
    borderRadius: 4,
    fontFamily: 'Open Sans SemiBold',
    fontSize: 18,
    height:44,
    borderRadius:100,
    marginLeft:5,
    width: '60%',
    '&:hover': {
        cursor: 'pointer'
    }
  },

}));
const StyledButton=withStyles(()=>({
  root:{
    marginLeft:5,
    marginRight:5,
    background:'#000000',
    color:'#fff',
    borderRadius:100,
    width:'100%',
    padding:10,
    fontWeight:'bold',
    fontSize:20,
    height: 44,
    textTransform:'none',
    '&:hover':{
      background:'#000000',
    }
  },
}))(Button);

const StyledProgress=withStyles(()=> ({
    circle:{
        color: '#fff'
    }
}))(CircularProgress)

const StyledSnackbar=withStyles(() => ({
    root: {
        fontFamily: 'Roboto',
        fontSize: 18,
    }
}))(Snackbar)

const StyledMuiAlert=withStyles(() => ({
    root: {
        fontFamily: 'Roboto',
        fontSize: 18,
        backgroundColor: '#fff',
        borderWidth: '2px',
        width: '450px'
    }
}))(MuiAlert)

const StyledAlertTitle=withStyles(() => ({
    root: {
        fontFamily: 'Roboto',
        fontWeight: '400',
        fontSize: 24,
    }
}))(AlertTitle)

const StyledCheckbox = withStyles(() => ({

  checked: {
    background: '#FF5F00',
    color: '#fff',

    '&:first-child': {
      marginLeft: 0,
      borderRadius: '2px',
      padding: 0,
      width: '26px',
      height: '26px',
      '&:hover': {
        background: '#FF5F00'
      }
    }
  },
  colorPrimary: {
    color: '#D7DCE8',
    padding: 0,
    marginRight: '10px'

  },
  label: {
    marginTop: 20

  }

}))(Checkbox);

const MainBox = withStyles(() => ({
  root: {

    padding: '15px 32px 15px 32px',

    background: '#fff',
    display: 'flex',
    flexDirection: 'column'
  }
}))(Box);

const WrapperBox = withStyles(() => ({
  root: {
    borderLeft: '1px solid #DCDCDC',
    marginTop: 80,
    width: '30%',
    display: 'flex',
    flexDirection: 'column',
    background: 'rgba(220, 220, 220, 0.2)'
  }
}))(Box);

const SecondBox = withStyles(() => ({
  root: {
    padding: '15px 32px 15px 32px',
    display: 'flex',
    flexDirection: 'column'
  }
}))(Box);
const TotalBox = withStyles(() => ({
  root: {
    fontFamily: 'Roboto',
    fontWeight: '400',
    fontSize: 20,

  }
}))(Box);
const TotalDivBox = withStyles(() => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between'

  }
}))(Box);

const PriceTotalBox = withStyles(() => ({
  root: {
    fontFamily: 'Roboto',
    fontWeight: '700',
    fontSize: 20,

  }
}))(Box);
const SpanBox = withStyles(() => ({
  root: {
    fontFamily: 'Roboto',
    fontWeight: '500',
    fontSize: 20,
    marginBottom: 15
  }
}))(Box);
const PrimaryBox = withStyles(() => ({
  root: {
    fontFamily: 'Roboto',
    fontWeight: 'normal',
    fontSize: 16,
    color: '#000',
    maxWidth: 200,
    width: '100%'
  }
}))(Box);
const DefaultBox = withStyles(() => ({
  root: {
    fontFamily: 'Roboto',
    fontWeight: 'normal',
    fontSize: 16,
    color: '#010A21',
    whiteSpace: 'nowrap'
  }
}))(Box);
const RowBox = withStyles(() => ({
  root: {
    display: 'flex',


    marginTop: '6px'
  }
}))(Box);


const baseMutation = `
    insert_transactions_reward_redemption(objects: $objects) {
        returning {
            id
            transaction_type_id
            company_id
            customer_id
            is_product_purchase
            is_simple_redemption
            point_token_type_id
            fiat_type
            point_token_amount
            fiat_amount
            xact_datetime_at
        }
    }
`;

const updateBalanceMutation = `
    update_customer_points_by_pk(
        _set: {
            balance: $balance
        }
        pk_columns: {id: $customer_points_id}
    ) {
        id
        customer_id
        balance
        points_token_types_id
        point_token_type {
            id
            name
        }
    }
`;


const UPDATE_BALANCE = gql`
    mutation updateBalance(
        $customer_points_id: uuid!
        $balance: bigint!
    ) {
        ${updateBalanceMutation}
    }
`;

const INSERT_TRANSACTIONS_UPDATE_BALANCE = gql`
    mutation insertTransactionsUpdateBalance(
        $objects: [transactions_reward_redemption_insert_input!]!
        $customer_points_id: uuid!
        $balance: bigint!
    ) {
        ${baseMutation}
        ${updateBalanceMutation}
    }
`;


const PaymentOptions = (props) => {
  const classes = useStyle();
  const history = useHistory();
  const baseStorCoBalance = 350000;
  const defaultValueCarbonOffset = 4;
  const pointTokenTypeId = '4e6bd837-f590-4199-813a-c97dacd53fc3';    // hard code for now
  const carbonOffsetPointId = '74219bde-e7d1-4ca3-be12-56eefc4eb573';

  const propsUser = props?.user ? props.user : null;
  const userDisplayName = propsUser ? propsUser.display_name : '';
  let propsCustomerPoints = propsUser?.customer_points ? propsUser.customer_points : [];
  const balancePoint = propsUser.customer_points[0].balance;
  // const [loadingOverride, setLoadingOverride] = useState(false);
  const [snackBar, setSnackBar] = useState(false);
  const [accountConnect, setAccountConnect] = useState('');
  const [provider, setProvider] = useState();
  const [popupConnectWallet, setPopupConnectWallet] = useState(false);
  const [reward, setReward] = useState({
    rewardSoliCarbonOffset : 0,
  });
  const [isCheckStripe, setIsCheckStripe] = useState(false)
  const cacheValueCarbon = localStorage.getItem('rewardCarbonOffset')
  const [switchLibrary, setSwitchLibrary] = useState()
  const [isConnectWallet, setIsConnectWallet] = useState(false)
  useEffect(() => {
    (async function() {
      const isCheckPayment =  localStorage.getItem('isStorcoSuccess')
      if (isCheckPayment) {
        setIsCheckStripe(true)
        setTimeout(() => {
          localStorage.clear()
        }, 3000)

      }
    })();
  }, []);

  useEffect(() => {
    (async function() {
    const walletAddress = localStorage.getItem('account')
      if (localStorage?.getItem('isWalletConnected') === 'true') {
        setIsConnectWallet(true)
        setAccountConnect(walletAddress)
      }
    })();
  }, []);

  const closePopupStripe = () => {
    setIsCheckStripe(false)
  }

  const formatAddress = (address) => {
    if (address) {
      const add1 = address.substring(0, 4);
      const add2 = address.substring(address.length - 4);
      const finalAddress = `${add1}...${add2}`;
      return finalAddress;
    }
  };

  const connect = async () => {
    try {
      const web3Provider = await web3Modal.connect();
      const library = new ethers.providers.Web3Provider(web3Provider,"any");
      setSwitchLibrary(library)
      const network = await library.getNetwork();
      const web3Accounts = await library.listAccounts();
      const signer = library.getSigner(web3Accounts[0]);
      setProvider(signer);
      setAccountConnect(web3Accounts[0]);
      localStorage.setItem('account', web3Accounts[0])
      localStorage.setItem('isWalletConnected', true)
      setIsConnectWallet(true)

      if(network.chainId !== PROVIDER_OPTION.CHAIN_ID) {
        switchNetwork(switchLibrary)
      }

    } catch (error) {
      console.log(error);
    }

  };

  const disconnect = async () => {
    setAccountConnect('');
    localStorage.setItem('isWalletConnected', false)
    setIsConnectWallet(false)
    localStorage.removeItem('-walletlink:https://www.walletlink.org:Addresses')
    window.location.reload();
  };

  const closePopupConnectWallet = () => {
    setPopupConnectWallet(false)
  }

  const payBycUSD = async (event, txHash) =>{
    // const typeToken = TYPE_TOKEN.CUSD;
    const dataIput = {
      ...cUSDInput(),
      txHash: txHash
    };

    if (props.mode === 'cUSD') {
      const data = await paymentToken(dataIput);
      if (data) {
        // let testerEmail = 'thuong.tran@ekoios.vn';
        let orderEmail = {
          ...data,
          // toEmail: testerEmail,
          toEmail: props.currentUser.account.email,
          subject: "[Procision] Payment succeed!",
          body: purchaseProductList()
        };
        sendOrderNotification(event, orderEmail);
      }

      if (data) {
        openSnackBar();
      }
    }
  }

  const btcButton = <StyledButton onClick={async(e) => {
    e.preventDefault();
    const typeToken = TYPE_TOKEN.BTC;
    const data = coinsInput(typeToken);

    if (props.mode === 'coins') {
      await switchNetworks({
        library:switchLibrary,
        chainId:'0xaa36a7',
        chainName:'Sepolia Test Network',
        rpcUrls:'https://rpc.sepolia.org/',
        blockExplorerUrls:'https://sepolia.etherscan.io/'
      })

      const totalPrice = calculateTotalToken();
      const companyId = props.currentCompany.id;
      const dataSoli = await submitPayment(data, totalPrice, companyId, typeToken, provider);
      if (data.txHash) {
        // let testerEmail = 'thuong.tran@ekoios.vn';
        let orderEmail = {
          ...dataSoli,
          // toEmail: testerEmail,
          toEmail: props.currentUser.account.email,
          subject: "[Procision] Payment succeed!",
          body: purchaseProductList()
        };
        sendOrderNotification(e, orderEmail);
      }
      if (dataSoli) {
        openSnackBar()
      }
      if (reward.rewardSoliCarbonOffset) {
        const soliInput = await solidInput(dataSoli.data)
        const response = await allocateCarbonOffset(soliInput)
        const inputCarbonOffset = await responseCarbonOffset(response.data,COMPANY_ID.STORCO)
        soliCarbonOffset(inputCarbonOffset)
        updateBalancePoint(e,inputBalanceCarbonOffset)
        setTotalCarbonOffset(countTotalCarbon)
        openSnackBar();
      }
    }
  }}>Buy With BTC</StyledButton>;

  const ethButton = <StyledButton onClick={async(e) => {
    e.preventDefault();
    const typeToken = TYPE_TOKEN.ETH;
    const data = coinsInput(typeToken);
    if (props.mode === 'coins') {
      await switchNetworks({
        library:switchLibrary,
        chainId:'0xaa36a7',
        chainName:'Sepolia Test Network',
        rpcUrls:'https://rpc.sepolia.org/',
        blockExplorerUrls:'https://sepolia.etherscan.io/'
      })
      const totalPrice = calculateTotalToken();
      const companyId = props.currentCompany.id;
      const dataSoli = await submitPayment(data, totalPrice, companyId, typeToken, provider);
      if (data.txHash) {
        // let testerEmail = 'thuong.tran@ekoios.vn';
        let orderEmail = {
          ...data,
          // toEmail: testerEmail,
          toEmail: props.currentUser.account.email,
          subject: "[Procision] Payment succeed!",
          body: purchaseProductList()
        };
        sendOrderNotification(e, orderEmail);
      }
      if (dataSoli) {
        openSnackBar()
      }
      if (reward.rewardSoliCarbonOffset) {
        const soliInput = await solidInput(dataSoli.data)
        const response = await allocateCarbonOffset(soliInput)
        const inputCarbonOffset = await responseCarbonOffset(response.data,COMPANY_ID.STORCO)
        soliCarbonOffset(inputCarbonOffset)
        updateBalancePoint(e,inputBalanceCarbonOffset)
        setTotalCarbonOffset(countTotalCarbon)
        openSnackBar();
      }
    }
  }}>Buy with ETH</StyledButton>;

  let button = <button
  className={classes.payment_options_button}
   onClick={async(e) => {
    e.preventDefault();
    // setLoadingOverride(true);
    // const inputs = generateTransactionInputs();
    const data = transactionInputs();
    // let testerEmail = 'thuong.tran@ekoios.vn';
    if (props.mode === 'fiat') {
      const stripeData = stripeInputs()
      getPublicKey(e,COMPANY_ID.STORCO)
      const dataTransaction = await pointsTransaction(e, data)
      let orderEmail = {
        ...data,
        // toEmail: testerEmail,
        toEmail: props.currentUser.account.email,
        subject: "[Procision] Payment succeed!",
        body: purchaseProductList()
      };
      sendOrderNotification(e, orderEmail);
      if (reward.rewardSoliCarbonOffset) {
        const inputTransactionSoli =  await solidInput(dataTransaction.data)
        const response = await allocateCarbonOffset(inputTransactionSoli)
        const inputCarbonOffset = await responseCarbonOffset(response.data,COMPANY_ID.STORCO)
        soliCarbonOffset(inputCarbonOffset)
        updateBalancePoint(e,inputBalanceCarbonOffset)
        setTotalCarbonOffset(countTotalCarbon)
       }
      handleApi(e,stripeData)
      stripeData.transactionIds = dataTransaction.data
      localStorage.setItem('dataStorco',JSON.stringify(stripeData))
      history.push("/checkout-storco")
    }
    if (props.mode === 'points') {
      const dataTransaction = await pointsTransaction(e, data)
      let orderEmail = {
        ...data,
        // toEmail: testerEmail,
        toEmail: props.currentUser.account.email,
        subject: "[Procision] Payment succeed!",
        body: purchaseProductList()
      };
      sendOrderNotification(e, orderEmail);
      if (reward.rewardSoliCarbonOffset) {
       const inputTransactionSoli =  await solidInput(dataTransaction.data)
       const response = await allocateCarbonOffset(inputTransactionSoli)
       const inputCarbonOffset = await responseCarbonOffset(response.data,COMPANY_ID.STORCO)
       soliCarbonOffset(inputCarbonOffset)
       updateBalancePoint(e,inputBalanceCarbonOffset)
       setTotalCarbonOffset(countTotalCarbon)
       }
      const storcoCoinData = await  getRewarData(propsUser.id,REWARD.STORCO_COIN_ID)
      const countTotalPoint = storcoCoinData.data[0]?.balance - calculateTotalPoints()
       const inputBalancePoint = {
        customerId:propsUser.id,
        pointsTokenTypesId:propsUser.customer_points[0].points_token_types_id,
        balance:countTotalPoint,
      }
      updateBalancePoint(e,inputBalancePoint)
      setTotalPoint(countTotalPoint)
      openSnackBar();
    }

    if (props.mode === 'coins' && !accountConnect) {
      setPopupConnectWallet(true)
    }
    // transactAndUpdate({variables: inputs});
  }}>Submit Payment</button>;

  let cancelButton = <button className={classes.cancel_btn}
    onClick={e => {
      e.preventDefault();
      const targetCP = getTargetCustomerPoints();
      updateCustomerPoints({
        variables: {
          balance: VALUE_DEFAULT_STORCO_COINS,
          customer_points_id: targetCP.id
        }
      });

      updateCustomerPoints({
        variables: {
          balance: VALUE_DEFAULT_CARBON_OFFSET,
          customer_points_id:CUSTOMER_ID
        }
      });
      
      window.location.reload();
    }}
  >
    Cancel
  </button>

  const connectWallet = <Button className={classes.connect_btn}
    onClick={e => {
      e.preventDefault();
      connect();
    }}
  >
    Connect Wallet
  </Button>

    const disconnectWallet = <Button className={classes.disconnect_btn}
    onClick={e => {
      e.preventDefault();
      disconnect();
    }}
  >
    Disconnect Wallet
  </Button>

    const openSnackBar = () => {
        setSnackBar(true);
    }


    const delayedUpdate = () => {
        openSnackBar();
        setTimeout(props.refetch, 3000);
    }


    const closeSnackBar = () => {
        setSnackBar(false);
    }

    const getTargetCustomerPoints = () => {
        const customerPoints = propsUser?.customer_points;
        let targetCP = customerPoints.filter(cp => cp.points_token_types_id === pointTokenTypeId);
        targetCP = targetCP ? targetCP[0] : null;
        return targetCP;
    }


  const [updateCustomerPoints] = useMutation(UPDATE_BALANCE)

  const [transactAndUpdate] = useMutation(INSERT_TRANSACTIONS_UPDATE_BALANCE, {
      'fetchPolicy': 'no-cache',
      onCompleted: delayedUpdate,
  });

  // if (loadingOverride) {
  //   button = <StyledButton ><StyledProgress /></StyledButton>
  // }

  // const generateTransactionInputs = () => {
  //     let input = {};
  //     let objects = [];

  //     const targetCP = getTargetCustomerPoints();
  //     input.customer_points_id = targetCP.id;

  //     const products = props?.products ? props.products : [];
  //     products.forEach(product => {

  //       const quantity = props.productQuantityMap[product.id];
  //       const fiatAmount = (product.fiat_list_price * quantity);
  //       const pointAmount = (product.list_price_points_tokens * quantity);

  //       let transactionInput = {
  //           product_id: product.id,
  //           product_quantity: quantity,
  //           company_id: product?.company ? product.company.id : null,
  //           customer_id: propsUser?.id ? propsUser.id : null,
  //           is_product_purchase: true,
  //           is_simple_redemption: false,
  //           point_token_type_id: null,
  //           point_token_amount: null,
  //           fiat_type: product?.fiat_type ? product.fiat_type : 'USD',
  //           fiat_amount: fiatAmount,
  //       };

  //       if (props.mode === 'points') {
  //           transactionInput.point_token_amount = pointAmount;
  //           transactionInput.point_token_type_id = pointTokenTypeId;
  //           transactionInput.is_simple_redemption = true;
  //           transactionInput.fiat_type = null;
  //           transactionInput.fiat_amount = null;
  //       }

  //       transactionInput.transaction_type_id = props.transactionTypeId;

  //       objects.push(transactionInput);
  //     });

  //     input.objects = objects;

  //     if (props.mode === 'fiat') {
  //         input.balance = targetCP.balance + 100;
  //     }

  //     if (props.mode === 'points') {

  //         let pointsTotal = 0;
  //         objects.forEach(object => {
  //             pointsTotal += object.point_token_amount;
  //         });

  //         const newBalance = targetCP.balance - pointsTotal;
  //         input.balance = newBalance;
  //     }

  //     return input;
  // }

  const solidInput =  async(dataInput) => {
    const result = {}
    result.companyId = 'a67b662d-c543-446e-9d3d-e39a147c9800';
    result.campaignId = '73b99f46-3be5-40b6-9ba6-d0b8c449e528';

    let transactions = [];

    dataInput.forEach((soli) => {
      let transactionInput = {
        offsetTransactionId: soli.id,
        customerOrgId: "c390fd1e-c417-464f-b9c5-0a29a0e28081",
        customerOrgName: "Storco",
        customerAccountId: "c390fd1e-c417-464f-b9c5-0a29a0e28081",
        metaData: {},
        customerUserId: propsUser.id,
        countOfOffsets: 4
      }
      transactions.push(transactionInput);
    })

    let input = {};
    input.transactions = transactions;

    const response = {...result, ...input}
    return response
  } 

  const coinsInput = (typeToken) => {
    const token = 3;
    let result = {};
    result.customerId = propsUser.id;
    result.transactionTypeId = props.transactionTypeId;
    result.currencyType= token
    let products = [];
    const propsProducts = props?.products ? props.products : [];

    propsProducts.forEach(product => {
      const quantity = props.productQuantityMap[product.id];
      let transactionInput;

      transactionInput = {
        productId: product.id,
        productQuantity: quantity,
        typeToken : typeToken === 1 ?'ETH':'BTC',
        coinsAmount :product.coin_amount
      };
        products.push(transactionInput);
    });

    let input = {};
    input.products = products;
    return {...input, ...result}
  }

  const cUSDInput = () => {
    const cUSD = 6;
    let result = {};
    result.customerId = propsUser.id;
    result.transactionTypeId = props.transactionTypeId;
    result.currencyType= cUSD
    let products = [];
    const propsProducts = props?.products ? props.products : [];

    propsProducts.forEach(product => {
      const quantity = props.productQuantityMap[product.id];
      let transactionInput;

      transactionInput = {
        productId: product.id,
        productQuantity: quantity,
        coinsAmount :product.list_price_points_tokens/100
      };
        products.push(transactionInput);
    });

    let input = {};
    input.products = products;
    return {...input, ...result}
  }
  const transactionInputs = () => {
    const fiat = 1;
    const point = 2;
    let result = {};
    result.customerId = propsUser.id;
    result.transactionTypeId = props.transactionTypeId;
    result.currencyType= props.mode === 'fiat' ? fiat: point;
    result.amount = props.mode === 'fiat' ? calculateTotalFiat() * 100: null;
    let products = [];
    const propsProducts = props?.products ? props.products : [];

    propsProducts.forEach(product => {
      const quantity = props.productQuantityMap[product.id];
      let transactionInput;

      transactionInput = {
        productId: product.id,
        productQuantity: quantity,
      };

        products.push(transactionInput);
    });

    let input = {};
    input.products = products;
    return {...input, ...result}
  }

  const purchaseProductList = () => {
    let products = [];
    const propsProducts = props?.products ? props.products : [];

    propsProducts.forEach(product => {
      const quantity = props.productQuantityMap[product.id];

      products.push(`Code: ${product.product_code} --- Desc: ${product.product_description} --- Quantity: ${quantity}`);
    });

    return products;
  }

  const stripeInputs = () => {
    const fiat = 1;
    let result = {};
    result.customerId = propsUser.id;
    result.transactionTypeId = props.transactionTypeId;
    result.currencyType=  fiat;
    result.amount = props.mode === 'fiat' ? calculateTotalFiat() * 100: null;
    return result
  }

  const calculateTotalFiat = () => {
    let total = 0;
    const products = props?.products ? props.products : [];
    products.forEach(product => {
        total += (product.fiat_list_price * props.productQuantityMap[product.id]);
    })
    return total ? (total / 100) : 0;
  }

  const calculateTotalPoints = () => {
    let total = 0;
    props.products.forEach(product => {
        total += (product.list_price_points_tokens * props.productQuantityMap[product.id]);
    })
    return total;
  }

  const calculateTotalCUSD = () => {
    let total = 0;
    props.products.forEach(product => {
        total += (product.list_price_points_tokens/100 * props.productQuantityMap[product.id]);
    })
    return total;
  }

  const calculateTotalToken = () => {
    let total = 0;
    props.products.forEach(product => {
        total += (Number(product.coin_amount) * props.productQuantityMap[product.id]);
    })
    return total;
  }
  const dataCarbonOffset = propsUser.customer_points.filter(data => data.points_token_types_id === carbonOffsetPointId)
  const [totalCarbonOffset, setTotalCarbonOffset ] = useState(dataCarbonOffset[0]?.balance ?? 0)
  const countTotalCarbon = Number(totalCarbonOffset + defaultValueCarbonOffset)
  const [totalPoint, setTotalPoint] = useState(balancePoint)

  const inputBalanceCarbonOffset = {
    customerId:propsUser.id,
    pointsTokenTypesId: carbonOffsetPointId,
    balance:countTotalCarbon
  }

  const countFiat = `$${calculateTotalFiat().toFixed(2)}`
  const countPoint = `${props.formatNumber(calculateTotalPoints())} STOR Coins`
  const countCoins = `${calculateTotalToken().toFixed(4)} ETH`
  const countCUSD =  `${calculateTotalCUSD()} cUSD`
  const modeFiat = props.mode === 'fiat'
  const modePoint = props.mode === 'points'
  const modeCoins = props.mode === 'coins'

  return (
    <WrapperBox>
      <StyledSnackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        open={popupConnectWallet}
        autoHideDuration={1000}
        onClose={closePopupConnectWallet}
      >
        <StyledMuiAlert
          onClose={closePopupConnectWallet}
        >
          <p>Please connect a wallet.</p>
        </StyledMuiAlert>
      </StyledSnackbar>
      <StyledSnackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        open={snackBar}
        autoHideDuration={3000}
        onClose={closeSnackBar}
      >
        <StyledMuiAlert
          onClose={closeSnackBar}
          severity="success"
          variant="outlined"
          iconMapping={{ success: <CheckCircleOutlineIcon fontSize="24" /> }}
        >
          <StyledAlertTitle>Success!</StyledAlertTitle>
          <p>Hang tight while we ship your items.</p>
          {/* {props?.mode === 'fiat' ? <><br></br><p>+100 STOR Coins granted!</p></> : null} */}
          {
            reward.rewardSoliCarbonOffset ?
              <><br></br><p>+{props.formatNumber(reward.rewardSoliCarbonOffset)} Carbon offset granted!</p></>
              : ''
          }
        </StyledMuiAlert>
      </StyledSnackbar>
      <StyledSnackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        open={isCheckStripe}
        autoHideDuration={3000}
        onClose={closePopupStripe}
      >
        <StyledMuiAlert
          onClose={closePopupStripe}
          severity="success"
          variant="outlined"
          iconMapping={{ success: <CheckCircleOutlineIcon fontSize="24" /> }}
        >
          <StyledAlertTitle>Success!</StyledAlertTitle>
          <p>Hang tight while we ship your items.</p>
          {
            cacheValueCarbon ?
              <><br></br><p>+{defaultValueCarbonOffset} Carbon offset granted!</p></>
              : ''
          }
        </StyledMuiAlert>
      </StyledSnackbar>
      <MainBox>
        <Box>
          <SpanBox component='span'>Account</SpanBox>
          <RowBox>
            <PrimaryBox component='span'>Name</PrimaryBox> <DefaultBox component='span'>{userDisplayName}</DefaultBox>
          </RowBox>
          <RowBox>
            <PrimaryBox component='span'>{propsCustomerPoints[0].point_token_type.name}</PrimaryBox>
            <DefaultBox component='span'>{totalPoint}</DefaultBox>
          </RowBox>
          <RowBox>
            <PrimaryBox component='span'>Carbon Offset</PrimaryBox> <DefaultBox component='span'>{totalCarbonOffset}</DefaultBox>
          </RowBox>
        </Box>
        <hr className={classes.compact_hr} />
        <SpanBox component='span'>Payment Options</SpanBox>
        <FormControlLabel
          className={classes.styled_label}
          value='end'
          control={
            <StyledCheckbox
              checked={props?.mode ? props.mode === 'fiat' : true}
              icon={<UnCheckedBox />}
              checkedIcon={<WhiteCheck />}
              color='primary'
              onClick={e => {
                e.preventDefault();
                props.toggleMode('fiat');
                setReward({
                  rewardSoliCarbonOffset:0,
              })
              }}
              name='checks_1' />}
          label={<div className={classes.div_logos}>
            <div>
              <MasterCardLogo />
              <VisaLogo />
              <AmericanExpressLogo />
            </div>
          </div>}
          labelPlacement='end'
        />
        <FormControlLabel
          className={classes.styled_label}
          value='end'
          control={
            <StyledCheckbox
            checked={props?.mode ? props.mode === 'points' : false}
            icon={<UnCheckedBox />}
              checkedIcon={<WhiteCheck />}
              color='primary'
              onClick={e => {
                  e.preventDefault();
                  props.toggleMode('points');
                  setReward({
                    rewardSoliCarbonOffset:0,
                })
                }}
              name='checks_2' />}
          label='Redeem STOR Coins'
          labelPlacement='end'
        />

        <FormControlLabel
          className={classes.styled_label}
          value='end'
          control={
            <StyledCheckbox
              checked={props?.mode ? props.mode === 'cUSD' : false}
              icon={<UnCheckedBox />}
              checkedIcon={<WhiteCheck />}
              color='primary'
              onClick={e => {
                e.preventDefault();
                props.toggleMode('cUSD');
                if (!accountConnect) {
                  setPopupConnectWallet(true)
                }
              }}
              
              name='checks_2' />}
          label='cUSD'
          labelPlacement='end'
        />

        <FormControlLabel
          className={classes.styled_label}
          value='end'
          control={
            <StyledCheckbox
            checked={props?.mode ? props.mode === 'coins' : false}
            icon={<UnCheckedBox />}
              checkedIcon={<WhiteCheck />}
              color='primary'
              onClick={e => {
                  e.preventDefault();
                  props.toggleMode('coins');
                  setReward({
                    rewardSoliCarbonOffset:0,
                })
                if (!accountConnect) {
                  setPopupConnectWallet(true)
                }
                }}
              name='checks_2' />}
          label='Redeem ETH/BTC'
          labelPlacement='end'
        />
        <SpanBox component='span'>Reward</SpanBox>
        <FormControlLabel
          className={classes.styled_label}
          value='end'
          control={
            <StyledCheckbox
            checked={reward.rewardSoliCarbonOffset}
            icon={<UnCheckedBox />}
              name='soli_carbon_offset'
              checkedIcon={<WhiteCheck />}
              color='primary'
              onClick={e => {
                  e.preventDefault();
                  setReward({
                    rewardSoliCarbonOffset: 4,
                });
                localStorage.setItem('rewardCarbonOffset',defaultValueCarbonOffset)
                }} />}
          label='Carbon Offset'
          labelPlacement='end'
        />
{/* 
        <p className={classes.fiat_reward}>* Earn 100 STOR Coins with credit purchase</p> */}
      </MainBox>
      <SecondBox style={{ marginBottom:-5,marginTop:-10 }}>
        <Box className={classes.total_div}>
          <Box className={classes.payment_div_text}>
            <span className={classes.payment_span}>Items Total</span>
            <span className={classes.payment_span_2}>
              {modeFiat && countFiat}
              {modePoint && countPoint}
              {modeCoins && countCoins}
              {props.mode === 'cUSD' && countCUSD}
               </span>

          </Box>
          {/* <Box className={classes.payment_div_text}>
            <span className={classes.payment_span}>Shipping</span>
            <span className={classes.payment_span_2}>{props.mode === 'fiat' ? `\$${fiatShipping.toFixed(2)}` : `${props.formatNumber(pointShipping)} STOR Coins`}</span>

          </Box> */}
        </Box>
        <hr className={classes.compact_hr} />
        <TotalDivBox>
          <TotalBox component='span'>Total</TotalBox>
          <PriceTotalBox component='span'>
              {modeFiat && countFiat}
              {modePoint && countPoint}
              {modeCoins && countCoins}
              {props.mode === 'cUSD' && countCUSD}
          </PriceTotalBox>
        </TotalDivBox>

      </SecondBox>
      <>
        {
          props.mode === "cUSD" ? (
            <WrappedCeloWalletConnect
              totalcUSD={calculateTotalCUSD()}
              payBycUSD={payBycUSD}
            />
          ) : (
            <>
              <Box className={classes.div_btn_wallet}>
                {
                  !accountConnect && !isConnectWallet ? (
                    connectWallet
                  ) : (
                    disconnectWallet
                  )
                }
              </Box>
              <Box style={{ marginBottom: 5, marginLeft: 5 }}>
                {
                  isConnectWallet && accountConnect && <span style={{ color: 'black', fontSize: 14 }}>Connected to {formatAddress(accountConnect)}</span>
                }
              </Box>
            </>
          )
        }
        <Box className={classes.div_btns}>
        { props.mode !== 'cUSD' && props.mode !== 'coins' && button}  
        {props.mode !== 'cUSD' && props.mode !== 'coins'  && cancelButton}
          { props.mode === 'coins'&& accountConnect && ethButton}
          { props.mode === 'coins' && accountConnect && btcButton}
        </Box>
      </>
    </WrapperBox>
  );
};

export default PaymentOptions;