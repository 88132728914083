import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import PrivateRoute from "src/routes/PrivateRoute";
import Login from "src/components/Login";
import Register from "src/components/Register";
import AccountActivated from "src/components/AccountActivated";
import AccountActivationError from "src/components/AccountActivationError";
import ChangePassword from "src/components/ChangePassword";
import ForgotPassword from "src/components/ForgotPassword";
import DashboardLayout from "src/layouts/DashboardLayout";
import UserLayout from "src/layouts/UserLayout";
import TransactionSuccess from "src/components/TransactionSuccess";
import TransactionFailed from "src/components/TransactionFailed";
import CheckoutStorco from "src/views/storco/CheckoutStorco";
import CheckoutBelta from "src/views/belta/ChechoutBelta";
import CheckoutEnterprise from "src/views/enterprisecorp/CheckoutEnterprise";
import CheckoutRetailco from "src/views/retailco/CheckoutRetailco";
export default function () {
  return (
    <Router>
      <Switch>
        <Route
          exact
          path="/change-password/:ticket"
          render={(props) => (<ChangePassword />)}
        />
        <Route
          exact
          path="/account-activated"
          render={(props) => (<AccountActivated />)}
        />
        <Route
          exact
          path="/account-activation-error"
          render={(props) => (<AccountActivationError />)}
        />
        <Route
          exact
          path="/forgot-password"
          render={(props) => (<ForgotPassword />)}
        />
        <Route
          exact
          path="/login"
          render={(props) => (<Login />)}
        />
        <Route
          exact
          path="/register"
          render={(props) => (<Register />)}
        />

       <Route
          exact
          path="/success"
          render={(props) => (<TransactionSuccess />)}
        />

        <Route
          exact
          path="/checkout-storco"
          render={(props) => (<CheckoutStorco/>)}
        />
        
        <Route
          exact
          path="/checkout-belta"
          render={(props) => (<CheckoutBelta/>)}
          
        />

       <Route
          exact
          path="/checkout-enterprisecorp"
          render={(props) => (<CheckoutEnterprise/>)}
          
        />

       <Route
          exact
          path="/checkout-retailcorp"
          render={(props) => (<CheckoutRetailco/>)}
        />  

       <Route
          exact
          path="/failed"
          render={(props) => (<TransactionFailed />)}
        />

        <PrivateRoute path="/company" isUser={true}>
          <UserLayout />
        </PrivateRoute>

        <PrivateRoute path="/">
          <DashboardLayout />
        </PrivateRoute>

      </Switch>
    </Router>
  );
}
