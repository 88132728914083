import React from 'react'
import classes from './Loading.module.css'

const Loading = () =>  {
    return (
        <div className={classes.loadingWrapper}>
            <div className={classes.Loading}>
                <div className={classes.Dot}></div>
                <div className={classes.Dot}></div>
                <div className={classes.Dot}></div>
                <div className={classes.Dot}></div>
                <div className={classes.Dot}></div>
            </div>
        </div>
    )
};

export default Loading