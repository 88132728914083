import React, { useState,useEffect } from 'react';
import { ethers } from "ethers";
import { makeStyles } from '@material-ui/core';
import {
  Checkbox,
  FormControlLabel
} from '@material-ui/core';
import '../../layouts/UserLayout/fonts.css';
import { useHistory } from "react-router-dom";
import { withStyles } from '@material-ui/core/styles';
import CheckedBox from '../../icons/user/CheckedBox';
import UnCheckedBox from '../../icons/user/UnCheckedBox';
import Snackbar from '@material-ui/core/Snackbar';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import CircularProgress from '@material-ui/core/CircularProgress';
import MuiAlert from '@material-ui/lab/Alert';
import {AlertTitle} from '@material-ui/lab';
import MasterCardLogo from '../../icons/MasterCardLogo';
import VisaLogo from '../../icons/user/VisaLogo';
import AmericanExpressLogo from '../../icons/user/AmericanExpressLogo';
import gql from 'graphql-tag';
import { useMutation } from '@apollo/client';
import recommendations from '../recommendOptions';
import { getPublicKey, handleApi, soliCarbonOffset,pointsTransaction, updateBalancePoint, allocateCarbonOffset, getRewarData } from 'src/utils/handleApi';
import { submitPayment } from 'src/utils/ether';
import { COMPANY_ID, PROVIDER_OPTION, REWARD, TYPE_TOKEN } from 'src/utils/constant';
import { web3Modal } from'src/utils/web3Modal';
import { responseCarbonOffset, switchNetwork } from 'src/utils/common';

const StyledCheckbox = withStyles(() => ({
  checked: {
    '&:first-child': {
      border: '1px solid #000',
      borderRadius: '2px',
      padding: 0,
      width: '26px',
      height: '26px'
    }
  },
  colorPrimary: {
    color: '#D7DCE8',
    padding: 0,
    marginRight: '10px'
  },
  label: {
    marginTop: 20
  }

}))(Checkbox);

const useStyles = makeStyles((theme) => ({
  styled_label: {
    fontFamily: 'Open Sans SemiBold',
    fontSize: 10,
    marginBottom: 20
  },
  root: {

    display: 'flex',
    overflow: 'hidden',
    width: '100%'
  },
  wrapper: {
    backgroundColor: '#F6F3F3',
    display: 'flex',
    width: '100%',
    padding: '80px 48px 0px 48px',
    overflow: 'auto'
  },
  trip_box: {
    display: 'flex',
    background: '#FFFFFF',
    marginTop: 30,
    marginRight: 40,
    borderRadius: '2px 8px 8px 2px'
  },
  content_header: {
    fontFamily: 'Open Sans Bold',
    fontSize: 24

  },
  col_1: {
    display: 'flex',
    flexDirection: 'column',
    width: '70% !important'

  },
  col_2: {
    display: 'flex',
    flexDirection: 'column',
    width: '30%'

  },
  left_hr: {
    background: '#DCDCDC',
    height: '100%',
    width: '4px',
    borderRadius: '2px'
  },
  data: {
    display: 'flex',
    flexDirection: 'column',
    padding: '20px 23px',
    width: '100%'

  },
  data_content: {
    marginTop: 14,
    border: '1px solid #DCDCDC',
    borderRadius: 8,
    padding: '19px 18px',
    display: 'flex',
    flexDirection: 'column'
  },
  spans: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  data_span: {
    marginRight: 30,
    fontFamily: 'Open Sans SemiBold',
    fontSize: 18,
    whiteSpace: 'nowrap',
    '&:last-child': {
      fontFamily: 'Open Sans Regular',
      color: '#777777'
    }
  },
  payment_options: {
    display: 'flex',
    background: '#FFFFFF',
    marginTop: 20,
    marginRight: 40,
    borderRadius: '2px 8px 8px 2px'
  },
  payment_options_data: {
    display: 'flex',
    padding: '14px 0px 0px 0px',
    width: '100%',
    justifyContent: 'space-between'
  },
  payment_options_col: {
    display: 'flex',
    flexDirection: 'column',

    '&:last-child': {
      marginLeft: 56

    },
    '& div input': {
      borderRadius: 0
    },
    '& label span:last-child': {
      fontFamily: 'Open Sans Bold',
      fontSize: '16px'

    }
  },
  payment_options_button: {
    padding: '8px 24px',
    color: '#fff',
    border: 'none',
    background: 'black',
    borderRadius: 4,
    fontFamily: 'Open Sans SemiBold',
    fontSize: 18,
    height:44,
    borderRadius:100,
    width: '60%',
    '&:hover': {
        cursor: 'pointer'
    }
  },
  recommend_button: {
    padding: '8px 24px',
    color: '#fff',
    border: 'none',
    background: 'black',
    borderRadius: 4,
    fontFamily: 'Open Sans SemiBold',
    fontSize: 18,
    height:44,
    width: 250,
    '&:hover': {
        cursor: 'pointer'
    }
  },
  recommendation_desc: {
    fontSize: '18px !important',
    padding: '10px !important'
  },
  payment_options_span: {
    width: '100%',
    '& span': {
      fontFamily: 'Open Sans Regular',
      fontSize: 16,
      lineHeight: '20px'
    }
  },
  div_logos: {
    display: 'flex',
    whiteSpace: 'nowrap',
    '& div:last-child': {
      alignItems: 'center',
      display: 'flex',
      width: '150px',
      justifyContent: 'space-around',
      marginLeft: 24
    }
  },
  reward: {
    display: 'flex',
    background: '#FFFFFF',
    marginTop: 20,
    marginRight: 40,
    borderRadius: '2px 8px 8px 2px',
    marginBottom: '100'
  },
  span_rewards: {
    fontFamily: 'Open Sans SemiBold !important'
  },
  reward_boxes: {
    width: '70%',
    marginTop: 14,
    display: 'flex',
    marginLeft: 11,
    justifyContent: 'space-between',
    '& label span:last-child': {
      fontFamily: 'Open Sans Regular',
      fontSize: '16px'
    }
  },
  account: {
    display: 'flex',
    background: '#FFFFFF',
    marginTop: 30,


    borderRadius: '2px 8px 8px 2px',
    '& div:first-child': {
      background: '#010A21',
      height: '100%',
      width: '4px',
      borderRadius: '2px'
    }

  },
  account_data_div: {
    padding: '20px 32px 0px 32px'
  },
  account_text_holder: {
    display: 'flex',
    margin: '18px 0px'
  },
  account_primary_text: {
    color: '#777777',
    fontFamily: 'Open Sans Regular',
    fontSize: 16
  },
  account_main_text_name: {
    fontFamily: 'Open Sans Regular',
    fontSize: 16,
    marginLeft:'30px',
  },
  account_main_text_coin: {
    fontFamily: 'Open Sans Regular',
    fontSize: 16,
    marginLeft:'65px',
  },
  account_main_text_mile:{
    fontFamily: 'Open Sans Regular',
    fontSize: 16,
    marginLeft:'76px',
  },
  account_main_text_carbon:{
    fontFamily: 'Open Sans Regular',
    fontSize: 16,
    marginLeft:'54px',
  },
  payment: {
    display: 'flex',
    background: '#FFFFFF',
    marginTop: 20,
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    borderRadius: '8px',
    '& div:first-child': {
      background: '#010A21',
      height: '100%',
      width: '4px',
      borderRadius: '2px'
    }

  },
  payment_div_text: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '96%',
    marginTop: 10,
    '& .first_payment':{
      marginTop: 18
    },
  },
  payment_span: {
    fontFamily: 'Open Sans Regular',
    fontSize: 18,
    '&:first-child': {
      color: '#777777'
    }

  },
  payment_total_span: {
    fontFamily: 'Open Sans Bold',
    fontSize: 18
  },
  div_buttons: {
    display: 'flex',
    marginTop: 20
  },
  cancel_button: {
    padding: '8px 24px',
    marginLeft: '8px',
    border: 'none',
    background: 'none',
    fontFamily: 'Open Sans SemiBold',
    fontSize: 18,
    height:44,
    '&:hover': {
        cursor: 'pointer'
    }
  },
  btn_connect_wallet:{
    marginTop:10,
    width:'60%',
    background:'#4CAF50',
    color:'#fff',
    borderRadius:100,
    fontSize:18,
    height: 44,
  },
  btn_disconnect_wallet:{
    marginTop:10,
    width:'60%',
    background:'#4CAF50',
    color:'#fff',
    borderRadius:100,
    fontSize:18,
    height: 44,
  },
  btn_approve: {
    marginTop:10,
    marginLeft:30,
    width:130,
    background:'#4CAF50',
    color:'#fff',
    borderRadius:100,
    fontWeight:'bold',
    fontSize:20,
    height: 44,
  },

  bnt_eth: {
    padding: '8px 24px',
    marginRight:'2%',
    color: '#fff',
    border: 'none',
    background: 'black',
    borderRadius: 4,
    fontFamily: 'Open Sans SemiBold',
    fontSize: 18,
    height:44,
    borderRadius:100,
    width: '60%',
    '&:hover': {
        cursor: 'pointer'
    }
  },

  bnt_btc: {
    padding: '8px 24px',
    color: '#fff',
    border: 'none',
    background: 'black',
    borderRadius: 4,
    fontFamily: 'Open Sans SemiBold',
    fontSize: 18,
    borderRadius:100,
    height:44,
    width: '60%',
    '&:hover': {
        cursor: 'pointer'
    }
  },
}));

const StyledSnackbar=withStyles(() => ({
    root: {
        fontFamily: 'Roboto',
        fontSize: 18,
    }
}))(Snackbar)

const StyledMuiAlert=withStyles(() => ({
    root: {
        fontFamily: 'Roboto',
        fontSize: 18,
        backgroundColor: '#fff',
        borderWidth: '2px',
        width: '450px'
    }
}))(MuiAlert)

const StyledAlertTitle=withStyles(() => ({
    root: {
        fontFamily: 'Roboto',
        fontWeight: '400',
        fontSize: 24,
    }
}))(AlertTitle)

const StyledProgress=withStyles(()=> ({
    circle:{
        color: '#fff',
    }
}))(CircularProgress)


const baseMutation = `
    insert_transactions_reward_redemption(objects: $objects) {
        returning {
            id
            transaction_type_id
            company_id
            customer_id
            is_product_purchase
            is_simple_redemption
            point_token_type_id
            fiat_type
            point_token_amount
            fiat_amount
            xact_datetime_at
        }
    }
`;

const updateBalanceMutation = (alias, balanceInputName, customerPointsIdInputName) => {
    return `${alias}:
    update_customer_points_by_pk(
        _set: {
            balance: $${balanceInputName}
        }
        pk_columns: {id: $${customerPointsIdInputName}}
    ) {
        id
        customer_id
        balance
        points_token_types_id
        point_token_type {
            id
            name
        }
    }
`;
}

const UPDATE_BALANCES = gql`
    mutation updateBalance(
        $customer_points_id: uuid!
        $balance: bigint!
        $customer_points_id_2: uuid!
        $balance_2: bigint!
    ) {
        ${updateBalanceMutation('miles_update', 'balance', 'customer_points_id')}
        ${updateBalanceMutation('coins_update', 'balance_2', 'customer_points_id_2')}
    }
`;

const INSERT_TRANSACTIONS_UPDATE_BALANCES = gql`
    mutation insertTransactionsUpdateBalance(
        $objects: [transactions_reward_redemption_insert_input!]!
        $customer_points_id: uuid!
        $balance: bigint!
        $customer_points_id_2: uuid!
        $balance_2: bigint!
    ) {
        ${baseMutation}
        ${updateBalanceMutation('miles_update', 'balance', 'customer_points_id')}
        ${updateBalanceMutation('coins_update', 'balance_2', 'customer_points_id_2')}
    }
`;


const Boxes = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const propsProducts = props?.products ? props.products : [];
  const propsUser = props?.users ? props.users[0] : null;
  const propsUserPoints = propsUser ? propsUser?.customer_points : [];
  const recommendOptions = recommendations?.belta;

  const fiatTax = 44;

  const beltaCoinCost = 26000;
  const defaultRewardMiles = 2200;
  const defaultRewardCoins = 480;
  const defaultRewardSoli = 4;

  const resetCustomerMiles = 50000;
  const resetCustomerCoins = 520000;
  const resetCustomerCarbon = 12;

  const beltaMilesTokenTypeId = '938f169d-11e6-4430-ac71-f2d244d45e7e';
  const beltaCoinsTokenTypeId = 'c579805f-7afd-4f8d-9d4e-bcaa2ee7f244';
  const beltaSoliTokenTypeId = '74219bde-e7d1-4ca3-be12-56eefc4eb573';

  const userBeltaMiles = props.filterPointType(beltaMilesTokenTypeId, propsUserPoints);
  const userBeltaCoins = props.filterPointType(beltaCoinsTokenTypeId, propsUserPoints);
  const userBeltaSoliCarbonOffset = props.filterPointType(beltaSoliTokenTypeId, propsUserPoints);


  const [loadingOverride, setLoadingOverride] = useState(false);
  const [snackBar, setSnackBar] = useState(false);
  const [popupConnectWallet, setPopupConnectWallet] = useState(false);
  const [reward, setReward] = useState({
    rewardMiles: 0,
    rewardCoins: 0,
    rewardSoliCarbonOffset : 0,
  });

  const [recommendationState, setRecommendationState] = useState({
      recommendation: null,
      idx: null
  });

  const [audioDelay, setaudioDelay] = useState(false);


  const [accountConnect, setAccountConnect] = useState('');
  const [provider, setProvider] = useState();

  const [isCheckStripe, setIsCheckStripe] = useState(false)
  const cacheValueCarbon = localStorage.getItem('rewardCarbonOffset')
  const cacheValueMiles = localStorage.getItem('rewardMiles')
  const cacheValueCoins = localStorage.getItem('rewardCoins')
  const [switchLibrary, setSwitchLibrary] = useState()
  const [isConnectWallet, setIsConnectWallet] = useState(false)
  useEffect(() => {
    (async function() {
      const isCheckPayment =  localStorage.getItem('isBeltaSuccess')
      if (isCheckPayment) {
        setIsCheckStripe(true)
        setTimeout(() => {
          localStorage.clear()
        }, 3000)
      }
    })();
  }, []);

  useEffect(() => {
    (async function() {
    const walletAddress = localStorage.getItem('account')
      if (localStorage?.getItem('isWalletConnected') === 'true') {
        setIsConnectWallet(true)
        setAccountConnect(walletAddress)
      }
    })();
  }, []);

  const closePopupStripe = () => {
    setIsCheckStripe(false)
  }



  const formatAddress = (address) => {
    if (address) {
      const add1 = address.substring(0, 4);
      const add2 = address.substring(address.length - 4);
      const finalAddress = `${add1}...${add2}`;
      return finalAddress;
    }
  };

const connectWallet = async () => {

    try {
      const web3Provider = await web3Modal.connect();
      const library = new ethers.providers.Web3Provider(web3Provider);
      setSwitchLibrary(library)
      const network = await library.getNetwork();
      const web3Accounts = await library.listAccounts();
      const signer = library.getSigner(web3Accounts[0]);
      setProvider(signer);
      setAccountConnect(web3Accounts[0]);
      localStorage.setItem('account', web3Accounts[0])
      localStorage.setItem('isWalletConnected', true)
      setIsConnectWallet(true)

      if(network.chainId !== PROVIDER_OPTION.CHAIN_ID) {
        switchNetwork(switchLibrary)
      }
      
    } catch (error) {
      console.log(error);
    }
};

const disconnectWallet = async () => {
  setAccountConnect('');
  localStorage.setItem('isWalletConnected', false)
  setIsConnectWallet(false)
  localStorage.removeItem('-walletlink:https://www.walletlink.org:Addresses')
  window.location.reload();
};

  const roundRobinRecommend = () => {
    let idx, recommendation;
    if (recommendationState.idx == null || recommendationState.idx == recommendOptions.length - 1) {
        idx = 0;
        recommendation = recommendOptions[idx];
    } else {
        idx = recommendationState.idx + 1;
        recommendation = recommendOptions[idx];
    }
    props.toggleMode(recommendation.mode);
    if (recommendation.mode === 'miles') {
        setReward({rewardMiles: 0, rewardCoins: 0, rewardSoliCarbonOffset: 0});
    } else if (recommendation.mode === 'coins') {
        setReward({rewardMiles: 0, rewardCoins: 0, rewardSoliCarbonOffset: 0});
    } else if (recommendation.mode === 'fiat') {
        setReward({rewardMiles: 0, rewardCoins: 0, rewardSoliCarbonOffset: 0})
    }
    setRecommendationState({
        recommendation: recommendOptions[idx],
        idx: idx
    });

    setaudioDelay(true);
    timeoutAudioDelay();
  }


    const timeoutAudioDelay = () => {
        setTimeout(setaudioDelay, 1000, false)
    }

  const delayedUpdate = () => {
    openSnackBar();
    setTimeout(props.refetch, 3000);
  }


  const openSnackBar = () => {
    setSnackBar(true);
  }


  const closeSnackBar = () => {
    setSnackBar(false);
  }

  const closePopupConnectWallet = () => {
    setPopupConnectWallet(false)
  }

  const getTargetCustomerPoints = (pointTokenTypeId) => {
    const customerPoints = propsUser?.customer_points;
    let targetCP = customerPoints.filter(cp => cp.points_token_types_id === pointTokenTypeId);
    targetCP = targetCP ? targetCP[0] : null;
    return targetCP;
  }


  const fiatTotal = () => {
    const sum = (total, num) => {return total + num}
    const total = propsProducts.map(p => p?.fiat_list_price ? p.fiat_list_price : 0).reduce(sum);
    if (total) {
        return total;
    }
  }

  const coinsTotal = () => {
    const sum = (total, num) => {return total + num}
    const total = propsProducts.map(p => p?.coin_amount ? Number(p.coin_amount) : 0).reduce(sum);
    if (total) {
        return total;
    }
  }


  const calculateRoundtripPrice = () => {
    if (props.mode === 'fiat') {
        const total = fiatTotal();
        if (total)
            return total;
    } else if (props.mode === 'miles') {
        const sum = (total, num) => {return total + num}
        return propsProducts.map(p => p?.list_price_points_tokens ? p.list_price_points_tokens : 0).reduce(sum);
    } else if (props.mode === 'coins') {
        return beltaCoinCost;
    }
  }


  const resetBalanceUpdateInputs = () => {
      return {
        balance: resetCustomerMiles,
        customer_points_id: getTargetCustomerPoints(beltaMilesTokenTypeId).id,
        balance_2: resetCustomerCoins,
        customer_points_id_2: getTargetCustomerPoints(beltaCoinsTokenTypeId).id,
      }
  }

  const dataCarbon = propsUser.customer_points.filter((carbon) => {
    return carbon.points_token_types_id === beltaSoliTokenTypeId
  })

  const dataMile = propsUser.customer_points.filter((mile) => {
    return mile.points_token_types_id === beltaMilesTokenTypeId
  })

  const dataCoins = propsUser.customer_points.filter((coin) => {
    return coin.points_token_types_id === beltaCoinsTokenTypeId
  })

  const [valueBalanceMile, setValueBalanceMile] = useState(dataMile[0].balance);
  const [valueBalanceCoin, setValueBalanceCoin] = useState(dataCoins[0].balance);
  const [valueBalanceCarbon, setValueBalanceCarbon] = useState(dataCarbon[0].balance)

  const modeFiat  = props.mode === 'fiat' && `\$${(calculateRoundtripPrice()/100).toFixed(2)}`;
  const modeMiles = props.mode ==='miles' && `${calculateRoundtripPrice()} Belta Miles`;
  const modeCoins = props.mode ==='coins' && `${props.formatNumber(calculateRoundtripPrice())} BELTA Coins`;
  const modeToken = props.mode ==='token' && `${coinsTotal().toFixed(4)} ETH`;

  const totalBalanceCarbon = valueBalanceCarbon + defaultRewardSoli

  const inputBalanceCarbon =  {
    customerId: propsUser.id,
    pointsTokenTypesId: dataCarbon[0].points_token_types_id,
    balance: totalBalanceCarbon,
  }
  
  // const transactionInputs = () => {
  //   const milesCost = props.mode === 'miles' ? calculateRoundtripPrice() : 0;
  //   const coinsCost = props.mode === 'coins' ? calculateRoundtripPrice() : 0;

  //   const {rewardMiles, rewardCoins} = reward;

  //   const milesBalance = userBeltaMiles.balance - milesCost + rewardMiles;
  //   const coinsBalance = userBeltaCoins.balance - coinsCost + rewardCoins;

  //   const balanceInputs = {
  //       balance: milesBalance,
  //       customer_points_id: getTargetCustomerPoints(beltaMilesTokenTypeId).id,
  //       balance_2: coinsBalance,
  //       customer_points_id_2: getTargetCustomerPoints(beltaCoinsTokenTypeId).id
  //   };

  //   let objects = [];
  //   const products = props?.products ? props.products : [];

  //   products.forEach(product => {
  //       const quantity = 1;
  //       const fiatAmount = (product.fiat_list_price * quantity);
  //       const pointAmount = (product.list_price_points_tokens * quantity);

  //       let transactionInput = {
  //           product_id: product.id,
  //           product_quantity: quantity,
  //           company_id: product?.company ? product.company.id : null,
  //           customer_id: propsUser?.id ? propsUser.id : null,
  //           is_product_purchase: true,
  //           is_simple_redemption: false,
  //           point_token_type_id: null,
  //           point_token_amount: null,
  //           fiat_type: product?.fiat_type ? product.fiat_type : 'USD',
  //           fiat_amount: fiatAmount
  //       };

  //       if (props.mode === 'miles') {
  //           transactionInput.point_token_amount = pointAmount;
  //           transactionInput.point_token_type_id = beltaMilesTokenTypeId;
  //           transactionInput.is_simple_redemption = true;
  //           transactionInput.fiat_type = null;
  //           transactionInput.fiat_amount = null;
  //       }

  //       if (props.mode === 'coins') {
  //           transactionInput.point_token_amount = beltaCoinCost / 2;
  //           transactionInput.point_token_type_id = beltaCoinsTokenTypeId;
  //           transactionInput.is_simple_redemption = true;
  //           transactionInput.fiat_type = null;
  //           transactionInput.fiat_amount = null;
  //       }

  //       transactionInput.transaction_type_id = props.transactionTypeId;

  //       objects.push(transactionInput);
  //   });

  //   let input = {};
  //   input.objects = objects;

  //   return {...input, ...balanceInputs}
  // }
 
  const coinsInput = (typeToken) => {
    const quantity = 1;
    const coins = 3;
    let result = {}
    result.customerId = propsUser.id;
    result.transactionTypeId = props.transactionTypeId;
    result.currencyType = coins;

    let products = [];
    const propsProducts = props?.products ? props.products : [];

    propsProducts.forEach(product => {
      let transactionInput = {
        productId: product.id,
        productQuantity: quantity,
        typeToken : typeToken === 1 ?'ETH':'BTC',
        coinsAmount :product.coin_amount
      };
      products.push(transactionInput);
  });

  let input = {};
  input.products = products;
  return {...input, ...result}
  }
  
  const solidInput =  async(dataInput) => {
    const result = {}
    result.companyId = 'a67b662d-c543-446e-9d3d-e39a147c9800';
    result.campaignId = '73b99f46-3be5-40b6-9ba6-d0b8c449e528';

    let transactions = [];

    dataInput.forEach((soli) => {
      let transactionInput = {
        offsetTransactionId: soli.id,
        customerOrgId: "c390fd1e-c417-464f-b9c5-0a29a0e28081",
        customerOrgName: "Belta",
        customerAccountId: "c390fd1e-c417-464f-b9c5-0a29a0e28081",
        metaData: {},
        customerUserId: propsUser.id,
        countOfOffsets: 4
      }
      transactions.push(transactionInput);
    })

    let input = {};
    input.transactions = transactions;

    const response = {...result, ...input}
    return response
  } 

  const inputPurchase = () => {
    const quantity = 1;
    const fiat = 1;
    const points = 2;
    const  dataInput = {};
    dataInput.customerId = propsUser.id;
    dataInput.transactionTypeId = props.transactionTypeId;
    dataInput.currencyType  = props.mode === 'fiat' ? fiat : points;
    dataInput.amount = props.mode === 'fiat' ? fiatTotal() : null;
    
    if (props.mode === 'miles') {
      dataInput.typePointId = REWARD.BELTA_MILE_ID
    }

    if (props.mode === 'coins') {
      dataInput.typePointId = REWARD.BELTA_COIN_ID
    }

    let products = [];
    const propsProducts = props?.products ? props.products : [];

    propsProducts.forEach(product => {
        let transactionInput = {
          productId: product.id,
          productQuantity: quantity,
        };
        products.push(transactionInput);
    });

    let input = {};
    input.products = products;

    return {...input, ...dataInput}
  }

  const [updateCustomerPoints] = useMutation(UPDATE_BALANCES)
  const [transactAndUpdate] = useMutation(INSERT_TRANSACTIONS_UPDATE_BALANCES, {
      'fetchPolicy': 'no-cache',
      onCompleted: delayedUpdate,
  });


  const ethBtn = <button
    className={classes.bnt_eth}
    onClick={async (e) => {
      e.preventDefault();
      e.preventDefault();

      const typeToken = TYPE_TOKEN.ETH;
      const data = coinsInput(typeToken)
      
      if (props.mode === 'token') {
        const totalPrice = coinsTotal();
        const companyId = props.currentCompany.id;
        const dataSoli = await submitPayment(data, totalPrice, companyId, typeToken, provider);
        
        if (dataSoli) {
          openSnackBar()
        } 

        if (reward.rewardSoliCarbonOffset) {
          const soliInput = await solidInput(dataSoli.data)
          const response = await allocateCarbonOffset(soliInput)
          const inputCarbonOffset = await responseCarbonOffset(response.data,COMPANY_ID.BELTA)
          soliCarbonOffset(inputCarbonOffset)
          updateBalancePoint(e,inputBalanceCarbon)
          setValueBalanceCarbon(totalBalanceCarbon)
          openSnackBar();
        }

      }
    }}
  >
    {loadingOverride ? <StyledProgress size={30} /> : 'Buy With ETH'}
  </button>

  const btcBtn = <button
    className={classes.bnt_btc}
    onClick={async (e) => {
      e.preventDefault();
      const typeToken = TYPE_TOKEN.BTC;
      const data = coinsInput(typeToken)
      if (props.mode === 'token') {
        const totalPrice = coinsTotal();
        const companyId = props.currentCompany.id;
        const dataSoli = await submitPayment(data, totalPrice, companyId, typeToken, provider);
       
        if (dataSoli) {
          openSnackBar()
        }

        if (reward.rewardSoliCarbonOffset) {
          const soliInput = await solidInput(dataSoli.data)
          const response = await allocateCarbonOffset(soliInput)
          const inputCarbonOffset = await responseCarbonOffset(response.data,COMPANY_ID.BELTA)
          soliCarbonOffset(inputCarbonOffset)
          updateBalancePoint(e,inputBalanceCarbon)
          setValueBalanceCarbon(totalBalanceCarbon)
          openSnackBar();
        }
      }
    }}
  >
    {loadingOverride ? <StyledProgress size={30} /> : 'Buy With BTC'}
  </button>

  const submitBnt = <button
    className={classes.payment_options_button}
    onClick={async(e) => {
      e.preventDefault();
      const data = inputPurchase();
      if (props.mode === 'fiat') {
        getPublicKey(e, COMPANY_ID.BELTA)
        const dataTransaction = await pointsTransaction(e, data)
        if (reward.rewardSoliCarbonOffset) {
          const inputTransactionSoli =  await solidInput(dataTransaction.data)
          const response = await allocateCarbonOffset(inputTransactionSoli)
          const inputCarbonOffset = await responseCarbonOffset(response.data,COMPANY_ID.BELTA)
          soliCarbonOffset(inputCarbonOffset)
          updateBalancePoint(e,inputBalanceCarbon)
          setValueBalanceCarbon(totalBalanceCarbon)
         }
        handleApi(e, data)
        data.transactionIds = dataTransaction.data
        localStorage.setItem('dataBelta', JSON.stringify(data))
        history.push("/checkout-belta")
      }

      if (props.mode === 'miles' || props.mode === 'coins') {
        // setLoadingOverride(true);
        // setTimeout(setLoadingOverride, false, 1000);
        const dataTransaction = await pointsTransaction(e, data)
        if (reward.rewardSoliCarbonOffset) {
          const inputTransactionSoli =  await solidInput(dataTransaction.data)
          const response = await allocateCarbonOffset(inputTransactionSoli)
          const inputCarbonOffset = await responseCarbonOffset(response.data,COMPANY_ID.BELTA)
          soliCarbonOffset(inputCarbonOffset)
          updateBalancePoint(e,inputBalanceCarbon)
          setValueBalanceCarbon(totalBalanceCarbon)
         }
         const {rewardMiles, rewardCoins} = reward;

        if (props.mode === 'miles') {
          const beltaMilesData = await  getRewarData(propsUser.id,REWARD.BELTA_MILE_ID)
          const countBeltaMiles = beltaMilesData.data[0].balance - calculateRoundtripPrice() + rewardMiles
          const inputMiles = {
            customerId: propsUser.id,
            pointsTokenTypesId: dataMile[0].points_token_types_id,
            balance: countBeltaMiles,
          }
          updateBalancePoint(e,inputMiles)
          setValueBalanceMile(countBeltaMiles)
        }

        if (props.mode === 'coins') {
          const beltaCoinsData = await  getRewarData(propsUser.id,REWARD.BELTA_COIN_ID)
          const countBeltaCoins = beltaCoinsData.data[0].balance - calculateRoundtripPrice() + rewardCoins
          const inputCoins = {
            customerId: propsUser.id,
            pointsTokenTypesId: dataCoins[0].points_token_types_id,
            balance: countBeltaCoins,
          }
          updateBalancePoint(e,inputCoins)
          setValueBalanceCoin(countBeltaCoins)
        }

        openSnackBar();
      }

      if (props.mode === 'token' && !accountConnect) {
        setPopupConnectWallet(true)
      }

    }}
  >
    {loadingOverride ? <StyledProgress size={30} /> : 'Submit Payment'}
  </button>

  const cancelBnt = <button
    className={classes.cancel_button}
    onClick={e => {
      e.preventDefault();
      const input = resetBalanceUpdateInputs();
      updateCustomerPoints({ variables: input });
      updateBalancePoint(e,{
        customerId: propsUser.id,
        pointsTokenTypesId: dataCarbon[0].points_token_types_id,
        balance: resetCustomerCarbon,
      })
      window.location.reload();
    }}
  >
    Cancel
  </button>


  return (
    <div className={classes.wrapper}>
              <StyledSnackbar
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}
            open={isCheckStripe}
            autoHideDuration={3000}
            onClose={closePopupStripe}
        >
            <StyledMuiAlert
                onClose={closePopupStripe}
                severity="success"
                variant="outlined"
                iconMapping={{ success: <CheckCircleOutlineIcon fontSize="24" /> }}
            >
                <StyledAlertTitle>Success!</StyledAlertTitle>
                <p>Hang tight while we ship your items.</p>
                {
                  cacheValueCarbon ?
                  <><br></br><p>+{ defaultRewardSoli } Carbon offset granted!</p></>
                  :''                 
                }
                {
                  cacheValueCoins ?
                  <><br></br><p>+{ defaultRewardCoins} BELTA Coins granted!</p></>
                  :''
                     
                }
                {
                   cacheValueMiles ?
                   <><br></br><p>+{ cacheValueMiles ? defaultRewardMiles : 0} BELTA Miles granted!</p></>
                   :''     
                }
            </StyledMuiAlert>
        </StyledSnackbar>
      <StyledSnackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        open={popupConnectWallet}
        autoHideDuration={3000}
        onClose={closePopupConnectWallet}
      >
        <StyledMuiAlert
          onClose={closePopupConnectWallet}
        >
          <p>Please connect a wallet.</p>
        </StyledMuiAlert>
      </StyledSnackbar>
      
        <StyledSnackbar
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}
            open={snackBar}
            autoHideDuration={3000}
            onClose={closeSnackBar}
        >
            <StyledMuiAlert
                onClose={closeSnackBar}
                severity="success"
                variant="outlined"
                iconMapping={{ success: <CheckCircleOutlineIcon fontSize="24" /> }}
            >
                <StyledAlertTitle>Success!</StyledAlertTitle>
                <p>You should receive a confirmation email with your itinerary shortly.</p>
                {
                    reward.rewardMiles ?
                        <><br></br><p>+{props.formatNumber(reward.rewardMiles)} Belta Miles granted!</p></>
                        : ''
                }
                {
                    reward.rewardCoins ?
                        <><br></br><p>+{props.formatNumber(reward.rewardCoins)} BELTA Coins granted!</p></>
                        : ''
                }
                {
                    reward.rewardSoliCarbonOffset ?
                        <><br></br><p>+{props.formatNumber(reward.rewardSoliCarbonOffset)} Carbon offset granted!</p></>
                        : ''
                }
            </StyledMuiAlert>
        </StyledSnackbar>
      <div className={classes.col_1}>
        <div className={classes.trip_box}>
          <div className={classes.left_hr}></div>
          <div className={classes.data}>
            <span className={classes.content_header}>Trip</span>

            {
                propsProducts.map((product, index) => {
                    return (
                        <div key={index} className={classes.data_content}>
                            <span style={{ color: '#777777', fontSize: 18, fontFamily: 'Open Sans Regular' }}>{index % 2 != 0 ? 'Returning' : 'Departing'}</span>
                            <div className={classes.spans}>
                                <span className={classes.data_span}>{product?.product_code}</span>
                                <span className={classes.data_span}>{index % 2 != 0 ? 'LAX - JFK' : 'JFK - LAX'}</span>
                                <span className={classes.data_span}>{index % 2 != 0 ? 'Feb 24' : 'Feb 17'}</span>
                                <span className={classes.data_span}>{index % 2 != 0 ? '12:05 pm - 8:39 pm' : '4:14 pm - 7:17 pm'}</span>
                                <span className={classes.data_span}>{index % 2 != 0 ? 'Nonstop, 5h 34m' : 'Nonstop, 6h 11m'}</span>
                            </div>
                        </div>
                    )
                })
            }
          </div>
        </div>
        <div className={classes.payment_options}>
          <div className={classes.left_hr}></div>
          <div className={classes.data}>
            <span className={classes.content_header}>Payment Options</span>
            <div className={classes.payment_options_data}>
              <div className={classes.payment_options_col}>
                <div>
                  <button
                    className={classes.payment_options_button, classes.recommend_button}
                    onClick={e => {
                        roundRobinRecommend();
                    }}
                  >
                      Recommend
                  </button>
                </div>
                <div className={classes.payment_options_span}>
                    <span className={classes.recommendation_desc}>
                        <p>{recommendationState?.recommendation ? recommendationState.recommendation.message : ''}</p>
                        <br></br>
                    </span>
                    {
                        recommendationState?.recommendation != null ? (
                            audioDelay ? <CircularProgress size="35px" color="black"/> : (
                                <audio className={classes.audio} key={recommendationState.idx} controls autoPlay>
                                    <source src={`/static/audio/${recommendationState.recommendation.file}`} type="audio/mp3" />
                                    Your browser does not support the audio element.
                                </audio>
                            )
                        ) : ''
                    }
                </div>
              </div>
              <div className={classes.payment_options_col}>
                <FormControlLabel

                  className={classes.styled_label}
                  value='end'
                  control={
                    <StyledCheckbox
                      checked={props.mode === 'fiat'}
                      icon={<UnCheckedBox />}
                      checkedIcon={<CheckedBox />}
                      color='primary'
                      onClick={e => {
                          e.preventDefault();
                          props.toggleMode('fiat');
                          setReward({
                            rewardCoins: 0,
                            rewardMiles: 0,
                            rewardSoliCarbonOffset: 0,
                          })
                      }}
                      name='fiat' />}
                  label={<div className={classes.div_logos}>
                    <span>Visa / Mastercard / Amex</span>
                    <div>
                      <MasterCardLogo />
                      <VisaLogo />
                      <AmericanExpressLogo />
                    </div>
                  </div>}
                  labelPlacement='end'
                />
                <FormControlLabel
                  className={classes.styled_label}
                  value='end'
                  control={
                    <StyledCheckbox
                      checked={props.mode === 'miles'}
                      icon={<UnCheckedBox />}
                      checkedIcon={<CheckedBox />}
                      color='primary'
                      onClick={e => {
                        e.preventDefault();
                        props.toggleMode('miles');
                        setReward({
                            rewardCoins: 0,
                            rewardMiles: 0,
                            rewardSoliCarbonOffset:0,
                        })
                      }}
                      name='miles' />}
                  label='Belta Miles'
                  labelPlacement='end'
                />
                <FormControlLabel
                  className={classes.styled_label}
                  value='end'
                  control={<StyledCheckbox
                    checked={props.mode === 'coins'}
                    icon={<UnCheckedBox />}
                    checkedIcon={<CheckedBox />}
                    color='primary'
                    onClick={e => {
                        e.preventDefault();
                        props.toggleMode('coins');
                        setReward({
                            rewardCoins: 0,
                            rewardMiles: 0,
                            rewardSoliCarbonOffset:0,
                        })
                      }}
                    name='coins' />}
                  label='BELTA Coins'
                  labelPlacement='end'
                />
                <FormControlLabel
                  className={classes.styled_label}
                  value='end'
                  control={<StyledCheckbox
                    checked={props.mode === 'token'}
                    icon={<UnCheckedBox />}
                    checkedIcon={<CheckedBox />}
                    color='primary'
                    onClick={e => {
                        e.preventDefault();
                        props.toggleMode('token');
                        setReward({
                            rewardCoins: 0,
                            rewardMiles: 0,
                            rewardSoliCarbonOffset:0,
                        })
                        if (!accountConnect) {
                          setPopupConnectWallet(true)
                        }
                      }}
                    name='token' />}
                  label='ETH/BTC'
                  labelPlacement='end'
                />
              </div>
            </div>
          </div>
        </div>
        <div className={classes.reward}>
          <div className={classes.left_hr}></div>
          <div className={classes.data}>
            <span className={classes.content_header}>Reward</span>
            <div className={classes.reward_boxes}>
              <FormControlLabel
                value='end'
                control={
                  <StyledCheckbox
                    icon={<UnCheckedBox />}
                    checkedIcon={<CheckedBox />}
                    color='primary'
                    name='reward_coins'
                    onClick={e => {
                        e.preventDefault();
                        setReward({
                            rewardMiles: 0,
                            rewardCoins: defaultRewardCoins,
                            rewardSoliCarbonOffset:0,
                        });
                        localStorage.setItem('rewardCoins',defaultRewardCoins);
                        localStorage.removeItem('rewardMiles')
                        localStorage.removeItem('rewardCarbonOffset')
                    }}
                    checked={!reward.rewardMiles && reward.rewardCoins || !reward.rewardSoliCarbonOffset && reward.rewardCoins}
                  />}
                label={<span><span className={classes.span_rewards}>{props.formatNumber(defaultRewardCoins)}</span> BELTA Coins</span>}
                labelPlacement='end'
              />
              <FormControlLabel
                value='end'
                control={
                  <StyledCheckbox
                    icon={<UnCheckedBox />}
                    checkedIcon={<CheckedBox />}
                    color='primary'
                    name='reward_miles'
                    onClick={e => {
                        e.preventDefault();
                        setReward({
                            rewardMiles: defaultRewardMiles,
                            rewardCoins: 0,
                            rewardSoliCarbonOffset:0,
                        });
                        localStorage.setItem('rewardMiles',defaultRewardMiles);
                        localStorage.removeItem('rewardCoins')
                        localStorage.removeItem('rewardCarbonOffset')
                    }}
                    checked={reward.rewardMiles && !reward.rewardCoins || reward.rewardMiles && !reward.rewardSoliCarbonOffset}
                  />}
                label={<span><span className={classes.span_rewards}>{props.formatNumber(defaultRewardMiles)}</span> Belta Miles</span>}
                labelPlacement='end'
              />
              <FormControlLabel
                value='end'
                control={
                  <StyledCheckbox
                    icon={<UnCheckedBox />}
                    checkedIcon={<CheckedBox />}
                    color='primary'
                    name='soli_carbon_offset'
                    onClick={e => {
                        e.preventDefault();
                        setReward({
                            rewardMiles: 0,
                            rewardCoins: 0,
                            rewardSoliCarbonOffset: defaultRewardSoli,
                        });
                        localStorage.setItem('rewardCarbonOffset',defaultRewardSoli)
                        localStorage.removeItem('rewardCoins')
                        localStorage.removeItem('rewardMiles')
                    }}
                    checked={reward.rewardSoliCarbonOffset && !reward.rewardCoins || reward.rewardSoliCarbonOffset && !reward.rewardMiles}
                  />}
                label={<span><span className={classes.span_rewards}>{props.formatNumber(defaultRewardSoli)}</span> Carbon Offset</span>}
                labelPlacement='end'
              />
            </div>
          </div>
        </div>
      </div>

      <div className={classes.col_2}>
        <div className={classes.account}>
          <div></div>
          <div className={classes.account_data_div}>
            <span className={classes.content_header}>Account</span>
            <div className={classes.account_text_holder}>
              <span className={classes.account_primary_text}>Passenger Name</span>
              <span className={classes.account_main_text_name}>{propsUser?.display_name}</span>
            </div>
            <div className={classes.account_text_holder}>
              <span className={classes.account_primary_text}>BELTA Coins</span>
              <span className={classes.account_main_text_coin}>{props.formatNumber(valueBalanceCoin)}</span>
            </div>
            <div className={classes.account_text_holder}>
              <span className={classes.account_primary_text}>Belta Miles</span>
              <span className={classes.account_main_text_mile}>{props.formatNumber(valueBalanceMile)}</span>
            </div>
            <div className={classes.account_text_holder}>
              <span className={classes.account_primary_text}>Carbon Offset</span>
              <span className={classes.account_main_text_carbon}>{props.formatNumber(valueBalanceCarbon)}</span>
            </div>
          </div>
        </div>
        <div className={classes.payment}>
          <div className={classes.left_hr}></div>
          <div className={classes.data}>
            <span className={classes.content_header}>Payment</span>
            <div className={[classes.payment_div_text, "first_payment"].join(' ')}>
              <span className={classes.payment_span}>Trip</span>
              <span
                className={classes.payment_span}>
                 {modeFiat}
                 {modeCoins}
                 {modeMiles}
                 {modeToken}
                </span>
            </div>
            <div className={classes.payment_div_text}>
              <span className={classes.payment_span}>Tax</span>
              <span className={classes.payment_span}>{props.mode === 'fiat' ? `\$${fiatTax.toFixed(2)}` : props.mode === 'miles' ? '0' : '0'}</span>
            </div>
            <div className={classes.payment_div_text}>
              <span className={classes.payment_total_span}>Total</span>
              <span
                className={classes.payment_total_span}>
                 {modeFiat}
                 {modeCoins}
                 {modeMiles}
                 {modeToken}
           </span>
            </div>

          </div>
        </div>
        <div className={classes.div_buttons}>
          {
            !accountConnect && !isConnectWallet ? (
              <button className={classes.btn_connect_wallet} onClick={connectWallet}>Connect Wallet</button>
            ) : (
              <button className={classes.btn_disconnect_wallet} onClick={disconnectWallet}>Disconnect Wallet</button>
            )
          }
        </div>
        {
          accountConnect && isConnectWallet && <span style={{ color: 'black', fontSize: 14 }}>Connected to ${formatAddress(accountConnect)}</span>
        } 
        <div className={classes.div_buttons}>
          {!accountConnect && submitBnt}
          {!accountConnect && cancelBnt}
          {accountConnect && ethBtn}
          {accountConnect && btcBtn}
        </div>
      </div>
    </div>
  );
};

export default Boxes;