import React from 'react';

import clsx from 'clsx';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  makeStyles
} from '@material-ui/core';

const useStyles = makeStyles(({
  root: {},
  item: {
    display: 'flex',
    flexDirection: 'column'
  }
}));

const SuperAdmin = ({ className, cancel, ...rest }) => {
  const classes = useStyles();

  return (
    <form
      className={clsx(classes.root, className)}
      {...rest}
    >
      <Card>
        <CardHeader
          subheader="Superadmin Pages"
          title="Superadmin Pages"
        />
        <Divider />
        <CardContent>
        <div onClick={e => {
             e.preventDefault();
             window.open("/sa/users");
             }}> User maintenance </div>
         <Box mt={1}>
         <div onClick={e => {
            e.preventDefault();
            window.open("/sa/companies");
            }}> Company maintenance </div>
         </Box>
        </CardContent>
        <Divider />
      </Card>
    </form>
  );
};


export default SuperAdmin;
