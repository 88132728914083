import React from 'react';
import {makeStyles, withStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ExitToAppRoundedIcon from '@material-ui/icons/ExitToAppRounded';
import AccountCircleOutlinedIcon from '@material-ui/icons/AccountCircleOutlined';
import {Link as RouterLink, useHistory} from 'react-router-dom';
import Avatar from 'src/components/ui/Avatar';
import Settings from "src/icons/Settings";
import {auth} from "src/utils/nhost";
import ShopingCart from '../../icons/user/ShopingCart';
import UserIcon from '../../icons/user/UserIcon';
const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5',
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles((theme) => ({
  root: {
    '&:focus': {
      backgroundColor: theme.palette.primary.main,
      '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
        color: theme.palette.common.white,
      },
    },
  },
}))(MenuItem);
const StyledButton=withStyles(()=>({
  text:{
    padding: '1px 8px 6px 8px',
  }
}))(Button);
const useStyles = makeStyles((theme) => ({
  main_div:{
    marginLeft:24,
    '& svg':{
      marginRight:16,
    }
  },
  wrapper: {
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'column',
    marginLeft: '24px'
  },
  user: {
    fontWeight: '600',
    fontSize: '16px',
    color: '#ffffff',
    whiteSpace: 'nowrap',
    lineHeight: '20px',
    textTransform: 'capitalize'
  },
  role: {
    fontWeight: 'normal',
    fontSize: '14px',
    lineHeight: '20px',
    color: '#FFFFFF',
    opacity: '.6',
    textTransform: 'capitalize'
  },
  icon: {
    fill: '#ffffff',
    color: '#ffffff'
  },

  navbar: {
    color: '#010A21',
    padding: '4px 25px',
    '&:hover': {
      color: '#ffffff',
      backgroundColor: '#00A896',
      borderRadius: '0',
      '& $icon': {
        fill: '#ffffff'
      }
    },
  },
  container: {
    '& .MuiMenu-paper': {
      boxShadow: '0 50px 100px rgb(50 50 93 / 10%), 0 15px 35px rgb(50 50 93 / 15%), 0 5px 15px rgb(0 0 0 / 10%)',
      border: 'none',
      overflow: 'visible',
      top: '72px !important',
      width: 190
    },
    '& .navbarDropdownArrow': {
      position: 'absolute',
      left: '50%',
      top: '-6px',
      margin: '0 0 0 -6px',
      width: '12px',
      height: '12px',
      transform: 'rotate(45deg)',
      borderRadius: '4px 0 0 0',
      backgroundColor: '#fff',
      boxShadow: '-3px -3px 5px rgb(82 95 127 / 4%)'
    },
  },
}));

const DropdownMenu = (props) => {
  const history = useHistory();
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const logoutHandler = () => {
    auth.logout();
    history.push("/login");
  };

  return (
    <div className={classes.main_div}>
      <UserIcon />
      <ShopingCart />
      <StyledMenu
        className={classes.container}
        id="customized-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <div className="navbarDropdownArrow"></div>

        <RouterLink to="/account">
          <StyledMenuItem className={classes.navbar}>
            <ListItemIcon>
              <AccountCircleOutlinedIcon fontSize="small" className={ classes.icon }/>
            </ListItemIcon>
            <ListItemText primary="Account"/>
          </StyledMenuItem>
        </RouterLink>

        <RouterLink to="/settings">
          <StyledMenuItem className={classes.navbar}>
            <ListItemIcon>
              <Settings className={classes.icon}/>
            </ListItemIcon>
            <ListItemText primary="Settings"/>
          </StyledMenuItem>
        </RouterLink>

        <StyledMenuItem className={classes.navbar} onClick={ logoutHandler }>
          <ListItemIcon>
            <ExitToAppRoundedIcon fontSize="small" className={classes.icon}/>
          </ListItemIcon>
          <ListItemText primary="Logout"/>
        </StyledMenuItem>
      </StyledMenu>
    </div>
  )
}

const GetCurrentUser = () => {
  return <DropdownMenu />
};
export default GetCurrentUser;

