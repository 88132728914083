import React,{useEffect} from "react";
import axios from 'axios';
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import CheckoutFormBelta from "src/views/belta/CheckoutFormBelta";
import { ENDPOINT } from "src/utils/constant";


export default function () {

  useEffect(() => {
    const dataInput = localStorage.getItem('dataBelta')
    axios.post(ENDPOINT.STRIPE_URL, JSON.parse(dataInput))
      .then(res => {
        localStorage.setItem('clientSecret',res.data.clientSecret)
        return res.data;
      })
      .catch(error => {
        console.log(error)
    });
  }, []);
  const clientSecretTest = localStorage.getItem('clientSecretTest')
  const clientSecret =  localStorage.getItem('clientSecret') ? localStorage.getItem('clientSecret') : clientSecretTest;
  const publicKey = localStorage.getItem('publicKey')
  const stripePromise =loadStripe(publicKey)
  if (clientSecret === clientSecretTest) {
    setTimeout(() => {
      window.location.reload();
    }, 1000);
  }  
  return (
      <Elements stripe={stripePromise} options ={{clientSecret}} >
      <h1  style={{
          marginTop: '3%',
          textAlign:'center',
          fontFamily:'cursive',
          fontWeight:'800'
          }}
        > Stripe Payment Form</h1>
    <CheckoutFormBelta/>
  </Elements>
  );

}

