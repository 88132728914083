import CoinbaseWalletSDK from "@coinbase/wallet-sdk";
import Web3Modal from "web3modal";
import WalletConnect from "@walletconnect/web3-provider";
const { PROVIDER_OPTION, WEB3MODAL } = require("./constant");
const providerOptions = {
    coinbasewallet: {
      package: CoinbaseWalletSDK,
      options: {
        appName: PROVIDER_OPTION.COINBASE,
        infuraId: PROVIDER_OPTION.INFURA_ID,
        chainId: PROVIDER_OPTION.CHAIN_ID,
      },
    },
    walletconnect: {
      package: WalletConnect, 
      options: {
        infuraId: PROVIDER_OPTION.INFURA_ID,
      }
    }
  };

 export const web3Modal = new Web3Modal({
    // network: WEB3MODAL.SEPOLIA_NETWORK,
    theme: WEB3MODAL.THEME,
    cacheProvider: false,
    providerOptions,
  });