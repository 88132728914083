import React from 'react';
import { Container, makeStyles } from '@material-ui/core';
import Page from 'src/components/Page';
import CompaniesList from './CompaniesList';
import Toolbar from './toolbar/Toolbar';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/client';
import Loading from 'src/components/ui/Loading/Loading';

export const GET_COMPANIES = gql`
   query getListCompanies {
      companies(order_by: {name: asc}, where: {is_active: {_eq: true}}) {
         id
         slug
         name
         co_type_id
         location_id
         co_phone
         co_contact_name
         co_contact_phone
         co_contact_email
      }
      company_types {
        id
        co_type
      }
}`;

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  progress: {}
}));

const CompanyView = (props) => {

  const classes = useStyles();
  return (
    <Page
      className={classes.root}
      title='DropdownMenu & Companies | Procision'
    >
      <Container maxWidth={false}>
        <Toolbar />
        <CompaniesList companies={props.companies} companyTypes={props.companyTypes} />
      </Container>
    </Page>
  );
};

const GetListCompanies = () => {
  const classes = useStyles();
  const { loading, error, data } = useQuery(GET_COMPANIES, {
    'fetchPolicy': 'no-cache'
  });
  if (loading) {
    return <Loading />;
  }

  if (error) {
    return <div className={classes.error}>Error!<br /> Something went wrong</div>;
  }

  return <CompanyView companies={data.companies} companyTypes={data.company_types} />;
};

export default GetListCompanies;

