import React from 'react';

const UnCheckedBox = (props) => {
  return (
    <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="1" y="1" width="24" height="24" rx="2" stroke="#000000"/>
    </svg>

  );
};

export default UnCheckedBox;
