import React, { useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Avatar, Box, Card, Table, TableBody, TableCell, TableHead, TableRow, makeStyles} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import Pagination from '@material-ui/lab/Pagination';
import Trash from 'src/icons/content/Trash'
import Edit from 'src/icons/content/Edit'

const useStyles = makeStyles((theme) => ({
    root: {
        borderRadius: '0',
        boxShadow: 'none'
    },
    icon: {
        margin: '0 5px',
        cursor: 'pointer',
        '&:hover': {
            '& $path': {
                stroke: '#00A896'
            }
        }
    }
}));

const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: "transparent",
        borderBottom: 'none',
        fontWeight: 'bold',
        color: '#010A21',
    },
    body: {
        fontSize: 14,

    },
}))(TableCell);

const StyledTableBody = withStyles((theme) => ({
    root: {
        backgroundColor: '#F0F4FC',
        borderRadius: '16px',

        '& .MuiTableCell-body': {
            borderBottom: 'none',
            color: '#010A21',
        },

        '& .MuiTableRow-hover':{
            '&:first-child':{
                '& .MuiTableCell-body': {
                    '&:first-child':{borderTopLeftRadius: '16px'},
                    '&:last-child':{borderTopRightRadius: '16px'}
                },
            },
            '&:last-child':{
                '& .MuiTableCell-body': {
                    '&:first-child':{ borderBottomLeftRadius: '16px'},
                    '&:last-child':{ borderBottomRightRadius: '16px'}
                },
            }
        },
        '& .MuiTableRow-hover:not(:last-child)': {
            borderBottom: '1px solid #D7DCE8'
        }
    },
}))(TableBody);


const Results = ({ className, companies, companyTypes, ...rest }) => {


    const [products, setProducts] = useState(null);
    const classes = useStyles();
    const [selectedUserIds, setSelectedUserIds] = useState([]);
    const [limit, setLimit] = useState(10);
    const [page, setPage] = useState(0);

    const handleSelectAll = (event) => {
        let newSelectedUserIds;

        if (event.target.checked) {
            newSelectedUserIds = companies.map((user) => user.id);
        } else {
            newSelectedUserIds = [];
        }

        setSelectedUserIds(newSelectedUserIds);
    };

    const handleSelectOne = (event, id) => {
        const selectedIndex = selectedUserIds.indexOf(id);
        let newSelectedUserIds = [];

        if (selectedIndex === -1) {
            newSelectedUserIds = newSelectedUserIds.concat(selectedUserIds, id);
        } else if (selectedIndex === 0) {
            newSelectedUserIds = newSelectedUserIds.concat(selectedUserIds.slice(1));
        } else if (selectedIndex === selectedUserIds.length - 1) {
            newSelectedUserIds = newSelectedUserIds.concat(selectedUserIds.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelectedUserIds = newSelectedUserIds.concat(
                selectedUserIds.slice(0, selectedIndex),
                selectedUserIds.slice(selectedIndex + 1)
            );
        }

        setSelectedUserIds(newSelectedUserIds);
    };

    const handleLimitChange = (event) => {
        setLimit(event.target.value);
    };

    const handlePageChange = (event, newPage) => {
        setPage(newPage);
    };
    const getCompanyTypeName = (id) => {
        return companyTypes.find(x => x.id === id).co_type
    }

    const StyledPagination = withStyles({
        root: {
            display: 'flex',
            justifyContent: 'flex-end',
            marginTop: '16px',
            '& ul li button': {
                fontSize: '13px',
                margin: 0,
                minWidth: '25px',
                maxHeight: '25px',
            },
            '& .Mui-selected': {
                color: '#00A896',
                backgroundColor: 'transparent'
            },
            '& .Mui-selected:hover': {
                color: '#ffffff'
            },
        },
    })(Pagination);

    return (
        <Card
            className={clsx(classes.root, className)}
            {...rest}
        >
            <PerfectScrollbar>
                <Box>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <StyledTableCell>
                                    Name
                                </StyledTableCell>
                                <StyledTableCell>
                                    Type
                                </StyledTableCell>
                                <StyledTableCell>
                                    Phone
                                </StyledTableCell>
                                <StyledTableCell>
                                    Contact Name
                                </StyledTableCell>
                                <StyledTableCell>
                                    Contact email
                                </StyledTableCell>
                                <StyledTableCell>
                                    Contact phone
                                </StyledTableCell>
                                <StyledTableCell>
                                    Actions
                                </StyledTableCell>
                            </TableRow>
                        </TableHead>

                        <StyledTableBody>
                            {companies.slice(0, limit).map((company) => (
                                <TableRow
                                    hover
                                    key={company.id}
                                    selected={selectedUserIds.indexOf(company.id) !== -1}
                                >
                                    <TableCell>
                                        {company.name}
                                    </TableCell>
                                    <TableCell>
                                        { getCompanyTypeName(company.co_type_id) }
                                    </TableCell>
                                    <TableCell>
                                        {company.co_phone}
                                    </TableCell>
                                    <TableCell>
                                        {company.co_contact_name}
                                    </TableCell>
                                    <TableCell>
                                        {company.co_contact_email}
                                    </TableCell>
                                    <StyledTableCell>
                                        {company.co_contact_phone}
                                    </StyledTableCell>
                                    <TableCell>
                                        <Box alignItems="center" display="flex">
                                            <Edit className={classes.icon}/>
                                            <Trash className={classes.icon}/>
                                        </Box>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </StyledTableBody>
                    </Table>
                </Box>
            </PerfectScrollbar>

            {/*<TablePagination*/}
            {/*  component="div"*/}
            {/*  count={users.length}*/}
            {/*  onChangePage={handlePageChange}*/}
            {/*  onChangeRowsPerPage={handleLimitChange}*/}
            {/*  page={page}*/}
            {/*  rowsPerPage={null}*/}
            {/*  rowsPerPageOptions={[5, 10, 25]}*/}
            {/*/>*/}

            <StyledPagination
                defaultPage={6}
                boundaryCount={2}
                count={20}
                color="primary" />
        </Card>
    );
};

Results.propTypes = {
    className: PropTypes.string,
    companies: PropTypes.array.isRequired
};

export default Results;
