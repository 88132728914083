import DateFnsUtils from '@date-io/date-fns';
import { makeStyles } from "@material-ui/core/styles";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import 'date-fns';
import React from 'react';
import Picker from "src/icons/Picker";

const useStyles = makeStyles((theme) => ({
  picker: {
    width: '342px',
    height: '52px',
    margin: '0',
    '& $formControl': {
      flexDirection: 'row-reverse'
    },
    '& label.Mui-focused': {
      color: '#010A21',
    },
    '& .MuiInput-underline': {
      flexDirection: 'row-reverse',
      marginTop: '6px',
      paddingBottom: '10px',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: '#010A21',
    },
    '& .MuiInputAdornment-positionEnd': {
      marginLeft: '-10px'
    },
    '& .MuiInput-underline:before': {
      borderBottomColor: '#010A21',
    },
    '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
      borderBottom: '2px solid #010A21',
    }
  },
  formControl : {
    flexDirection: 'row-reverse',
    marginTop: '6px',
    paddingBottom: '10px'
  },
  root: {
    '& .MuiPopover-paper': {
      top: 69
    },
  },
}));

export default function MaterialUIPickers(props) {
  const [selectedDate, setSelectedDate] = React.useState(new Date());

  const handleDateChange = (date) => {
    if (props.setSelectedDate) props.setSelectedDate(date);
    else setSelectedDate(date)
  };

  const classes = useStyles();

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <KeyboardDatePicker
        keyboardIcon = { <Picker/> }
        disableToolbar
        variant="inline"
        format="MM/dd/yyyy"
        margin="normal"
        id="date-picker-inline"
        label="Select date"
        value={ props.selectedDate || selectedDate }
        onChange={ handleDateChange }
        KeyboardButtonProps={{
          'aria-label': 'change date',
          display: 'flex'
        }}
        inputProps={{
          style: {
            color: '#010A21',
            borderColor: '#010A21',
          },
        }}
        className={ classes.picker }
      />
    </MuiPickersUtilsProvider>
  );
}
