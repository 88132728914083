import { UseCelo } from '@celo/react-celo';
// import Web3 from 'web3';
const Units = require('ethereumjs-units');
const { WALLET } = require('./constant');

export async function sendTransaction(
  performActions: UseCelo['performActions'], amount
) {
  return await performActions(async (k) => {
    // const celo = await k.contracts.getGoldToken();
    const cUSD = await k.contracts.getStableToken();
    const total = Units.convert(amount, 'eth', 'wei');
    let tx = await cUSD
      .transfer(
        // impact market contract
        // '0x6c05a57742ccd2c1c0a3c12b302a822f159ac85d',
        WALLET.COMPANY,
        // Web3.utils.toWei(amount, 'wei')
        total
      )
      .sendAndWaitForReceipt({
        from: k.connection.defaultAccount,
        gasPrice: k.connection.defaultGasPrice,
      });
      return tx;
  });
}
