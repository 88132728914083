import React, { useEffect, useState } from 'react';
import { Box, Avatar, MenuItem, makeStyles } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import ArrowDown from 'src/icons/ArrowDown';
import Trash from 'src/icons/Trash';
import Select from './Select'
import Scrollbar from 'react-scrollbars-custom';

const StyledSelect = withStyles(() => ({
  root: {
    width: 110,
    border: '1px solid #DCDCDC',
    padding: '12px 24px',
    borderRadius: 100,
    height: '25%',
    fontSize: 18,
    fontWeight: 'normal',
    position:'relative',
    zIndex:1,
    '&:focus': {
      borderRadius: 100,
      background: '#fff',
      display: 'flex',
      fontFamily: 'Roboto',
      fontSize: 18,
      fontWeight: 'normal',
      position:'relative',
      zIndex:1,
    },



  },
  nativeInput: {}
}))(Select);
const StyledMenuItem = withStyles(() => ({}))(MenuItem);
const StyledAvatar = withStyles(() => ({
  root: {
    width: '95px',
    height: '95px'
  },
  rounded: {
    borderRadius: 12
  }
}))(Avatar);

const useStyles = makeStyles(() => ({
  mainBox : {
    border: '1px solid #DCDCDC',
    borderRadius: 20,
    padding:'16px 24px',
    marginBottom: 12,
    marginRight:10,
    display: 'flex',
    justifyContent: 'space-between',
    '& div span:first-child': {
      fontWeight: 'bold',
      fontSize: 20,
      fontFamily: 'Roboto'
    },
    '& div span:last-child': {
      fontSize: 18,
      color: '#000',
      fontFamily: 'Roboto',
      fontWeight: 'normal'
    }
  },
  buttonRemove: {
    display:'flex',
    width:48,
    height:48,
    alignItems: 'center',
    justifyContent: 'center',
    border:'1px solid #000',
    borderRadius:'100%',
    cursor: 'pointer',
    transition: '.2s all',
    '&:hover': {
      border:'1px solid #FF5F00',
      // '& $path': {
      //   stroke: '#FF5F00'
      // }
    }
  },
  priceBox: {
      justifyContent: 'flex-start'
  },
  rightBox: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems:'center',
    maxWidth:'400px',
    width:'100%'
  },
  leftBox: {
    display: 'flex',
    justifyContent: 'center'
  },
  spanBox: {
    marginLeft: 24,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    width:'100%'
  },
  select:{
    maxWidth:110,
    width:'100%',
    height:48,
    display:'flex',
    '& div svg': {
      position:'relative',
      zIndex:0,
      right:39,
    },
    marginRight:'20px',
    marginLeft:'30px'
  }
}));

const ItemCart = (props) => {
  const classes = useStyles();
  const propsProduct = props?.product ? props.product : null;

  const mediaMatch = window.matchMedia('(max-width: 1440px)');
  const [matches, setMatches] = useState(mediaMatch.matches);

  useEffect(() => {
    const handler = e => setMatches(e.matches);
    mediaMatch.addListener(handler);
    return () => mediaMatch.removeListener(handler);
  });

  const renderPrice = () => {
      return `${props.mode === 'fiat' ? '$' : ''}${price()} ${props.mode === 'points' ? 'Retail Coins' : ''} ${props.mode === 'coins' ? 'ETH' : ''}`;
  }

  const price = () => {
      const quantityMap = props?.productQuantityMap;
      const productQuantity = quantityMap[props.product.id];
      console.log('productQuantity',productQuantity);

      if(props?.mode === 'fiat') {
          return props.product.fiat_list_price ? (productQuantity * (props.product.fiat_list_price / 100)).toFixed(2) : null;
      } else if (props?.mode === 'points') {
          return props.product.list_price_points_tokens ? props.formatNumber(productQuantity * props.product.list_price_points_tokens) : null;
      } else if(props?.mode === 'coins') {
        return props.product.coin_amount ? (productQuantity * (props.product.coin_amount)).toFixed(4) : null;
      }
  }

  const styles = {
    container: isMatches => ({

      padding: isMatches ? '24px 32px 24px 24px !important': '16px 24px !important' ,

    })
  };

  return (
    <div className={classes.mainBox} style={matches?{padding:'24px 32px 24px 24px !important'}:{padding:'16px 24px !important'}}>
      <div className={classes.leftBox}>
        <StyledAvatar variant='rounded' src='' />
        <div className={classes.spanBox}>
          <Box component='span'>
            {propsProduct?.product_description}
          </Box>
          <Box component='span'>
            {props?.size}
          </Box>
        </div>
      </div>
      <div className={classes.rightBox}>
      <Box component='span' className={classes.priceBox}>
          {renderPrice()}
        </Box>
        <Box className={classes.select}>
            <Select updateProductQuantity={props.updateProductQuantity} product={props.product}/>
        </Box>
        <div className={classes.buttonRemove}>
          <Trash />
        </div>
      </div>
    </div>
  );
};

export default ItemCart;