import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import * as serviceWorker from './serviceWorker';
import { NhostAuthProvider, NhostApolloProvider } from "react-nhost";
import { auth } from "src/utils/nhost.js";
import BaseRouter from 'src/routes/BaseRouter';

ReactDOM.render(
  <React.StrictMode>
    <NhostAuthProvider auth={auth}>
      <NhostApolloProvider
        auth={auth}
        gql_endpoint={process.env.REACT_APP_GRAPHQL_URL || `https://poc.procision.co/hasura/v1/graphql`}
      >
        <BaseRouter />
      </NhostApolloProvider>
    </NhostAuthProvider>
  </React.StrictMode>,
  document.getElementById('root')
);


serviceWorker.unregister();
