import React, { useState } from 'react';
import clsx from 'clsx';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  makeStyles,
  Tooltip,
  FormControlLabel,
  Switch
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import Pagination from '@material-ui/lab/Pagination';
import Trash from 'src/icons/content/Trash';
import Edit from 'src/icons/content/Edit';
import View from 'src/icons/content/View';
import moment from 'moment';

import { useMutation } from '@apollo/client';
import gql from 'graphql-tag';
import {GET_TRANSACTIONS} from '../index';

const useStyles = makeStyles((theme) => ({
  root: {
    borderRadius: '0',
    boxShadow: 'none'
  },
  icon: {
    margin: '0 5px',
    cursor: 'pointer',
    '&:hover': {
      '& $path': {
        stroke: '#00A896'
      }
    }
  },
  pagination : {
    float: 'right',
    marginTop: 10
  }
}));
const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: 'transparent',
    fontWeight: 'bold',
    color: '#010A21',
    borderBottom: 'none'
  },
  body: {
    fontSize: 14
  }
}))(TableCell);

const IOSSwitch = withStyles((theme) => ({
  root: {
    width: 48,
    height: 26,
    padding: 0,
    borderRadius: 20,
    backgroundColor: '#fff'
  },
  switchBase: {
    padding: 2,
    margin: 0,
    color: '#31BD00',
    '&$checked': {
      transform: 'translateX(22px)',
      color: '#ffffff',
      '& + $track': {
        backgroundColor: '#ffffff',
        opacity: 1,
      },
      '& .MuiSwitch-thumb': {
        background: '#31BD00',
      },
    },
  },
  thumb: {
    width: 22,
    height: 22,
    background: '#D7DCE8',
    position: 'relative',
    '&:before': {
      content: 'x',
      position: 'absolute',
    }
  },
  track: {
    borderRadius: 14,
    border: '1px solid #D7DCE8',
    backgroundColor: '#ffffff',
    opacity: 1,
    transition: theme.transitions.create(['background-color', 'border']),
  },
  checked: {
  },
  focusVisible: {},
}))(({classes, ...props}) => {
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      {...props}
    />
  );
});

const StyledTableBody = withStyles((theme) => ({
  root: {
    backgroundColor: '#F0F4FC',
    borderRadius: '16px',

    '& .MuiTableCell-body': {
      borderBottom: 'none',
      color: '#010A21'

    },
    '& .MuiTableRow-hover': {
      '&:first-child': {
        '& .MuiTableCell-body': {
          '&:first-child': { borderTopLeftRadius: '16px' },
          '&:last-child': { borderTopRightRadius: '16px' }
        }
      },
      '&:last-child': {
        '& .MuiTableCell-body': {
          '&:first-child': { borderBottomLeftRadius: '16px' },
          '&:last-child': { borderBottomRightRadius: '16px' }
        }
      }
    },
    '& .MuiTableRow-hover:not(:last-child)': {
      borderBottom: '1px solid #D7DCE8'
    }
  }
}))(TableBody);



const DELETE_TRANSACTION = gql`
    mutation deleteTransactionsRewardRedemptionByPk($id: uuid!) {
        delete_transactions_reward_redemption_by_pk(id: $id) {
            id
        }
    }
`;


const TransactionList = ({ className, data, ...rest }) => {
  const classes = useStyles();
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [deleteTransaction] = useMutation(DELETE_TRANSACTION, {
      refetchQueries: [{query: GET_TRANSACTIONS}]
  });

  const StyledPagination = withStyles({
    root: {
      display: 'flex',
      justifyContent: 'flex-end',
      marginTop: '16px',
      '& ul li button': {
        fontSize: '13px',
        margin: 0,
        minWidth: '25px',
        maxHeight: '25px'
      },
      '& .Mui-selected': {
        color: '#00A896',
        backgroundColor: 'transparent'
      },
      '& .Mui-selected:hover': {
        color: '#ffffff'
      }
    }
  })(Pagination);


  const formatDate = (date) => {
    return moment(date).format('MM-DD-YYYY h:mm a')
  }

  const handlePageChange = (e,newPage) => {
    e.preventDefault();
    setPage(newPage);
  };

  return (
        <Card
          className={clsx(classes.root, className)}
          {...rest}
        >
          <PerfectScrollbar>
            <Box>
              <Table>
                <TableHead>
                  <TableRow>
                    <StyledTableCell>
                      Date
                    </StyledTableCell>
                    <StyledTableCell>
                      Type
                    </StyledTableCell>
                    <StyledTableCell>
                      Company
                    </StyledTableCell>
                    <StyledTableCell>
                      Brand
                    </StyledTableCell>
                    <StyledTableCell>
                      Product
                    </StyledTableCell>
                    <StyledTableCell>
                      Fiat
                    </StyledTableCell>
                    <StyledTableCell>
                      Token
                    </StyledTableCell>
                    <StyledTableCell>
                      Amount
                    </StyledTableCell>
                    <StyledTableCell>
                      Cust
                    </StyledTableCell>
                    <StyledTableCell>
                      Actions
                    </StyledTableCell>
                  </TableRow>
                </TableHead>

                <StyledTableBody>
                  {data?.transactions_reward_redemption.slice((page - 1) * limit, page * limit).map((transaction) => (
                    <TableRow
                      hover
                      key={transaction.id}
                    >
                        <TableCell>
                            { formatDate(transaction.xact_datetime_at)}
                        </TableCell>
                        <TableCell>
                            {transaction?.transaction_type ? transaction.transaction_type.name : null}
                        </TableCell>
                        <TableCell>
                            {transaction?.company ? transaction.company.name : null}
                        </TableCell>
                        <TableCell>
                            {transaction?.product ? transaction.product.product_brand : null}
                        </TableCell>
                        <TableCell>
                            {transaction?.product ? transaction.product.product_code : null}
                        </TableCell>
                        <TableCell>
                            {transaction?.fiat_type ? transaction.fiat_type : null}
                        </TableCell>
                        <TableCell>
                            {transaction?.point_token_type ? transaction.point_token_type.name : null}
                        </TableCell>
                        <TableCell>
                        {
                          (transaction?.point_token_amount ? ((transaction.point_token_amount).toFixed(2)) : null)
                        }
                        {
                          (transaction?.coin_type ? (transaction.coin_amount) : null)
                        }
                        {
                          (transaction?.fiat_amount ? ((transaction.fiat_amount / 100).toFixed(2)) : null)
                        }
                        </TableCell>
                        <TableCell>
                            {transaction?.customer ? `...${transaction.customer.id.slice(-4)}` : null}
                        </TableCell>

                        <TableCell>
                            <Box alignItems='center'>
                            <Edit className={classes.icon}  />
                            <View className={classes.icon} />
                            <Trash className={classes.icon} onClick={e => {
                                deleteTransaction({variables: {id: transaction.id}});
                            }
                            }/>
                        </Box>
                      </TableCell>
                    </TableRow>
                  ))}
                </StyledTableBody>
              </Table>
            </Box>
          </PerfectScrollbar>

          <Pagination
            className={classes.pagination}
            defaultPage={1}
            boundaryCount={2}
            onChange={handlePageChange}
            count={Math.round(Number(data?.transactions_reward_redemption.length)/ limit)}
            color='primary' />
        </Card>
  );
};


export default TransactionList;
