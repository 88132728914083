import React from 'react';

const OffRecommend = (props) => {
  return (
    <svg width='28' height='28' viewBox='0 0 28 28' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <rect x='1' y='1' width='26' height='26' rx='13' fill='white' stroke='#D7DCE8' />
      <rect x='3' y='3' width='22' height='22' rx='11' fill='#D7DCE8' />
      <path d='M16 12L12 16' stroke='white' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' />
      <path d='M12 12L16 16' stroke='white' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' />
    </svg>
  );
};

export default OffRecommend;
