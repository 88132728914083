import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { AppBar, Box, Toolbar, makeStyles } from '@material-ui/core';
import DropdownMenu from '../DropdownMenu';
import '../../../layouts/UserLayout/fonts.css';
import SearchInput from '../SearchInput';
import './Tolbar.module.scss'
const useStyles = makeStyles(() => ({
  root: {
    left: '0',
    width: '100%',
    top: 0,
    backgroundColor: '#4CAF50',
    padding: '0 32px 0 48px',
    height: 80,
    justifyContent: 'center',

  },
  avatar: {
    width: 32,
    height: 32
  },
  icon: {
    position: 'absolute',
    top: '20px'
  },
  span: {
    color: '#fff',
    fontSize: 32,
    fontWeight: 'bold',
    fontFamily: 'Roboto'
  },

}));

const TopBar = ({
                  className,
                  onMobileNavOpen,
                  isSuperAdmin,
                  isAdmin,
                  currentUser,
                  currentCompany,
                  ...rest
                }) => {
  const classes = useStyles();

  return (
    <AppBar
      className={clsx(classes.root, className)}
      elevation={0}
      {...rest}
    >
      <Toolbar  disableGutters>
        <Box component='span' flexGrow={1}>
          <span className={classes.span}>Climate token</span>

        </Box>
        <SearchInput />
        <DropdownMenu
          currentUser={currentUser}
          isAdmin={isAdmin}
          isSuperAdmin={isSuperAdmin}
        />
      </Toolbar>
    </AppBar>
  );
};

TopBar.propTypes = {
  className: PropTypes.string,
  onMobileNavOpen: PropTypes.func
};

export default TopBar;

