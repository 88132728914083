import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import './CollapseCard.css';

const CollapseCard = (props) => {
  const [show, setShow] = useState(false);
  const cardEl = useRef(null);
  const cardHeadEl = useRef(null);

  const toggleCard = () => {
    if (show) {
      cardEl.current.style.height = `${cardHeadEl.current.offsetHeight + 6}px`;
    } else {
      cardEl.current.style.height = `100%`;
    }

    setShow(!show);
  };

  useEffect(() => {
    cardEl.current.style.height = `${cardHeadEl.current.offsetHeight + 6}px`;
  }, []);

  return (
    <div className={`CollapseCard ${show}`} ref={cardEl}>
      <div className="CollapseCard-Head" ref={cardHeadEl}>
        <div className="wrap-title">
          <Typography style={{cursor: 'pointer'}} onClick={toggleCard} color="textPrimary" gutterBottom variant="h3">
            {props.title}
          </Typography>
          {/* <Title size="sm" color="dark" isResponsive={true}>
            {props.title}
          </Title> */}
        </div>
        <div className="wrap-arrow" onClick={toggleCard}>
          <span className="arrow">
            <FontAwesomeIcon icon={show ? faChevronUp : faChevronDown} />
          </span>
        </div>
      </div>
      <div className="CollapseCard-Body">{props.children}</div>
    </div>
  );
};

CollapseCard.propTypes = {
  title: PropTypes.string.isRequired
};

export default CollapseCard;
