import {
  Alfajores,
  CeloProvider,
  SupportedProviders,
  // Theme,
  UseCelo,
  useCelo,
} from '@celo/react-celo';
import "@celo/react-celo/lib/styles.css";
import { useCallback, useEffect, useState } from "react";
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { Box } from '@material-ui/core';
import { sendTransaction } from 'src/utils/celoAction';

import Web3 from 'web3';
import TOKEN_ABI from '../utils/celoToken.abi.json';
// const CONTRACT_ADDRESS = '0x6c05a57742ccd2c1c0a3c12b302a822f159ac85d';
// const CLIMATE_TOKEN_TRANSFER = '0x59ee0992af398a7DAD18fB5c591927A31C434eD4';
import { CLIMATE_TOKEN_TRANSFER } from '../utils/constant';
import { getNftOfAddress } from 'src/utils/handleApi';
const Units = require('ethereumjs-units');

export async function getClimeBalance(walletAddress: string) {
  return getTokenBalance(walletAddress, CLIMATE_TOKEN_TRANSFER);
}

async function getTokenBalance(walletAddress: string, tokenAddress: string) {
  const celoChain = new Web3(
    new Web3.providers.HttpProvider("https://celo-alfajores.infura.io/v3/fee98de9651149e8910cc308598861e4")
  );

  const contract = new celoChain.eth.Contract(TOKEN_ABI, tokenAddress);
  const balance = await contract.methods.balanceOf(walletAddress).call();
  const total = Units.convert(balance, 'wei', 'eth');

  return total;
}

const WC_PROJECT_ID = process.env.WC_PROJECT_ID || "b36e3d94cc4e0f99f04a3c9c85ab71e4";

const useStyle = makeStyles(() => ({
  hr: {
    border: 'none',
    height: 1,
    background: '#DCDCDC',
    margin: '20px 0px'
  },
  compact_hr: {
    border: 'none',
    height: 1,
    background: '#DCDCDC',
    margin: '15px 0px'
  },
  styled_label: {
    marginLeft: 0,
    marginBottom: 22,
    display: 'flex',
    alignItems: 'center',
    '&:last-child': {
      marginBottom: 0,
    },
    '& span div': {
      display: 'flex',
      alignItems: 'center',
      '& div svg': {
        marginRight: 16
      }
    }
  },
  payment_div_text: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  payment_span_2: {
    fontFamily: 'Roboto',
    fontSize: 18,
    fontWeight: 'bold',
    '&:first-child': {
      color: '#000000'
    }
  },
  payment_span: {
    fontFamily: 'Roboto',
    fontSize: 18,
    '&:first-child': {
      color: '#000000'
    }
  },
  total_div: {
    width: '100%',
  },
  cancel_btn: {
    padding: '8px 24px',
    marginLeft: '8px',
    border: 'none',
    background: 'none',
    fontFamily: 'Open Sans SemiBold',
    fontSize: 18,
    height: 44,
    '&:hover': {
      cursor: 'pointer'
    }
  },
  div_btns: {
    display: 'flex',
    marginTop: 5,
    // alignItems:'center',
    // flexDirection:'column',
    // padding:'0px 32px',
  },
  div_btn_wallet: {
    display: 'flex',
    marginTop: 5,
  },
  connect_btn: {
    borderRadius: 100,
    padding: '10px',
    height: 44,
    border: '1px solid #000',
    background: '#4CAF50',
    fontSize: 18,
    width: '60%',
    textTransform: 'capitalize',
    marginRight: 5,
    marginLeft: 5,
    color: '#fff',
  },

  disconnect_btn: {
    borderRadius: 100,
    padding: '10px',
    height: 44,
    border: '1px solid #000',
    background: '#4CAF50',
    fontSize: 18,
    width: '60%',
    textTransform: 'capitalize',
    marginRight: 5,
    marginLeft: 5,
    marginBottom: 5,
    color: '#fff',
  },
  payment_options_button: {
    padding: '8px 24px',
    color: '#fff',
    border: 'none',
    background: 'black',
    borderRadius: 4,
    fontFamily: 'Open Sans SemiBold',
    fontSize: 18,
    height: 44,
    borderRadius: 100,
    marginLeft: 5,
    width: '60%',
    '&:hover': {
      cursor: 'pointer'
    }
  },

}));

const CeloWalletConnect = (props) => {
  const {
    // kit,
    connect,
    disconnect,
    address,
    performActions,
    // network,
    // supportsFeeCurrency,
    // walletType,
    // feeCurrency,
    // updateFeeCurrency,
  } = useCelo();
  const [isCeloConnected, setCeloConnected] = useState(false);
  const [accountConnected, setAccountConnected] = useState('');

  const classes = useStyle();

  useEffect(() => {
    if (!address) {
      return;
    }
    setCeloConnected(true);
    setAccountConnected(address);
    localStorage.setItem('celo_account', address);
    localStorage.setItem('isCeloConnected', true);
    getAccountSummary();
  }, [address]);

  const formatAddress = (address) => {
    if (address) {
      const add1 = address.substring(0, 4);
      const add2 = address.substring(address.length - 4);
      const finalAddress = `${add1}...${add2}`;
      return finalAddress;
    }
  };

  async function getAccountSummary() {
    const balance = await getTokenBalance(address, CLIMATE_TOKEN_TRANSFER);
    await localStorage.setItem('CLIME_balance', balance);
    if (props?.updateClime) {
      const res = await getNftOfAddress(localStorage.getItem('react-celo/last-used-address'));
      let countNft = 0;
      if (res.data) {
        res?.data.forEach(element => {
          countNft += element?.metadata?.length;
        });
      }
      props?.updateClime(countNft); 
    }
  }

  const ConnectButton = <Button className={classes.connect_btn}
    onClick={async(e) => {
      e.preventDefault();
      try {
        await connect();
        // console.log(`Connected to Celo: ${address}`);
        // setCeloConnected(true);
        // setAccountConnected(address);
        localStorage.setItem('celo_account', address);
        // localStorage.setItem('isCeloConnected', true);
        if (props?.connect)
          props?.connect();
      } catch (error) {
        console.log(`Connect error: ${error}`);
      }
    }}
  >Connect Wallet</Button>

  const DisconnectButton = <Button
    className={classes.disconnect_btn}
    onClick={async (e) => {
      e.preventDefault();
      await disconnect();
      setCeloConnected(false);
      setAccountConnected('');
      // localStorage.setItem('celo_account', '');
      localStorage.removeItem('celo_account');
      localStorage.setItem('isCeloConnected', false);
      localStorage.removeItem('CLIME_balance');
      if (props?.updateClime)
        props?.updateClime('N/A');
      if (props?.disconnect)
        props?.disconnect();
    }}
  >Disconnect Wallet</Button>

  const PayByCUSD =
    <button
      className={classes.payment_options_button}
      onClick={async (e) => {
        e.preventDefault();
        // const totalcUSD = props?.totalcUSD/100;
        const totalcUSD = 0.01;
        let tx = await sendTransaction(performActions, totalcUSD);
        console.log(tx);
        props?.payBycUSD(e, tx[0]?.transactionHash);
      }}
    >Buy With cUSD</button>;

  return(
    <>
      <Box className={classes.div_btn_wallet}>
        {
          !isCeloConnected ? (
            ConnectButton
          ) : (
            DisconnectButton
          )
        }
      </Box>
      <Box style={{ marginBottom: 5, marginLeft: 5 }}>
        {
          isCeloConnected &&
          accountConnected &&
          <span style={{ color: 'black', fontSize: 14 }}>Connected to {formatAddress(address)}</span>
        }
      </Box>
      <Box className={classes.div_btns}>
        { !props?.connectOnly && PayByCUSD }
      </Box>
    </>
  );
};

const WrappedCeloWalletConnect = (props): React.ReactElement =>  {
  const [isMounted, setIsMounted] = useState(false);

  const defaultDark = {
    primary: '#6366f1',
    secondary: '#eef2ff',
    text: '#ffffff',
    textSecondary: '#cbd5e1',
    textTertiary: '#64748b',
    muted: '#334155',
    background: '#1e293b',
    error: '#ef4444',
  };

  useEffect(() => {
    setIsMounted(true);
  }, []);

  return(
    <CeloProvider
      dapp={{
        name: 'Procision Shopping Cart',
        description: 'A connection to Celo Wallet Extension',
        url: 'https://react-celo.vercel.app',
        icon: 'https://react-celo.vercel.app/favicon.ico',
        walletConnectProjectId: WC_PROJECT_ID,
      }}
      theme={defaultDark}
      defaultNetwork={Alfajores.name}
      connectModal={{
        // This options changes the title of the modal and can be either a string or a react element
        title: <span style={{ color: '#cbd5e1' }}>Connect your Wallet</span>,
        providersOptions: {
          // This option hides specific wallets from the default list
          hideFromDefaults: [
            // SupportedProviders.CeloExtensionWallet,
            SupportedProviders.MetaMask,
            SupportedProviders.PrivateKey,
            SupportedProviders.Valora,
            SupportedProviders.CeloTerminal,
            SupportedProviders.CeloWallet,
            SupportedProviders.CeloDance,
            SupportedProviders.Injected,
            SupportedProviders.Ledger,
            SupportedProviders.MetaMask,
            SupportedProviders.WalletConnect,
            // backwards compatibility
            SupportedProviders.Steakwallet,
            SupportedProviders.Omni,
            SupportedProviders.CoinbaseWallet,
          ],

          // This option hides all default wallets
          // hideFromDefaults: true,
          // additionalWCWallets: [],

          // This option toggles on and off the searchbar
          // searchable: true,
        },
      }}
    >
      {
        isMounted &&
        <CeloWalletConnect
          totalcUSD={props?.totalcUSD}
          payBycUSD={props?.payBycUSD}
          connectOnly={props?.connectOnly}
          updateClime={props?.updateClime}
          connect={props?.connect}
          disconnect={props?.disconnect}/>
      }
    </CeloProvider>
  );
};

export default WrappedCeloWalletConnect;