import React, { useState } from 'react';
import { useHistory } from "react-router-dom";
import clsx from 'clsx';
import Error from 'src/icons/alerts/Error';
import Success from 'src/icons/alerts/Success';
import {GET_TRANSACTIONS} from 'src/views/history-and-transactions';
import CircularProgress from '@material-ui/core/CircularProgress';
import moment from 'moment';
import {
  Box,
  Button,
  Card,
  CardHeader,
  Divider,
  List,
  ListItem,
  ListItemText,
  makeStyles,
  Avatar
} from '@material-ui/core';
import { useQuery } from '@apollo/client';
import { flexbox } from '@material-ui/system';

const alerts = [
  {
    types: 'error',
    description: 'A transaction error has occurred.',
    details: 'Edit here',
    imageUrl: <Error />
  },
  {
    types: 'success',
    description: 'The transaction was successful.',
    details: 'View details',
    imageUrl: <Success />
  },
  {
    types: 'error',
    description: 'A transaction error has occurred.',
    details: 'Edit here',
    imageUrl: <Error />
  },
  {
    types: 'success',
    description: 'The transaction was successful.',
    details: 'View details',
    imageUrl: <Success />
  },
  {
    types: 'error',
    description: 'A transaction error has occurred.',
    details: 'Edit here',
    imageUrl: <Error />
  },
  {
    types: 'success',
    description: 'The transaction was successful.',
    details: 'View details',
    imageUrl: <Success />
  }
];
const useStyles = makeStyles(({
  root: {
    marginBottom:16,
    boxShadow: 'none',
    '& .MuiCardHeader-root':{
      padding:'0 16px 16px 0',
    }
  },
  image: {
    height: 48,
    width: 48
  },
  label: {
    color: '#6B799D',
    textDecoration: 'underline',
    fontFamily: 'Open Sans',
    fontSize: 14,
    padding: 0,
    textTransform: 'capitalize',
    '&:hover': {
      textDecoration: 'underline'
    }
  },
  list: {
    marginTop: '16px',
    borderRadius: '12px'
  },
  list_item: {
    padding: '8px 8px 8px 0'
  },
  hide_all: {
    height: '150px',
    overflow: 'hidden',
    opacity: 0
  },
  list_item_text: {
    color: '#000',
    margin:'8px 0',
  },
  list_item_text_flex: {
    paddingLeft: '10px',
    paddingRight: '10px',
    paddingTop: '5px',
    justifyContent: 'space-between',
    display: 'flex',
  },
  list_item_text_secondary: {
    paddingTop: '2.5px',
    paddingBottom: '5px',
    color: 'rgb(1,10,33,0.64)',
  },
  error_item: {
    background: 'rgba(206, 12, 12, 0.05)',
    borderRadius: 8,
    padding: 0,
    height: 50,
    marginBottom:8,
    '& span': {
      fontFamily: 'Open Sans SemiBold',
      fontSize: 13,
      color: '#CE0C0C',
      textTransform: 'capitalize'
    },
    '& div:first-child':{
      width:4,
      background:'#CE0C0C',
      height:'70%',
      borderRadius:2,
    },
    '& p span': {
      textTransform: 'none',
      fontFamily: 'Open Sans Regular',
      fontSize: 13,
      color: 'rgb(1,10,33,0.64)',
      display: 'flex',
      alignItems: 'center'
    }
  },
  success_item: {
    background: 'rgba(49, 189, 0, 0.05)',
    borderRadius: 8,
    padding: 0,
    marginBottom:8,
    height: 50,
    '& span': {
      fontFamily: 'Open Sans SemiBold',
      fontSize: 13,
      color: '#31BD00',
    },
    '& div:first-child':{
      width:4,
      background:'#31BD00',
      height:'100%',
      borderRadius:2,
    },
    '& p span': {
      textTransform: 'none',
      fontFamily: 'Open Sans Regular',
      fontSize: 13,
      color: 'rgb(1,10,33,0.64)',
      display: 'flex',
      alignItems: 'center'
    }
  },
  avatar: {
    width: 18,
    background: 'none',
    margin:'0px 8px',
    height: 18
  },

}));

const Notification = ({ className, ...rest }) => {
  const [isSeeAll, setIsSeeAll] = useState(false);
  const classes = useStyles();
  const [alert] = useState(alerts);
  const history = useHistory();

  const handleIsSeeAll = () => {
    setIsSeeAll(!isSeeAll);
  };

  const {loading, error, data} = useQuery(GET_TRANSACTIONS);

  if (loading) {
    return <CircularProgress />
  }

  if (error) {
    return <div className={ classes.error }>Error!<br /> Something went wrong</div>;
  }

  if (data) {
    const transactions = data?.transactions_reward_redemption;
    return (
        <Card
          className={clsx(classes.root, className)}
          {...rest}
        >
          <CardHeader
            subtitle={`${alert.length} in total`}
            className={classes.card_header}
            title={
              <Box
                display='flex'
                justifyContent='space-between'
                alignItems='center'
              >
                <Box component='span' fontFamily='Open Sans' fontSize='24px'>Notifications</Box>
                <Button
                  className={classes.label}
                  size='small'
                  variant='text'
                  onClick={handleIsSeeAll}
                >
                  {(isSeeAll ? 'Hide all' : 'See all')}
                </Button>
              </Box>}
          />
          <Divider />
          <List className={classes.list}>
            {transactions.slice(0, 10).map((transaction, i) => (
                (isSeeAll || i < 2 ?
                    <ListItem className={classes.success_item}>
                        <Box className={classes.success_divider}/>
                        <Avatar className={classes.avatar} variant='circular'>
                            <Success />
                        </Avatar>
                        <ListItemText
                            primary={
                                <span className={`${classes.list_item_text_flex}`}>
                                    <span>Success</span>
                                    <span></span>
                                    <span>{`${moment(transaction.xact_datetime_at).format('MM-DD-YYYY h:mm a')}`}</span>
                                </span>
                            }
                            className={classes.list_item_text}
                            secondary={
                                <span className={`${classes.list_item_text_secondary} ${classes.list_item_text_flex}`}>
                                    <span>
                                        {` ...${transaction?.customer?.id.slice(-4)}`}
                                    </span>
                                    <span>
                                        {`${transaction?.product?.product_brand} | ${transaction?.product?.product_code}`}
                                    </span>
                                    <Button
                                        className={classes.label}
                                        size='small'
                                        variant='text'
                                        onClick={e => {
                                            e.preventDefault();
                                            history.push('/history-and-transactions');
                                        }}
                                    >
                                        View All
                                    </Button>
                                </span>
                            }
                        />
                    </ListItem> : null)
                ))}
          </List>
        </Card>
      );
  }
};

export default Notification;
