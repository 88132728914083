import React from 'react';

const Dashboard = (props) => {
  return (
    <svg {...props} width="16" height="16" viewBox="0 0 16 16" fill="#B9C2D9" xmlns="http://www.w3.org/2000/svg">
      <g>
        <path d="M6.16663 5.33337H1.16663C0.523315 5.33337 0 4.81006 0 4.16663V1.16663C0 0.523315 0.523315 0 1.16663 0H6.16663C6.81006 0 7.33337 0.523315 7.33337 1.16663V4.16663C7.33337 4.81006 6.81006 5.33337 6.16663 5.33337ZM1.16663 1C1.07471 1 1 1.07471 1 1.16663V4.16663C1 4.25867 1.07471 4.33337 1.16663 4.33337H6.16663C6.25867 4.33337 6.33337 4.25867 6.33337 4.16663V1.16663C6.33337 1.07471 6.25867 1 6.16663 1H1.16663Z" />
        <path d="M6.16663 16H1.16663C0.523315 16 0 15.4767 0 14.8334V7.83338C0 7.18995 0.523315 6.66663 1.16663 6.66663H6.16663C6.81006 6.66663 7.33337 7.18995 7.33337 7.83338V14.8334C7.33337 15.4767 6.81006 16 6.16663 16ZM1.16663 7.66663C1.07471 7.66663 1 7.74134 1 7.83338V14.8334C1 14.9253 1.07471 15 1.16663 15H6.16663C6.25867 15 6.33337 14.9253 6.33337 14.8334V7.83338C6.33337 7.74134 6.25867 7.66663 6.16663 7.66663H1.16663Z" />
        <path d="M14.643 16H9.64296C8.99953 16 8.47621 15.4767 8.47621 14.8334V11.8334C8.47621 11.19 8.99953 10.6666 9.64296 10.6666H14.643C15.2863 10.6666 15.8096 11.19 15.8096 11.8334V14.8334C15.8096 15.4767 15.2863 16 14.643 16ZM9.64296 11.6666C9.55092 11.6666 9.47621 11.7414 9.47621 11.8334V14.8334C9.47621 14.9253 9.55092 15 9.64296 15H14.643C14.7349 15 14.8096 14.9253 14.8096 14.8334V11.8334C14.8096 11.7414 14.7349 11.6666 14.643 11.6666H9.64296Z" />
        <path d="M14.643 9.33337H9.64296C8.99953 9.33337 8.47621 8.81006 8.47621 8.16663V1.16663C8.47621 0.523315 8.99953 0 9.64296 0H14.643C15.2863 0 15.8096 0.523315 15.8096 1.16663V8.16663C15.8096 8.81006 15.2863 9.33337 14.643 9.33337ZM9.64296 1C9.55092 1 9.47621 1.07471 9.47621 1.16663V8.16663C9.47621 8.25867 9.55092 8.33337 9.64296 8.33337H14.643C14.7349 8.33337 14.8096 8.25867 14.8096 8.16663V1.16663C14.8096 1.07471 14.7349 1 14.643 1H9.64296Z" />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Dashboard;
