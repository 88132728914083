import React  from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { AppBar, Box, Toolbar, makeStyles } from '@material-ui/core';
import DropdownMenu from './DropdownMenu';
import 'src/layouts/UserLayout/fonts.css'

const useStyles = makeStyles(() => ({
  root: {
    left: '0',
    width: '100%',
    top: 0,
    backgroundColor: '#010A21',
    padding:'0px 12px',
    minHeight:80,
    justifyContent:'center',
  },
  avatar: {
    width: 32,
    height: 32
  },
  icon: {
    position: 'absolute',
    top: '20px'
  },
  left_span:{
    color:'white',
    fontFamily:'Open Sans SemiBold',
    fontSize:36,
    paddingLeft:24,
  },
  right_span:{
    color:'white',
    fontFamily:'Open Sans LightItalic',
    fontSize:36
  }
}));

const TopBar = ({
  className,
  onMobileNavOpen,
  isSuperAdmin,
  isAdmin,
  currentUser,
  currentCompany,
  formatNumber,
  ...rest
}) => {
  const classes = useStyles();

  return (
    <AppBar
      className={clsx(classes.root, className)}
      elevation={0}
      {...rest}
    >
      <Toolbar disableGutters>
        <Box component="div" flexGrow={1} >
          <span className={classes.left_span}>Belta</span>
          <span className={classes.right_span}> Airlines Checkout</span>
        </Box>
        <DropdownMenu
            formatNumber={formatNumber}
          currentUser={currentUser}
          isAdmin={isAdmin}
          isSuperAdmin={isSuperAdmin}
          users={rest?.users}
        />
      </Toolbar>
    </AppBar>
  );
};

TopBar.propTypes = {
  className: PropTypes.string,
  onMobileNavOpen: PropTypes.func
};

export default TopBar;

