import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { auth } from "../utils/nhost";
import './Login.css';
import { Col, Layout, Row, Typography, Button } from "antd";
import { Link } from "react-router-dom";
import Page from './Page';

const { Header, Content, Footer } = Layout;
const { Text } = Typography;

const H3 = (props) => (
  <Typography.Title level={3} {...props} />
)

export const contentMinHeight = "calc(100vh - 64px - 70px)";

const LoginInner = ( props ) => {
  const [userData, setUserData] = useState({ email: '', password: '', error: '' });
  const history = useHistory();

  async function handleSubmit (event) {
    event.preventDefault()
    setUserData({
      ...userData,
      error: ''
    })


    const { email, password } = userData;

    try {
      await auth.login(email, password);
      history.push("/");
    } catch (error) {
      console.error(
        'You have an error in your code or there are Network issues.',
        error
      )

      const { response } = error
      setUserData(
        Object.assign({}, userData, {
          error: response ? response.statusText : error.message
        })
      )
    }
  }
  return (
    <div className="login">
        <form onSubmit={handleSubmit}>
          <input
            type='text'
            id='email'
            name='email'
            value={userData.email}
            placeholder='Email'
            onChange={event =>
              setUserData({
                ...userData,
                email: event.target.value
              })
            }
          />
          <input
            type='password'
            id='password'
            name='password'
            value={userData.password}
            placeholder='Password'
            onChange={event =>
              setUserData({
                ...userData,
                password: event.target.value
              })
            }
          />

          <Button
            type="primary"
            htmlType="submit"
            data-cy="loginpage-button-submit"
            style={{backgroundColor: '#010A21'}}
          >
            Login
          </Button>

          {userData.error && <p className='error'>Error: {userData.error}</p>}
        </form>
        <Link to="/register"> Don't have an account? Sign up </Link>
        <br/>
        <Link to="/forgot-password"> Forgot password?</Link>
    </div>
  );
};

export default function () {
  const title = "Login";
  return (
    <Page
      title="Login | Procision"
    >
    <Layout>
       <Header
          style={{
            boxShadow: "0 2px 8px #f0f1f2",
            backgroundColor: '#010A21',
            zIndex: 1,
            paddingLeft: '20px',
            paddingRight: '20px',
            overflow: "hidden",
          }}
        >
        <Row justify="space-between">
          <Col span={6}>
            <Link to="/"><img src="/static/icons/logo.svg" style={{maxWidth: '100px'}}></img></Link>
          </Col>
          <Col span={12}>
            <H3
              style={{
                margin: 0,
                padding: 0,
                textAlign: "center",
                lineHeight: "64px",
                color: '#fff'
              }}
              data-cy="layout-header-title"
            >
                { title }
            </H3>
          </Col>
          <Col span={6} style={{ textAlign: "right" }} />
          </Row>
       </Header>
        <Content style={{ minHeight: contentMinHeight, paddingTop: '100px'}}>
          <LoginInner />
        </Content>
      <Footer>
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "space-between"
          }}
        >
          <Text>
            Copyright &copy; {new Date().getFullYear()} {"Aquire"}. All
            rights reserved.
          </Text>
        </div>
      </Footer>
    </Layout>
    </Page>
  )
}

