import React from 'react';

const ApiDocumentation = (props) => {
  return (
    <svg {...props} width="16" height="16" viewBox="0 0 16 16" fill="#B9C2D9" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14.5938 2.29877H1.40625C0.630844 2.29877 0 2.92961 0 3.70502V12.295C0 13.0705 0.630844 13.7013 1.40625 13.7013H14.5938C15.3692 13.7013 16 13.0705 16 12.295V3.70502C16 2.92958 15.3692 2.29877 14.5938 2.29877ZM15.0625 12.295C15.0625 12.5535 14.8522 12.7638 14.5938 12.7638H1.40625C1.14778 12.7638 0.9375 12.5535 0.9375 12.295V5.56142H15.0625V12.295ZM15.0625 4.62392H0.9375V3.70502C0.9375 3.44655 1.14778 3.23627 1.40625 3.23627H14.5938C14.8522 3.23627 15.0625 3.44655 15.0625 3.70502V4.62392Z"
      />

      <path
        d="M5.36689 6.78949C4.53098 6.78949 3.85089 7.46958 3.85089 8.30549V10.3511C3.85089 10.61 4.06077 10.8199 4.31964 10.8199C4.57851 10.8199 4.78839 10.61 4.78839 10.3511V9.46255H5.94535V10.3511C5.94535 10.61 6.15523 10.8199 6.4141 10.8199C6.67298 10.8199 6.88285 10.61 6.88285 10.3511V8.30549C6.88288 7.46958 6.20279 6.78949 5.36689 6.78949ZM5.94539 8.52508H4.78842V8.30552H4.78839C4.78839 7.98652 5.04792 7.72702 5.36689 7.72702C5.68585 7.72702 5.94539 7.98652 5.94539 8.30552V8.52508Z"
      />

      <path
        d="M9.22672 6.78949H8C7.74112 6.78949 7.53125 6.99936 7.53125 7.25824V10.3511C7.53125 10.61 7.74112 10.8199 8 10.8199C8.25888 10.8199 8.46875 10.61 8.46875 10.3511V9.46255H9.22672C9.96369 9.46255 10.5633 8.86296 10.5633 8.12599C10.5633 7.38905 9.96369 6.78949 9.22672 6.78949ZM9.22672 8.52508H8.46875V7.72702H9.22672C9.44675 7.72702 9.62578 7.90605 9.62578 8.12608C9.62578 8.34615 9.44675 8.52508 9.22672 8.52508Z"
      />

      <path
        d="M11.6804 6.78949C11.4215 6.78949 11.2116 6.99936 11.2116 7.25824V10.3511C11.2116 10.61 11.4215 10.8199 11.6804 10.8199C11.9392 10.8199 12.1491 10.61 12.1491 10.3511V7.25824C12.1491 6.99936 11.9392 6.78949 11.6804 6.78949Z"
      />
    </svg>
  );
};

export default ApiDocumentation;
