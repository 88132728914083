import React from 'react';
import ImageUploader from 'react-images-upload';
import classes from './ImageUploader.module.css'

const ImagesUpload = ({ onChildClick }) => {
  return (
    <ImageUploader
      singleImage={true}
      withIcon={true}
      onChange={(picture) => onChildClick(picture)}
      buttonText='Upload picture'
      imgExtension={['.jpg', '.png', '.jpeg']}
      maxFileSize={5242880}
      className={classes.ImageUploaderButton}
    />
  );
}

export default ImagesUpload
